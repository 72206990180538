<template>
    <div :key="componentKey" class="navigation">
        <div id="left">
            <slot name="left">
                <div v-if="checkRoute && loggedIn && pendingTipsCount > 0 && pendingAnswersCount === 0" class="indicator">{{ pendingTipsCount }}</div>
                <div v-else-if="checkRoute && loggedIn && pendingAnswersCount > 0 && pendingTipsCount === 0" class="indicator">{{ pendingAnswersCount }}</div>
                <div v-else-if="checkRoute && loggedIn && pendingAnswersCount > 0 && pendingTipsCount > 0" class="indicator">{{ pendingTipsCount + pendingAnswersCount }}</div>
                <div v-else-if="checkRoute && loggedIn && chatUnreadCount > 0" class="indicator">{{ chatUnreadCount }}</div>
                <div v-if="checkRoute && loggedIn" v-on:click="openMenu" class="burger_menu">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div v-else>
                </div>
                <img v-else v-on:click="goBackClicked" src="../assets/icons/ic_16_back.svg" class="back" alt="">
            </slot>
        </div>
        <div id="center" class="navigation_title">
            <slot name="center">
            </slot>
        </div>
        <div id="right">
            <slot name="right">
            </slot>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'NavigationBar',
        data () {
          return {
            showNavbar: true,
            lastScrollPosition: 0,
            componentKey: 0
          }
        },
        mounted () {
          //window.addEventListener('scroll', this.onScroll)
          this.componentKey += 1
        },
        beforeDestroy () {
          //window.removeEventListener('scroll', this.onScroll)
        },
        computed: {
            checkRoute() {
              return this.$route.name !== 'join'
            },
            loggedIn() {
                return this.$store.getters.loggedIn
            },
            chatUnreadCount() {
              return this.$store.state.unreadCount ? this.$store.state.unreadCount : 0
            },
            pendingAnswersCount() {
              if(this.$store.state.questions) {
                const questions = this.$store.state.questions.filter(q => parseInt(q.fk_betgame) === parseInt(this.$store.state.betgameId))
                var result = 0
                for (var i = 0; i < questions.length; i++) {
                  const question = questions[i]
                  const editableUntil = this.parseMatchdate(question.editable_until)
                  if(editableUntil > Date.now()) {
                    const answers = this.$store.state.answers.filter(a => parseInt(a.fk_question) === parseInt(question.id))
                    if(answers.length !== question.answer_count) {
                      result += question.answer_count
                    }
                  }
                }
                return result
              }
              return 0
            },
            answerCount() {
              if(!this.$store.state.answers || this.$store.state.updateAnswerCount) {
                this.$store.dispatch('updateAnswerCount', false).then(function (response) {
                })
                this.$store.dispatch('fetchUserAnswers', []).then(function (response) {
                })
              }
              return this.$store.state.answers ? this.$store.state.answers.length : 0
            },
            pendingTipsCount() {
              if(this.$store.state.missing_bets !== undefined && this.$store.state.missing_bets !== null) {
                const mb = this.$store.state.missing_bets
                var result = 0
                for (var i = 0; i < mb.length; i++) {
                  const item = mb[i]
                  if (item.matchcount > item.betcount) {
                    result += item.matchcount - item.betcount
                  }
                }
                return result
              } 
              return 0
            }
        },
        methods: {
          onScroll () {
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
            if (currentScrollPosition < 0) {
              return
            }
            // Stop executing this function if the difference between
            // current scroll position and last scroll position is less than some offset
            if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 64) {
              return
            }
            this.showNavbar = currentScrollPosition < this.lastScrollPosition
            this.lastScrollPosition = currentScrollPosition
          },
          homeClicked() {
            this.$router.push('/')
          },
          openProfile() {
            this.$router.push({ name: 'profile' })
          },
          goBackClicked() {
            this.$router.go(-1)
          }
        }
    }
</script>

<style lang="scss">
    
    .navigation.navigation--hidden {
        box-shadow: none;
        transform: translate3d(0, -100%, 0);
    }
    
    .navigation {
        display: flex;
        background: $colorWhite;
        position: fixed;
        align-items: center;
        text-align: center;
        top: 0;
        left: 0;
        min-height: 64px;
        width: 100%;
        box-shadow: inset 0 -1px 0 0 rgba(95,93,97,0.24);
        z-index: 100;
        transform: translate3d(0, 0, 0);
        transition: 0.3s all ease-out;

        .burger_menu {
            width: 48px;
            height: 64px;
            position: relative;
            cursor: pointer;

            span {
                width: 16px;
                height: 2px;
                position: absolute;
                left: 16px;
                top: 31px;
                background: var(--color-primary);

                &:first-child {
                    top: 26px;
                }

                &:last-child {
                    top: 36px;
                }
            }
        }

        .back {
            padding: 24px 32px 24px 16px;
            cursor: pointer;
        }

        .navigation_title {
            justify-content: center;
            color: $colorGreyDark;
            flex-direction: column;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &--headline{
                font-size: 17px;
                line-height: 19px;
                margin-bottom: 2px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &--subline {
                font-size: 14px;
                line-height: 17px;
                color: $colorGreyMid;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .navigation_icon{
            width: 24px;
            height: 24px;
            padding: 20px 16px 20px 24px;
            cursor: pointer;

            &.small {
                padding: 20px 8px;

                &:last-child {
                    padding: 20px 16px 20px 8px;
                }
            }
        }

        .indicator {
            display: flex;
            position: absolute;
            z-index: 1;
            left: 24px;
            top: 18px;
            width: 16px;
            height: 16px;
            align-items: center;
            justify-content: center;
            background: $colorNegative;
            border-radius: 8px;
            box-shadow: 0 0 0 2px $colorWhite;
            color: $colorWhite;
            font-size: 11px;
            font-weight: 500;
            pointer-events: none;
        }

        #left {
            display: flex;
            flex: 1;
        }
        #right {
            display: flex;
            flex: 1;
            justify-content: flex-end;
        }
    }
</style>
