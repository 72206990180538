<template>
  
    <div class="devrankings" v-bind:class="{ 'bwin-visible': showOdds && !isTeamsBetgame(), 
        'tabbar-visible': !showOdds && isTeamsBetgame(), 
        'tabbar-bwin-visible': showOdds && isTeamsBetgame()}" >
        
        <NavigationBar>
            <template v-slot:center>
                <div class="navigation_title--headline">
                    {{ $t('menu.matchday_ranking') }}
                </div>
                <div v-if="!isBattle() && !isTeamsBetgame()" v-on:click="selectMatchday" class="navigation_title--subline">
                    {{ matchdayText }}
                </div>
            </template>
            <template v-slot:right>
                <div class="navigation_icon small" v-bind:class="{ small: matchRunning }" v-on:click="refreshRankingByMatchday">
                  <UpdateIcon></UpdateIcon>
                </div>
                <CalendarIcon v-if="!isBattle()" v-on:click.native="selectMatchday" class="navigation_icon small"></CalendarIcon>
            </template>
        </NavigationBar>
        
        <!-- TODO @Lennard: Show this button only if the case "2 matches from different matchdays" is active  -->
        <!--<a href="" class="button button--fixed">
            <img src="../assets/icons/ic_back_white.svg" alt="" class="button-icon button-icon--left">Zurück zur Spieltagsübersicht
        </a>-->
        
        <!-- BWIN Banner -->
        <!-- TODO @Lennard: Hide this container if the case "2 matches from different matchdays" is active -->
        <div v-if="showOdds" class="banner-box">
            <div class="banner-box__content">
                <div class="banner-box__icon">
                    <img src="@/assets/icons/ic_96_smiley.png" srcset="@/assets/icons/ic_96_smiley@2x.png 2x">
                </div>
                <div class="banner-box__text" v-on:click="deepLinkClicked">
                    <div class="banner-box__headline">
                        Noch nicht gewettet?
                    </div>
                    <div class="banner-box__copy">
                        Und später ärgerst du dich wieder...
                    </div>
                    <a :href="deepLink" target="_blank" class="button">
                        Dieses Mal nicht
                        <img src="@/assets/icons/ic_12_next_white.svg" class="button-icon button-icon--right">
                    </a>
                </div>
            </div>
            <div class="banner-box__meta">
                Lizenziert (Whitelist) | 18+ | Suchtrisiko | <a target="_blank" href="https://buwei.de" style="color: darkgray">buwei.de</a> | -w-
            </div>
        </div>

        <div v-if="isTeamsBetgame()" id="tournament_nav">
          <div v-if="hasTodaysMatches" id="todaysMatches" v-on:click="competitionTabChanged('todaysMatches')" class="tournament_tab" v-bind:class="{ tournament_tab_selected: selectedTab === 'todaysMatches'}">{{ $t('bet.tab_todays_matches') }}</div>
          <div v-for="c in competitions" :id="c.heimspielid" v-on:click="competitionTabChanged(c.heimspielid)" class="tournament_tab" v-bind:class="{ tournament_tab_selected: selectedTab === c.heimspielid }">{{ c.name }}</div>
        </div>
      
        <!-- Table Container -->
        <div v-visibility-change="visibilityChange" class="ranking">
            
            <!-- Table Header -->
            <div class="ranking__header">
                <div class="ranking__header__item ranking__header__item--user">
                    #
                </div>
                <div class="ranking__header__item ranking__header__item--points">
                    <span v-on:click="sortByTotal" v-bind:class="sorting === 1 ? 'selected' : ''" >{{ $t('total') }}</span>
                    <span v-if="!isBattle() && !isTournament() && !isTeamsBetgame()" v-on:click="sortByMatchday" v-bind:class="sorting === 2 ? 'selected' : ''" >{{ $t('matchday_short') }}</span>
                    <span v-else-if="!isBattle() && !isTournament() && isTeamsBetgame()" v-on:click="sortByMatchday" v-bind:class="sorting === 2 ? 'selected' : ''" >{{ selectedTabText(selectedTab) }}</span>
                </div>
                <div v-for="(match, index) in matches" class="ranking__header__item ranking__header__item--match">
                    <span v-bind:class="{ lost: loserTeam(match, match.fk_team_home), win: winnerTeam(match, match.fk_team_home), draw: draw(match) || !started(match), finished: finished(match), cancelled: cancelled(match) }">
                        <img v-if="teamLogo(match.fk_team_home) && teamLogo(match.fk_team_home).length > 6" :src="teamLogo(match.fk_team_home)" width="16">
                        <flag v-else-if="teamLogo(match.fk_team_home) && teamLogo(match.fk_team_home).length <= 6" :iso="teamLogo(match.fk_team_home)" class="flag"/>
                        <svg v-else width="16px" height="16px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': getTeamColor(match.fk_team_home)}"></path>
                        </svg>
                        {{ getTeamShort(match.fk_team_home, match.team_home) }}
                    </span>
                    <span v-bind:class="{ lost: loserTeam(match, match.fk_team_guest), win: winnerTeam(match, match.fk_team_guest), draw: draw(match) || !started(match), finished: finished(match), cancelled: cancelled(match) }">
                        {{ getTeamShort(match.fk_team_guest, match.team_guest) }}
                        <img v-if="teamLogo(match.fk_team_guest) && teamLogo(match.fk_team_guest).length > 6" :src="teamLogo(match.fk_team_guest)" width="16">
                        <flag v-else-if="teamLogo(match.fk_team_guest) && teamLogo(match.fk_team_guest).length <= 6" :iso="teamLogo(match.fk_team_guest)" class="flag"/>
                        <svg v-else width="16px" height="16px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': getTeamColor(match.fk_team_guest)}"></path>
                        </svg>
                    </span>
                    <span v-if="match.status && match.status === 'Wertung'">{{ matchStatusText(match) }}</span>
                    <span v-else-if="betgame.result_mode === 3 && isNumeric(match.goalshome_90) && isNumeric(match.goalsguest_90)"
                          class="finished">{{ match.goalshome_90 }}:{{ match.goalsguest_90 }}</span>
                    <span v-else-if="betgame.result_mode === 2 && isNumeric(match.goalshome_120) && isNumeric(match.goalsguest_120)"
                          class="finished">{{ match.goalshome_120 }}:{{ match.goalsguest_120 }}</span>
                    <span v-else-if="isNumeric(match.goalshome) && isNumeric(match.goalsguest)"
                          class="finished">{{ match.goalshome }}:{{ match.goalsguest }}</span>
                    <span v-else-if="isNumeric(match.livegoalshome) && isNumeric(match.livegoalsguest)"
                          class="live_result">{{ match.livegoalshome }}:{{ match.livegoalsguest }}</span>
                    <span v-else-if="match.status && match.status.length > 2">{{
                        match.status.slice(0, 3).toUpperCase()
                        }}</span>
                    <span v-else>-:-</span>
                </div>
            </div>
            
            <!-- Table Content -->
            <div class="ranking__content">
                <div v-for="(ranking, index) in matchdayRankings" class="ranking__item" v-bind:class="{ 'ranking__item--currentuser' : ranking.fk_user === currentUser }" :id="ranking.fk_user" :ref="ranking.fk_user">
                    <div class="ranking__item__column ranking__item__column--user">
                        <span v-if="sorting === 1 && !equalPoints(index)" v-bind:class="{ 'morethan999' : $store.state.betgameUsers && $store.state.betgameUsers.length > 999 }">{{ ranking.position }}.</span>
                        <span v-else-if="sorting === 1 && equalPoints(index)" v-bind:class="{ 'morethan999' : $store.state.betgameUsers && $store.state.betgameUsers.length > 999 }"></span>
                        <span v-else-if="sorting === 2 && !equalPoints(index)" v-bind:class="{ 'morethan999' : $store.state.betgameUsers && $store.state.betgameUsers.length > 999 }">{{ index + 1 }}.</span>
                        <span v-else-if="sorting === 2 && equalPoints(index)" v-bind:class="{ 'morethan999' : $store.state.betgameUsers && $store.state.betgameUsers.length > 999 }"></span>
                        <img v-if="!isBattle() && !isTeamsBetgame() && sorting === 1 && matchday === $store.state.maxStartedMatchday && upOrDown(ranking.fk_user, sorting === 1 ? ranking.position : index + 1) === '='"
                             src="../assets/icons/ic_12_ranking_equal.svg">
                        <img v-else-if="!isBattle() && !isTeamsBetgame() && sorting === 1 && matchday === $store.state.maxStartedMatchday && upOrDown(ranking.fk_user, sorting === 1 ? ranking.position : index + 1) === 'd'"
                             src="../assets/icons/ic_12_ranking_down.svg">
                        <img v-else-if="!isBattle() && !isTeamsBetgame() && sorting === 1 && matchday === $store.state.maxStartedMatchday && upOrDown(ranking.fk_user, sorting === 1 ? ranking.position : index + 1) === 'u'"
                             src="../assets/icons/ic_12_ranking_up.svg">
                        <div>{{ ranking.user_name }}</div>
                    </div>
                    <div class="ranking__item__column ranking__item__column--points">
                        <span v-bind:class="sorting === 1 ? 'selected' : ''" >{{ totalpoints(ranking.fk_user) }}</span>
                        <span v-if="!isBattle() && !isTournament() && !isTeamsBetgame()" v-bind:class="sorting === 2 ? 'selected' : ''" >{{ livepoints(ranking.fk_user) }}</span>
                        <span v-else-if="!isBattle() && !isTournament() && isTeamsBetgame()" v-bind:class="sorting === 2 ? 'selected' : ''" >{{ livepoints(ranking.fk_user) }}</span>
                    </div>
                    <div v-for="(match, index) in matches" class="ranking__item__column ranking__item__column--match">
                        <span v-if="betmode === 1 && match.status && match.status === 'Wertung'" class="bet_no_points" v-bind:class="getClass(match, ranking.fk_user)">
                          x
                        </span>
                        <div v-else-if="betmode === 2 && match.status && match.status === 'Wertung'" class="toto-match-cancelled" v-bind:class="getClass(match, ranking.fk_user)">
                            x
                        </div>
                        <div v-else-if="betmode === 2" class="toto-result" v-bind:class="getClass(match, ranking.fk_user)">
                            {{ betToto(match, ranking.fk_user) }}
                        </div>
                        <div v-else-if="getBetResult(match, ranking.fk_user) === 'bet_exact'" class="ranking__item__column__goals ranking__item__column__goals--exact">
                            {{ bet(match, ranking.fk_user) }}
                            <span v-if="betPoints(match, ranking.fk_user) > 0">{{ betPoints(match, ranking.fk_user) }}</span>
                        </div>
                        <div v-else-if="getBetResult(match, ranking.fk_user) === 'bet_no_points'" class="ranking__item__column__goals">
                            {{ bet(match, ranking.fk_user) }}
                            <span v-if="betPoints(match, ranking.fk_user) > 0">{{ betPoints(match, ranking.fk_user) }}</span>
                        </div>
                        <div v-else-if="getBetResult(match, ranking.fk_user) === 'bet_result'" class="ranking__item__column__goals ranking__item__column__goals--result">
                            {{ bet(match, ranking.fk_user) }}
                            <span v-if="betPoints(match, ranking.fk_user) > 0">{{ betPoints(match, ranking.fk_user) }}</span>
                        </div>
                        <div v-else-if="getBetResult(match, ranking.fk_user) === 'bet_goal_diff'" class="ranking__item__column__goals  ranking__item__column__goals--tendency">
                            {{ bet(match, ranking.fk_user) }}
                            <span v-if="betPoints(match, ranking.fk_user) > 0">{{ betPoints(match, ranking.fk_user) }}</span>
                        </div>
                        <div v-else class="ranking__item__column__goals">
                            -:-
                        </div>
                    </div>
                </div>
                <div v-if="this.$store.state.liveRankings.length > this.PAGE_SIZE" class="ranking__pagination">
                  <div v-on:click="pageIndexDown" class="ranking__pagination__arrow ranking__pagination__arrow--left" v-bind:class="{  'ranking__pagination__arrow--disabled': this.pageIndex < 1 }">
                    <img src="@/assets/icons/ic_16_back.svg">
                  </div>
                  <div v-on:click="resetPageIndex">{{ pageIndexText }}</div>
                  <div v-on:click="pageIndexUp" class="ranking__pagination__arrow ranking__pagination__arrow--right" v-bind:class="{  'ranking__pagination__arrow--disabled': this.maxPage > 0 && this.pageIndex >= this.maxPage }">
                    <img src="@/assets/icons/ic_16_next.svg">
                  </div>
                </div>
            </div>
        </div>
        
        <!-- TODO @LENNARD: Show this container only if the group has a high number of members and the current position of the user is "30" or higher. By clicking on the container the view should jump to the positon of the user and the container should disappear (best case: with scroll animation) -->
        <div v-for="(ranking, index) in usermatchdayRanking" class="ranking__sticky"
             v-bind:class="{ 'ranking__sticky--hidden': userTableEntryVisible, sticky_correct_bottom: isBattle() }">
            <div class="ranking__sticky__position">
                {{ overlayUserPosition }}
            </div>
            <div v-on:click="scrollUserVisible" class="ranking__sticky__text">
                <div class="ranking__sticky__text__label">
                    {{ ranking.user_name }}
                </div>
            </div>
            <div class="ranking__sticky__points">
                {{ overlayUserPoints }}
            </div>
            <div v-on:click="scrollUserVisible" class="ranking__sticky__button">
                <img src="../assets/icons/ic_12_down_white.svg" alt="">
            </div>
        </div>
        
        <!-- TODO @Lennard: Show this button only if the case "2 matches from different matchdays" is active -->
        <div v-if="!todaysMatchesSameMatchday && userTableEntryVisible" class="ranking__sticky ranking__sticky--hint">
            <div class="ranking__sticky__icon">
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="ranking__sticky__text">
                <div class="ranking__sticky__text__label">
                    <span class="highlight">LIVE:</span> {{ $t('rankings.multiple_matchdays') }}
                </div>
                <div class="ranking__sticky__text__sublabel">
                    {{ $t('rankings.multiple_matchdays_text') }}
                </div>
            </div>
            <div v-on:click="showTodaysRanking" class="ranking__sticky__button">
                <img src="../assets/icons/ic_12_down_white.svg" alt="">
            </div>
        </div>
      
        <!--
        <div class="navigation_bottom" v-if="this.isEM2024()">
          <div class="navigation_bottom--left" v-on:click="pageIndexDown">
            <img src="../assets/icons/ic_16_back.svg">
          </div>
          <div style="text-align: center;" class="navigation_bottom--center" variant="outline-primary" v-on:click="resetPageIndex">
            {{ pageIndexText }}
          </div>
          <div class="navigation_bottom--right" v-on:click="pageIndexUp">
            <img src="../assets/icons/ic_16_next.svg">
          </div>
        </div>
        -->
        <div class="navigation_bottom" v-if="!isBattle() && !isTeamsBetgame()">
            <div class="navigation_bottom--left" v-on:click="decrement">
                <img src="../assets/icons/ic_16_back.svg">
            </div>
            <div v-if="isTournament()" class="navigation_bottom--center" variant="outline-primary"
                 v-on:click="selectMatchday">
                {{ $t('select_round') }}
            </div>
            <div v-else class="navigation_bottom--center" variant="outline-primary" v-on:click="selectMatchday">
                {{ $t('select_matchday') }}
            </div>
            <div class="navigation_bottom--right" v-on:click="increment">
                <img src="../assets/icons/ic_16_next.svg">
            </div>
        </div>
        
        <!-- Matchday Select Code -->
        <modal name="matchday-select" id="matchday-select" :scrollable="true" width="100%" height="auto">
            <div class="menu_header">
                <div @click="$modal.hide('matchday-select')" class="burger_menu">
                    <span></span>
                    <span></span>
                </div>
                <span v-if="isTournament()" class="menu_header-title">{{ $t('select_round') }}</span>
                <span v-else class="menu_header-title">{{ $t('select_matchday') }}</span>
            </div>
            <div v-if="isTournament()" class="matchday-select-list matchday-select-list--longtitles">
                <button v-for="md in matchdayCount" :key="md"
                        v-bind:class="{ selected_matchday: md === matchday }"
                        v-on:click="matchdaySelected(md)">{{ matchdayTextGlobal(md, competition) }}
                </button>
            </div>
            <div v-else class="matchday-select-list">
                <button v-for="md in matchdayCount" :key="md"
                        v-bind:class="{ selected_matchday: md === matchday }"
                        v-on:click="matchdaySelected(md)">{{ md }}
                </button>
            </div>
        </modal>

        <transition v-if="overlayOpened" name="review-modal">
          <div class="review-modal-mask">
            <div class="review-modal-wrapper">
              <div class="review-modal-container">
                <div class="input-header">
                  {{ $t('review_request_title') }}
                </div>
                <div class="input-infotext">
                  {{ $t('review_request_text') }}
                </div>
                <star-rating style="justify-content: center;" @rating-selected="ratingChanged($event)" :rating="rating" :show-rating="false"></star-rating>
                <div class="review-modal-footer">
                  <button class="review-modal-default-button" @click="ratingSkipped()">
                    {{ $t('later') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <transition v-else-if="isLoading" name="review-modal">
          <div class="review-modal-mask" style="background-color: rgba(0, 0 ,0 , 0.1);">
            <div class="review-modal-wrapper">
              <div class="review-modal-container">
                <div class="input-header" style="font-weight: bold; color: rgba(12, 62, 193, 1.0)">
                  {{ $t('refreshing') }}
                </div>
              </div>
            </div>
          </div>
        </transition>
      
    </div>
</template>

<script>
import NavigationBar from './../components/NavigationBar'
import CalendarIcon from './../components/icons/CalendarIcon'
import { isNumeric } from '@/vuex/helpers'
import {
  RANKINGS_PAGE_SIZE,
  RESULT_MODE_120M,
  RESULT_MODE_90M,
  RESULT_MODE_TOTAL,
  TOP_TEN,
  TYPE_BATTLE_ID,
  TYPE_TEAMS_ID
} from '@/main'
import UpdateIcon from '@/components/icons/UpdateIcon.vue'
import SearchIcon from '@/components/icons/SearchIcon.vue'
import moment from 'moment'
import Vue from 'vue'

export default {
  name: 'rankingsbymatchday',
  data () {
    return {
        rating: 0,
        overlayOpened: false,
        isLoading: false,
        parsedMatches: [],
        selectedTab: 'allMatches',
        deepLink: 'https://sports.bwin.de/de/sports/fussball-4/wetten?wm=5047188',
        userTableEntryVisible: false,
        pageIndex: 0,
        PAGE_SIZE: parseInt(this.getBGValue('spmrps') ? this.getBGValue('spmrps') : RANKINGS_PAGE_SIZE),
        betPointsMapping: new Map()
    }
  },
  components: { SearchIcon, UpdateIcon, NavigationBar, CalendarIcon },
  beforeCreate () {
  },
  created () {
      Vue.$log.debug('DevRakingsByMatchday created ' + this.$route.params.push + ' --- ' + this.betPointsMapping.size)
      const md = this.$route.params.matchday
      if(this.isNumeric(md)) {
          //this.matchday = md
          this.$store.dispatch('setCurrentMatchdayRankingByMatchday', md)
      } else {
        const filtered = this.$store.state.matches.filter(t => this.parseMatchdate(t.matchdate).isSame(moment(), 'days'))
        if(filtered.length > 0) {
          const matchday = filtered[0].matchday
          var sameday = true
          for(var i = 1; i < filtered.length; i++) {
            if(filtered[i].matchday !== matchday) {
              sameday = false
              break
            }
          }
          if(sameday) {
            this.$store.dispatch('setCurrentMatchdayRankingByMatchday', matchday)
          }
        }
      }
    
      if(this.isBattle() || this.isTeamsBetgame()) {
        this.parsedMatches = JSON.parse(JSON.stringify(this.$store.state.matches))
      }
    
      if(this.isTeamsBetgame() && this.hasTodaysMatches) {
        this.selectedTab = 'todaysMatches'
      } else if(this.isTeamsBetgame() && this.competitions.length > 0) {
        this.selectedTab = this.competitions[0].heimspielid
      }
    
      if(this.isBattle()) {
          const lastFetchLiveRanking = this.getBGValue('spmlflr')
          const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
          if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
              this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
              this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
                  this.$store.dispatch('fetchBetsForMatchdayRankingBattle', [this.$store.state.betgameId, this.matchday]).then(() => {
                      this.setBGValue('spmlflr', new Date())
                  })
              })

          }
      } else if(this.isTeamsBetgame()) {
        const lastFetchLiveRanking = this.getBGValue('spmlflr')
        const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
        if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 || 
            this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
          this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
            this.$store.dispatch('fetchBetsForMatchdayRankingTeam', [this.$store.state.betgameId, this.matchday]).then(() => {
              this.setBGValue('spmlflr', new Date())
              })
            })
          }
      } else {
          if(this.$store.state.leagueId) {
              const lastFetchLiveRanking = this.getBGValue('spmlflr')
              const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
              if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
                  this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
                  this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
                      this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
                          this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
                              this.setBGValue('spmlflr', new Date())
                          })
                      })
                  })
              }
          } else {
              this.$store.dispatch('fetchBetgameLeague', this.$store.state.betgameId)
          }
      }

      const lastFetchSchedule = this.getBGValue('spmlfs')
      const duration2 = (new Date() - new Date(lastFetchSchedule)) / 1000
      if(duration2 > this.$store.getters.getCachingDuration || this.$store.state.matches == null || this.$store.state.matches.length === 0) {
          this.$store.dispatch('fetchSchedule', this.$store.state.leagueId).then(() => {
            if(this.isNumeric(md)) {
              this.$store.dispatch('setCurrentMatchdayRankingByMatchday', md)
            }
          })
      }

      if (this.$store.state.teams.length === 0) {
          this.$store.dispatch('fetchTeams', this.$store.state.betgameId)
      }
      
      if(this.hasFiveStarRating()) {
        try {
          if (window.TeamtipAppInterface !== undefined && window.TeamtipAppInterface !== null) {
            const a = window.TeamtipAppInterface.requestAppStoreReview()
          } else if (window.webkit && window.webkit.messageHandlers.TeamtipAppInterface !== undefined && window.webkit.messageHandlers.TeamtipAppInterface !== null) {
            const b = window.webkit.messageHandlers.TeamtipAppInterface.postMessage('requestAppStoreReview')
          } else {
            Vue.$log.debug('rankingsbymatchday ratingChanged - no app interface found')
          }
        } catch (e) {
          Vue.$log.debug('rankingsbymatchday ratingChanged - no app interface found', e)
        }
      } else if(this.$route.params.push && this.$route.params.push === 'mdwinner' && this.$store.state.reviewPopupShown === 0) {
        this.overlayOpened = true
      }
  },
  mounted () {
      Vue.$log.debug('DevRakingsByMatchday mounted ' + this.betPointsMapping.size)
      const elementToWatch = document.getElementById('' + this.$store.state.user.id)
      if(elementToWatch) {
        const options = {
          root: document.querySelector("#ranking__content"),
          rootMargin: "-80px 0px -20px 00px",
          threshold: 1.0,
        }
        const observer = new IntersectionObserver(entries => {
          const firstEntry = entries[0]
          if (firstEntry.isIntersecting) {
            this.userTableEntryVisible = true
          } else {
            this.userTableEntryVisible = false
          }
        }, options);
        observer.observe(elementToWatch)
      }
  },
  computed: {
      maxPage() {
        if (this.$store.state.liveRankings && this.$store.state.liveRankings.length > this.PAGE_SIZE) {
          return Math.floor(this.$store.state.liveRankings.length / this.PAGE_SIZE)
        }
        return 0
      },
      pageIndexText() {
        if(((this.pageIndex + 1) * this.PAGE_SIZE) > this.$store.state.liveRankings.length) {
          return this.$i18n.t('position') + ' ' + (1 + (this.pageIndex) * this.PAGE_SIZE + ' - ' + this.$store.state.liveRankings.length) + ' ' +
              this.$i18n.t('of') + ' ' + this.$store.state.liveRankings.length
        }
        return this.$i18n.t('position') + ' ' + (1 + (this.pageIndex) * this.PAGE_SIZE + ' - ' + (this.pageIndex + 1) * this.PAGE_SIZE) + ' ' + 
            this.$i18n.t('of') + ' ' + this.$store.state.liveRankings.length
      },
      competitions() {
        var r = []
        for(var i = 0; i < this.$store.state.betgameTeamsCompetitions.length; i++) {
          const item = this.$store.state.betgameTeamsCompetitions[i]
          const c = this.$store.state.competitions.filter(c => c.id === item)
          if(c.length > 0) {
            r.push(c[0])
          }
        }
        return r.sort((a, b) => a.id - b.id)
      },    
      hasTodaysMatches() {
        const filtered = this.parsedMatches.filter(t => this.parseMatchdate(t.matchdate).isSame(moment(), 'days'))
        return filtered.length > 0
      },
      competition() {
          return this.$store.state.betgame.competition.id
      },
      todaysMatchesSameMatchday() {
          return !this.$store.getters.todaysMatchesMultipleMatchdays
      },
      betmode() {
          return this.$store.state.betgame.bet_mode || 1
      },
      overlayUserPoints() {
          if(this.$store.state.matchdayRankingSorting === 1) {
              return this.userTotalPoints
          } else {
              return this.livepoints(this.$store.state.user.id)
          }
      },
      overlayUserPosition() {
          if(this.$store.state.matchdayRankingSorting === 1) {
              return this.userLivePosition + '.'
          } else {
              if (!this.matchdayRankings || this.matchdayRankings.length === 0) {
                  return ''
              }
              var position = 1
              for(let i = 0; i < this.matchdayRankings.length; i++) {
                  if(i > 0 && this.matchdayRankings[i].points_total !== this.matchdayRankings[i - 1].points_total) {
                      position = i + 1
                  }
                  if (this.matchdayRankings[i].fk_user === this.$store.state.user.id) {
                      return position + '.'
                  }
              }
              return ''
          }
      },
      showOdds() {
          return false
          /*
          if(this.$store.state.betgame && this.$store.state.user) {
              return !this.isHandballBetgame && !this.countryBlocked() && this.$store.state.betgame.hide_ads === false && this.$store.state.user.isadult === true
          }
          return false
          */
      },
      matchRunning() {
          if(this.$store.state.matches === null || this.$store.state.matches === undefined) {
              return false
          }
          for (var i = 0; i < this.$store.state.matches.length; i++) {
              const match = this.$store.state.matches[i]
              if ((this.isBattle() || match.matchday === this.matchday) && this.isNumeric(match.livegoalshome) && this.isNumeric(match.livegoalsguest)) {
                  return true
              }
          }
      },
      matchdayText() {
        if(this.$store.state.betgame && this.$store.state.betgame.competition &&
            (this.$store.state.betgame.competition.id === 19 || this.$store.state.betgame.competition.id === 132)) {
          const nextMatchday = this.matchday
          if(nextMatchday === 9) {
            return this.$i18n.t('ko_round_playoffs_first_legs')
          } else if(nextMatchday === 10) {
            return this.$i18n.t('ko_round_playoffs_second_legs')
          } else if(nextMatchday === 11) {
            return this.$i18n.t('ko_round_of_16_first_legs')
          } else if(nextMatchday === 12) {
            return this.$i18n.t('ko_round_of_16_second_legs')
          } else if(nextMatchday === 13) {
            return this.$i18n.t('ko_round_quarter_finals_first_legs')
          } else if(nextMatchday === 14) {
            return this.$i18n.t('ko_round_quarter_finals_second_legs')
          } else if(nextMatchday === 15) {
            return this.$i18n.t('ko_round_semi_finals_first_legs')
          } else if(nextMatchday === 16) {
            return this.$i18n.t('ko_round_semi_finals_second_legs')
          } else if(nextMatchday === 17) {
            return this.$i18n.t('ko_round_final')
          }
        } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 6395) {
          const nextMatchday = this.matchday
          if(nextMatchday === 7) {
            return this.$i18n.t('ko_round_playoffs_first_legs')
          } else if(nextMatchday === 8) {
            return this.$i18n.t('ko_round_playoffs_second_legs')
          } else if(nextMatchday === 9) {
            return this.$i18n.t('ko_round_of_16_first_legs')
          } else if(nextMatchday === 10) {
            return this.$i18n.t('ko_round_of_16_second_legs')
          } else if(nextMatchday === 11) {
            return this.$i18n.t('ko_round_quarter_finals_first_legs')
          } else if(nextMatchday === 12) {
            return this.$i18n.t('ko_round_quarter_finals_second_legs')
          } else if(nextMatchday === 13) {
            return this.$i18n.t('ko_round_semi_finals_first_legs')
          } else if(nextMatchday === 14) {
            return this.$i18n.t('ko_round_semi_finals_second_legs')
          } else if(nextMatchday === 15) {
            return this.$i18n.t('ko_round_final')
          }
        } else if(this.$store.state.betgame && this.$store.state.betgame.competition && 
              (this.$store.state.betgame.competition.id === 10700 || this.$store.state.betgame.competition.id === 36)) {
              const nextMatchday = this.matchday
              if(nextMatchday === 1) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 2) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 3) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 4) {
                  return this.$i18n.t('em_ko_round_of_16')
              } else if(nextMatchday === 5) {
                  return this.$i18n.t('em_ko_round_quarter_finals')
              } else if(nextMatchday === 6) {
                  return this.$i18n.t('em_ko_round_semi_finals')
              } else if(nextMatchday === 7) {
                  return this.$i18n.t('ko_round_final')
              }
          } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 533) {
              const nextMatchday = this.matchday
              if(nextMatchday === 1) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 2) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 3) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 8) {
                  return this.$i18n.t('ko_round_fifth_place')
              } else if(nextMatchday === 9) {
                  return this.$i18n.t('em_ko_round_semi_finals')
              } else if(nextMatchday === 10) {
                  return this.$i18n.t('ko_round_third_place')
              } else if(nextMatchday === 11) {
                  return this.$i18n.t('ko_round_final')
              }
          } else if(this.$store.state.betgame && this.$store.state.betgame.competition &&
              (this.$store.state.betgame.competition.id === 139 || this.$store.state.betgame.competition.id === 1110)) {
              const nextMatchday = this.matchday
              if(nextMatchday === 1) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 2) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 3) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 4) {
                  return this.$i18n.t('em_ko_round_of_16')
              } else if(nextMatchday === 5) {
                  return this.$i18n.t('em_ko_round_quarter_finals')
              } else if(nextMatchday === 6) {
                  return this.$i18n.t('em_ko_round_semi_finals')
              } else if(nextMatchday === 7) {
                  return this.$i18n.t('ko_round_third_place_short')
              } else if(nextMatchday === 8) {
                  return this.$i18n.t('ko_round_final')
              }
          } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 1180) {
              const nextMatchday = this.matchday
              if(nextMatchday === 1) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 2) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 3) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 4) {
                  return this.$i18n.t('em_ko_round_quarter_finals')
              } else if(nextMatchday === 5) {
                  return this.$i18n.t('em_ko_round_semi_finals')
              } else if(nextMatchday === 6) {
                  return this.$i18n.t('ko_round_final')
              }
          } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 999) {
            const nextMatchday = this.matchday
            if(nextMatchday === 9) {
              return this.$i18n.t('em_ko_round_of_16')
            } else if(nextMatchday === 10) {
              return this.$i18n.t('em_ko_round_quarter_finals')
            } else if(nextMatchday === 11) {
              return this.$i18n.t('em_ko_round_semi_finals')
            } else if(nextMatchday === 12) {
              return this.$i18n.t('ko_round_final')
            }
          }
          if(this.$store.state.tournamentRounds && this.$store.state.tournamentRounds.length > 0) {
              var matchdays = 0
              for (var i = 0; i < this.$store.state.tournamentRounds.length; i++) {
                  const round = this.$store.state.tournamentRounds[i]
                  matchdays += round.matchdaycount
                  if(matchdays >= this.matchday) {
                      if(this.$store.state.betgame.competition.id === 19 || this.$store.state.betgame.competition.id === 132 ||
                          this.$store.state.betgame.competition.id === 4228 || this.$store.state.betgame.competition.id === 6395) {
                          return round.name + ', ' + this.$i18n.t('matchday', { matchday: this.matchday })
                      } else {
                          return round.name
                      }
                  }
              }
          }
          return this.$i18n.t('matchday', { matchday: this.matchday })
      },
      userLivePosition() {
        if(this.$store.state.userLiveRanking) {
          return this.$store.state.userLiveRanking.position
        }
        return ''
      },
      userTotalPoints() {
          return this.$store.state.userLiveRanking.points_total
      },
    sorting () {
      return this.$store.state.matchdayRankingSorting
    },
    matchdayRankings () {
      if (!this.hasSeasonStarted()) {
        if (!this.$store.state.betgameUsers || this.$store.state.betgameUsers.length === 0) {
          return []
        }
        const bu = this.$store.state.betgameUsers
        var res = []
        for (var i = 0; i < bu.length; i++) {
          var u = bu[i]
          u.position = 1
          u.user_name = u.nickname
          res.push(u)
        }
        return res
      } else {
        if (this.sorting === 1) {
          const min = parseInt(this.pageIndex * this.PAGE_SIZE)
          const max = parseInt(this.pageIndex * this.PAGE_SIZE + this.PAGE_SIZE)
          //const temp = this.$store.state.liveRankings.slice(this.pageIndex*this.PAGE_SIZE, this.pageIndex*this.PAGE_SIZE+this.PAGE_SIZE)
          const temp = this.$store.state.liveRankings.slice(min, max)
          return temp
        } else if (this.sorting === 2) {
          if(this.isTeamsBetgame()) {
            const temp = this.$store.state.liveRankings.slice(0, TOP_TEN)
            temp.sort((a, b) => {
              return this.livepoints(b.fk_user) - this.livepoints(a.fk_user)
            })
            return temp
          } else {
            return this.$store.state.matchdayRankings[this.matchday]
          }
        }
        return []
      }
    },
    matchday () {
      return this.$store.state.currentMatchdayRankingByMatchday
    },
    matchdayCount () {
      return this.$store.state.matchdayCount
    },
    betgame () {
      return this.$store.state.betgame
    },
    matches () {
      if (this.isBattle()) {
        const m = JSON.parse(JSON.stringify(this.$store.state.matches))
        const t = m.sort(function (a, b) {
          const livea = isNumeric(a.livegoalshome) && isNumeric(a.livegoalsguest) && !isNumeric(a.goalshome) && !isNumeric(a.goalsguest)
          const liveb = isNumeric(b.livegoalshome) && isNumeric(b.livegoalsguest) && !isNumeric(b.goalshome) && !isNumeric(b.goalsguest)
          const live = livea === liveb ? 0 : (livea ? -1 : 1)
          if (a.status && (a.status.trim() === 'annulliert' || a.status.trim() === 'abgesagt' || a.status.trim() === 'verlegt' || a.status.trim() === 'Wertung')) {
            return 1
          } else if (b.status && (b.status.trim() === 'annulliert' || b.status.trim() === 'abgesagt' || b.status.trim() === 'verlegt' || b.status.trim() === 'Wertung')) {
            return -1
          }
          return live || Date.parse(a.matchdate) - Date.parse(b.matchdate) || a.id - b.id
        })
        return t
      } else if(this.isTeamsBetgame()) {
        const allmatches = this.parsedMatches //JSON.parse(JSON.stringify(this.$store.state.matches))
        if(this.selectedTab === 'todaysMatches') {
          const filtered = allmatches.filter(t => this.parseMatchdate(t.matchdate).isSame(moment(), 'days'))
          const sorted = filtered.sort(function (a, b) {
            const livea = isNumeric(a.livegoalshome) && isNumeric(a.livegoalsguest) && !isNumeric(a.goalshome) && !isNumeric(a.goalsguest)
            const liveb = isNumeric(b.livegoalshome) && isNumeric(b.livegoalsguest) && !isNumeric(b.goalshome) && !isNumeric(b.goalsguest)
            const live = livea === liveb ? 0 : (livea ? -1 : 1)
            if (a.status && (a.status.trim() === 'annulliert' || a.status.trim() === 'abgesagt' || a.status.trim() === 'verlegt' || a.status.trim() === 'Wertung')) {
              return 1
            } else if (b.status && (b.status.trim() === 'annulliert' || b.status.trim() === 'abgesagt' || b.status.trim() === 'verlegt' || b.status.trim() === 'Wertung')) {
              return -1
            }
            return live || Date.parse(a.matchdate) - Date.parse(b.matchdate) || a.id - b.id
          })
          return sorted
        } else if (parseInt(this.selectedTab) > 0) {
          const selectedcompetition = this.$store.state.competitions.filter(t => t.heimspielid === this.selectedTab)
          const filteredmatches = allmatches.filter(t => t.fk_competition === selectedcompetition[0].id)
          const sortedmatches = filteredmatches.sort(function (a, b) {
            const livea = isNumeric(a.livegoalshome) && isNumeric(a.livegoalsguest) && !isNumeric(a.goalshome) && !isNumeric(a.goalsguest)
            const liveb = isNumeric(b.livegoalshome) && isNumeric(b.livegoalsguest) && !isNumeric(b.goalshome) && !isNumeric(b.goalsguest)
            const live = livea === liveb ? 0 : (livea ? -1 : 1)
            if (a.status && (a.status.trim() === 'annulliert' || a.status.trim() === 'abgesagt' || a.status.trim() === 'verlegt' || a.status.trim() === 'Wertung')) {
              return 1
            } else if (b.status && (b.status.trim() === 'annulliert' || b.status.trim() === 'abgesagt' || b.status.trim() === 'verlegt' || b.status.trim() === 'Wertung')) {
              return -1
            }
            return live || Date.parse(a.matchdate) - Date.parse(b.matchdate) || a.id - b.id
          })
          return sortedmatches
        }
        return []
      } else {
        const m = this.$store.getters.getMatchesByMatchday(this.matchday)
        .sort(function (a, b) {
          const livea = isNumeric(a.livegoalshome) && isNumeric(a.livegoalsguest) && !isNumeric(a.goalshome) && !isNumeric(a.goalsguest)
          const liveb = isNumeric(b.livegoalshome) && isNumeric(b.livegoalsguest) && !isNumeric(b.goalshome) && !isNumeric(b.goalsguest)
          const live = livea === liveb ? 0 : (livea ? -1 : 1)
          if (a.status && (a.status.trim() === 'annulliert' || a.status.trim() === 'abgesagt' || a.status.trim() === 'verlegt' || a.status.trim() === 'Wertung')) {
            return 1
          } else if (b.status && (b.status.trim() === 'annulliert' || b.status.trim() === 'abgesagt' || b.status.trim() === 'verlegt' || b.status.trim() === 'Wertung')) {
            return -1
          }
          return live || Date.parse(a.matchdate) - Date.parse(b.matchdate) || a.id - b.id
        })
        return m
      }
    },
    sortmode () {
      return this.$store.state.betgame.sort_mode || 1
    },
      pointsBetResult() {
          return this.$store.state.betgame.points_bet_result
      },
      pointsBetGoaldiff() {
          return this.$store.state.betgame.points_bet_goaldiff
      },
      pointsBetTendency() {
          return this.$store.state.betgame.points_bet_tendency
      },
      usermatchdayRanking() {
        if(this.isTournament() || this.$store.state.betgame.type === TYPE_BATTLE_ID || this.$store.state.betgame.type === TYPE_TEAMS_ID) {
          if(this.$store.state.userLiveRanking) {
            return [this.$store.state.userLiveRanking]
          } else {
            return []
          }
        }
        return this.$store.state.userMatchdayRankings[this.matchday]
      },
    currentUser () {
        if(this.$store.state.user) {
          return this.$store.state.user.id
        }
    },
    resultmode() {
      return this.$store.state.betgame.result_mode || RESULT_MODE_TOTAL
    },
  },
  methods: {
    ratingSkipped() {
      this.overlayOpened = false
      this.$store.dispatch('addRating', {'rating': 0, 'date': new Date(), 'version':
        this.buildVersion(), 'client': this.userAgent(), 'appOpened': this.appOpenedCount(), 'info': 'mdpush',
        'reviewRequested': this.reviewRequestedCount() } ).then(() => {})
    },
    ratingChanged(r) {
      this.rating = r
      this.overlayOpened = false
      this.$store.dispatch('addRating', {'rating': r, 'date': new Date(),
        'version': this.buildVersion(), 'client': this.userAgent(), 'appOpened': this.appOpenedCount(), 'info': 'mdpush',
        'reviewRequested': this.reviewRequestedCount() } ).then(() => {})
    },
    visibilityChange(evt, hidden) {
      Vue.$log.debug('DevRakingsByMatchday.vue visibilityChange hidden ' + hidden)
      if(hidden === true) {
        Vue.$log.debug('DevRakingsByMatchday.vue visibilityChange hidden ' + this.betPointsMapping.size)
        this.betPointsMapping.clear()
      }
      if(hidden === false) {
        if(this.isBattle() || this.isTeamsBetgame()) {
          this.parsedMatches = JSON.parse(JSON.stringify(this.$store.state.matches))
        }

        if(this.isTeamsBetgame() && this.hasTodaysMatches) {
          this.selectedTab = 'todaysMatches'
        } else if(this.isTeamsBetgame() && this.competitions.length > 0) {
          this.selectedTab = this.competitions[0].heimspielid
        }

        if(this.isBattle()) {
          const lastFetchLiveRanking = this.getBGValue('spmlflr')
          const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
          if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
              this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
            this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
              this.$store.dispatch('fetchBetsForMatchdayRankingBattle', [this.$store.state.betgameId, this.matchday]).then(() => {
                this.setBGValue('spmlflr', new Date())
              })
            })

          }
        } else if(this.isTeamsBetgame()) {
          const lastFetchLiveRanking = this.getBGValue('spmlflr')
          const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
          if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
              this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
            this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
              this.$store.dispatch('fetchBetsForMatchdayRankingTeam', [this.$store.state.betgameId, this.matchday]).then(() => {
                this.setBGValue('spmlflr', new Date())
              })
            })
          }
        } else {
          if(this.$store.state.leagueId) {
            const lastFetchLiveRanking = this.getBGValue('spmlflr')
            const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
            if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
                this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
              this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
                this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
                  this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
                    this.setBGValue('spmlflr', new Date())
                  })
                })
              })
            }
          } else {
            this.$store.dispatch('fetchBetgameLeague', this.$store.state.betgameId)
          }
        }

        this.$store.dispatch('fetchSchedule', this.$store.state.leagueId)

        //const lastFetchSchedule = this.getBGValue('spmlfs')
        //const duration2 = (new Date() - new Date(lastFetchSchedule)) / 1000
        //if(duration2 > this.$store.getters.getCachingDuration || this.$store.state.matches == null || this.$store.state.matches.length == 0) {
        //  this.$store.dispatch('fetchSchedule', this.$store.state.leagueId)
        //}

        if (this.$store.state.teams.length === 0) {
          this.$store.dispatch('fetchTeams', this.$store.state.betgameId)
        }
      }
    },
    selectedTabText(tab) {
          if(tab === 'todaysMatches') {
              return this.$i18n.t('bet.tab_todays_matches')
          } else {
              const competition = this.$store.state.competitions.filter(t => t.heimspielid === tab)
              if(competition && competition.length > 0) {
                  return competition[0].name
              }
          }
          return ''
      },
      competitionTabChanged(tabid) {
        Vue.$log.debug('DevRakingsByMatchday.vue competitionTabChanged ' + tabid + ' ' + this.hasTodaysMatches)
        if(this.selectedTab !== tabid) {
          this.selectedTab = tabid
          this.$store.dispatch('setBattleBetSelectedTab', this.selectedTab)
          const tabbar = document.getElementById('tournament_nav')
          const tab = document.getElementById(tabid)
          tabbar.scrollLeft = tab.offsetLeft - (tabbar.offsetWidth/4 + tab.offsetWidth/2)
        }
      },    
      teamLogo(teamid) {
          const l = this.getTeamLogo(teamid)
          if(l && l.length > 6) {
              return this.getBGValue('spmau') + '/images/jerseys/24/' + l
          } else if(l && l.length <= 6) {
              return l
          }
          return null
      },
      deepLinkClicked() {
          if(this.deepLink) {
              this.$gtag.event('Kombiwette', {
                  'event_category': 'bwin',
                  'event_label': this.matchday ? '' + this.matchday + '. Spieltag' : '',
                  'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
                  'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
                  'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set',
                  'dimension5': this.$store.state.betgame && this.$store.state.betgame.type ? this.$store.state.betgame.type : 'betgame type not set'
              })
              if(window.parent) {
                  window.parent.open(this.deepLink, '_blank')
              } else {
                  window.open(this.deepLink, '_blank')
              }
          }
      },
      showTodaysRanking() {
          this.$router.push('/todaysrankings').then(() => {
              
          })
      },
      scrollUserVisible() {
          const index = Math.floor((this.userLivePosition-1)/this.PAGE_SIZE)
          if(index !== this.pageIndex) {
              this.pageIndex = index
              this.componentKey += 1
          }
          const elements = this.$refs['' + this.$store.state.user.id]
          if(elements && elements.length > 0) {
              elements[0].scrollIntoView({ behavior: "smooth", block: "center" })
          }
      },
      sortByTotal() {
          if(this.$store.state.matchdayRankingSorting === 2) {
              this.$emit('sortingChanged', {})
              const lastFetchLiveRanking = this.getBGValue('spmlflr')
              const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
              if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length == 0 ||
                  this.$store.state.allbets == null || this.$store.state.allbets.length == 0) {
                  if (this.$store.state.leagueId) {
                      this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
                          this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
                              this.componentKey += 1
                          })
                      })
                  }
              }
              this.$store.dispatch('setMatchdayRakingSorting', 1)
          }
      },
      sortByMatchday() {
          if(this.$store.state.matchdayRankingSorting === 1) {
              this.$emit('sortingChanged', {})
              if(this.isTeamsBetgame()) {
          
              } else {
                if (this.$store.state.leagueId) {
                    this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
                        this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
                            this.componentKey += 1
                        })
                    })
                }
              }
              this.$store.dispatch('setMatchdayRakingSorting', 2)
          }
      },
      getBetResult (match, user) {
          const bp = this.betPoints(match, user)
          switch (bp) {
              case this.pointsBetResult:
                  return 'bet_exact'
              case this.pointsBetGoaldiff:
                  return 'bet_goal_diff'
              case this.pointsBetTendency:
                  return 'bet_result'
              default:
                  return 'bet_no_points'
          }
      },
    getClass (match, user) {
      const bp = this.betPoints(match, user)
      return { bet_exact: bp === this.pointsBetResult,
            bet_goal_diff: bp === this.pointsBetGoaldiff,
            bet_result: bp === this.pointsBetTendency,
            bet_no_points: bp === 0 }
    },
    bet (match, user) {
      if (user !== this.$store.state.user.id && !this.isNumeric(match.goalshome) &&
        !this.isNumeric(match.goalsguest) && !this.isNumeric(match.livegoalshome) &&
        !this.isNumeric(match.livegoalsguest)) {
        return '-:-'
      }
      const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, user)
      if (bet !== undefined && bet !== null && isNumeric(bet.goalshome) && isNumeric(bet.goalsguest)) {
        //const p = this.getPoints(bet, match)
        return bet.goalshome + ' : ' + bet.goalsguest //+ ' (' + p + ')'
      } else {
        return '-:-'
      }
    },
    totalmatchdaywins (user) {
      if (this.sorting === 1) {
        const t = this.$store.state.liveRankings.filter(r => r.fk_user === user)
        if (t.length === 1) {
          return t[0].matchday_wins
        }
        return 0
      } else if (this.sorting === 2) {
        const t = this.$store.state.liveRankingsByMatchday[this.matchday].filter(r => r.fk_user === user)
        if (t.length === 1) {
          return t[0].matchday_wins
        }
        return 0
      }
    },
    upOrDown (user, cur_position) {
      if(!this.hasSeasonStarted()) {
          return '='
      }
      const bu = this.$store.state.betgameUsers
      if (bu && bu.length > 50) {
        return ''
      }
      var res = []
      for (var i = 0; i < bu.length; i++) {
        var u = bu[i]
        var tp = this.totalpoints(u.fk_user)
        var lp = this.livepoints(u.fk_user)
        var tmp = { fk_user: u.fk_user, prev_points: tp - lp }
        res.push(tmp)
      }
      const res_sorted = res.sort(function (a, b) {
        return b.prev_points - a.prev_points
      })
      var upos = 0
      for (var i = 0; i < res_sorted.length; i++) {
        var u = res_sorted[i]
        if (user === u.fk_user) {
          if (i === 0) {
            upos = i + 1
          } else {
            var u_prev = res_sorted[i - 1]
            if (u_prev.prev_points === u.prev_points) {
              upos = i
            } else {
              upos = i + 1
            }
          }
          break
        }
      }
      if (cur_position === upos) {
        return '='
      } else if (cur_position < upos) {
        return 'u'
      } else if (cur_position > upos) {
        return 'd'
      } else {
        return ''
      }
    },
  betToto(match, user) {
      if(user !== this.$store.state.user.id && !this.isNumeric(match.goalshome) &&
          !this.isNumeric(match.goalsguest) && !this.isNumeric(match.livegoalshome) &&
          !this.isNumeric(match.livegoalsguest)) {
          return '-'
      }
      const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, user)
      if(bet !== undefined && bet !== null && isNumeric(bet.goalshome) && isNumeric(bet.goalsguest))  {
          return bet.goalshome
      } else {
          return '-'
      }
  },
    livepoints (user) {
      var result = 0
      for (var i = 0; i < this.matches.length; i++) {
        const match = this.matches[i]
        const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, user)
        const p = this.getPoints(bet, match)
        if (p > -1) {
          result += p
        }
      }
      return result
    },
    betPoints (match, user) {
      if (user !== this.$store.state.user.id &&
        match.goalshome === null && match.goalsguest === null &&
        match.livegoalshome === null && match.livegoalsguest === null) {
        return ''
      }
      const k = match.id + '_' + match.goalshome + '_' + match.goalsguest + '_' + match.livegoalshome + '_' + 
          match.livegoalsguest + '_' + user + '_' + this.$store.state.betgameId
      const v = this.betPointsMapping.get(k)
      if (v !== undefined) {
        return v
      }
      const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, user)
      if (bet !== undefined && bet !== null) {
        const p = this.getPoints(bet, match)
        this.betPointsMapping.set(k, p)
        return p
      } else {
        if ((this.isNumeric(match.goalshome) && this.isNumeric(match.goalsguest)) ||
          (this.isNumeric(match.livegoalshome) && this.isNumeric(match.livegoalsguest))) {
          return 0
        }
      }
    },
    totalpoints (user) {
      if (this.sorting === 1) {
        const t = this.$store.state.liveRankings.filter(r => r.fk_user === user)
        if (t.length === 1) {
          return t[0].points_total
        }
        return 0
      } else if (this.sorting === 2) { 
        if(this.$store.state.liveRankingsByMatchday && this.$store.state.liveRankingsByMatchday[this.matchday]) {
          const t = this.$store.state.liveRankingsByMatchday[this.matchday].filter(r => r.fk_user === user)
          if (t.length === 1) {
            return t[0].points_total
          }
        } else {
          if(this.isTeamsBetgame()) {
            const t = this.$store.state.liveRankings.filter(r => r.fk_user === user)
            if (t.length === 1) {
              return t[0].points_total
            }
          }
          return 0
        }
        return 0
      }
      return 0
    },
    equalPoints (index) {
      if (index === 0 || !this.matchdayRankings || this.matchdayRankings.length <= index) {
        return false
      }
      const r1 = this.matchdayRankings[index]
      const r2 = this.matchdayRankings[index - 1]
      if (this.isBattle() || this.isTournament()) {
        return this.totalpoints(r1.fk_user) === this.totalpoints(r2.fk_user) &&
          r1.exact === r2.exact && r1.goaldiff === r2.goaldiff
      } else {
        if (this.sorting === 1) {
          if (this.sortmode === 1) {
            return this.totalpoints(r1.fk_user) === this.totalpoints(r2.fk_user) &&
              this.totalmatchdaywins(r1.fk_user) === this.totalmatchdaywins(r2.fk_user) &&
              r1.exact === r2.exact && r1.goaldiff === r2.goaldiff
          } else if (this.sortmode === 2) {
            return this.totalpoints(r1.fk_user) === this.totalpoints(r2.fk_user) &&
              r1.exact === r2.exact &&
              this.totalmatchdaywins(r1.fk_user) === this.totalmatchdaywins(r2.fk_user) &&
              r1.goaldiff === r2.goaldiff
          }
        } else if (this.sorting === 2) {
          return this.livepoints(r1.fk_user) === this.livepoints(r2.fk_user)
        }
      }
      return false
    },
    getPoints (bet, match) {
      if (bet === null || match === null) {
          return -1
      }
      if (match.status && match.status === 'Wertung') {
          return -1
      }
      if (!this.isNumeric(match.goalshome) && !this.isNumeric(match.goalsguest) &&
          !this.isNumeric(match.livegoalshome) && !this.isNumeric(match.livegoalsguest)) {
          return -1
      }
      var goalshome = null
      var goalsguest = null
      if (this.resultmode === RESULT_MODE_90M && match.goalshome_90 !== null && match.goalsguest_90 !== null) {
          goalshome = parseInt(match.goalshome_90)
          goalsguest = parseInt(match.goalsguest_90)
      } else if (this.resultmode === RESULT_MODE_120M && match.goalshome_120 !== null && match.goalsguest_120 !== null) {
          goalshome = parseInt(match.goalshome_120)
          goalsguest = parseInt(match.goalsguest_120)
      } else if (match.livegoalshome !== null && match.livegoalsguest !== null &&
          match.goalshome === null && match.goalsguest === null) {
          goalshome = parseInt(match.livegoalshome)
          goalsguest = parseInt(match.livegoalsguest)
      } else if (match.goalshome !== null && match.goalsguest !== null) {
          goalshome = parseInt(match.goalshome)
          goalsguest = parseInt(match.goalsguest)
      }
      if (goalshome !== null && goalsguest !== null) {
          const betgoalshome = parseInt(bet.goalshome)
          const betgoalsguest = parseInt(bet.goalsguest)
          if (this.betmode === 1) {
              if ((betgoalshome === goalshome) && (betgoalsguest === goalsguest)) {
                  return this.$store.getters.pointsBetResult
              } else if ((betgoalshome - betgoalsguest) === (goalshome - goalsguest)) {
                  return this.$store.getters.pointsBetGoaldiff
              } else if ((betgoalshome > betgoalsguest) && (goalshome > goalsguest)) {
                  return this.$store.getters.pointsBetTendency
              } else if ((betgoalshome < betgoalsguest) && (goalshome < goalsguest)) {
                  return this.$store.getters.pointsBetTendency
              }
          } else if (this.betmode === 2) {
              if ((betgoalshome === 1) && (goalshome > goalsguest)) {
                  return this.$store.getters.pointsBetResult
              } else if ((betgoalshome === 0) && (goalshome === goalsguest)) {
                  return this.$store.getters.pointsBetResult
              } else if ((betgoalshome === 2) && (goalshome < goalsguest)) {
                  return this.$store.getters.pointsBetResult
              }
          }
      }
      return 0
    },
    started (match) {
      return (match.goalshome !== null && match.goalsguest !== null) || (match.livegoalshome !== null && match.livegoalsguest !== null)
    },
    finished (match) {
        return match.goalshome !== null && match.goalsguest !== null
    },
    cancelled (match) {
        return match.status !== null && match.status !== undefined && match.status === 'abgebrochen'
    },
    draw (match) {
      if (match.goalshome === null || match.goalsguest === null) {
        if (match.livegoalshome !== null && match.livegoalsguest !== null) {
          return match.livegoalshome === match.livegoalsguest
        }
        return false
      }
      return match.goalshome === match.goalsguest
    },
    winnerTeam (match, teanId) {
      if (match.goalshome === null || match.goalsguest === null) {
        if (match.livegoalshome !== null && match.livegoalsguest !== null) {
          if (match.livegoalshome > match.livegoalsguest) {
            return match.fk_team_home === teanId
          } else if (match.livegoalshome < match.livegoalsguest) {
            return match.fk_team_guest === teanId
          } 
        }
        return false
      }
      if (match.goalshome > match.goalsguest) {
        return match.fk_team_home === teanId
      } else if (match.goalshome < match.goalsguest) {
        return match.fk_team_guest === teanId
      } else {
        return false
      }
    },
    loserTeam (match, teanId) {
      if (match.goalshome === null || match.goalsguest === null) {
        if (match.livegoalshome !== null && match.livegoalsguest !== null) {
          if (match.livegoalshome > match.livegoalsguest) {
            return match.fk_team_guest === teanId
          } else if (match.livegoalshome < match.livegoalsguest) {
            return match.fk_team_home === teanId
          }
        }
        return false
      }
      if (match.goalshome > match.goalsguest) {
        return match.fk_team_guest === teanId
      } else if (match.goalshome < match.goalsguest) {
        return match.fk_team_home === teanId
      } else {
        return false
      }
    },
    matchdaySelected (m) {
      //this.matchday = m
      this.pageIndex = 0
      this.betPointsMapping.clear()
      this.$store.dispatch('setCurrentMatchdayRankingByMatchday', m)
      this.$modal.hide('matchday-select')
      if (this.$store.state.matchdayRankings.length > this.matchday) {
        const mdr = this.$store.state.matchdayRankings[this.matchday]
        if (mdr === undefined || mdr === null) {
          this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
            this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
            })
          })
        } else {
          this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
          })
        }
      } else {
        this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
          this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
          })
        })
      }
    },
    matchStatusText (match) {
      return this.$i18n.t('match_dismissed_short')
    },
    selectMatchday () {
      this.$modal.show('matchday-select')
    },
    increment () {
      this.pageIndex = 0
      this.betPointsMapping.clear()
      if (this.matchday < this.$store.state.matchdayCount) {
        //this.matchday++
        this.$store.dispatch('setCurrentMatchdayRankingByMatchday', this.matchday + 1)
      } else {
        //this.matchday = 1
        this.$store.dispatch('setCurrentMatchdayRankingByMatchday', 1)
      }
      if (this.$store.state.matchdayRankings.length > this.matchday) {
        const mdr = this.$store.state.matchdayRankings[this.matchday]
        if (mdr === undefined || mdr === null) {
          this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
            this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
            })
          })
        } else {
          this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
          })
        }
      } else {
        this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
          this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
          })
        })
      }
    },
    decrement () {
      this.pageIndex = 0
      this.betPointsMapping.clear()
      if (this.matchday > 1) {
        //this.matchday--
        this.$store.dispatch('setCurrentMatchdayRankingByMatchday', this.matchday - 1)
      } else {
        //this.matchday = this.$store.state.matchdayCount
        this.$store.dispatch('setCurrentMatchdayRankingByMatchday', this.$store.state.matchdayCount)
      }
      if (this.$store.state.matchdayRankings.length > this.matchday) {
        const mdr = this.$store.state.matchdayRankings[this.matchday]
        if (mdr === undefined || mdr === null) {
          this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
            this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
            })
          })
        } else {
          this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
          })
        }
      } else {
        this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
          this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
          })
        })
      }
    },
    resetPageIndex() {
      this.pageIndex = 0
      this.componentKey += 1
    },
    pageIndexDown() {
      Vue.$log.debug('DevRakingsByMatchday pageIndexDown ' + this.betPointsMapping.size)
      if (this.pageIndex > 0) {
        this.pageIndex -= 1
        this.componentKey += 1
      }
    },
    pageIndexUp() {
      Vue.$log.debug('DevRakingsByMatchday pageIndexUp ' + this.betPointsMapping.size)
      if (this.$store.state.liveRankings && this.$store.state.liveRankings.length > this.PAGE_SIZE && 
          this.pageIndex < Math.floor(this.$store.state.liveRankings.length / this.PAGE_SIZE)) {
        this.pageIndex += 1
        this.componentKey += 1
      }
    },
    refreshRankingByMatchday () {
      const LOADING_TIMEOUT = 400 
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, LOADING_TIMEOUT)
      this.betPointsMapping.clear()
      if (this.isBattle()) {
          this.$store.dispatch('fetchSchedule', this.$store.state.leagueId).then(() => {
          })
          const lastFetchLiveRanking = this.getBGValue('spmlflr')
          const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
          if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
              this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
              const that = this
              this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
                  this.$store.dispatch('fetchBetsForMatchdayRankingBattle', [this.$store.state.betgameId, this.matchday]).then(() => {
                    if(this.$store.state.user) {
                      this.$store.dispatch('fetchBets', this.$store.state.user.id).then(() => {
                        this.setBGValue('spmlflr', new Date())
                        this.componentKey += 1
                      })
                    } else {
                      //this.$store.dispatch('fetchMatchdaySchedule', [this.$store.state.leagueId, this.matchday]).then(() => {
                      this.setBGValue('spmlflr', new Date())
                      this.componentKey += 1
                      //})
                    }
                  })
              }).finally(function () {
                  that.isLoading = false
              })
          } else {
              const that = this
              setTimeout(() => {
                  that.isLoading = false
              }, LOADING_TIMEOUT)
          }
      } else if (this.isTeamsBetgame()) {
        this.$store.dispatch('fetchSchedule', this.$store.state.leagueId).then(() => {
        })
        const lastFetchLiveRanking = this.getBGValue('spmlflr')
        const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
        if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
            this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
          const that = this
          this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
            this.$store.dispatch('fetchBetsForMatchdayRankingTeam', [this.$store.state.betgameId, this.matchday]).then(() => {
              if(this.$store.state.user) {
                this.$store.dispatch('fetchBets', this.$store.state.user.id).then(() => {
                  this.setBGValue('spmlflr', new Date())
                  this.componentKey += 1
                })
              } else {
                //this.$store.dispatch('fetchMatchdaySchedule', [this.$store.state.leagueId, this.matchday]).then(() => {
                this.setBGValue('spmlflr', new Date())
                this.componentKey += 1
                //})
              }
            })
          }).finally(function () {
            that.isLoading = false
          })
        } else {
          const that = this
          setTimeout(() => {
            that.isLoading = false
          }, LOADING_TIMEOUT)
        }
      } else {
          this.$store.dispatch('fetchMatchdaySchedule', [this.$store.state.leagueId, this.matchday]).then(() => {
          })
          const lastFetchLiveRanking = this.getBGValue('spmlflr')
          const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
          if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
              this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
              if (this.$store.state.leagueId) {
                  const that = this
                  this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
                      this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
                          this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
                            if(this.$store.state.user) {
                              this.$store.dispatch('fetchBets', this.$store.state.user.id).then(() => {
                                this.setBGValue('spmlflr', new Date())
                                this.componentKey += 1
                                })
                            } else {
                              //this.$store.dispatch('fetchMatchdaySchedule', [this.$store.state.leagueId, this.matchday]).then(() => {
                              this.setBGValue('spmlflr', new Date())
                              this.componentKey += 1
                              //})
                            }
                          })
                      })
                  }).finally(function () {
                    setTimeout(() => {
                      that.isLoading = false
                    }, LOADING_TIMEOUT)
                  })
              }
          } else {
              const that = this
              setTimeout(() => {
                  that.isLoading = false
              }, LOADING_TIMEOUT)
          }
      }
    }
  }
}
</script>

<style lang="scss">
    .banner-box {
        max-width: 440px;
        margin: 16px auto 0;

        &__content {
            display: flex;
            align-items: center;
            padding: 16px 16px 16px 0;
            background: $colorWhite;
            box-shadow: 0 8px 24px 0 rgba($colorGreyDark,0.08);
            border-radius: 4px;
            overflow: hidden;
            box-sizing: border-box;

            @media screen and (min-width: 440px) {
                padding: 16px 16px 16px 24px;
            }
        }

        &__icon {
            width: 96px;
            height: 96px;
            margin: 0 16px 0 -24px;

            @media screen and (min-width: 440px) {
                margin: 0 24px 0 0;
            }
        }

        &__text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &__headline {
            font-size: 17px;
            font-weight: 500;
            color: $colorGreyDark;
            margin-bottom: 2px;
        }

        &__copy {
            font-size: 14px;
            color: rgba($colorGreyDark, .48);
            margin-bottom: 12px;
        }

        .button {
            display: inline-flex;
            font-size: 14px;
            padding: 8px 12px 7px;
            border-radius: 4px;
            white-space: nowrap;

            img {
                margin-top: -2px;
            }
        }

        &__meta {
            display: flex;
            justify-content: center;
            margin-top: 10px;
            font-size: 11px;
            font-weight: 400;
            color: rgba($colorGreyDark, .48);
        }
    }
</style>

<style lang="scss" scoped>

/* THIS PADDING NEEDS TO BE SIZE OF THE TOPNAVIGATION + THE HEIGHT OF THE BWIN-BANNER */
.bwin-visible {
    padding-top: calc(64px + 168px) !important;
}

/* THIS PADDING NEEDS TO BE SIZE OF THE TOPNAVIGATION + THE HEIGHT OF THE LEAGUE TABBAR */
.tabbar-visible {
    padding-top: calc(64px + 44px) !important;
}

.tabbar-bwin-visible {
    padding-top: calc(64px + 44px + 168px) !important;
}

/* THIS PADDING NEEDS TO BE SIZE OF THE TOPNAVIGATION + THE HEIGHT OF THE BACK BUTTON */
.today-view {
  padding-top: calc(64px + 48px) !important;
}

.banner-box {
  position: fixed;
  left: 50%;
  width: calc(100vw - 32px);
  top: 64px;
  transform: translateX(-50%);
  box-sizing: border-box;

    .tabbar-bwin-visible & {
        top: 108px;
    }
}

.ranking {
  position: relative;
  z-index: 2;
  width: calc(100% + 32px);
  margin: 12px -16px 0;
  box-sizing: border-box;
  color: $colorGreyDark;
  padding-bottom: 140px;

  @media screen and (min-width: 1024px) {
    padding-bottom: 56px;
  }

  &__header {
    position: sticky;
    top: 64px;
    display: flex;
    background: $colorBackground;
    z-index: 3;
    box-shadow: inset 0 -1px 0 0 rgba($colorGreyDark, .08);

    &__item {
      display: flex;
      flex-shrink: 0;
      padding: 4px 4px;
      box-sizing: border-box;
      background: $colorBackground;
      box-shadow: inset 0 -1px 0 0 rgba($colorGreyDark, .08);

      &--user {
        display: flex;
        align-items: center;
        width: 160px;
        padding-left: 16px;
        box-sizing: border-box;
        position: sticky;
        left: 0;
        background: $colorBackground;
        z-index: 2;
        box-shadow: inset -1px -1px 0 0 rgba($colorGreyDark, .08);
        font-size: 14px;
        font-weight: 500;
        color: rgba($colorGreyDark, .48);
      }

      &--points {
        display: flex;
        align-items: center;
        padding: 0 8px;
        font-size: 9px;
        font-weight: 500;
        text-transform: uppercase;
        box-shadow: inset -1px -1px 0 0 rgba($colorGreyDark, .08);

        span {
          background: rgba(var(--color-primary-rgb), .12);
          color: var(--color-primary);
          border-radius: 0 2px 2px 0;
          padding: 3px 4px;
          cursor: pointer;
          transition: all .2s ease-in-out;
          width: 48px;
          box-sizing: border-box;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          &:hover {
            background: rgba(var(--color-primary-rgb), .2);
          }

          &.selected {
            background: var(--color-primary);
            color: $colorWhite;
            border-radius: 2px 0 0 2px;
          }
        }
      }

      &--match {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 11px;
        font-weight: 500;
        width: 40px;

        @media screen and (min-width: 1024px) {
          flex: 1;
        }

        span {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 24px;
          color: rgba($colorGreyDark, .32);

          img, svg, .flag {
            opacity: .32;
            width: 16px;
            height: 16px;
          }

          .flag {
            border-radius: 50%;
            width: 12px;
            height: 12px;
            margin: 2px 0;
          }

          &:first-of-type {
            border-bottom: dashed 1px rgba($colorGreyDark, .32);
          }

          &.finished {
            color: rgba($colorGreyDark, .64);

            img, svg, .flag {
                opacity: 1;
            }
          }

          &.lost, &.cancelled {
            color: rgba($colorGreyDark, .32);

            img, svg, .flag {
                opacity: .32;
            }
          }

          &.win, &.draw {
            color: rgba($colorGreyDark, .64);

            img, svg, .flag {
                opacity: 1;
            }
          } 
            
          &.live_result {
              color: $colorNegative;
          }  
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    background: $colorWhite;
    box-shadow: 0 8px 24px 0 rgba(49, 49, 55, 0.08);

    @media screen and (min-width: 1024px) {
      margin-bottom: 48px;
    }
  }

  &__item {
    display: flex;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;

    &:not(:last-child) {
      box-shadow: inset 0 -1px 0 0 rgba($colorGreyDark, .08);

      .ranking__item__column {
        box-shadow: inset 0 -1px 0 0 rgba($colorGreyDark, .08);

        &--user {
          box-shadow: inset -1px -1px 0 0 rgba($colorGreyDark, .08);
        }

        &--points {
          box-shadow: inset -1px -1px 0 0 rgba($colorGreyDark, .08);
        }
      }
    }

    &--currentuser {
        background: #F2F2F3;
    }

    &__column {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      padding: 14px 4px;
      box-sizing: border-box;
      background: $colorWhite;
      white-space: nowrap;
      min-width: 32px;

      .ranking__item--currentuser & {
        background: #F2F2F3 !important;
      }

      &:nth-child(2n+4) {
        background: #FAFAFA;
      }

      &--user {
        width: 160px;
        box-shadow: inset -1px 0 0 0 rgba($colorGreyDark, .08);
        padding-left: 16px;
        box-sizing: border-box;
        position: sticky;
        left: 0;
        background: $colorWhite;
        z-index: 2;

        span {
            flex-shrink: 0;
            width: 32px;
            text-align: center;
            margin-left: -16px;
          
            &.morethan999 {
              width: 40px;
            }
        }

        img {
          width: 12px;
          height: 12px;
          margin-right: 8px;
        }

        div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
      }

      &--points {
        padding: 0 8px;
        box-shadow: inset -1px 0 0 0 rgba($colorGreyDark, .08);

        span {
          font-weight: 400;
          color: rgba($colorGreyDark, .48);
          text-align: center;

          &.selected {
            font-weight: 500;
            color: $colorGreyDark;
          }

          &:first-of-type {
            width: 48px;
          }

          &:nth-child(2) {
            width: 48px;
          }
        }

      }

      &--match {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;

        @media screen and (min-width: 1024px) {
          flex: 1;
        }
      }

      &__goals {
        font-weight: 400;
        letter-spacing: -1.4px;
        color: rgba($colorGreyDark, .48);
        position: relative;

        &--exact, &--tendency, &--result {
          font-weight: 500;
        }

        &--exact {
          color: $colorPositive;
        }

        &--tendency {
          color: $colorYellow;
        }

        &--result {
          color: $colorGreyDark;
        }

        span {
          top: -6px;
          right: -6px;
          position: absolute;
          font-size: 8px;
        }
      }
    }
  }

  &__sticky {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 4;
    bottom: calc(8px + 44px);
    left: 8px;
    width: calc(100% - 16px);
    background: $colorWhite;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .08), 0 12px 24px 0 rgba(0, 0, 0, .08), 0 0 0 1px rgba($colorGreyDark, .08);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: $colorGreyDark;
    padding: 10px 10px 10px 8px;
    box-sizing: border-box;
    overflow: hidden;
    opacity: 1;
    transform: translateY(0);
    transition: all .2s ease-in-out;

    /* "BOTTOM" IS "0", BECAUSE THE BOTTOMNAVIGATION IS NOT DISPLAYED, IN THE VIEW FOR MATCHES, WHICH ARE RUNNING ONLY TODAY */
    .today-view & {
      bottom: 8px;
    }

    @media screen and (min-width: 1024px) {
      max-width: 768px;
      bottom: calc(8px + 52px);
      left: 50%;
      transform: translateX(-50%);
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .12), 0 12px 24px 0 rgba(0, 0, 0, .12), inset 0 0 1px 0 rgba($colorGreyDark, .08);
    }

    &--hidden {
        opacity: 0;
        transform: translateY(25%);

        @media screen and (min-width: 1024px) {
            transform: translate(-50%, 25%);
        }
    }

    &__icon {
      position: relative;
      display: flex;
      margin: 0 12px 0 -32px;
      width: 48px;
      height: 48px;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform-origin: center center;
        transform: translate(-50%, -50%);
        background: $colorLive;
        border-radius: 50%;

        &:nth-child(1) {
          width: 12px;
          height: 12px;
          animation: live-01 3s infinite;
        }

        @keyframes live-01 {
          0% {
            width: 12px;
            height: 12px;
          }
          50% {
            width: 16px;
            height: 16px;
          }
          100% {
            width: 12px;
            height: 12px;
          }
        }

        &:nth-child(2) {
          width: 12px;
          height: 12px;
          animation: live-02 3s infinite .75s;
        }

        @keyframes live-02 {
          0% {
            transform: translate(-50%, -50%) scale(1);
            opacity: .6;
          }
          100% {
            transform: translate(-50%, -50%) scale(4);
            opacity: 0;
          }
        }

        &:nth-child(3) {
          width: 12px;
          height: 12px;
          animation: live-03 3s infinite 1s;
        }

        @keyframes live-03 {
          0% {
            transform: translate(-50%, -50%) scale(1);
            opacity: .6;
          }
          100% {
            transform: translate(-50%, -50%) scale(4);
            opacity: 0;
          }
        }
      }
    }

    &__position {
      width: 32px;
      text-align: center;
    }

    &__text {
      flex: 1;
      margin: 3px 8px 3px 8px;

      &__label {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        .highlight {
          font-weight: 600;
          color: $colorLive;
        }
      }

      &__sublabel {
        font-size: 11px;
        font-weight: 400;
        line-height: 14px;
        color: rgba($colorGreyDark, .48);
        margin-top: 4px;
      }
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 3px;
      background: var(--color-primary);
      margin-left: 8px;

      img {
        .ranking__sticky--hint & {
          transform: rotate(-90deg);
        }
      }
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: rgba($colorGreyDark, .48);

    span {
      color: $colorGreyDark;
      margin: 0 3px;
    }

    &__arrow {
      display: flex;
      align-items: center;
      padding: 14px 16px;
        cursor: pointer;

      &--left {
        margin-right: auto;
      }

      &--right {
        margin-left: auto;
      }

      &--disabled {
        opacity: .48;
        pointer-events: none;
        cursor: inherit;
      }
    }
  }
}

.button--fixed {
  position: fixed;
  z-index: 4;
  top: 64px;
  left: 0;
  width: 100%;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
}

.toto-result {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin: -2px auto;
    font-size: 11px;
    font-weight: 600;
    color: $colorGreyDark;
    border-radius: 3px;
    box-shadow: inset 0 0 0 1.5px rgba($colorGreyDark, .16);
    background: none;

    &.bet_no_points {
        color: $colorWhite;
        background: $colorNegative;
        box-shadow: none;
    }

    &.bet_exact {
        color: $colorWhite;
        background: $colorPositive;
        box-shadow: none;
    }
}

.toto-match-cancelled {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin: -2px auto;
    font-size: 11px;
    font-weight: 600;
    color: $colorGreyDark;
    border-radius: 3px;
    box-shadow: inset 0 0 0 1.5px rgba($colorGreyDark, .16);
    background: none;
}

.burger_menu {
    width: 64px;
    height: 64px;
    position: relative;
    cursor: pointer;

    span {
        width: 16px;
        height: 2px;
        position: absolute;
        left: 16px;
        top: 31px;
        background: $colorGreyDark;

        &:first-child {
            top: 31px;
            transform: rotate(45deg);
        }

        &:last-child {
            top: 31px;
            transform: rotate(-45deg);
        }
    }
}

.menu_header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    height: 64px;
    align-items: center;
    background: linear-gradient(to bottom, $colorWhite 65%, rgba($colorWhite, .8) 80%, rgba($colorWhite, 0) 100%);

    &-title {
        font-size: 17px;
        color: $colorGreyDark;
        text-align: center;
        justify-content: center;
        display: flex;
        flex: 1;
    }
}

.matchday-select-list {
    padding: 64px 8px 16px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 576px;
    margin: 0px auto;

    button {
        background: rgba($colorGreyDark, .04);
        width: calc(20% - 16px);
        box-shadow: inset 0 0 0 1px rgba(95,93,97,0.08);
        border-radius: 4px;
        color: $colorGreyDark;
        padding: 0;
        display: flex;
        flex: 1 0 calc(20% - 16px);
        flex-grow: 0;
        margin: 8px 8px;
        justify-content: center;
        align-items: center;
        position: relative;

        @media screen and (min-width: 768px) {
            width: calc(16.3% - 16px);
            flex: 1 0 calc(16.3% - 16px);
            flex-grow: 0;
        }

        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }
    }

    &--longtitles {
        flex-direction: column;

        button {
            width: 100%;
            flex: 1;
            padding: 12px 0;
            margin: 8px 0;

            &:before {
                padding-top: 0;
            }
        }
    }
}

/* Hide scrollbar for IE and Edge */
#tournament_nav {
    margin: 0;
    position: fixed;
    top: 64px;
    left: 0;
    background: $colorBackground;
}

.sticky_correct_bottom {
  bottom: 12px !important;
}

</style>
