<template>

  <!-- TODO @Lennard: Add class "bwin-visible" if bwin banner is displayed and the class "today-view" if the view with matches only from today are displayed -->
    <div class="devrankings today-view">
        
        <NavigationBar>
            <template v-slot:center>
                <div class="navigation_title--headline">
                    {{ $t('menu.matchday_ranking') }}
                </div>
                <div class="navigation_title--subline">
                  {{ $t('today') }}
                </div>
            </template>
            <template v-slot:right>
                <!--
                <div v-on:click="toggleSearch" class="navigation_icon small">
                    <SearchIcon></SearchIcon>
                </div>
                -->
            </template>
        </NavigationBar>
        
        <!-- TODO @Lennard: Show this button only if the case "2 matches from different matchdays" is active  -->
        <router-link :to="{ name: 'rankingsbymatchday', params: {}}" class="router_link">
            <a href="" class="button button--fixed">
                <img src="../assets/icons/ic_back_white.svg" alt="" class="button-icon button-icon--left">Zurück zur Spieltagsübersicht
            </a>
        </router-link>
        
        <!-- Table Container -->
        <div class="ranking">
            
            <!-- Table Header -->
            <div class="ranking__header">
                <div class="ranking__header__item ranking__header__item--user">
                    #
                </div>
                <div class="ranking__header__item ranking__header__item--points">
                    <span v-on:click="sortByTotal" v-bind:class="sorting === 1 ? 'selected' : ''" >{{ $t('total') }}</span>
                    <span v-if="!isBattle() && !isTournament()" v-on:click="sortByMatchday" v-bind:class="sorting === 2 ? 'selected' : ''" >{{ $t('today') }}</span>
                </div>
                <div v-for="(match, index) in matches" class="ranking__header__item ranking__header__item--match">
                    <span v-bind:class="{ lost: loserTeam(match, match.fk_team_home), win: winnerTeam(match, match.fk_team_home) }">
                        <!--
                        <img v-if="teamLogo(match.fk_team_home) && teamLogo(match.fk_team_home).length > 6" :src="teamLogo(match.fk_team_home)" width="16">
                        <div v-else-if="teamLogo(match.fk_team_home) && teamLogo(match.fk_team_home).length <= 6">
                            <flag :iso="teamLogo(match.fk_team_home)"/>
                        </div>
                        <div v-else>
                            <svg width="24px" height="16px" viewBox="0 0 24 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': getTeamColor(match.fk_team_home)}"></path></svg>
                        </div>
                        -->
                        {{ getTeamShort(match.fk_team_home, match.team_home) }}
                    </span>
                    <span v-bind:class="{ lost: loserTeam(match, match.fk_team_guest), win: winnerTeam(match, match.fk_team_guest) }">
                        {{ getTeamShort(match.fk_team_guest, match.team_guest) }}
                        <!--
                        <img v-if="teamLogo(match.fk_team_guest) && teamLogo(match.fk_team_guest).length > 6" :src="teamLogo(match.fk_team_guest)" width="16">
                        <div v-else-if="teamLogo(match.fk_team_guest) && teamLogo(match.fk_team_guest).length <= 6">
                            <flag :iso="teamLogo(match.fk_team_guest)" />
                        </div>
                        <div v-else>
                            <svg width="24px" height="16px" viewBox="0 0 24 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': getTeamColor(match.fk_team_guest)}"></path></svg>
                        </div>
                        -->
                    </span>
                    <span v-if="match.status && match.status === 'Wertung'">{{ matchStatusText(match) }}</span>
                    <span v-else-if="betgame.result_mode === 3 && isNumeric(match.goalshome_90) && isNumeric(match.goalsguest_90)"
                          class="finished">{{ match.goalshome_90 }}:{{ match.goalsguest_90 }}</span>
                    <span v-else-if="betgame.result_mode === 2 && isNumeric(match.goalshome_120) && isNumeric(match.goalsguest_120)"
                          class="finished">{{ match.goalshome_120 }}:{{ match.goalsguest_120 }}</span>
                    <span v-else-if="isNumeric(match.goalshome) && isNumeric(match.goalsguest)"
                          class="finished">{{ match.goalshome }}:{{ match.goalsguest }}</span>
                    <span v-else-if="isNumeric(match.livegoalshome) && isNumeric(match.livegoalsguest)"
                          class="live_result">{{ match.livegoalshome }}:{{ match.livegoalsguest }}</span>
                    <span v-else-if="match.status && match.status.length > 2">{{
                        match.status.slice(0, 3).toUpperCase()
                        }}</span>
                    <span v-else>-:-</span>
                </div>
            </div>
            
            <!-- Table Content -->
            <div class="ranking__content">
                <div v-for="(ranking, index) in matchdayRankings" class="ranking__item" :id="ranking.fk_user" :ref="ranking.fk_user">
                    <div class="ranking__item__column ranking__item__column--user">
                        <span v-if="sorting === 1 && !equalPoints(index)">{{ ranking.position }}.</span>
                        <span v-else-if="sorting === 1 && equalPoints(index)"></span>
                        <span v-else-if="sorting === 2 && !equalPoints(index)">{{ index + 1 }}.</span>
                        <span v-else-if="sorting === 2 && equalPoints(index)"></span>
                        <img v-if="upOrDown(ranking.fk_user, sorting === 1 ? ranking.position : index + 1) === '='"
                             src="../assets/icons/ic_12_ranking_equal.svg">
                        <img v-else-if="upOrDown(ranking.fk_user, sorting === 1 ? ranking.position : index + 1) === 'd'"
                             src="../assets/icons/ic_12_ranking_down.svg">
                        <img v-else-if="upOrDown(ranking.fk_user, sorting === 1 ? ranking.position : index + 1) === 'u'"
                             src="../assets/icons/ic_12_ranking_up.svg">
                        <div>{{ ranking.user_name }}</div>
                    </div>
                    <div class="ranking__item__column ranking__item__column--points">
                        <span v-bind:class="sorting === 1 ? 'selected' : ''" >{{ totalpoints(ranking.fk_user) }}</span>
                        <span v-if="!isBattle() && !isTournament()" v-bind:class="sorting === 2 ? 'selected' : ''" >{{ livepoints(ranking.fk_user) }}</span>
                    </div>
                    <div v-for="(match, index) in matches" class="ranking__item__column ranking__item__column--match">
                        <span v-if="betmode === 1 && match.status && match.status === 'Wertung'" class="bet_no_points" v-bind:class="getClass(match, ranking.fk_user)">
                          x
                        </span>
                        <div v-else-if="betmode === 2 && match.status && match.status === 'Wertung'" class="toto-match-cancelled" v-bind:class="getClass(match, ranking.fk_user)">
                            x
                        </div>
                        <div v-else-if="betmode === 2" class="toto-result" v-bind:class="getClass(match, ranking.fk_user)">
                            {{ betToto(match, ranking.fk_user) }}
                        </div>
                        <div v-else-if="getClass(match, ranking.fk_user) === 'bet_exact'" class="ranking__item__column__goals ranking__item__column__goals--exact">
                            {{ bet(match, ranking.fk_user) }}
                            <span v-if="betPoints(match, ranking.fk_user) > 0">{{ betPoints(match, ranking.fk_user) }}</span>
                        </div>
                        <div v-else-if="getClass(match, ranking.fk_user) === 'bet_no_points'" class="ranking__item__column__goals">
                            {{ bet(match, ranking.fk_user) }}
                            <span v-if="betPoints(match, ranking.fk_user) > 0">{{ betPoints(match, ranking.fk_user) }}</span>
                        </div>
                        <div v-else-if="getClass(match, ranking.fk_user) === 'bet_result'" class="ranking__item__column__goals ranking__item__column__goals--result">
                            {{ bet(match, ranking.fk_user) }}
                            <span v-if="betPoints(match, ranking.fk_user) > 0">{{ betPoints(match, ranking.fk_user) }}</span>
                        </div>
                        <div v-else-if="getClass(match, ranking.fk_user) === 'bet_goal_diff'" class="ranking__item__column__goals  ranking__item__column__goals--tendency">
                            {{ bet(match, ranking.fk_user) }}
                            <span v-if="betPoints(match, ranking.fk_user) > 0">{{ betPoints(match, ranking.fk_user) }}</span>
                        </div>
                        <div v-else class="ranking__item__column__goals">
                            -:-
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
        
        <!-- TODO @LENNARD: Show this container only if the group has a high number of members and the current position of the user is "30" or higher. By clicking on the container the view should jump to the positon of the user and the container should disappear (best case: with scroll animation) -->
        <div v-for="(ranking, index) in usermatchdayRanking" class="ranking__sticky" :class="userTableEntryVisible ? 'ranking__sticky--hidden' : ''">
            <div class="ranking__sticky__position">
                {{ overlayUserPosition }}
            </div>
            <div class="ranking__sticky__text">
                <div class="ranking__sticky__text__label">
                    {{ ranking.user_name }}
                </div>
            </div>
            <div class="ranking__sticky__points">
                {{ overlayUserPoints }}
            </div>
            <div v-on:click="scrollUserVisible" class="ranking__sticky__button">
                <img src="../assets/icons/ic_12_down_white.svg" alt="">
            </div>
        </div>
        
    </div>
</template>

<script>
import NavigationBar from './../components/NavigationBar'
import CalendarIcon from './../components/icons/CalendarIcon'
import { isNumeric } from '@/vuex/helpers'
import { TOP_TEN } from '@/main'
import UpdateIcon from '@/components/icons/UpdateIcon.vue'
import SearchIcon from '@/components/icons/SearchIcon.vue'
import moment from 'moment'

export default {
  name: 'rankingstodaysmatches',
  data () {
    return {
        deepLink: 'https://sports.bwin.de/de/sports/fussball-4/wetten?wm=5047188',
        userTableEntryVisible: false,
    }
  },
  components: { SearchIcon, UpdateIcon, NavigationBar, CalendarIcon },
  beforeCreate () {
  },
  created () {
      const md = this.$route.params.matchday
      if(this.isNumeric(md)) {
          //this.matchday = md
          this.$store.dispatch('setCurrentMatchdayRankingByMatchday', md)
      } else {
          //this.matchday = this.$store.state.currentMatchday
      }

      if(this.isBattle()) {
          const lastFetchLiveRanking = this.getBGValue('spmlflr')
          const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
          if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
              this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
              this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
                  this.$store.dispatch('fetchBetsForMatchdayRankingBattle', [this.$store.state.betgameId, this.matchday]).then(() => {
                      this.setBGValue('spmlflr', new Date())
                  })
              })

          }
      } else if(this.isTeamsBetgame()) {
        const lastFetchLiveRanking = this.getBGValue('spmlflr')
        const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
        if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
            this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
          this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
            this.$store.dispatch('fetchBetsForMatchdayRankingTeam', [this.$store.state.betgameId, this.matchday]).then(() => {
              this.setBGValue('spmlflr', new Date())
            })
          })
  
        }
      } else {
          if(this.$store.state.leagueId) {
              //const lastFetchLiveRanking = this.getBGValue('spmlflr')
              //const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
              //if (this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
              //    this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
                  this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
                      this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
                          this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday, '', 'ignoreMatchday']).then(() => {
                              this.setBGValue('spmlflr', new Date())
                          })
                      })
                  })
              //}
          } else {
              this.$store.dispatch('fetchBetgameLeague', this.$store.state.betgameId)
          }
      }

      const lastFetchSchedule = this.getBGValue('spmlfs')
      const duration2 = (new Date() - new Date(lastFetchSchedule)) / 1000
      if(duration2 > this.$store.getters.getCachingDuration || this.$store.state.matches == null || this.$store.state.matches.length == 0) {
          this.$store.dispatch('fetchSchedule', this.$store.state.leagueId)
      }

      if (this.$store.state.teams.length === 0) {
          this.$store.dispatch('fetchTeams', this.$store.state.betgameId)
      }
  },
  mounted () {
      const elementToWatch = document.getElementById('' + this.$store.state.user.id)
      const options = {
          root: document.querySelector("#ranking__content"),
          rootMargin: "-80px 0px -20px 00px",
          threshold: 1.0,
      }
      const observer = new IntersectionObserver(entries => {
          const firstEntry = entries[0]
          if (firstEntry.isIntersecting) {
              this.userTableEntryVisible = true
          } else {
              this.userTableEntryVisible = false
          }
      }, options);
      observer.observe(elementToWatch)
  },
  computed: {
      competition() {
          return this.$store.state.betgame.competition.id
      },
      todaysMatchesSameMatchday() {
          return !this.$store.getters.todaysMatchesMultipleMatchdays
      },
      betmode() {
          return this.$store.state.betgame.bet_mode || 1
      },
      overlayUserPoints() {
          if(this.$store.state.matchdayRankingSorting === 1) {
              return this.userTotalPoints
          } else {
              return this.livepoints(this.$store.state.user.id)
          }
      },
      overlayUserPosition() {
          if(this.$store.state.matchdayRankingSorting === 1) {
              return this.userLivePosition + '.'
          } else {
              if (!this.matchdayRankings || this.matchdayRankings.length === 0) {
                  return ''
              }
              for(let i = 0; i < this.matchdayRankings.length; i++) {
                  if (this.matchdayRankings[i].fk_user === this.$store.state.user.id) {
                      return i + 1 + '.'
                  }
              }
              return ''
          }
      },
      showOdds() {
        return false
        /*
          if(this.$store.state.betgame && this.$store.state.user) {
              return !this.isHandballBetgame && !this.countryBlocked() && this.$store.state.betgame.hide_ads === false && this.$store.state.user.isadult === true
          }
          return false
          
         */
      },
      matchRunning() {
          if(this.$store.state.matches === null || this.$store.state.matches === undefined) {
              return false
          }
          for (var i = 0; i < this.$store.state.matches.length; i++) {
              const match = this.$store.state.matches[i]
              if ((this.isBattle() || match.matchday === this.matchday) && this.isNumeric(match.livegoalshome) && this.isNumeric(match.livegoalsguest)) {
                  return true
              }
          }
      },
      matchdayText() {
        if(this.$store.state.betgame && this.$store.state.betgame.competition &&
            (this.$store.state.betgame.competition.id === 19 || this.$store.state.betgame.competition.id === 132)) {
          const nextMatchday = this.matchday
          if(nextMatchday === 9) {
            return this.$i18n.t('ko_round_playoffs_first_legs')
          } else if(nextMatchday === 10) {
            return this.$i18n.t('ko_round_playoffs_second_legs')
          } else if(nextMatchday === 11) {
            return this.$i18n.t('ko_round_of_16_first_legs')
          } else if(nextMatchday === 12) {
            return this.$i18n.t('ko_round_of_16_second_legs')
          } else if(nextMatchday === 13) {
            return this.$i18n.t('ko_round_quarter_finals_first_legs')
          } else if(nextMatchday === 14) {
            return this.$i18n.t('ko_round_quarter_finals_second_legs')
          } else if(nextMatchday === 15) {
            return this.$i18n.t('ko_round_semi_finals_first_legs')
          } else if(nextMatchday === 16) {
            return this.$i18n.t('ko_round_semi_finals_second_legs')
          } else if(nextMatchday === 17) {
            return this.$i18n.t('ko_round_final')
          }
        } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 6395) {
          const nextMatchday = this.matchday
          if(nextMatchday === 7) {
            return this.$i18n.t('ko_round_playoffs_first_legs')
          } else if(nextMatchday === 8) {
            return this.$i18n.t('ko_round_playoffs_second_legs')
          } else if(nextMatchday === 9) {
            return this.$i18n.t('ko_round_of_16_first_legs')
          } else if(nextMatchday === 10) {
            return this.$i18n.t('ko_round_of_16_second_legs')
          } else if(nextMatchday === 11) {
            return this.$i18n.t('ko_round_quarter_finals_first_legs')
          } else if(nextMatchday === 12) {
            return this.$i18n.t('ko_round_quarter_finals_second_legs')
          } else if(nextMatchday === 13) {
            return this.$i18n.t('ko_round_semi_finals_first_legs')
          } else if(nextMatchday === 14) {
            return this.$i18n.t('ko_round_semi_finals_second_legs')
          } else if(nextMatchday === 15) {
            return this.$i18n.t('ko_round_final')
          }
        } else if(this.$store.state.betgame && this.$store.state.betgame.competition && 
              (this.$store.state.betgame.competition.id === 10700 || this.$store.state.betgame.competition.id === 36)) {
              const nextMatchday = this.matchday
              if(nextMatchday === 1) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 2) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 3) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 4) {
                  return this.$i18n.t('em_ko_round_of_16')
              } else if(nextMatchday === 5) {
                  return this.$i18n.t('em_ko_round_quarter_finals')
              } else if(nextMatchday === 6) {
                  return this.$i18n.t('em_ko_round_semi_finals')
              } else if(nextMatchday === 7) {
                  return this.$i18n.t('ko_round_final')
              }
          } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 533) {
              const nextMatchday = this.matchday
              if(nextMatchday === 1) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 2) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 3) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 8) {
                  return this.$i18n.t('ko_round_fifth_place')
              } else if(nextMatchday === 9) {
                  return this.$i18n.t('em_ko_round_semi_finals')
              } else if(nextMatchday === 10) {
                  return this.$i18n.t('ko_round_third_place')
              } else if(nextMatchday === 11) {
                  return this.$i18n.t('ko_round_final')
              }
          } else if(this.$store.state.betgame && this.$store.state.betgame.competition &&
              (this.$store.state.betgame.competition.id === 139 || this.$store.state.betgame.competition.id === 1110)) {
              const nextMatchday = this.matchday
              if(nextMatchday === 1) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 2) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 3) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 4) {
                  return this.$i18n.t('em_ko_round_of_16')
              } else if(nextMatchday === 5) {
                  return this.$i18n.t('em_ko_round_quarter_finals')
              } else if(nextMatchday === 6) {
                  return this.$i18n.t('em_ko_round_semi_finals')
              } else if(nextMatchday === 7) {
                  return this.$i18n.t('ko_round_third_place_short')
              } else if(nextMatchday === 8) {
                  return this.$i18n.t('ko_round_final')
              }
          } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 1180) {
              const nextMatchday = this.matchday
              if(nextMatchday === 1) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 2) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 3) {
                  return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
              } else if(nextMatchday === 4) {
                  return this.$i18n.t('em_ko_round_quarter_finals')
              } else if(nextMatchday === 5) {
                  return this.$i18n.t('em_ko_round_semi_finals')
              } else if(nextMatchday === 6) {
                  return this.$i18n.t('ko_round_final')
              }
          }
          if(this.$store.state.tournamentRounds && this.$store.state.tournamentRounds.length > 0) {
              var matchdays = 0
              for (var i = 0; i < this.$store.state.tournamentRounds.length; i++) {
                  const round = this.$store.state.tournamentRounds[i]
                  matchdays += round.matchdaycount
                  if(matchdays >= this.matchday) {
                      if(this.$store.state.betgame.competition.id === 19 || this.$store.state.betgame.competition.id === 132 ||
                          this.$store.state.betgame.competition.id === 6395) {
                          return round.name + ', ' + this.$i18n.t('matchday', { matchday: this.matchday })
                      } else {
                          return round.name
                      }
                  }
              }
          }
          return this.$i18n.t('matchday', { matchday: this.matchday })
      },
      userLivePosition() {
          return this.$store.state.userLiveRanking.position
      },
      userTotalPoints() {
          return this.$store.state.userLiveRanking.points_total
      },
    sorting () {
      return this.$store.state.matchdayRankingSorting
    },
    matchdayRankings () {
      if (!this.hasSeasonStarted()) {
        if (!this.$store.state.betgameUsers || this.$store.state.betgameUsers.length === 0) {
          return []
        }
        const bu = this.$store.state.betgameUsers
        var res = []
        for (var i = 0; i < bu.length; i++) {
          var u = bu[i]
          u.position = 1
          u.user_name = u.nickname
          res.push(u)
        }
        return res
      } else {
        if (this.sorting === 1) {
          return this.$store.state.liveRankings.slice(0, TOP_TEN)
        } else if (this.sorting === 2) {
          return this.$store.state.matchdayRankings[this.matchday]
        }
        return []
      }
    },
    matchday () {
      return this.$store.state.currentMatchdayRankingByMatchday
    },
    matchdayCount () {
      return this.$store.state.matchdayCount
    },
    betgame () {
      return this.$store.state.betgame
    },
    matches () {
        const m = JSON.parse(JSON.stringify(this.$store.state.matches))
        const t = m.filter(t => this.parseMatchdate(t.matchdate).isSame(moment(), 'days'))
        return t
    },
    sortmode () {
      return this.$store.state.betgame.sort_mode || 1
    },
      pointsBetResult() {
          return this.$store.state.betgame.points_bet_result
      },
      pointsBetGoaldiff() {
          return this.$store.state.betgame.points_bet_goaldiff
      },
      pointsBetTendency() {
          return this.$store.state.betgame.points_bet_tendency
      },
      usermatchdayRanking() {
          return this.$store.state.userMatchdayRankings[this.matchday]
      },
  },
  methods: {
      teamLogo(teamid) {
          const l = this.getTeamLogo(teamid)
          if(l && l.length > 6) {
              return this.getBGValue('spmau') + '/images/jerseys/24/' + l
          } else if(l && l.length <= 6) {
              return l
          }
          return null
      },
      deepLinkClicked() {
          if(this.deepLink) {
              this.$gtag.event('Kombiwette', {
                  'event_category': 'bwin',
                  'event_label': this.matchday ? '' + this.matchday + '. Spieltag' : '',
                  'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
                  'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
                  'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set',
                  'dimension5': this.$store.state.betgame && this.$store.state.betgame.type ? this.$store.state.betgame.type : 'betgame type not set'
              })
              if(window.parent) {
                  window.parent.open(this.deepLink, '_blank')
              } else {
                  window.open(this.deepLink, '_blank')
              }
          }
      },
      scrollUserVisible() {
          const elements = this.$refs['' + this.$store.state.user.id]
          elements[0].scrollIntoView({ behavior: "smooth", block: "center" })
      },
      sortByTotal() {
          if(this.$store.state.matchdayRankingSorting === 2) {
              this.$emit('sortingChanged', {})
              const lastFetchLiveRanking = this.getBGValue('spmlflr')
              const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
              if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length == 0 ||
                  this.$store.state.allbets == null || this.$store.state.allbets.length == 0) {
                  if (this.$store.state.leagueId) {
                      this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
                          this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
                              this.componentKey += 1
                          })
                      })
                  }
              }
              this.$store.dispatch('setMatchdayRakingSorting', 1)
          }
      },
      sortByMatchday() {
          if(this.$store.state.matchdayRankingSorting === 1) {
              this.$emit('sortingChanged', {})
              if (this.$store.state.leagueId) {
                  this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
                      this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
                          this.componentKey += 1
                      })
                  })
              }
              //}
              this.$store.dispatch('setMatchdayRakingSorting', 2)
          }
      },
    getClass (match, user) {
      const bp = this.betPoints(match, user)
      switch (bp) {
        case this.pointsBetResult:
          return 'bet_exact'    
        case this.pointsBetGoaldiff:
          return 'bet_goal_diff'        
        case this.pointsBetTendency:
          return 'bet_result'
        default:
          return 'bet_no_points'
      }
    },
    bet (match, user) {
      if (user !== this.$store.state.user.id && !this.isNumeric(match.goalshome) &&
        !this.isNumeric(match.goalsguest) && !this.isNumeric(match.livegoalshome) &&
        !this.isNumeric(match.livegoalsguest)) {
        return '-:-'
      }
      const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, user)
      if (bet !== undefined && bet !== null && isNumeric(bet.goalshome) && isNumeric(bet.goalsguest)) {
        //const p = this.getPoints(bet, match)
        return bet.goalshome + ' : ' + bet.goalsguest //+ ' (' + p + ')'
      } else {
        return '-:-'
      }
    },
    totalmatchdaywins (user) {
      if (this.sorting === 1) {
        const t = this.$store.state.liveRankings.filter(r => r.fk_user === user)
        if (t.length === 1) {
          return t[0].matchday_wins
        }
        return 0
      } else if (this.sorting === 2) {
        const t = this.$store.state.liveRankingsByMatchday[this.matchday].filter(r => r.fk_user === user)
        if (t.length === 1) {
          return t[0].matchday_wins
        }
        return 0
      }
    },
    upOrDown (user, cur_position) {
      const bu = this.$store.state.betgameUsers
      if (bu && bu.length > 50) {
        return ''
      }
      var res = []
      for (var i = 0; i < bu.length; i++) {
        var u = bu[i]
        var tp = this.totalpoints(u.fk_user)
        var lp = this.livepoints(u.fk_user)
        var tmp = { fk_user: u.fk_user, prev_points: tp - lp }
        res.push(tmp)
      }
      const res_sorted = res.sort(function (a, b) {
        return b.prev_points - a.prev_points
      })
      var upos = 0
      for (var i = 0; i < res_sorted.length; i++) {
        var u = res_sorted[i]
        if (user === u.fk_user) {
          if (i === 0) {
            upos = i + 1
          } else {
            var u_prev = res_sorted[i - 1]
            if (u_prev.prev_points === u.prev_points) {
              upos = i
            } else {
              upos = i + 1
            }
          }
          break
        }
      }
      if (cur_position === upos) {
        return '='
      } else if (cur_position < upos) {
        return 'u'
      } else if (cur_position > upos) {
        return 'd'
      } else {
        return ''
      }
    },
  betToto(match, user) {
      if(user !== this.$store.state.user.id && !this.isNumeric(match.goalshome) &&
          !this.isNumeric(match.goalsguest) && !this.isNumeric(match.livegoalshome) &&
          !this.isNumeric(match.livegoalsguest)) {
          return '-'
      }
      const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, user)
      if(bet !== undefined && bet !== null && isNumeric(bet.goalshome) && isNumeric(bet.goalsguest))  {
          return bet.goalshome
      } else {
          return '-'
      }
  },
    livepoints (user) {
      var result = 0
      for (var i = 0; i < this.matches.length; i++) {
        const match = this.matches[i]
        const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, user)
        const p = this.getPoints(bet, match)
        if (p > -1) {
          result += p
        }
      }
      return result
    },
    betPoints (match, user) {
      if (user !== this.$store.state.user.id &&
        match.goalshome === null && match.goalsguest === null &&
        match.livegoalshome === null && match.livegoalsguest === null) {
        return ''
      }
      const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, user)
      if (bet !== undefined && bet !== null) {
        const p = this.getPoints(bet, match)
        return p
      } else {
        if ((this.isNumeric(match.goalshome) && this.isNumeric(match.goalsguest)) ||
          (this.isNumeric(match.livegoalshome) && this.isNumeric(match.livegoalsguest))) {
          return 0
        }
      }
    },
    totalpoints (user) {
      if (this.sorting === 1) {
        const t = this.$store.state.liveRankings.filter(r => r.fk_user === user)
        if (t.length === 1) {
          return t[0].points_total
        }
        return 0
      } else if (this.sorting === 2 && this.$store.state.liveRankingsByMatchday && this.$store.state.liveRankingsByMatchday[this.matchday]) {
        const t = this.$store.state.liveRankingsByMatchday[this.matchday].filter(r => r.fk_user === user)
        if (t.length === 1) {
          return t[0].points_total
        }
        return 0
      }
    },
    equalPoints (index) {
      if (index === 0 || !this.matchdayRankings || this.matchdayRankings.length <= index) {
        return false
      }
      const r1 = this.matchdayRankings[index]
      const r2 = this.matchdayRankings[index - 1]
      if (this.isBattle() || this.isTournament()) {
        return this.totalpoints(r1.fk_user) === this.totalpoints(r2.fk_user) &&
          r1.exact === r2.exact && r1.goaldiff === r2.goaldiff
      } else {
        if (this.sorting === 1) {
          if (this.sortmode === 1) {
            return this.totalpoints(r1.fk_user) === this.totalpoints(r2.fk_user) &&
              this.totalmatchdaywins(r1.fk_user) === this.totalmatchdaywins(r2.fk_user) &&
              r1.exact === r2.exact && r1.goaldiff === r2.goaldiff
          } else if (this.sortmode === 2) {
            return this.totalpoints(r1.fk_user) === this.totalpoints(r2.fk_user) &&
              r1.exact === r2.exact &&
              this.totalmatchdaywins(r1.fk_user) === this.totalmatchdaywins(r2.fk_user) &&
              r1.goaldiff === r2.goaldiff
          }
        } else if (this.sorting === 2) {
          return this.livepoints(r1.fk_user) === this.livepoints(r2.fk_user)
        }
      }
      return false
    },
    winnerTeam (match, teanId) {
      if (match.goalshome === null || match.goalsguest === null) {
        return false
      }
      if (match.goalshome > match.goalsguest) {
        return match.fk_team_home === teanId
      } else if (match.goalshome < match.goalsguest) {
        return match.fk_team_guest === teanId
      } else {
        return false
      }
    },
    loserTeam (match, teanId) {
      if (match.goalshome === null || match.goalsguest === null) {
        return false
      }
      if (match.goalshome > match.goalsguest) {
        return match.fk_team_guest === teanId
      } else if (match.goalshome < match.goalsguest) {
        return match.fk_team_home === teanId
      } else {
        return false
      }
    },
    matchdaySelected (m) {
      //this.matchday = m
      this.$store.dispatch('setCurrentMatchdayRankingByMatchday', m)
      this.$modal.hide('matchday-select')
      if (this.$store.state.matchdayRankings.length > this.matchday) {
        const mdr = this.$store.state.matchdayRankings[this.matchday]
        if (mdr === undefined || mdr === null) {
          this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
            this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
            })
          })
        } else {
          this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
          })
        }
      } else {
        this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
          this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
          })
        })
      }
    },
    matchStatusText (match) {
      return this.$i18n.t('match_dismissed_short')
    },
    selectMatchday () {
      this.$modal.show('matchday-select')
    },
    increment () {
      if (this.matchday < this.$store.state.matchdayCount) {
        //this.matchday++
        this.$store.dispatch('setCurrentMatchdayRankingByMatchday', this.matchday + 1)
      } else {
        //this.matchday = 1
        this.$store.dispatch('setCurrentMatchdayRankingByMatchday', 1)
      }
      if (this.$store.state.matchdayRankings.length > this.matchday) {
        const mdr = this.$store.state.matchdayRankings[this.matchday]
        if (mdr === undefined || mdr === null) {
          this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
            this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
            })
          })
        } else {
          this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
          })
        }
      } else {
        this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
          this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
          })
        })
      }
    },
    decrement () {
      if (this.matchday > 1) {
        //this.matchday--
        this.$store.dispatch('setCurrentMatchdayRankingByMatchday', this.matchday - 1)
      } else {
        //this.matchday = this.$store.state.matchdayCount
        this.$store.dispatch('setCurrentMatchdayRankingByMatchday', this.$store.state.matchdayCount)
      }
      if (this.$store.state.matchdayRankings.length > this.matchday) {
        const mdr = this.$store.state.matchdayRankings[this.matchday]
        if (mdr === undefined || mdr === null) {
          this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
            this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
            })
          })
        } else {
          this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
          })
        }
      } else {
        this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
          this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
          })
        })
      }
    },
    refreshRankingByMatchday () {
      this.isLoading = true
      if (this.isBattle()) {
          this.$store.dispatch('fetchSchedule', this.$store.state.leagueId).then(() => {
          })
          const lastFetchLiveRanking = this.getBGValue('spmlflr')
          const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
          if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
              this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
              const that = this
              this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
                  this.$store.dispatch('fetchBetsForMatchdayRankingBattle', [this.$store.state.betgameId, this.matchday]).then(() => {
                      //this.$store.dispatch('fetchSchedule', this.$store.state.leagueId).then(() => {
                      this.setBGValue('spmlflr', new Date())
                      //})
                  })
              }).finally(function () {
                  that.isLoading = false
              })
          } else {
              const that = this
              setTimeout(() => {
                  that.isLoading = false
              }, 200)
          }
      } else if (this.isTeamsBetgame()) {
        this.$store.dispatch('fetchSchedule', this.$store.state.leagueId).then(() => {
        })
        const lastFetchLiveRanking = this.getBGValue('spmlflr')
        const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
        if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
            this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
          const that = this
          this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
            this.$store.dispatch('fetchBetsForMatchdayRankingTeam', [this.$store.state.betgameId, this.matchday]).then(() => {
              //this.$store.dispatch('fetchSchedule', this.$store.state.leagueId).then(() => {
              this.setBGValue('spmlflr', new Date())
              //})
            })
          }).finally(function () {
            that.isLoading = false
          })
        } else {
          const that = this
          setTimeout(() => {
            that.isLoading = false
          }, 200)
        }
      } else {
          this.$store.dispatch('fetchMatchdaySchedule', [this.$store.state.leagueId, this.matchday]).then(() => {
          })
          const lastFetchLiveRanking = this.getBGValue('spmlflr')
          const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
          if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
              this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
              if (this.$store.state.leagueId) {
                  const that = this
                  this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
                      this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
                          this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
                              //this.$store.dispatch('fetchMatchdaySchedule', [this.$store.state.leagueId, this.matchday]).then(() => {
                              this.setBGValue('spmlflr', new Date())
                              //})
                          })
                      })
                  }).finally(function () {
                      that.isLoading = false
                  })
              }
          } else {
              const that = this
              setTimeout(() => {
                  that.isLoading = false
              }, 200)
          }
      }
    }
  }
}
</script>

<style lang="scss">
    .banner-box {
        max-width: 440px;
        margin: 16px auto 0;

        &__content {
            display: flex;
            align-items: center;
            padding: 16px 16px 16px 0;
            background: $colorWhite;
            box-shadow: 0 8px 24px 0 rgba($colorGreyDark,0.08);
            border-radius: 4px;
            overflow: hidden;
            box-sizing: border-box;

            @media screen and (min-width: 440px) {
                padding: 16px 16px 16px 24px;
            }
        }

        &__icon {
            width: 96px;
            height: 96px;
            margin: 0 16px 0 -24px;

            @media screen and (min-width: 440px) {
                margin: 0 24px 0 0;
            }
        }

        &__text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &__headline {
            font-size: 17px;
            font-weight: 500;
            color: $colorGreyDark;
            margin-bottom: 2px;
        }

        &__copy {
            font-size: 14px;
            color: rgba($colorGreyDark, .48);
            margin-bottom: 12px;
        }

        .button {
            display: inline-flex;
            font-size: 14px;
            padding: 8px 12px 7px;
            border-radius: 4px;

            img {
                margin-top: -2px;
            }
        }

        &__meta {
            display: flex;
            justify-content: center;
            margin-top: 10px;
            font-size: 11px;
            font-weight: 400;
            color: rgba($colorGreyDark, .48);
        }
    }
</style>

<style lang="scss" scoped>

/* THIS PADDING NEEDS TO BE SIZE OF THE TOPNAVIGATION + THE HEIGHT OF THE BWIN-BANNER */
.bwin-visible {
  @media screen and (max-width: 440px) {
    padding-top: calc(64px + 168px) !important;
  }
}

/* THIS PADDING NEEDS TO BE SIZE OF THE TOPNAVIGATION + THE HEIGHT OF THE BACK BUTTON */
.today-view {
  padding-top: calc(64px + 48px) !important;
}

.banner-box {
  position: fixed;
  left: 16px;
  width: calc(100vw - 32px);
  top: 64px;
  box-sizing: border-box;

  @media screen and (min-width: 440px) {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    margin: 40px auto;
  }
}

.ranking {
  position: relative;
  z-index: 2;
  width: calc(100% + 32px);
  margin: 24px -16px 0;
  box-sizing: border-box;
  color: $colorGreyDark;
  padding-bottom: 28px;

  @media screen and (min-width: 1024px) {
    max-width: 800px;
    margin: 48px auto 0;
  }

  &__header {
    position: sticky;
    top: 64px;
    display: flex;
    background: $colorBackground;
    z-index: 3;

    &__item {
      display: flex;
      flex-shrink: 0;
      padding: 4px 4px;
      box-sizing: border-box;
      background: $colorBackground;
      box-shadow: inset 0 -1px 0 0 rgba($colorGreyDark, .08);

      &--user {
        display: flex;
        align-items: center;
        width: 160px;
        padding-left: 16px;
        box-sizing: border-box;
        position: sticky;
        left: 0;
        background: $colorBackground;
        z-index: 2;
        box-shadow: inset -1px -1px 0 0 rgba($colorGreyDark, .08);
        font-size: 14px;
        font-weight: 500;
        color: rgba($colorGreyDark, .48);
      }

      &--points {
        display: flex;
        align-items: center;
        padding: 0 8px;
        font-size: 9px;
        font-weight: 500;
        text-transform: uppercase;
        box-shadow: inset -1px -1px 0 0 rgba($colorGreyDark, .08);

        span {
          background: rgba(var(--color-primary-rgb), .12);
          color: var(--color-primary);
          border-radius: 0 2px 2px 0;
          padding: 3px 4px;
          cursor: pointer;
          transition: all .2s ease-in-out;

          &:hover {
            background: rgba(var(--color-primary-rgb), .2);
          }

          &.selected {
            background: var(--color-primary);
            color: $colorWhite;
            border-radius: 2px 0 0 2px;
          }
        }
      }

      &--match {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 11px;
        font-weight: 500;
        width: 40px;

        @media screen and (min-width: 1024px) {
          flex: 1;
        }

        span {
          display: flex;
          justify-content: center;
          width: 24px;
          color: rgba($colorGreyDark, .32);

          &:first-of-type {
            border-bottom: dashed 1px rgba($colorGreyDark, .32);
          }

          &.finished {
            color: rgba($colorGreyDark, .64);
          }

          &.win {
            color: rgba($colorGreyDark, .64);
          } 
            
          &.live_result {
              color: $colorNegative;
          }  
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    background: $colorWhite;
    box-shadow: 0 8px 24px 0 rgba(49, 49, 55, 0.08);

    @media screen and (min-width: 1024px) {
      border-radius: 0 0 4px 4px;
      overflow: hidden;
      margin-bottom: 48px;
    }
  }

  &__item {
    display: flex;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;

    &:not(:last-child) {
      box-shadow: inset 0 -1px 0 0 rgba($colorGreyDark, .08);

      .ranking__item__column {
        box-shadow: inset 0 -1px 0 0 rgba($colorGreyDark, .08);

        &--user {
          box-shadow: inset -1px -1px 0 0 rgba($colorGreyDark, .08);
        }

        &--points {
          box-shadow: inset -1px -1px 0 0 rgba($colorGreyDark, .08);
        }
      }
    }

    &__column {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      padding: 14px 4px;
      box-sizing: border-box;
      background: $colorWhite;

      &--user {
        width: 160px;
        box-shadow: inset -1px 0 0 0 rgba($colorGreyDark, .08);
        padding-left: 16px;
        box-sizing: border-box;
        position: sticky;
        left: 0;
        background: $colorWhite;
        z-index: 2;

        span {
            flex-shrink: 0;
            width: 32px;
            text-align: center;
            margin-left: -16px;

            &.morethan999 {
                width: 40px;
            }
        }

        img {
          width: 12px;
          height: 12px;
          margin-right: 8px;
        }

        div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
      }

      &--points {
        padding: 0 8px;
        box-shadow: inset -1px 0 0 0 rgba($colorGreyDark, .08);

        span {
          font-weight: 400;
          color: rgba($colorGreyDark, .48);
          text-align: center;

          &.selected {
            font-weight: 500;
            color: $colorGreyDark;
          }

          &:first-of-type {
            width: 45px;
          }

          &:nth-child(2) {
            width: 49px;

            .today-view & {
                width: 37px;
            }
          }
        }

      }

      &--match {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;

        @media screen and (min-width: 1024px) {
          flex: 1;
        }
      }

      &__goals {
        font-weight: 400;
        letter-spacing: -1.4px;
        color: rgba($colorGreyDark, .48);
        position: relative;

        &--exact, &--tendency, &--result {
          font-weight: 500;
        }

        &--exact {
          color: $colorPositive;
        }

        &--tendency {
          color: $colorYellow;
        }

        &--result {
          color: $colorGreyDark;
        }

        span {
          top: -6px;
          right: -6px;
          position: absolute;
          font-size: 8px;
        }
      }
    }
  }

  &__sticky {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 4;
    bottom: calc(8px + 44px);
    left: 8px;
    width: calc(100% - 16px);
    background: $colorWhite;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .08), 0 12px 24px 0 rgba(0, 0, 0, .08), 0 0 0 1px rgba($colorGreyDark, .08);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: $colorGreyDark;
    padding: 10px 10px 10px 8px;
    box-sizing: border-box;
    overflow: hidden;
    opacity: 1;
    transform: translateY(0);
    transition: all .2s ease-in-out;

    /* "BOTTOM" IS "0", BECAUSE THE BOTTOMNAVIGATION IS NOT DISPLAYED, IN THE VIEW FOR MATCHES, WHICH ARE RUNNING ONLY TODAY */
    .today-view & {
      bottom: 8px;
    }

    @media screen and (min-width: 1024px) {
      max-width: 768px;
      bottom: calc(8px + 52px);
      left: 50%;
      transform: translateX(-50%);
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .12), 0 12px 24px 0 rgba(0, 0, 0, .12), inset 0 0 1px 0 rgba($colorGreyDark, .08);
    }

    &--hidden {
        opacity: 0;
        transform: translateY(25%);

        @media screen and (min-width: 1024px) {
            transform: translate(-50%, 25%);
        }
    }

    &__icon {
      position: relative;
      display: flex;
      margin: 0 12px 0 -32px;
      width: 48px;
      height: 48px;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform-origin: center center;
        transform: translate(-50%, -50%);
        background: $colorLive;
        border-radius: 50%;

        &:nth-child(1) {
          width: 12px;
          height: 12px;
          animation: live-01 3s infinite;
        }

        @keyframes live-01 {
          0% {
            width: 12px;
            height: 12px;
          }
          50% {
            width: 16px;
            height: 16px;
          }
          100% {
            width: 12px;
            height: 12px;
          }
        }

        &:nth-child(2) {
          width: 12px;
          height: 12px;
          animation: live-02 3s infinite .75s;
        }

        @keyframes live-02 {
          0% {
            transform: translate(-50%, -50%) scale(1);
            opacity: .6;
          }
          100% {
            transform: translate(-50%, -50%) scale(4);
            opacity: 0;
          }
        }

        &:nth-child(3) {
          width: 12px;
          height: 12px;
          animation: live-03 3s infinite 1s;
        }

        @keyframes live-03 {
          0% {
            transform: translate(-50%, -50%) scale(1);
            opacity: .6;
          }
          100% {
            transform: translate(-50%, -50%) scale(4);
            opacity: 0;
          }
        }
      }
    }

    &__position {
      width: 32px;
      text-align: center;
    }

    &__text {
      flex: 1;
      margin: 3px 8px 3px 0;

      &__label {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        .highlight {
          font-weight: 600;
          color: $colorLive;
        }
      }

      &__sublabel {
        font-size: 11px;
        font-weight: 400;
        line-height: 14px;
        color: rgba($colorGreyDark, .48);
        margin-top: 4px;
      }
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 3px;
      background: var(--color-primary);
      margin-left: 8px;

      img {
        .ranking__sticky--hint & {
          transform: rotate(-90deg);
        }
      }
    }
  }
}

.button--fixed {
  position: fixed;
  z-index: 4;
  top: 64px;
  left: 0;
  width: 100%;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
}

.toto-result {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin: -2px auto;
    font-size: 11px;
    font-weight: 600;
    color: $colorGreyDark;
    border-radius: 3px;
    box-shadow: inset 0 0 0 1.5px rgba($colorGreyDark, .16);
    background: none;

    &.bet_no_points {
        color: $colorWhite;
        background: $colorNegative;
        box-shadow: none;
    }

    &.bet_exact {
        color: $colorWhite;
        background: $colorPositive;
        box-shadow: none;
    }
}

.toto-match-cancelled {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin: -2px auto;
    font-size: 11px;
    font-weight: 600;
    color: $colorGreyDark;
    border-radius: 3px;
    box-shadow: inset 0 0 0 1.5px rgba($colorGreyDark, .16);
    background: none;
}

</style>
