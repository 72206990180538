<template>
    <div :key="componentKey" class="betgameDelete" id="betgameDelete">
        
        <NavigationBar>
            <template v-slot:left>
                <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
            </template>
            <template v-if="this.action === 'delete'" v-slot:center>
              {{ $t('betgame_settings.betgame_delete') }}
            </template>
            <template v-else v-slot:center>
              {{ $t('betgame_settings.betgame_transfer') }}
            </template>
            <template v-slot:right>
            </template>
        </NavigationBar>
        
        <form v-if="deletion" @submit.prevent="deleteBetgame">
            <div class="input-container">
                <div>
                    <div class="input-header">
                      {{ $t('betgame_settings.betgame_delete') }}
                    </div>
                    <div class="input-infotext">
                      {{ $t('betgame_settings.betgame_delete_description_1') }}<br><br>
                      {{ $t('betgame_settings.betgame_delete_description_2') }} <a href="/betgameedit">{{ $t('betgame_settings.betgame_transfer') }}</a>
                    </div>
                    <div class="input-element">
                        <input autofocus id="email" name="email" placeholder="email"
                               type="email" v-model="email" value/>
                        <label for="email">{{ $t('email') }}</label>
                    </div>
                    <div class="input-element">
                        <input id="pasword" name="pasword" placeholder="pasword"
                               type="password" v-model="password" value/>
                        <label for="pasword">{{ $t('password') }}</label>
                    </div>
                </div>
                <div class="input-button-row">
                    <button name="button" type="submit">
                      {{ $t('betgame_settings.button_delete_text') }}
                    </button>
                </div>
            </div>
        </form>
    
        <form v-else-if="betgame.transfer && betgame.transfer.bg_user" @submit.prevent="stopBetgameTransfer">
            <div class="input-container">
                <div class="input-header">
                  {{ $t('betgame_settings.betgame_transfer') }}
                </div>
                <div class="input-infotext">
                  {{ $t('betgame_settings.betgame_will_be_transferred_to_useremail') }} <b>{{ betgame.transfer.bg_user.email }}</b>
                </div>
                <div class="input-button-row">
                    <button name="button" type="submit">
                      {{ $t('betgame_settings.button_cancel_transfer_text') }}
                    </button>
                </div>
            </div>
        </form>
        
        <form v-else @submit.prevent="changeBetgameOwner">
            <div class="input-container">
                <div>
                    <div class="input-header">
                      {{ $t('betgame_settings.betgame_transfer') }}
                    </div>
                    <div v-if="this.action === 'delete'" class="input-infotext">
                      {{ $t('betgame_settings.betgame_delete_error_betgame_allready_started') }}
                    </div>
                    <div class="input-infotext">
                      {{ $t('betgame_settings.betgame_transfer_description') }}
                    </div>
                    <div class="input-element">
                        <input v-model="transferEmail" type="email" name="transferEmail" id="transferEmail" placeholder="email" value />
                        <label for="transferEmail">{{ $t('email') }}</label>
                    </div>
                </div>
                <div class="input-button-row">
                    <button name="button" type="submit">
                      {{ $t('betgame_settings.button_transfer_text') }}
                    </button>
                </div>
            </div>
        </form>
    
        <ul class="error-container">
            <li v-for="error in errors">{{ error }}</li>
        </ul>
        
    </div>
</template>

<script>
  
  import NavigationBar from './../components/NavigationBar'
  import { TOAST_DURATION } from '../main'
  import APIService from '@/services/APIService'
  import Vue from 'vue'
  
  export default {
    name: 'BetgameDelete',
    components: { NavigationBar },
    data () {
      return {
        deletion: true,
        errors: [],
        betgame: null,
        email: '',
        password: '',
        componentKey: 0,
        transferEmail: '',
        action: ''
      }
    },
    created () {
      this.betgame = this.$route.params.betgame
      this.action = this.$route.params.action
      Vue.$log.debug('BetgameDelete created ' + this.betgame.id + ' --- ' + this.action)
      const that = this
      if(this.action !== undefined && this.action === 'transfer') {
        that.deletion = false
      } else {
        that.deletion = true
        /*
        this.$store.dispatch('checkDeleteBetgame', this.betgame.id).then(function (response) {
          that.deletion = true
          that.componentKey += 1
        }).catch(error => {
          that.deletion = false
        })
        */
      }
      this.$store.dispatch('fetchBetgameTransfer', this.betgame.id).then(function (response) {
        that.componentKey += 1
      }).catch(error => {
      })
    },
    mounted () {
      Vue.$log.debug('BetgameDelete mounted ' + JSON.stringify(this.betgame))
      if (this.betgame === null || this.betgame === undefined) {
        this.goBack()
      }
    },
    methods: {
      stopBetgameTransfer() {
        this.$store.dispatch('deleteBetgameTransfer', this.betgame.id).then(() => {
          this.$toast.open({ message: this.$i18n.t('betgame_settings.stop_betgame_transfer_success'), position: 'top', duration: TOAST_DURATION, type: 'success' })
          this.$router.push('/betgameedit')
        }).catch(function (error) {
          this.errors.push(this.$i18n.t('betgame_settings.stop_betgame_transfer_error'))
        })
      },
      changeBetgameOwner() {
        this.errors = []
        if(this.isNullOrUndefined(this.transferEmail) || this.transferEmail.length === 0) {
          this.errors.push(this.$i18n.t('betgame_settings.enter_betgame_transfer_receiver_email'))
        }
        if (this.errors.length == 0) {
          Vue.$log.debug('changeBetgameOwner 1')
          APIService.isEmailRegistered(this.transferEmail).then(response => {
            if (response.data.valid === '1') {
              this.errors.push(this.$i18n.t('betgame_settings.enter_betgame_transfer_receiver_email_error'))
            } else {
              const that = this
              this.$store.dispatch('startBetgameTransfer', [this.betgame.id, this.transferEmail]).then(() => {
                this.$toast.open({ message: this.$i18n.t('betgame_settings.betgame_transfer_started'), position: 'top', duration: TOAST_DURATION, type: 'success' })
                this.$router.push('/betgameedit')
              }).catch(function (error) {
                that.errors.push(that.$i18n.t('betgame_settings.betgame_transfer_not_possible'))
              })
            }
          })
        }
      },
      deleteBetgame() {
        this.errors = []
        if(this.isNullOrUndefined(this.email) || this.email.length === 0) {
          this.errors.push(this.$i18n.t('betgame_settings.enter_email_address'))
        } else {
          if (this.isNullOrUndefined(this.$store.state.user.email) || String(this.email).toLowerCase() !== String(this.$store.state.user.email).toLowerCase()) {
            this.errors.push(this.$i18n.t('betgame_settings.enter_administrator_email_address'))
          }
        }
        if(this.isNullOrUndefined(this.password) || this.password.length === 0) {
          this.errors.push(this.$i18n.t('betgame_settings.enter_password'))
        }
        if (this.errors.length === 0) {
          const that = this
          this.$store
          .dispatch('deleteBetgame', {
            betgame: this.betgame.id,
            email: String(this.email).toLowerCase(),
            pass: this.password
          })
          .then(() => {
            this.$toast.open({ message: this.$i18n.t('betgame_settings.delete_betgame_success'), position: 'top', duration: TOAST_DURATION, type: 'success' })
            this.$router.push('/mybetgames')
          }).catch(function (error) {
            alert(that.$i18n.t('betgame_settings.delete_betgame_error') + ' ' + error)
          })
        }
      },
      goBack () {
        this.$router.push('/betgameedit')
      },
      isNullOrUndefined(data) {
        return data === null || data === undefined
      }
    }
  }
</script>

<style lang="scss" scoped>
    .betgameDelete {
        
        form {
            padding-top: 16px;
        }
        
        h1 {
            text-align: center;
        }
        
        p {
            text-align: center;
            margin-bottom: 26px;
        }
    }
</style>
