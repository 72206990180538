<template>
  <div class="deleteAccount" id="deleteAccount" style="justify-content: flex-start; margin-top: 32px;">
    
    <NavigationBar>
      <template v-slot:left>
        <img v-on:click="goBack" class="back" src="../assets/icons/ic_16_back.svg">
      </template>
      <template v-slot:center>
        {{ $t('profile.delete_account') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>

    <img class="illustration" src="../assets/images/delete/delete-illustration.png">
    
    <h1>{{ $t('profile.delete_account_title') }}</h1>
    <p v-if="isBetgameOwner">
      {{ $t('profile.delete_account_text_manage_betgames') }}
    </p>
    <p v-else>
      {{ $t('profile.delete_account_text') }}
    </p>
    
    <form v-if="!isBetgameOwner" @submit.prevent="deleteAccount">
      
      <div class="input-container">
        
        <div>
          
          <div class="input-element">
            <input v-model="password" ref="password" type="password" name="password" id="password" placeholder="password" autofocus value />
            <label for="password">{{ $t('profile.delete_account_password_confirmation') }}</label>
          </div>
        </div>
        
        <div class="input-button-row">
          <button type="submit" name="button">
            {{ $t('profile.delete_account') }}
          </button>
        </div>
      
      </div>
  
      <ul class="error-container">
        <li v-for="error in errors">{{ error }}</li>
      </ul>
      
    </form>
    
    <div v-if="isBetgameOwner" class="input-button-row">
      <button @click="gotoMyBetgames" name="button" >
        {{ $t('manage_my_betgames') }}
      </button>
    </div>
    
  </div>
</template>

<script>
  
  import NavigationBar from './../components/NavigationBar'
  import { TOAST_DURATION } from '../main'
  
  export default {
    components: { NavigationBar },
    data() {
      return {
        password: '',
        errors: []
      }
    },
    computed: {
      isBetgameOwner() {
        return this.$store.state.betgames_owner.length > 0
      }
    },
    methods: {
      gotoMyBetgames() {
        this.$router.push('/mybetgames')
      },
      goBack() {
        this.$router.push('/profile')
      },
      deleteAccount() {
        this.errors = []
        if(this.isNullOrUndefined(this.password) || this.password.length === 0) {
          this.errors.push(this.$t('errors.error_delete_account_password_confirmation_missing'))
        }
        if (this.errors.length === 0) {
          this.$store
          .dispatch('deleteAccount', {
            id: this.$store.state.user.id,
            email: this.$store.state.user.email,
            pass: this.password
          })
          .then(() => {
            this.$toast.open({ message:  this.$i18n.t('profile.success_account_deleted'), position: 'top', duration: TOAST_DURATION, type: 'success' })
            this.$store.dispatch('logout').then(() => {
              this.$router.push('/profiledeleted').then(() => {
                window.location.reload()
              })
            })
          }).catch(function (error) {
            alert(this.$t('errors.error_delete_account_failed') + ' ' + error)
          })
        }
      },
      isNullOrUndefined(data) {
        return data === null || data === undefined
      }
    }
  }
</script>

<style lang="scss" scoped>
  .deleteAccount {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 64px);
    min-height: calc((var(--vh, 1vh) * 100) - 64px);
    padding: 64px 16px 0 16px;
    max-width: 576px;
    margin: 0px auto;

    img.illustration {
      max-width: 180px;
      margin: 0 auto 19px;
    }
    
    h1 {
      text-align: center;
    }
    p {
      text-align: center;
      margin-bottom: 42px;
    }
  }
</style>
