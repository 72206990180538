<template>
    <div class="betgameEditPoints" id="betgameEditPoints">
        
        <NavigationBar>
            <template v-slot:left>
                <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
            </template>
            <template v-slot:center>
              {{ $t('betgame_settings.betgame_evaluation_method') }}
            </template>
            <template v-slot:right>
              <div v-if="betmode === 1" v-on:click="openHelp"><HintIcon class="navigation_icon"></HintIcon></div>
            </template>
        </NavigationBar>

        <ul class="error-container">
            <li v-for="error in errors">{{ error }}</li>
        </ul>

        <ul v-if="showPointsHint" class="error-container" style="list-style-type: none;">
          <li>{{ $t('betgame_settings.edit_points_warning') }}</li>
        </ul>
      
        <ul v-if="showChangedPointsHint" class="error-container" style="list-style-type: none;">
          <li v-for="hint in hints">{{ hint }}</li>
        </ul>
      
        <form @submit.prevent="changeBetgamePoints">
            
            <div class="input-container">
                
                <div>

                    <div class="input-header">
                      {{ $t('betgame_settings.betgame_evaluation_method_points_title') }}
                    </div>

                    <div v-if="betmode === 1" class="input-infotext">
                      {{ $t('betgame_settings.betgame_evaluation_method_text_1_see_examples') }}
                    </div>
                    <div v-else-if="betmode === 2" class="input-infotext">
                      {{ $t('betgame_settings.betgame_evaluation_method_text_1') }}<br><br>{{ $t('betgame_settings.betgame_evaluation_method_text_2') }}
                    </div>
                  
                    <div v-if="betmode === 1" class="input-stepper">
                        <div @click="pointsBetResultUp" class="input-stepper-button input-stepper-button-up"></div>
                        <input id="points1" max="99" min="0" 
                               step="1" placeholder="-" type="number" v-model="points_bet_result_new">
                        <div @click="pointsBetResultDown" class="input-stepper-button input-stepper-button-down"></div>
                        <label for="points1">{{ $t('betgame_settings.bet_exact_match_result') }}</label>
                    </div>

                    <div v-if="betmode === 1" class="input-stepper">
                        <div @click="pointsBetGoaldiffUp" class="input-stepper-button input-stepper-button-up"></div>
                        <input id="points2" max="99" min="0" 
                               step="1" placeholder="-" type="number" v-model="points_bet_goaldiff_new">
                        <div @click="pointsBetGoaldiffDown" class="input-stepper-button input-stepper-button-down"></div>
                        <label for="points2">{{ $t('betgame_settings.bet_goal_difference') }}</label>
                    </div>

                    <div v-if="betmode === 1" class="input-stepper">
                        <div @click="pointsBetTendencyUp" class="input-stepper-button input-stepper-button-up"></div>
                        <input id="points3" max="99" min="0" 
                               step="1" placeholder="-" type="number" v-model="points_bet_tendency_new">
                        <div @click="pointsBetTendencyDown" class="input-stepper-button input-stepper-button-down"></div>
                        <label for="points3">{{ $t('betgame_settings.bet_tendency') }}</label>
                    </div>

                    <div v-if="betmode === 2" class="input-stepper">
                      <div @click="pointsBetResultUp" class="input-stepper-button input-stepper-button-up"></div>
                      <input id="points4" max="99" min="0" 
                             step="1" placeholder="-" type="number" v-model="points_bet_result_new">
                      <div @click="pointsBetResultDown" class="input-stepper-button input-stepper-button-down"></div>
                      <label for="points4">{{ $t('points') }}</label>
                    </div>
                  
                </div>
                
                <div class="input-button-row">
                    <button name="button" type="submit">
                      {{ $t('button_save_text') }}
                    </button>
                </div>
            
            </div>
        
        </form>
      
        <div v-if="betmode === 1" class="input-container" style="margin-top: 20px; margin-bottom: 40px;">

            <div class="input-infotext">
              {{ $t('betgame_settings.betgame_evaluation_method_text_5') }}<br><br>{{ $t('betgame_settings.betgame_evaluation_method_text_3') }}<br><br>{{ $t('betgame_settings.betgame_evaluation_method_text_4') }}<br><br>{{ $t('betgame_settings.betgame_evaluation_method_text_2') }}
            </div>
          
            <div class="input-header">
              {{ $t('examples') }}
            </div>
            <table class="table">
              <thead>
                <th style="width: 25%; text-align: left">{{ $t('result') }}</th>
                <th style="width: 20%; text-align: left">{{ $t('the_bet') }}</th>
                <th style="width: 20%; text-align: left">{{ $t('points') }}</th>
                <th style="width: 25%; text-align: left"></th>
              </thead>
              <tbody>
              <tr v-for="(sample, index) in samples" :key="sample.id">
                <td style="width: 25%; text-align: left">{{ sample.result }}</td>
                <td style="width: 20%; text-align: left;">{{ sample.bet }}</td>
                <td style="width: 20%; text-align: left;">{{ getPoints(sample.points) }}</td>
                <td style="width: 25%; text-align: left;">{{ sample.text }}</td>
              </tr>
              </tbody>
            </table>
        </div>
      
        <modal class="modal" name="edit-points-help" id="edit-points-help" :scrollable="true" width="100%" height="auto">
          <div class="modal-header">
            <div class="modal-header-left"></div>
            <div class="modal-header-center">{{ $t('betgame_settings.betgame_evaluation_method_examples') }}</div>
            <div class="modal-header-right">
              <button
                type="button"
                class="btn-close"
                @click="$modal.hide('edit-points-help')"
                aria-label="Close modal">
              </button>
            </div>
          </div>
          <div class="modal-body">
            <div class="table-view">
              <div class="table-view-title">
                {{ $t('betgame_settings.bet_exact_match_result') }}
              </div>
              <div class="table-view-cell grouped">
                <div class="table-view-cell-label">
                  {{ $t('betgame_settings.bet_exact_match_result_example') }}
                </div>
              </div>
              <div class="table-view-title">
                {{ $t('betgame_settings.bet_goal_difference') }}
              </div>
              <div class="table-view-cell grouped">
                <div class="table-view-cell-label">
                  {{ $t('betgame_settings.bet_goal_difference_example') }}
                </div>
              </div>
              <div class="table-view-title">
                {{ $t('betgame_settings.bet_tendency') }}
              </div>
              <div class="table-view-cell grouped">
                <div class="table-view-cell-label">
                  {{ $t('betgame_settings.bet_tendency_example') }}
                </div>
              </div>
              <div class="table-view-title">
                {{ $t('betgame_settings.bet_no_points') }}
              </div>
              <div class="table-view-cell grouped">
                <div class="table-view-cell-label">
                  {{ $t('betgame_settings.bet_no_points_example') }}
                </div>
              </div>
            </div>
          </div>
        </modal>
        
    </div>
    
    <!--<div class="notification">
        <h1>Exaktes Ergebnis</h1>
        <p>Beispiel: Spiel endet 3:1 und der Tipp lautet 3:1</p>
        <h1>Tordifferenz</h1>
        <p>Beispiel: Spiel endet 3:1 und der Tipp lautet 2:0 oder 4:2</p>
        <h1>Ergebnis</h1>
        <p>Beispiel: Spiel endet 3:1 und der Tipp lautet 2:1 oder 1:0</p>
        <h1>Keine Punkte</h1>
        <p>Beispiel: Spiel endet 3:1 und der Tipp lautet 1:1 oder 0:2</p>
    </div>-->

    
</template>

<script>
  
  import NavigationBar from './../components/NavigationBar'
  import { TOAST_DURATION } from '../main'
  import HintIcon from './../components/icons/HintIcon'
  import Vue from 'vue'
  
  export default {
    name: 'BetgameEditPoints',
    components: { HintIcon, NavigationBar },
    data () {
      return {
        betgame: null,
        errors: [ ],
        hints: [ this.$i18n.t('betgame_settings.edited_points_hint') ],
        showChangedPointsHint: false,
        points_bet_result_new: '',
        points_bet_goaldiff_new: '',
        points_bet_tendency_new: '',
        samples: [
          { id: 0, result: '2:0', bet: '2:0', points: 3, text: this.$i18n.t('betgame_settings.bet_exact_match_result') },
          { id: 1, result: '2:0', bet: '3:1', points: 2, text: this.$i18n.t('betgame_settings.bet_goal_difference') },
          { id: 2, result: '2:0', bet: '4:2', points: 2, text: this.$i18n.t('betgame_settings.bet_goal_difference') },
          { id: 3, result: '2:0', bet: '1:0', points: 1, text: this.$i18n.t('betgame_settings.bet_tendency') },
          { id: 4, result: '2:0', bet: '1:1', points: 0, text: '' },
          { id: 5, result: '2:0', bet: '0:1', points: 0, text: '' },
          { id: 6, result: '2:0', bet: '0:2', points: 0, text: '' },
          { id: 7, result: '1:3', bet: '1:3', points: 3, text: this.$i18n.t('betgame_settings.bet_exact_match_result') },
          { id: 8, result: '1:3', bet: '0:2', points: 2, text: this.$i18n.t('betgame_settings.bet_goal_difference') },
          { id: 9, result: '1:3', bet: '3:5', points: 2, text: this.$i18n.t('betgame_settings.bet_goal_difference') },
          { id: 10, result: '1:3', bet: '0:3', points: 1, text: this.$i18n.t('betgame_settings.bet_tendency') },
          { id: 11, result: '1:3', bet: '2:2', points: 0, text: '' },
          { id: 12, result: '1:3', bet: '1:0', points: 0, text: '' },
          { id: 13, result: '1:3', bet: '2:0', points: 0, text: '' },
          { id: 14, result: '1:1', bet: '1:1', points: 3, text: this.$i18n.t('betgame_settings.bet_exact_match_result') },
          { id: 15, result: '1:1', bet: '2:2', points: 2, text: this.$i18n.t('betgame_settings.bet_goal_difference') },
          { id: 16, result: '1:1', bet: '0:0', points: 2, text: this.$i18n.t('betgame_settings.bet_goal_difference') },
          { id: 17, result: '1:1', bet: '0:1', points: 0, text: '' },
          { id: 18, result: '1:1', bet: '1:0', points: 0, text: '' },        
        ]
      }
    },
    created () {
      this.betgame = this.$route.params.betgame
      if(this.betgame) {
        this.points_bet_result_new = this.betgame.points_bet_result
        this.points_bet_goaldiff_new = this.betgame.points_bet_goaldiff
        this.points_bet_tendency_new = this.betgame.points_bet_tendency
      }
    },
    mounted () {
      if (this.betgame === null || this.betgame === undefined) {
        this.goBack()
      };
    },
    computed: {
      showPointsHint() {
        if(!this.betgame || this.betmode === 2) {
          return false
        } else if(this.points_bet_goaldiff_new > this.points_bet_result_new) {
          return true
        } else if(this.points_bet_tendency_new > this.points_bet_result_new) {
          return true
        } else if(this.points_bet_tendency_new > this.points_bet_goaldiff_new) {
          return true
        } else if(this.points_bet_result_new === 0) {
          return true
        } else if(this.points_bet_goaldiff_new === 0) {
          return true
        } else if(this.points_bet_tendency_new === 0) {
          return true
        } else {
          return false
        }
      },
      betmode() {
        return this.$store.state.betgame.bet_mode || 1
      }
    },
    methods: {
      getPoints(points) {
        if(points === 0 || !this.betgame) {
          return 0
        } else if(points === 1) {
          return this.points_bet_tendency_new
        } else if(points === 2) {
          return this.points_bet_goaldiff_new
        } else if(points === 3) {
          return this.points_bet_result_new
        } else {
          return 0
        }
      },
      openHelp() {
        this.$modal.show('edit-points-help');
      },
      pointsBetGoaldiffUp() {
        if(this.points_bet_goaldiff_new < 100) {
          this.points_bet_goaldiff_new += 1
        } else {
          this.points_bet_goaldiff_new = 0
        }
      },
      pointsBetGoaldiffDown() {
        if(this.points_bet_goaldiff_new > 0) {
          this.points_bet_goaldiff_new -= 1
        }
      },
      pointsBetResultUp() {
        if(this.points_bet_result_new < 100) {
          this.points_bet_result_new += 1
        } else {
          this.points_bet_result_new = 0
        }
      },
      pointsBetResultDown() {
        if(this.points_bet_result_new > 0) {
          this.points_bet_result_new -= 1
        }
      },
      pointsBetTendencyUp() {
        if(this.points_bet_tendency_new < 100) {
          this.points_bet_tendency_new += 1
        } else {
          this.points_bet_tendency_new = 0
        }
      },
      pointsBetTendencyDown() {
        if(this.points_bet_tendency_new > 0) {
          this.points_bet_tendency_new -= 1
        }
      },
      updateBetgame (data) {
        this.$store.dispatch('patchBetgame', data)
        .then(() => {
          this.$toast.open({ message: this.$i18n.t('betgame_settings.save_changes_success'),
            position: 'top',
            duration: TOAST_DURATION,
            type: 'success'
          })
          this.showChangedPointsHint = true
        })
        .catch(function (error) {
          this.points_bet_result_new = this.betgame.points_bet_result
          this.points_bet_goaldiff_new = this.betgame.points_bet_goaldiff
          this.points_bet_tendency_new = this.betgame.points_bet_tendency
          this.$toast.open({ message: this.$i18n.t('betgame_settings.save_changes_error'),
            position: 'top',
            duration: TOAST_DURATION,
            type: 'error'
          })
        })
        .finally(function () {
        })
      },
      goBack () {
        this.$router.push('/betgameedit')
      },
      changeBetgamePoints () {
        if(this.points_bet_result_new === this.betgame.points_bet_result && 
            this.points_bet_goaldiff_new === this.betgame.points_bet_goaldiff && 
            this.points_bet_tendency_new === this.betgame.points_bet_tendency) {
          Vue.$log.debug('changeBetgamePoints no modification made')
          return
        }
        this.errors = []
        if (!this.isNumeric(this.points_bet_result_new) || this.points_bet_result_new < 0 || this.points_bet_result_new > 99) {
          this.errors.push(this.$i18n.t('betgame_settings.change_on_the_nose_points_error'))
        }
        if (!this.isNumeric(this.points_bet_goaldiff_new) || this.points_bet_goaldiff_new < 0 || this.points_bet_goaldiff_new > 99) {
          this.errors.push(this.$i18n.t('betgame_settings.change_goal_difference_points_error'))
        }
        if (!this.isNumeric(this.points_bet_tendency_new) || this.points_bet_tendency_new < 0 || this.points_bet_tendency_new > 99) {
          this.errors.push(this.$i18n.t('betgame_settings.change_outcome_points_error'))
        }
        if (this.betmode === 1) {
          if (this.points_bet_goaldiff_new > this.points_bet_result_new ||
              this.points_bet_tendency_new > this.points_bet_result_new ||
              this.points_bet_tendency_new > this.points_bet_goaldiff_new) {
            // this.errors.push(this.$i18n.t('betgame_settings.edit_points_warning'))
          }
        }
        if (this.betmode === 2 && this.points_bet_result_new < 1) {
          this.errors.push(this.$i18n.t('betgame_settings.change_points_error'))
        }
        if (this.errors.length === 0) {
          //this.$store.dispatch('setBetgame', this.betgame)
          this.updateBetgame({
            points_bet_result: this.points_bet_result_new,
            points_bet_goaldiff: this.points_bet_goaldiff_new,
            points_bet_tendency: this.points_bet_tendency_new
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .betgameEditPoints {
    
    form {
      padding-top: 16px;
    }
    
    h1 {
      text-align: center;
    }
    
    p {
      text-align: center;
      margin-bottom: 26px;
    }
  }

  .grouped {
    margin-top: -16px;
  }

  .table-view {
    margin-bottom: 0;
  }

  .table-view-cell:last-child {
    border-bottom: 0;
  }

  .table-view-cell-label {
    color: rgba($colorGreyDark, .48);
    font-weight: 400;
  }

  .table {
    td, th {
      border-bottom: 1px solid rgba($colorGreyLight, .08);
      &:first-child {
        //max-width: 4px;
      }
      thead & {
        font-weight: 400;
        color: $colorGreyLight;
      }
    }
  }
</style>
