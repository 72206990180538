<template>
  <div class="whitelabel">
    
    <!-- HEADER -->

    <div class="header" id="header">
      <div class="container">
        <nav>
          <div class="navigation-logo">
            <a href="../landingpage" class="whitelabel__logo">
              <img src="../assets/whitelabel/logo.png" alt="">
            </a>
            <div class="navigation-divider"></div>
            <a href="https://teamtip.net" target="_blank">
              <img src="../assets/landingpage/logo-teamtip.svg" alt="">
            </a>
          </div>
          <ul class="navigation-list">
            <!--
            <li v-if="!this.isApp()" class="navigation-list-item">
              <a href="#whitelabel_id" v-smooth-scroll="{ updateHistory: false }">{{ $t('home.whitelabel') }}</a>
            </li>
            <li v-if="!this.isApp()" class="navigation-list-item">
              <a href="#features_id" v-smooth-scroll="{ updateHistory: false }">{{ $t('home.features') }}</a>
            </li>
            <li v-if="!this.isApp()" class="navigation-list-item">
              <router-link to="/about">
                Über Uns
              </router-link>
            </li>
            -->
            <router-link to="/login" class="router_link">
              <li class="navigation-list-button">
                <LoginIcon></LoginIcon>
                Login
              </li>
            </router-link>
          </ul>
        </nav>
        <div class="header-content">
          <div class="header-content-left">
            <h1>Das offizielle<br>FC St.Pauli Tippspiel</h1>
            <p>Lorem ipsum dolor sit amet, id altera persequeris vim, mea an appareat prodesset. Cu mea error eripuit delicata. Nec soluta aliquid similique eu.</p>
            <div class="button-group">
              <router-link to="/register" class="router_link">
                <div class="button">Anmelden, tippen & gewinnen</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="header-image"></div>
    </div>

    <!-- ABOUT -->
    <div class="container">
      <div class="textblock textblock--imageLeft">
        <div class="textblock__text">
          <h3>Spieltagsgewinner:in</h3>
          <p>Lorem ipsum dolor sit amet, id altera persequeris vim, mea an appareat prodesset. Cu mea error eripuit delicata. Nec soluta aliquid similique eu.aLorem ipsum dolor sit amet, id altera persequeris vim, mea an appareat prodesset.</p>
        </div>
        <div class="textblock__image">
          <img src="../assets/whitelabel/spieltagsgewinner.png" srcset="../assets/whitelabel/spieltagsgewinner@2x.png 2x" alt="Spieltagsgewinner:in">
        </div>
      </div>

      <div class="textblock textblock--imageRight">
        <div class="textblock__text">
          <h3>Hinrundensieger:in</h3>
          <p>Lorem ipsum dolor sit amet, id altera persequeris vim, mea an appareat prodesset. Cu mea error eripuit delicata. Nec soluta aliquid similique eu.aLorem ipsum dolor sit amet, id altera persequeris vim, mea an appareat prodesset.</p>
        </div>
        <div class="textblock__image">
          <img src="../assets/whitelabel/hinrundensieger.png" srcset="../assets/whitelabel/hinrundensieger@2x.png 2x" alt="Hinrundensieger:in">
        </div>
      </div>

      <div class="textblock textblock--imageLeft">
        <div class="textblock__text">
          <h3>Rückrundensieger:in</h3>
          <p>Lorem ipsum dolor sit amet, id altera persequeris vim, mea an appareat prodesset. Cu mea error eripuit delicata. Nec soluta aliquid similique eu.aLorem ipsum dolor sit amet, id altera persequeris vim, mea an appareat prodesset.</p>
        </div>
        <div class="textblock__image">
          <img src="../assets/whitelabel/rueckrundensieger.png" srcset="../assets/whitelabel/rueckrundensieger@2x.png 2x" alt="Rückrundensieger:in">
        </div>
      </div>
    </div>

    <!-- BUTTON -->
    <div class="button__wrapper">
      <router-link to="/register" class="button">
        Jetzt teilnehmen
      </router-link>
    </div>

    <!-- FOOTER -->
    <footer class="footer">
      <div class="container">
        <div class="logo">
          <a href="">
            <img src="../assets/landingpage/logo-teamtip.svg" alt="Logo TEAMTIP">
          </a>
        </div>
        <ul>
          <li>
            <router-link to="/imprint" class="router_link">
              {{ $t('home.footer_imprint') }}
            </router-link>
          </li>
          <li>
            <router-link to="/termsofuse" class="router_link">
              {{ $t('home.footer_terms_of_use') }}
            </router-link>
          </li>
          <li>
            <router-link to="/privacy" class="router_link">
              {{ $t('home.footer_privacy') }}
            </router-link>
          </li>
          <li>
            <a v-if="selectedLanguage === 'de'" href="https://teamtip.zendesk.com/hc/de" target="_blank" style="text-decoration: none; color: white">{{ $t('home.footer_faq') }}</a>
            <a v-else-if="!selectedLanguage || selectedLanguage !== 'de'" href="https://teamtip.zendesk.com/hc/en-us" target="_blank" style="text-decoration: none; color: white">{{ $t('home.footer_faq') }}</a>
          </li>
          <li>
            <router-link to="/contact" class="router_link">
              {{ $t('home.footer_contact') }}
            </router-link>
          </li>
        </ul>
        <a href="#header" class="back-to-top" v-smooth-scroll="{ updateHistory: false }">
          <span>{{ $t('home.footer_scroll_to_top') }}</span>
          <img src="../assets/landingpage/ic_32_back-to-top.svg" alt="Back to top">
        </a>
      </div>
    </footer>
    
  </div>
</template>

<script>

import LoginIcon from './../components/icons/LoginIcon'

export default {
  name: 'homewhitelabel',
  components: {
    LoginIcon
  },
  data() {
    return {
    }
  },
  beforeCreate() {
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  methods: {
  }
}

</script>

<style lang="scss" scoped>
  .whitelabel {
    padding: 0 !important;
    background: url('../assets/whitelabel/bg-structure.png');
    background-color: #413B30;
    background-size: 800px 400px;
    background-position: center bottom;
    background-blend-mode: exclusion;

    &__logo {
      img {
        width: 56px;
        height: 56px;
      }
    }
  }

  .router_link {
    text-decoration: none;
  }

  .container {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    max-width: 1108px;
    margin: 0px auto;
    padding: 0 16px;
    box-sizing: border-box;
  }

  /* HEADER OVERWRITES */
  .header {
    background: url('../assets/whitelabel/header-bg.png');
    background-color: rgba(#413B30, .5);
    background-size: cover;
    background-position: center bottom;
    background-blend-mode: hard-light;
    height: auto;

    @media screen and (max-width: 767px) {
      background-position: center top;
      background-image: url('../assets/whitelabel/header-bg-mobile.png');
    }

    nav {
      position: relative;
      z-index: 2;

      .navigation-logo {
        a:nth-of-type(2) {
          display: flex;
        }
        .navigation-divider {
          display: flex;
        }
      }

    }

    .navigation-list-button {
      color: #363128;
    }

    &-content {
      position: relative;
      z-index: 2;
      margin: 96px 0;

      @media screen and (max-width: 767px) {
        margin: 24px 0 320px;
      }
    }

    h1 {
      text-transform: uppercase;

      @media screen and (max-width: 767px) {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 12px;
      }
    }

    p {
      @media screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .button-group {
      @media screen and (max-width: 767px) {
        margin-top: 18px;
      }
    }

  }

  footer {
    background: none;
    box-shadow: inset 0 2px 0 0 rgba(255,255,255,.04);
    margin-top: 128px;
  }

  .button {
    display: inline-flex;
    width: auto;
    background: #F71100;
    color: #FFFFFF;
    font-size: 17px;
    font-weight: 500;
    border-radius: 6px;
    padding: 14px 19px;
    text-decoration: none !important;

    &__wrapper {
      display: flex;
      justify-content: center;
      margin: 64px 0;
    }
  }

  .textblock {
    h3, p {
      color: #FFFFFF;
    }
  }

</style>
