<template>
  <div class="createbetgame" id="createbetgame">

    <NavigationBar>
      <template v-slot:left>
        <img v-on:click="goBack" class="back" src="../assets/icons/ic_16_back.svg">
      </template>
      <template v-slot:center>
        {{ $t('create_betgame.title') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>

    <ul v-if="errors && errors.length > 0" class="error-container" style="margin-top: 0px; margin-bottom: 16px; list-style-type: none;">
      <li v-for="error in errors">{{ error }}</li>
    </ul>
    
    <form-wizard v-if="notLoggedIn" ref="wizard" title="" subtitle="" :nextButtonText="nextButtonText" :backButtonText="backButtonText" 
                 :finishButtonText="startBetgameText" stepSize="xs" shape="circle" color="#0C3EC1"
                 @on-complete="formWizardOnComplete" @on-loading="formWizardOnLoading"
                 @on-validate="formWizardOnValidate" @on-error="formWizardOnError"
                 @on-change="formWizardOnChange"
                 v-bind:class="{ wizard_error: errors && errors.length > 0 }">
      <tab-content lazy="true" title="" :beforeChange="beforeChangeBetgameType">
        <BetgameType></BetgameType>
      </tab-content>
      <tab-content lazy="true" title="" :beforeChange="beforeChangeCompetition">
        <SelectMatches></SelectMatches>
      </tab-content>
      <tab-content lazy="true" title="" :beforeChange="beforeChangeBetgameName">
        <BetgameName></BetgameName>
      </tab-content>
      <tab-content title="" :beforeChange="beforeChangeBetgameDescription">
        <BetgameDescription></BetgameDescription>
      </tab-content>
      <tab-content title="" :beforeChange="beforeChangeBetmode">
        <Betmode></Betmode>
      </tab-content>
      <tab-content title="" :beforeChange="beforeChangeUserData">
        <UserData @emailError="onEmailError"></UserData>
      </tab-content>
    </form-wizard>

    <!--
    <form-wizard v-else-if="this.isSPMUser()" title="" subtitle="" nextButtonText="Weiter" backButtonText="Zurück"
                 finishButtonText="Tipprunde eröffnen" stepSize="xs" shape="circle" color="#0C3EC1"
                 @on-complete="formWizardOnComplete" @on-loading="formWizardOnLoading"
                 @on-validate="formWizardOnValidate" @on-error="formWizardOnError"
                 @on-change="formWizardOnChange">
      <tab-content title="" :beforeChange="beforeChangeBetgameType">
        <BetgameType></BetgameType>
      </tab-content>
      <tab-content title="" :beforeChange="beforeChangeCompetition">
        <SelectMatches></SelectMatches>
      </tab-content>
      <tab-content title="" :beforeChange="beforeChangeBetgameName">
        <BetgameName></BetgameName>
      </tab-content>
      <tab-content title="" :beforeChange="beforeChangeBetgameDescription">
        <BetgameDescription></BetgameDescription>
      </tab-content>
      <tab-content title="" :beforeChange="beforeChangeBetmode">
        <Betmode></Betmode>
      </tab-content>
      <tab-content title="" v-if="this.$store.state.betgame && userBetgamesOwnerCount > 0">
        <AddUsers></AddUsers>
      </tab-content>
    </form-wizard>
    -->
    
    <form-wizard v-else ref="wizard" title="" subtitle="" :nextButtonText="nextButtonText" :backButtonText="backButtonText" 
                 :finishButtonText="startBetgameText" stepSize="xs" shape="circle" color="#0C3EC1"
                 @on-complete="formWizardOnComplete" @on-loading="formWizardOnLoading"
                 @on-validate="formWizardOnValidate" @on-error="formWizardOnError"
                 @on-change="formWizardOnChange"
                 v-bind:class="{ wizard_error: errors && errors.length > 0 }">
      <tab-content title="" :beforeChange="beforeChangeBetgameType">
        <BetgameType></BetgameType>
      </tab-content>
      <tab-content title="" :beforeChange="beforeChangeCompetition">
        <SelectMatches></SelectMatches>
      </tab-content>
      <tab-content title="" :beforeChange="beforeChangeBetgameName">
        <BetgameName></BetgameName>
      </tab-content>
      <tab-content title="" :beforeChange="beforeChangeBetgameDescription">
        <BetgameDescription></BetgameDescription>
      </tab-content>
      <tab-content title="" :beforeChange="beforeChangeBetmode">
        <Betmode></Betmode>
      </tab-content>
      <tab-content title="" v-if="this.$store.state.betgame && userBetgamesOwnerCount > 0">
        <AddUsers></AddUsers>
      </tab-content>
    </form-wizard>
    
  </div>
</template>

<script>

import NavigationBar from './../components/NavigationBar'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Vue from 'vue'
import BetgameName from '@/components/create/BetgameName'
import BetgameType from '@/components/create/BetgameType'
import AddUsers from '@/components/create/AddUsers'
import UserData from '@/components/create/UserData'
import Betmode from '@/components/create/Betmode'
import SelectMatches from '@/components/create/SelectMatches'
import {
  BATTLE_COMPETITION,
  BETMODE_RESULT,
  BETMODE_TOTO,
  TYPE_BATTLE,
  TYPE_BATTLE_ID,
  TYPE_BETGAME,
  TYPE_BETGAME_ID,
    TYPE_TEAMS,
    TYPE_TEAMS_ID
} from '@/main'
import Numeral from 'numeral'
import moment from 'moment'
import APIService from '@/services/APIService'
import BetgameDescription from '@/components/create/BetgameDescription'
import jwt_decode from 'jwt-decode'

export default {
  name: 'CreateBetgame',
  components: { BetgameDescription, SelectMatches, Betmode, UserData, AddUsers, BetgameName, BetgameType, FormWizard, TabContent, NavigationBar },
  props: {
    
  },
  data() {
    return {
      errors: [],
      submitClicked: 0,
      // emailAlreadyRegistered: false
      // betgameName: 'Champions League',
      // betgameType: 'betgame',
      //selectedCompetition: '19',
      // addUsers: false,
      //selectedBetmode: '1',
      //name: '',
      //email: this.emailparam,
      //password: this.passparam,
      //agreedTOS: false,
      //isUnder18: false,
      //competitionMatches: [],
      //selectedMatches: {}
    }
  },
  metaInfo: {
    title: 'Tipprunde eröffnen',
  },
  beforeCreate () {
    //Vue.$log.debug('CreateBetgame.beforeCreate ' + this.$route.query.type)
    const t = this.$route.query.type 
    const c = this.$route.query.competition
    const team = this.$route.query.team
    this.$store.dispatch('clearCreateBetgame', { }).then(() => {
      Vue.$log.debug('CreateBetgame.beforeCreate clearCreateBetgame ok ' + t + ' --- ' + c)
      if(t && parseInt(t) === TYPE_BETGAME_ID && c && this.isValidCompetition(c) && this.isCompetitionAvailable(c)) {
        this.$refs.wizard.changeTab(0,1)
        Vue.$log.debug('CreateBetgame.beforeCreate wizard.changeTab(0,1) ok ')
      } else if(t && parseInt(t) === TYPE_TEAMS_ID && team && parseInt(team) > 0 && parseInt(team) < 1000) {
        this.$refs.wizard.changeTab(0,1)
        Vue.$log.debug('CreateBetgame.beforeCreate wizard.changeTab(0,1) ok ')
      }
    }).catch(function (error) {
      Vue.$log.debug('CreateBetgame.beforeCreate clearCreateBetgame error ' + error)
    })
  },
  created () {
    Vue.$log.debug('CreateBetgame.created ')
    if (!this.$store.state.teams || this.$store.state.teams.length === 0) {
      this.$store.dispatch('fetchTeams', [])
    }
  },
  mounted () {
    Vue.$log.debug('CreateBetgame.mounted ')
  },
  computed: {
    startBetgameText() {
      return this.$t('create_betgame.button_open_betgame')
    },
    nextButtonText() {
      return this.$t('next')
    },
    backButtonText() {
      return this.$t('back')
    },
    notLoggedIn() {
      return !this.$store.getters.loggedIn
    },
    betgameType() {
      return this.$store.state.createBetgame.betgameType
    },
    userBetgamesOwnerCount() {
      return this.$store.state.betgames_owner ? this.$store.state.betgames_owner.length : 0 
    },
  },
  methods: {
    onEmailError (value) {
      this.errors = []
      this.errors.push(value)
    },
    goBack() {
      const at = this.$refs.wizard.activeTabIndex
      if(at > 0) {
        this.$refs.wizard.changeTab(at, at-1)
      } else {
        this.$store.dispatch('clearCreateBetgame', { }).then(() => {
          Vue.$log.debug('CreateBetgame.goBack ok ')
        }).catch(function (error) {
          Vue.$log.debug('CreateBetgame.goBack error ' + error)
        })
        if(this.hasHistory()) {
          this.$router.go(-1)
        } else {
          if (this.$store.getters.loggedIn) {
            this.$router.push({ name: 'mybetgames' }).catch(err => {
            })
          } else {
            this.$router.push({ name: 'home' })
          }
        }
      }
    },
    formWizardOnComplete () {
      Vue.$log.debug('FormWizard.formWizardOnComplete ' + JSON.stringify(this.$store.state.createBetgame, null, 4))
      if (this.submitClicked > 0) {
        Vue.$log.debug('FormWizard.formWizardOnComplete submitClicked ' + this.submitClicked)
        return
      }
      this.submitClicked += 1
      if (!this.$store.getters.loggedIn) {
        const language = this.$store.state.language ? this.$store.state.language : 'de'
        const us = this.$store.state.userSource || this.getBGValue('userSource') || ''
        this.$store.dispatch('registerUserCreateBetgame', {
          email: this.$store.state.createBetgame.email.toLowerCase(),
          pass: this.$store.state.createBetgame.password,
          nick: this.$store.state.createBetgame.userName.trim(),
          lang: language,
          betgame: this.$store.state.createBetgame.betgameName.trim(),
          competition: this.$store.state.createBetgame.betgameType === TYPE_BETGAME ? parseInt(this.$store.state.createBetgame.competition) : 0
        })
        .then(() => {
          this.$store.dispatch('patchUser', {
            source: us,
            acceptedtos: this.$store.state.createBetgame.agreedTOS,
            isadult: !this.$store.state.createBetgame.isUnder18
          }).then(() => {
          })
          this.$store.dispatch('patchBetgame', {
            bet_mode: this.$store.state.createBetgame.betmode,
            description: this.$store.state.createBetgame.betgameDescription,
            type: this.$store.state.createBetgame.betgameType === 'battle' ? TYPE_BATTLE_ID : (this.$store.state.createBetgame.betgameType === 'teams' ? TYPE_TEAMS_ID : TYPE_BETGAME_ID)
          }).then(() => {
          })
          const uid = '' + this.$store.state.user.id
          const uem = '' + this.$store.state.user.email.charAt(0) + this.reverse(uid)
          const temp = this.reverse(String(this.$store.state.user.registration_date)).replaceAll('T', this.reverse(uem))
          .replaceAll(':', '').replaceAll('-', uem).concat(uid)
          const chatdata = {
            '_id': uid,
            'first_name': this.$store.state.user.nickname,
            'email': this.$store.state.user.email.toLowerCase().trim(),
            'password': temp
          }
          this.$store.dispatch('chatCreateUser', chatdata).then(response => {
            Vue.$log.debug('chatCreateUser response: ' + JSON.stringify(response))
            this.$store.dispatch('chatLoginUser', {'user': chatdata._id, 'password': chatdata.password}).then(response => {
              Vue.$log.debug('chatLoginUser response: ' + JSON.stringify(response))
              this.$store.dispatch('patchUser', {
                chatuser: uid + '-' + temp
              })
              this.$store.dispatch('chatCreateConversation', {
                'title': this.$store.state.createBetgame.betgameName.trim(),
                'participant_ids': [uid]
              }).then(response => {
                Vue.$log.debug('chatCreateConversation response: ' + JSON.stringify(response))
              })
              .catch(function (error) {
                Vue.$log.debug('chatCreateConversation error: ' + JSON.stringify(error))
              })
            })
            .catch(function (error) {
              Vue.$log.debug('chatLoginUser error: ' + JSON.stringify(error))
            })
          })
          .catch(function (error) {
            Vue.$log.debug('chatCreateUser error: ' + JSON.stringify(error))
          })
          if (this.$store.state.language !== undefined && this.$store.state.language !== null &&
              this.$store.state.language.length > 1) {
            Numeral.locale(this.$store.state.language)
            moment.locale(this.$store.state.language)
          }
          if (this.$store.state.language !== undefined && this.$store.state.language !== null &&
              this.$store.state.language.length > 1 && this.$i18n.locale !== this.$store.state.language) {
            this.$i18n.locale = this.$store.state.language
          }
          if (this.$store.state.createBetgame.betgameType === TYPE_BATTLE) {
            const sm = this.$store.state.createBetgame.selectedMatches
            var m = []
            for (var key in sm) {
              if (sm.hasOwnProperty(key)) {
                for (var key1 in sm[key]) {
                  if (sm[key].hasOwnProperty(key1)) {
                    const v = sm[key][key1]
                    if (v === true) {
                      m.push({ fk_betgame: this.$store.state.betgameId, fk_match: parseInt(key1) })
                    }
                  }
                }
              }
            }
            const data = {
              fk_betgame: this.$store.state.betgameId,
              matches: m
            }
            Vue.$log.debug('FormWizard.formWizardOnComplete createBetgame saveBetgameMatches data ' + JSON.stringify(data))
            this.$store.dispatch('saveBetgameMatches', data).then(response => {
              if (response.status >= 200 && response.status <= 204) {
                Vue.$log.debug('FormWizard.formWizardOnComplete createBetgame saveBetgameMatches ' + response.status)
                this.$store.dispatch('clearSelectedMatches', {}).then(() => {
                  Vue.$log.debug('FormWizard.formWizardOnComplete createBetgame saveBetgameMatches clearSelectedMatches ok ')
                })
              }
            }, error => {
              Vue.$log.debug('createBetgame saveBetgameMatches error ' + error)
            })
          } else if (this.$store.state.createBetgame.betgameType === TYPE_TEAMS) {
            const st = this.$store.state.createBetgame.selectedTeams
            var data = []
            for (var i=0; i<st.length; i++) {
              data.push({ fk_betgame: this.$store.state.betgameId, fk_team: parseInt(st[i]), fk_season: parseInt(this.$store.state.currentSeason.id) })
            }
            Vue.$log.debug('FormWizard.formWizardOnComplete createBetgame saveBetgameTeams data ' + JSON.stringify(data))
            this.$store.dispatch('saveBetgameTeams', data).then(response => {
              if (response.status >= 200 && response.status <= 204) {
                Vue.$log.debug('FormWizard.formWizardOnComplete createBetgame saveBetgameTeams ' + response.status)
                this.$store.dispatch('clearSelectedTeams', {}).then(() => {
                  Vue.$log.debug('FormWizard.formWizardOnComplete createBetgame saveBetgameTeams clearSelectedTeams ok ')
                })
              }
            }, error => {
              Vue.$log.debug('createBetgame saveBetgameTeams error ' + error)
            })
          }
          Vue.$log.debug('FormWizard.formWizardOnComplete OK ' + this.$store.state.user.id)
          this.$store.dispatch('fetchUserBetgames', this.$store.state.user.id)
          .then(() => {
            Vue.$log.debug('FormWizard.formWizardOnComplete OK fetchUserBetgames + ' + this.$store.state.userBetgames)
            Vue.$log.debug('FormWizard.formWizardOnComplete bgame ' + this.$store.state.betgameId)
            this.$store.dispatch('switchBetgame', this.$store.state.betgameId).then(() => {
              //this.$store.dispatch('fetchBetgame', this.$store.state.betgameId).then(() => {
              this.$router.push('/betgameedit')
              //})
            })
          }).catch(function (error) {
            Vue.$log.debug('FormWizard.formWizardOnComplete ' + error)
          })
        }).catch(function (error) {
          this.submitClicked = 0
          if (error.response.status === 409) {
            alert(this.$t('errors.email_already_registered'))
          } else {
            alert(this.$t('errors.registration_failed'))
          }
        })
      } else {
        if (this.$store.state.createBetgame.addUsers && this.$store.state.createBetgame.addUsersBetgame > 0) {
          this.$store.dispatch('createBetgameAddUsers', {
            email: this.$store.state.user.email.toLowerCase(),
            user_id: '' + this.$store.state.user.id,
            betgame: this.$store.state.createBetgame.betgameName.trim(),
            competition: this.$store.state.createBetgame.betgameType === TYPE_BETGAME ? parseInt(this.$store.state.createBetgame.competition) : BATTLE_COMPETITION,
            add_users: '' + this.$store.state.createBetgame.addUsers,
            betgame_src: '' + this.$store.state.createBetgame.addUsersBetgame
          })
          .then(() => {
            this.$store.dispatch('patchBetgame', {
              bet_mode: this.$store.state.createBetgame.betmode,
              description: this.$store.state.createBetgame.betgameDescription,
              /*
              primary_color: this.$store.state.createBetgame.betgameType === TYPE_BETGAME ? '#0C3EC1' : '#EC0E01',
              button_color: this.$store.state.createBetgame.betgameType === TYPE_BETGAME ? '#0C3EC1' : '#EC0E01',
              link_color: this.$store.state.createBetgame.betgameType === TYPE_BETGAME ? '#0C3EC1' : '#EC0E01',
               */
              type: this.$store.state.createBetgame.betgameType === 'battle' ? TYPE_BATTLE_ID : (this.$store.state.createBetgame.betgameType === 'teams' ? TYPE_TEAMS_ID : TYPE_BETGAME_ID)
            }).then(() => {
            })
            this.$store.dispatch('addBetgamesOwner', {
              id: this.$store.state.betgameId,
              name: this.$store.state.createBetgame.betgameName.trim(),
              type: this.$store.state.createBetgame.betgameType === TYPE_BATTLE ? 2 : 1
            })
            if(this.$store.state.chat_token && this.$store.state.chat_token !== '') {
              const payload = jwt_decode(JSON.stringify(this.$store.state.chat_token))
              const a = payload.exp * 1000
              const b = moment()
              if(a > b) {
                this.$store.dispatch('chatCreateConversation', {
                  'title': this.$store.state.createBetgame.betgameName.trim(),
                  'participant_ids': ['' + this.$store.state.user.id]
                }).then(response => {
                  Vue.$log.debug('FormWizard.formWizardOnComplete chatCreateConversation response: ' + JSON.stringify(response))
                })
                .catch(function (error) {
                  Vue.$log.debug('FormWizard.formWizardOnComplete chatCreateConversation error: ' + JSON.stringify(error))
                })
              }
            }
            if (this.$store.state.createBetgame.betgameType === TYPE_BATTLE) {
              const sm = this.$store.state.createBetgame.selectedMatches
              var m = []
              for (var key in sm) {
                if (sm.hasOwnProperty(key)) {
                  for (var key1 in sm[key]) {
                    if (sm[key].hasOwnProperty(key1)) {
                      const v = sm[key][key1]
                      if (v === true) {
                        m.push({ fk_betgame: this.$store.state.betgameId, fk_match: parseInt(key1) })
                      }
                    }
                  }
                }
              }
              const data = {
                fk_betgame: this.$store.state.betgameId,
                matches: m
              }
              Vue.$log.debug('FormWizard.formWizardOnComplete createBetgame saveBetgameMatches data ' + JSON.stringify(data))
              this.$store.dispatch('saveBetgameMatches', data).then(response => {
                if (response.status >= 200 && response.status <= 204) {
                  Vue.$log.debug('FormWizard.formWizardOnComplete createBetgame saveBetgameMatches ' + response.status)
                  this.$store.dispatch('clearSelectedMatches', {}).then(() => {
                    Vue.$log.debug('FormWizard.formWizardOnComplete createBetgame saveBetgameMatches clearSelectedMatches ok ')
                  })
                }
              }, error => {
                Vue.$log.debug('createBetgame saveBetgameMatches error ' + error)
              })
            } else if (this.$store.state.createBetgame.betgameType === TYPE_TEAMS) {
              const st = this.$store.state.createBetgame.selectedTeams
              var data = []
              for (var i=0; i<st.length; i++) {
                data.push({ fk_betgame: this.$store.state.betgameId, fk_team: parseInt(st[i]), fk_season: parseInt(this.$store.state.currentSeason.id) })
              }
              Vue.$log.debug('FormWizard.formWizardOnComplete createBetgame saveBetgameTeams data ' + JSON.stringify(data))
              this.$store.dispatch('saveBetgameTeams', data).then(response => {
                if (response.status >= 200 && response.status <= 204) {
                  Vue.$log.debug('FormWizard.formWizardOnComplete createBetgame saveBetgameTeams ' + response.status)
                  this.$store.dispatch('clearSelectedTeams', {}).then(() => {
                    Vue.$log.debug('FormWizard.formWizardOnComplete createBetgame saveBetgameTeams clearSelectedTeams ok ')
                  })
                }
              }, error => {
                Vue.$log.debug('createBetgame saveBetgameTeams error ' + error)
              })
            }
            Vue.$log.debug('FormWizard.formWizardOnComplete createBetgame OK ' + this.$store.state.user.id)
            Vue.$log.debug('FormWizard.formWizardOnComplete createBetgame OK fetchUserBetgames + ' + this.$store.state.userBetgames)
            Vue.$log.debug('FormWizard.formWizardOnComplete bgame ' + this.$store.state.betgameId)
            this.$store.dispatch('switchBetgame', this.$store.state.betgameId).then(() => {
              //this.$store.dispatch('fetchBetgame', this.$store.state.betgameId).then(() => {
              this.$router.push('/betgameedit')
              //})
            })
          }).catch(function (error) {
            this.submitClicked = 0
            alert(this.$t('errors.betgame_save_failed') + ' ' + error.toString())
          })
        } else {
          this.$store.dispatch('createBetgame', {
            email: this.$store.state.user.email.toLowerCase(),
            user_id: '' + this.$store.state.user.id,
            betgame: this.$store.state.createBetgame.betgameName.trim(),
            competition: this.$store.state.createBetgame.betgameType === TYPE_BETGAME ? parseInt(this.$store.state.createBetgame.competition) : BATTLE_COMPETITION
          })
          .then(() => {
            this.$store.dispatch('patchBetgame', {
              bet_mode: this.$store.state.createBetgame.betmode,
              description: this.$store.state.createBetgame.betgameDescription,
              /*
              primary_color: this.$store.state.createBetgame.betgameType === TYPE_BETGAME ? '#0C3EC1' : '#EC0E01',
              button_color: this.$store.state.createBetgame.betgameType === TYPE_BETGAME ? '#0C3EC1' : '#EC0E01',
              link_color: this.$store.state.createBetgame.betgameType === TYPE_BETGAME ? '#0C3EC1' : '#EC0E01',
               */
              type: this.$store.state.createBetgame.betgameType === 'battle' ? TYPE_BATTLE_ID : (this.$store.state.createBetgame.betgameType === 'teams' ? TYPE_TEAMS_ID : TYPE_BETGAME_ID)
            }).then(() => {
            })
            this.$store.dispatch('addBetgamesOwner', {
              id: this.$store.state.betgameId,
              name: 'Neue Tipprunde',
              type: 'Wettbewerb'
            })
            if(this.$store.state.chat_token && this.$store.state.chat_token !== '') {
              const payload = jwt_decode(JSON.stringify(this.$store.state.chat_token))
              const a = payload.exp * 1000
              const b = moment()
              if(a > b) {
                this.$store.dispatch('chatCreateConversation', {
                  'title': this.$store.state.createBetgame.betgameName.trim(),
                  'participant_ids': ['' + this.$store.state.user.id]
                }).then(response => {
                  Vue.$log.debug('FormWizard.formWizardOnComplete chatCreateConversation response: ' + JSON.stringify(response))
                })
                .catch(function (error) {
                  Vue.$log.debug('FormWizard.formWizardOnComplete chatCreateConversation error: ' + JSON.stringify(error))
                })
              }
            }
            if (this.$store.state.createBetgame.betgameType === TYPE_BATTLE) {
              const sm = this.$store.state.createBetgame.selectedMatches
              var m = []
              for (var key in sm) {
                if (sm.hasOwnProperty(key)) {
                  for (var key1 in sm[key]) {
                    if (sm[key].hasOwnProperty(key1)) {
                      const v = sm[key][key1]
                      if (v === true) {
                        m.push({ fk_betgame: this.$store.state.betgameId, fk_match: parseInt(key1) })
                      }
                    }
                  }
                }
              }
              const data = {
                fk_betgame: this.$store.state.betgameId,
                matches: m
              }
              Vue.$log.debug('FormWizard.formWizardOnComplete createBetgame saveBetgameMatches data ' + JSON.stringify(data))
              this.$store.dispatch('saveBetgameMatches', data).then(response => {
                if (response.status >= 200 && response.status <= 204) {
                  Vue.$log.debug('FormWizard.formWizardOnComplete createBetgame saveBetgameMatches ' + response.status)
                  this.$store.dispatch('clearSelectedMatches', {}).then(() => {
                    Vue.$log.debug('FormWizard.formWizardOnComplete createBetgame saveBetgameMatches clearSelectedMatches ok ')
                  })
                }
              }, error => {
                Vue.$log.debug('createBetgame saveBetgameMatches error ' + error)
              })
            } else if (this.$store.state.createBetgame.betgameType === TYPE_TEAMS) {
              const st = this.$store.state.createBetgame.selectedTeams
              var data = []
              for (var i=0; i<st.length; i++) {
                data.push({ fk_betgame: this.$store.state.betgameId, fk_team: parseInt(st[i]), fk_season: parseInt(this.$store.state.currentSeason.id) })
              }
              Vue.$log.debug('FormWizard.formWizardOnComplete createBetgame saveBetgameTeams data ' + JSON.stringify(data))
              this.$store.dispatch('saveBetgameTeams', data).then(response => {
                if (response.status >= 200 && response.status <= 204) {
                  Vue.$log.debug('FormWizard.formWizardOnComplete createBetgame saveBetgameTeams ' + response.status)
                  this.$store.dispatch('clearSelectedTeams', {}).then(() => {
                    Vue.$log.debug('FormWizard.formWizardOnComplete createBetgame saveBetgameTeams clearSelectedTeams ok ')
                  })
                }
              }, error => {
                Vue.$log.debug('createBetgame saveBetgameTeams error ' + error)
              })
            }
            Vue.$log.debug('FormWizard.formWizardOnComplete createBetgame OK ' + this.$store.state.user.id)
            Vue.$log.debug('FormWizard.formWizardOnComplete createBetgame OK fetchUserBetgames + ' + this.$store.state.userBetgames)
            Vue.$log.debug('FormWizard.formWizardOnComplete bgame ' + this.$store.state.betgameId)
            this.$store.dispatch('switchBetgame', this.$store.state.betgameId).then(() => {
              //this.$store.dispatch('fetchBetgame', this.$store.state.betgameId).then(() => {
              this.$router.push('/betgameedit')
              //})
            })
          }).catch(function (error) {
            this.submitClicked = 0
            alert(this.$t('errors.betgame_save_failed') + ' ' + error.toString())
          })
        }
      }
    },    
    formWizardOnLoading() {
      Vue.$log.debug('FormWizard.formWizardOnLoading ')
    },
    formWizardOnValidate(valid, index) {
      Vue.$log.debug('FormWizard.formWizardOnValidate ' + valid + ' --- ' + index)
    },
    formWizardOnError() {
      Vue.$log.debug('FormWizard.formWizardOnError ')
    },
    formWizardOnChange() {
      Vue.$log.debug('FormWizard.formWizardOnChange ' + JSON.stringify(this.$store.state.createBetgame))
      this.errors = []
    },
    beforeChangeBetgameType() {
      Vue.$log.debug('beforeChangeBetgameType ' + this.$store.state.createBetgame.betgameType)
      this.errors = []
      if(this.$store.state.createBetgame.betgameType === TYPE_BATTLE || this.$store.state.createBetgame.betgameType === TYPE_BETGAME) {
        return true
      }
      if(this.$store.state.createBetgame.betgameType === TYPE_TEAMS) {
        this.$store.dispatch('fetchAllTeams', '').then(() => {
          Vue.$log.debug('beforeChangeBetgameType fetchAllTeams OK ')
        })
        return true
      }
      this.errors.push(this.$t('errors.select_betgame_type'))
      return false
    },
    beforeChangeBetgameName() {
      Vue.$log.debug('beforeChangeBetgameName ' + this.$store.state.createBetgame.betgameName)
      this.errors = []
      if(!this.$store.state.createBetgame.betgameName || this.$store.state.createBetgame.betgameName.trim().length < 3 || 
          this.$store.state.createBetgame.betgameName.trim().length >= 100) {
        this.errors.push(this.$t('errors.betgame_name_length'))
        return false
      }
      /*
      if (this.isNullOrUndefined(this.$store.state.createBetgame.betgameName)) {
        //this.betgameErrors.push(this.$t('errors.betgame_name_length'))
      } else {
        if (this.$store.state.createBetgame.betgameName.trim().length < 6) {
          //this.betgameErrors.push(this.$t('errors.betgame_name_length'))
        } else if (this.$store.state.createBetgame.betgameName.trim().length >= 100) {
          //this.betgameErrors.push(this.$t('errors.betgame_name_max_length'))
        }
      }
      */
      return true
    },
    beforeChangeBetgameDescription() {
      Vue.$log.debug('beforeChangeBetgameDescription ' + this.$store.state.createBetgame.betgameDescription)
      if(this.$store.state.createBetgame.betgameDescription && this.$store.state.createBetgame.betgameDescription.length >= 1000) {
        return false
      }
      return true
    },
    beforeChangeCompetition() {
      Vue.$log.debug('beforeChangeCompetition ' + this.$store.state.createBetgame.competition)
      this.errors = []
      if(this.$store.state.createBetgame.betgameType === TYPE_BETGAME) {
        if(!this.$store.state.createBetgame.competition) {
          Vue.$log.debug('beforeChangeCompetition competition error ' + this.$store.state.createBetgame.competition)
          this.errors.push(this.$t('errors.select_betgame_competition'))
          return false
        }
      } else if(this.$store.state.createBetgame.betgameType === TYPE_BATTLE) {
        if(!this.$store.state.createBetgame.selectedMatches) {
          Vue.$log.debug('beforeChangeCompetition battle error ' + this.$store.state.createBetgame.competition)
          this.errors.push(this.$t('errors.select_battes_matches'))
          return false
        }
        const sm = this.$store.state.createBetgame.selectedMatches
        var natchSelected = false
        var m = []
        for (var key in sm) {
          if(sm.hasOwnProperty(key)) {
            for (var key1 in sm[key]) {
              if (sm[key].hasOwnProperty(key1)) {
                const v = sm[key][key1]
                if(v === true && !natchSelected) {
                  natchSelected = true
                }
                if (v === true) {
                  m.push(parseInt(key1))
                }
              }
            }
          }
        }
        if(!natchSelected) {
          Vue.$log.debug('beforeChangeCompetition battle no matches selected ' + JSON.stringify(sm, null, 4))
          this.errors.push(this.$t('errors.select_battes_matches'))
          return false
        } else if(m.length > 120) {
          Vue.$log.debug('beforeChangeCompetition battle too many matches selected ' + m.length)
          this.errors.push(this.$t('errors.select_battes_matches_max'))
          return false
        }
      } else if(this.$store.state.createBetgame.betgameType === TYPE_TEAMS) {
        if(!this.$store.state.createBetgame.selectedTeams || !Array.isArray(this.$store.state.createBetgame.selectedTeams) ||
            this.$store.state.createBetgame.selectedTeams.length === 0 || 
            this.$store.state.createBetgame.selectedTeams.length > 3) {
          Vue.$log.debug('beforeChangeCompetition teams error ' + this.$store.state.createBetgame.selectedTeams)
          window.scrollTo(0,0)
          this.errors.push(this.$t('errors.select_teams'))
          return false
        }
      }
      return true
    },
    beforeChangeBetmode() {
      Vue.$log.debug('beforeChangeBetmode ' + this.$store.state.createBetgame.betmode)
      this.errors = []
      if(parseInt(this.$store.state.createBetgame.betmode) === BETMODE_RESULT || 
          parseInt(this.$store.state.createBetgame.betmode) === BETMODE_TOTO) {
        return true
      }
      this.errors.push(this.$t('errors.select_betgame_mode'))
      return false
    },
    beforeChangeUserData() {
      Vue.$log.debug('beforeChangeUserData')
      this.errors = []
      if(!this.$store.state.createBetgame.userName || this.$store.state.createBetgame.userName.trim().length < 3 || 
          this.$store.state.createBetgame.userName.trim().length >= 100) {
        Vue.$log.debug('beforeChangeUserData userName')
        this.errors.push(this.$t('errors.enter_nickname_length'))
        return false
      }
      if(!this.$store.state.createBetgame.email || this.$store.state.createBetgame.email.trim().length === 0 ||
          this.$store.state.createBetgame.email.trim().length >= 100) {
        Vue.$log.debug('beforeChangeUserData email')
        this.errors.push(this.$t('errors.enter_valid_email'))
        return false
      }
      if(!this.isValidEmail(this.$store.state.createBetgame.email.trim())) {
        Vue.$log.debug(this.$t('errors.enter_valid_email'))
        this.errors.push(this.$t('errors.enter_valid_email'))
        return false
      }
      if(!this.$store.state.createBetgame.password || this.$store.state.createBetgame.password.trim().length < 8 ||
          this.$store.state.createBetgame.password.trim().length >= 100) {
        Vue.$log.debug('beforeChangeUserData password')
        this.errors.push(this.$t('errors.enter_password_length'))
        return false
      }
      if (!this.$store.state.createBetgame.agreedTOS) {
        this.errors.push(this.$t('errors.confirm_terms_of_use'))
        return false
      }
      
      return true
      
      /*
      APIService.isEmailRegistered(this.$store.state.createBetgame.email).then(response => {
        if (response.data.valid === '1') {
          //this.emailAlreadyRegistered = false

          return true
        } else {
          //this.emailAlreadyRegistered = true
          this.errors.push(this.$t('errors.email_already_registered'))
          return false
        }
      })
      */
      
      /*
      if (this.isNullOrUndefined(this.name) || this.name.trim().length === 0 || this.name.trim().length > 100) {
        this.errors.push(this.$t('errors.enter_nickname'))
      }
      if (this.isNullOrUndefined(this.email) || this.email.trim().length === 0 || this.email.trim().length > 100) {
        this.errors.push(this.$t('errors.enter_email'))
      } else if (!this.isValidEmail(this.email)) {
        this.errors.push(this.$t('errors.enter_valid_email'))
      }
      if (this.isNullOrUndefined(this.password) || this.password.trim().length === 0 || this.password.trim().length > 100) {
        this.errors.push(this.$t('errors.enter_password_length'))
      }
      */
      
    }
  }
}

</script>

<style lang="scss" scoped>

.createbetgame {
  max-width: 400px;
  margin: 16px auto 16px;
}

.input-radio-image {
  width: 100%;
  background: $colorWhite;
  border-bottom: 1px solid rgba($colorGreyLight, .2);
  margin-top: -8px;
  position: relative;
  z-index: 2;padding-bottom: 8px;

  img {
    width: 100%;

    @media screen and (min-width: 450px) {
      max-width: 300px;
    }
  }
}

.input-container {
  margin-top: 0 !important;
}

</style>

