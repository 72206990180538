<template>
  <div class="invitationlink">
  
    <NavigationBar>
      <template v-slot:left>
        <img v-on:click="goBack" class="back" src="../assets/icons/ic_16_back.svg">
      </template>
      <template v-slot:center>
        {{ $t('betgame_settings.renew_invitation_link') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>
    
    
    <div class="notification">
      <p>{{ $t('share_betgame.text') }}</p>
      <div v-clipboard:copy="share_betgame_link" v-on:click="shareLinkClicked" id="shareInput">
        <p style="width: 100%;">{{ share_betgame_link }}</p>
      </div>
      <div v-if="isPrivateBetgame" style="margin-top: 20px">
        <p>{{ $t('share_betgame.refresh_invitation_link_text') }}</p>
        <button style="margin-top: 10px" v-on:click="refreshInvitationCode">{{ $t('share_betgame.button_refresh_invitation') }}</button>
      </div>
    </div>
    
  </div>
</template>

<script>
  import NavigationBar from './../components/NavigationBar'
  import { TOAST_DURATION } from '@/main'
export default {
  name: 'invitationlink',
  components: {
    NavigationBar
  },
  data() {
    return {
    }
  },
  beforeCreate() {
  },
  mounted() {
  },
  created() {
  },
  computed: {
    isPrivateBetgame() {
      return this.$store.state.betgame.is_public === false
    },
    share_betgame_link() {
      return this.$store.state.apiUrl + '/join/' + this.$store.state.betgame.id + '/' + this.$store.state.betgame.invitation_code
    },
    share_betgame_text() {
      return this.$t('betgame_settings.share_invitation_text')
    }
  },
  methods: {
    refreshInvitationCode() {
      const newcode = Math.random().toString().slice(2,10)
      this.$store.dispatch('patchBetgame', {invitation_code: newcode})
      .then(() => {
        this.$store.dispatch('updateBetgameInvitationCode', newcode)
        .then(() => {
        })        
      })
      .catch(function (error) {
        this.$toast.open({ message: this.$i18n.t('share_betgame.refresh_invitation_failed_try_again'),
          position: 'top',
          duration: TOAST_DURATION,
          type: 'error'
        })
      })
      .finally(function () {
      })
    },
    shareLinkClicked() {
      this.$toast.open({ message:  this.$i18n.t('share_betgame.invitation_link_copied_to_clipboard'), position: 'top', duration: 1000, type: 'info' })
    },
    goBack() {
      this.$router.push('/betgameedit')
    }
  }
}

</script>

<style lang="scss" scoped>

  #shareInput {
    word-break: break-all;
    position: relative;
    display: flex;
    width: 100%;
    padding: 16px 8px 16px;
    margin-top: 16px;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-primary);
    text-align: center;
    box-sizing: border-box;
    background: rgba($colorGreyDark, .08);
    border-radius: 6px;
    border: 0;
    -webkit-text-fill-color: var(--color-primary);
    opacity: 1;
  }

  .divider {
    display: flex;
    align-items: center;
    margin: 24px 0;

    &-stroke {
      flex: 1;
      height: 1px;
      background: rgba($colorGreyLight, .08);
    }

    &-label {
      margin: 0 8px;
      font-size: 14px;
      color: $colorGreyMid;
    }
  }

  .list {
    padding: 8px 16px;

    a {
      text-decoration: none;
    }
  }

</style>
