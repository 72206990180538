<template>

  <!-- TODO @Lennard: Die Klasse ".trendbox--many" muss hinzugefügt werden, wenn 10 Spiele dargestellt werden -->
	<div v-if="matchdayPoints.length > 0" :key="componentKey" class="trendbox" :class="{'trendbox--many' : this.$store.state.trendBoxSize === 10}">

    <!-- Header -->
    <div class="trendbox__header">
      <div class="trendbox__header__label">
        {{ $t('dashboard.my_shape_curve') }}
      </div>
      <div class="trendbox__header__select">
        <select @change="selectedSizeChanged($event)">
          <option :value="5" :selected="this.$store.state.trendBoxSize === 5">{{ $t('dashboard.last_5_matches') }}</option>
          <option :value="10" :selected="this.$store.state.trendBoxSize === 10">{{ $t('dashboard.last_10_matches') }}</option>
        </select>
      </div>
    </div>

    <!-- Content -->
    <div class="trendbox__content">

      <!-- Item -->
      <!-- TODO @Lennard: Die Klasse 'trendbox__item--negative' soll auch dann gegeben werden, wenn der User "0 Pkt" erreicht hat -->
      <div v-for="(data, index) in matchdayPoints" :key="index" class="trendbox__item" v-bind:class="{ 'trendbox__item--star' : data.win === true, 'trendbox__item--positive' : data.win === false && data.points >= 9, 'trendbox__item--middle' : data.win === false && data.points < 9 && data.points >= 5, 'trendbox__item--negative' : data.win === false && data.points < 5 && data.points > 0 }">

        <!-- Bar -->
        <div v-if="data.points > 0" class="trendbox__bar" v-bind:style="'height: calc((100% - 50px) * ' + data.points/maxPoints + ')'">{{ data.points }} <span>{{ $t('rankings_table_header_points') }}</span></div>
        <!-- TODO @Lennard: Wenn diese Bar angezeigt wird, weil z.B. erst 2 Spiele stattgefunden werden, aber ja mind. 5 Spiele angezeigt werden müssen, dann soll anstelle der Punktzahl ein Fragezeichen dargestellt werden -->
        <div v-else-if="data.placeholder === true" class="trendbox__bar--zero">?</div>
        <div v-else-if="data.placeholder === false && data.points === 0" class="trendbox__bar--zero">{{ data.points }} <span>{{ $t('rankings_table_header_points') }}</span></div>
        
        <!-- Label -->
        <!-- TODO @Lennard: Das Wort "Spieltag" im Label soll bei 5 Spieltagen mit "Sp." abgekürzt werden und bei 10 Spieltagen komplett ausgeblendet werden (sodass nur noch die Spieltagszahl angezeigt wird -> z.B. "32.") -->
        <div class="trendbox__label">{{ matchdayText(data.matchday) }}</div>

      </div>

      <!-- WIP -->
      <!--
      <div class="trendbox__content__gutter">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      -->

    </div>

  </div>
</template>

<script>
  	import { isNumeric } from '@/vuex/helpers'
  
    export default {
  	  name: 'DashboardTrendbox',
      data () {
        return {
          maxPoints: 8,
          componentKey: 0
        }
      },
      computed: {
        matchdayPoints () {
          var result = []
          const bets = this.$store.state.bets
          const md = this.$store.state.currentMatchdayRankingByMatchday > 0 ? this.$store.state.currentMatchdayRankingByMatchday : 1
          const sel = this.$store.state.trendBoxSize
          for (var matchday = md; matchday > 0 && result.length < (sel); matchday--) {
            var points = 0
            const matchdayMatches = this.$store.getters.getMatchesByMatchday(matchday)
            if (matchdayMatches.length > 0) {
              for (var i = 0; i < matchdayMatches.length; i++) {
                const match = matchdayMatches[i]
                var matchdayStarted = false
                if((!isNumeric(match.goalshome) || !isNumeric(match.goalsguest)) && 
                    (!isNumeric(match.livegoalshome) || !isNumeric(match.livegoalsguest))) {
                  continue
                } else {
                  matchdayStarted = true
                }
                const betMatches = bets.filter(betMatch => betMatch.fk_match === match.id)
                if (betMatches.length === 1) {
                  const p = this.getPoints(betMatches[0], match)
                  if(p > 0) {
                    points += p
                  }
                }
              }
            }
            if(points > 0) {
              if(points > this.maxPoints) {
                this.maxPoints = points
              }
              const mdw = this.$store.getters.getMatchdayWin(this.$store.state.user.id, matchday)
              result.push( {matchday: matchday, points: points, win: mdw.length === 1, placeholder: false } )
            } else {
              result.push( {matchday: matchday, points: 0, win: false, placeholder: !matchdayStarted } )
            }
          }
          if(result.length < sel) {
            for(var i = result.length; i < sel; i++) {
              result.unshift( {matchday: i+1, points: 0, win: false, placeholder: true } )
            }
          }
          return result
        }
      },
      methods: {
        selectedSizeChanged(event) {
          const t = parseInt(event.target.value)
          if(t) {
            this.$store.dispatch('setTrendBoxSize', t)
            this.componentKey++
          }
        },
        matchdayText(matchday) {
          if(matchday === undefined || matchday === null || matchday < 1) {
            return ''
          }
          if(this.$store.state.betgame && this.$store.state.betgame.competition &&
              (this.$store.state.betgame.competition.id === 19 || this.$store.state.betgame.competition.id === 132)) {
            if(matchday === 9) {
              return this.$i18n.t('ko_round_playoffs_first_legs')
            } else if(matchday === 10) {
              return this.$i18n.t('ko_round_playoffs_second_legs')
            } else if(matchday === 11) {
              return this.$i18n.t('ko_round_of_16_first_legs')
            } else if(matchday === 12) {
              return this.$i18n.t('ko_round_of_16_second_legs')
            } else if(matchday === 13) {
              return this.$i18n.t('ko_round_quarter_finals_first_legs')
            } else if(matchday === 14) {
              return this.$i18n.t('ko_round_quarter_finals_second_legs')
            } else if(matchday === 15) {
              return this.$i18n.t('ko_round_semi_finals_first_legs')
            } else if(matchday === 16) {
              return this.$i18n.t('ko_round_semi_finals_second_legs')
            } else if(matchday === 17) {
              return this.$i18n.t('ko_round_final')
            }
          } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 6395) {
            if(matchday === 7) {
              return this.$i18n.t('ko_round_playoffs_first_legs')
            } else if(matchday === 8) {
              return this.$i18n.t('ko_round_playoffs_second_legs')
            } else if(matchday === 9) {
              return this.$i18n.t('ko_round_of_16_first_legs')
            } else if(matchday === 10) {
              return this.$i18n.t('ko_round_of_16_second_legs')
            } else if(matchday === 11) {
              return this.$i18n.t('ko_round_quarter_finals_first_legs')
            } else if(matchday === 12) {
              return this.$i18n.t('ko_round_quarter_finals_second_legs')
            } else if(matchday === 13) {
              return this.$i18n.t('ko_round_semi_finals_first_legs')
            } else if(matchday === 14) {
              return this.$i18n.t('ko_round_semi_finals_second_legs')
            } else if(matchday === 15) {
              return this.$i18n.t('ko_round_final')
            }
          } else if(this.$store.state.betgame && this.$store.state.betgame.competition && 
              (this.$store.state.betgame.competition.id === 10700 || this.$store.state.betgame.competition.id === 36)) {
            if(matchday === 4) {
              return this.$i18n.t('em_ko_round_of_16')
            } else if(matchday === 5) {
              return this.$i18n.t('em_ko_round_quarter_finals')
            } else if(matchday === 6) {
              return this.$i18n.t('em_ko_round_semi_finals')
            } else if(matchday === 7) {
              return this.$i18n.t('ko_round_final')
            }
          } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 533) {
            const nextMatchday = matchday
            if(nextMatchday === 1) {
              return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
            } else if(nextMatchday === 2) {
              return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
            } else if(nextMatchday === 3) {
              return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
            } else if(nextMatchday === 8) {
              return this.$i18n.t('ko_round_fifth_place_short')
            } else if(nextMatchday === 9) {
              return this.$i18n.t('em_ko_round_semi_finals')
            } else if(nextMatchday === 10) {
              return this.$i18n.t('ko_round_third_place_short')
            } else if(nextMatchday === 11) {
              return this.$i18n.t('ko_round_final')
            }
          } else if(this.$store.state.betgame && this.$store.state.betgame.competition && 
              (this.$store.state.betgame.competition.id === 139 || this.$store.state.betgame.competition.id === 1110)) {
            const nextMatchday = matchday
            if(nextMatchday === 1) {
              return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
            } else if(nextMatchday === 2) {
              return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
            } else if(nextMatchday === 3) {
              return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
            } else if(nextMatchday === 4) {
              return this.$i18n.t('em_ko_round_of_16')
            } else if(nextMatchday === 5) {
              return this.$i18n.t('em_ko_round_quarter_finals')
            } else if(nextMatchday === 6) {
              return this.$i18n.t('em_ko_round_semi_finals')
            } else if(nextMatchday === 7) {
              return this.$i18n.t('ko_round_third_place_short')
            } else if(nextMatchday === 8) {
              return this.$i18n.t('ko_round_final')
            }
          } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 1180) {
            const nextMatchday = matchday
            if(nextMatchday === 1) {
              return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
            } else if(nextMatchday === 2) {
              return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
            } else if(nextMatchday === 3) {
              return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
            } else if(nextMatchday === 4) {
              return this.$i18n.t('em_ko_round_quarter_finals')
            } else if(nextMatchday === 5) {
              return this.$i18n.t('em_ko_round_semi_finals')
            } else if(nextMatchday === 6) {
              return this.$i18n.t('ko_round_final')
            }
          }
          if(this.$store.state.trendBoxSize === 5) {
            return this.$i18n.t('matchday_number_short', { matchday: matchday })
          } else {
            return matchday + '.'
          }
        },
        getPoints(bet, match) {
          if(bet === null || match === null) {
            return -1
          }
          if(!this.isNumeric(match.goalshome) && !this.isNumeric(match.goalsguest) &&
                  !this.isNumeric(match.livegoalshome) && !this.isNumeric(match.livegoalsguest)) {
            return -1
          }
          var goalshome = null
          var goalsguest = null
          if(match.livegoalshome !== null && match.livegoalsguest !== null &&
                  match.goalshome === null && match.goalsguest === null) {
            goalshome = match.livegoalshome
            goalsguest = match.livegoalsguest
          } else if(match.goalshome !== null && match.goalsguest !== null) {
            goalshome = match.goalshome
            goalsguest = match.goalsguest
          }
          if(goalshome !== null && goalsguest !== null) {
            if(!this.$store.state.betgame.bet_mode || this.$store.state.betgame.bet_mode === 1) {
              if ((bet.goalshome === goalshome) && (bet.goalsguest === goalsguest)) {
                return this.$store.getters.pointsBetResult
              } else if ((bet.goalshome - bet.goalsguest) === (goalshome - goalsguest)) {
                return this.$store.getters.pointsBetGoaldiff
              } else if ((bet.goalshome > bet.goalsguest) && (goalshome > goalsguest)) {
                return this.$store.getters.pointsBetTendency
              } else if ((bet.goalshome < bet.goalsguest) && (goalshome < goalsguest)) {
                return this.$store.getters.pointsBetTendency
              }
            } else if(this.$store.state.betgame.bet_mode === 2) {
              if ((bet.goalshome === 1) && (goalshome > goalsguest)) {
                return this.$store.getters.pointsBetResult
              } else if ((bet.goalshome === 0) && (goalshome === goalsguest)) {
                return this.$store.getters.pointsBetResult
              } else if ((bet.goalshome === 2) && (goalshome < goalsguest)) {
                return this.$store.getters.pointsBetResult
              }
            }
          }
          return 0
        }
      }
	}
</script>

<style lang="scss" scoped>
.trendbox {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: $colorWhite;
  box-shadow: 0 0 1px 0 rgba($colorGreyDark, .08), 0px 4px 16px rgba($colorGreyDark, 0.08);
  border-radius: 8px;
  margin-bottom: 24px;

  &__header {
    display: flex;
    align-items: center;
    padding: 16px 16px 12px;
    color: $colorGreyDark;
    box-sizing: border-box;
    
    &__label {
      flex: 1;
      font-size: 17px;
      font-weight: 500;
    }

    &__select {
      position: relative;

      select {
        /* Reset */
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 0;
        background: none !important;

        /* New styles */
        font-size: 14px;
        color: $colorGreyDark;
        padding-right: 12px;
        text-align-last: right;

        &:focus, &:active {
          outline: none;
          color: #000000;
        }

        option {
          direction: rtl;
        }
      }

      select::-ms-expand {
        display: none;
      }

      &:before {
        position: absolute;
        content: url('../assets/icons/ic_12_dropdown.svg');
        right: 0;
        top: 1px;
        pointer-events: none;
      }
    }
  }

  &__content {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 160px;
    padding: 0 16px 12px;
    flex-direction: row-reverse;

    &__gutter {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: absolute;
      bottom: calc(22px + ((100% - 50%) / 10));
      left: 0;
      width: 100%;
      height: calc(100% - 50px - 12px);
      display: flex;
      flex-direction: column;

      span {
        width: 100%;
        height: 1px;
        background: rgba($colorGreyDark, .06);
        margin: 1px 0;

        &:last-child {
          visibility: hidden;
        }
      }
    }
  }

  &__item {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex: 0 1 25%;
    justify-content: flex-end;
    align-items: center;

    .trendbox--many & {
      margin: 0 4px;

      &:first-child {
        margin: 0 0 0 4px;
      }

      &:nth-last-child(2) {
        margin: 0 4px 0 0;
      }

      span {
        display: none;
      }
    }
  }
  
  &__bar {
    width: 100%;
    max-width: 48px;
    height: 100%;
    font-size: 11px;
    font-weight: 500;
    color: $colorWhite;
    text-align: center;
    padding: 5px 0 0;
    border-radius: 4px;
    min-height: 18px;
    box-sizing: border-box;

    &--zero {
      position: relative;
      width: 100%;
      max-width: 48px;
      font-size: 11px;
      font-weight: 500;
      color: #ACACAC;
      text-align: center;
      padding: 5px 0 0;
      border-radius: 4px;
      min-height: 18px;

      .trendbox__item--negative & {
        color: $colorNegative;
      }

      &:before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        border-radius: 1px;
        background: rgba($colorGreyDark, .08);

        .trendbox__item--negative & {
          background: $colorNegative;
        }
      }
    }

    .trendbox__item--positive & {
      background: $colorPositive;
    }
    .trendbox__item--middle & {
      background: $colorGreyMid;
    }
    .trendbox__item--negative & {
      background: $colorNegative;
    }
    .trendbox__item--star & {
      background: $colorYellow;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 32px;
        height: 32px;
        top: -36px;
        left: 50%;
        transform: translateX(-50%);
        background: url('../assets/icons/ic_32_star.svg');
      }
    }
  }

  &__label {
    font-size: 11px;
    font-weight: 500;
    color: $colorGreyMid;
    margin-top: 6px;
    white-space: nowrap;
  }
}
</style>
