<template>
    <div class="table">
        <div class="searchbar" v-bind:class="{ searchbar_active: searchActive }">
            <div class="searchbar-item searchtype-user">
              <input class="search-input" @input="searchInputChanged" type="text" placeholder="User suchen..." ref="searchinput">
            </div>
            <div v-if="false" class="searchbar-item searchtype-location">
              <select class="search-input" @input="searchInputChanged" ref="searchinput">
                <option value="">Alle Landkreise</option>
                <option value="Wandsbek">Wandsbek</option>
                <option value="St.Pauli">St.Pauli</option>
              </select>
            </div>
        </div>

        <thead>
            <!--<th class="col_search" colspan="2">
                <input @input="searchInputChanged" type="text" placeholder="User suchen..." style="width: 100%; max-width: 200px;"></input>
            </th>-->
            <th class="col_pos"></th>
            <th class="col_avatar"></th>
            <th class="col_user"></th>
            <th class="col_user_source"></th>
            <th class="col_value col_value--all">{{ $t('rankings_table_header_points') }}</th>
            <th v-if="questions && questions.length > 0" class="col_value col_value--points"><img src="../assets/icons/ic_16_points.svg" v-bind:alt="$t('rankings.total_points')" v-bind:title="$t('rankings.total_points')"></th>
            <th v-if="questions && questions.length > 0" class="col_value col_value--questions"><img src="../assets/icons/ic_16_questions.svg" v-bind:alt="$t('rankings.help_questions_points')" v-bind:title="$t('rankings.help_questions_points')"></th>
            <th v-if="hasPointsCorrections" class="col_value col_value--questions">{{ $t('rankings.help_points_correction_short') }}</th>
            <th v-if="!isBattle() && !isTournament() && !isTeamsBetgame()" style="padding: 14px 8px 12px 4px" class="col_value col_value--points"><img src="../assets/icons/ic_16_star.svg" v-bind:alt="$t('rankings.help_matchday_wins')" v-bind:title="$t('rankings.help_matchday_wins')"></th>
            <th v-if="betmode === 1" class="col_value col_value--points mobile-hidden">Exa</th>
            <th v-else-if="betmode === 2" class="col_value col_value--points mobile-hidden"><img src="../assets/icons/ic_16_points.svg" v-bind:alt="$t('rankings.help_bet_points')" v-bind:title="$t('rankings.help_bet_points')"></th>
            <th v-if="betmode === 1" class="col_value col_value--points mobile-hidden">Dif</th>
            <th v-if="betmode === 1" class="col_value col_value--points mobile-hidden">Ten</th>
            <th class="col_value col_value--points mobile-hidden">{{ $t('rankings.zero_points') }}</th>
            <th class="col_value mobile-hidden">{{ $t('rankings.count_short') }}</th>
        </thead>
        <tr
                v-for="(ranking, index) in rankings"
                :key="infiniteId + '_' + ranking.fk_user"
                v-bind:class="{ current_user: isCurrentUser(ranking.fk_user) }"
        >
            <td v-if="!equalPoints(index)" class="col_pos">{{ ranking.position }}.</td>
            <td v-else class="col_pos"></td>  
            <td class="col_avatar" v-if="isValidIcon(getAvatar(ranking.fk_user))"><img v-bind:src="buildAvatarPath(getAvatar(ranking.fk_user), 300)"></td>
            <td :colspan="isValidIcon(getAvatar(ranking.fk_user)) ? 2 : 3" class="col_user">{{ ranking.user_name }}</td>
            <td class="col_value col_value--all">{{ ranking.points_total }}</td>
            <td v-if="questions && questions.length > 0" class="col_value col_value--points">{{ ranking.bet_points }}</td>
            <td v-if="questions && questions.length > 0" class="col_value col_value--questions">{{ ranking.question_points }}</td>
            <td v-if="hasPointsCorrections" class="col_value col_value--points">{{ ranking.points_correction }}</td>
            <td v-if="!isBattle() && !isTournament() && !isTeamsBetgame()" class="col_value col_value--points">{{ ranking.matchday_wins }}</td>
            <td class="col_value col_value--points mobile-hidden">{{ ranking.exact }}</td>
            <td v-if="betmode === 1" class="col_value col_value--points mobile-hidden">{{ ranking.goaldiff }}</td>
            <td v-if="betmode === 1" class="col_value col_value--points mobile-hidden">{{ ranking.tendency }}</td>
            <td class="col_value col_value--points mobile-hidden">{{ ranking.no_points }}</td>
            <td class="col_value col_value--points mobile-hidden">{{ ranking.betcount }}</td>
        </tr>
    
        <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
            <div slot="spinner"><div class="messgae">Lade...</div></div>
            <div slot="no-more">
                <div class="statistics-empty">
                  {{ $t('rankings_table_footer') }}
                </div>
            </div>
            <div slot="no-results">
                <div class="statistics-empty">
                  {{ $t('rankings_table_footer_teaser') }}
                </div>
            </div>
        </infinite-loading>
        
    </div>
    
</template>

<script>
  
  import InfiniteLoading from 'vue-infinite-loading';
  import APIService from '@/services/APIService'
  
  export default {
    name: 'RankingsTableScrolling',
    components: {
      InfiniteLoading,
    },
    data() {
      return {
        page: 1,
        rankings: [],
        searchString: '',
        infiniteId: +new Date(),
      };
    },
    props: {
      searchActive: {
        type: Boolean,
        default: () => {
          return false
        }
      }
    },
    created() {
      this.$store.dispatch('fetchQuestions', []).then(function (response) {
      })
    },
    computed: {
      hasPointsCorrections() {
        return this.$store.state.pointsCorrectionsCount > 0
      },
      sortmode() {
        return this.$store.state.betgame.sort_mode || 1
      },
      betmode() {
        return this.$store.state.betgame.bet_mode || 1
      },
      questions() {
        return this.$store.state.questions.filter(q => parseInt(q.fk_betgame) === parseInt(this.$store.state.betgameId))
      },
    },
    methods: {
      equalPoints(index) {
        if(index === 0 || !this.rankings || this.rankings.length <= index) {
          return false
        }
        const r1 = this.rankings[index]
        const r2 = this.rankings[index - 1]
        if(this.isBattle() || this.isTournament()) {
          if (r1.points_total === r2.points_total && r1.exact === r2.exact && r1.goaldiff === r2.goaldiff) {
            return true
          }
        } else {
          if (this.sortmode === 1) {
            if (r1.points_total === r2.points_total && r1.matchday_wins === r2.matchday_wins
                && r1.exact === r2.exact && r1.goaldiff === r2.goaldiff) {
              return true
            }
          } else if (this.sortmode === 2) {
            if (r1.points_total === r2.points_total && r1.exact === r2.exact &&
                r1.matchday_wins === r2.matchday_wins && r1.goaldiff === r2.goaldiff) {
              return true
            }
          }
        }
        return false
      },      
      searchInputChanged({ type, target }) {
        this.searchString = target.value.trim()
        this.page = 1
        this.rankings = []
        this.infiniteId += 1
      },
      infiniteHandler($state) {

        if(this.isBattle()) {
          APIService.getLiveRankingPageBattle(this.$store.state.betgameId, this.$store.state.leagueId, this.searchString, (this.page - 1) * 500, 500)
          .then(response => {
            if (response.data.length) {
              this.page += 1;
              this.rankings.push(...response.data);
              $state.loaded();
            } else {
              $state.complete();
            }
          })
          .catch(error => {
          })
        } else if(this.isTeamsBetgame()) {
          APIService.getLiveRankingPageTeam(this.$store.state.betgameId, this.$store.state.leagueId, this.searchString, (this.page - 1) * 500, 500)
          .then(response => {
            if (response.data.length) {
              this.page += 1;
              this.rankings.push(...response.data);
              $state.loaded();
            } else {
              $state.complete();
            }
          })
          .catch(error => {
          })
        } else {
          const bg = this.$store.state.betgame
          APIService.getLiveRankingPage(this.$store.state.betgameId, this.$store.state.leagueId, bg.season.id, this.searchString, (this.page - 1) * 500, 500)
          .then(response => {
            if (response.data.length) {
              this.page += 1;
              this.rankings.push(...response.data);
              $state.loaded();
            } else {
              $state.complete();
            }
          })
          .catch(error => {
          })          
        }

        
      },
      isCurrentUser(userId) {
        if(this.$store.state.user) {
          return this.$store.state.user.id === userId
        }
      }
    },
    watch: {
      searchActive: function (newValue) {
        if(newValue === false && this.searchString.trim().length > 1) {
          this.searchString = ''
          this.page = 1
          this.rankings = []
          this.infiniteId += 1
        } else if(newValue === true) {
          this.$refs.searchinput.value = ''
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    .message {
        text-align: center;
        color: darkgray;
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .table {

        td, th {
            border-bottom: 1px solid rgba($colorGreyLight, .08);

            thead & {
                font-weight: 400;
                color: $colorGreyLight;
            }
        }
      
        @media screen and (max-width: 567px) {
          .mobile-hidden {
            display: none;
        }
      }
    }

    .col_value {
      text-align: right;
      overflow: hidden;
      font-weight: 400;

      &:last-child {
        padding-left: 4px;
        padding-right: 8px;
        text-align: center;
      }

      &--all, &--points, &--questions {
        max-width: 32px;
        min-width: 24px;
        padding: 0 4px;
        text-align: center;

        img {
          margin-bottom: -3px;
        }
      }

      &--all {
        font-weight: 500;

        thead & {
          font-weight: 400;
        }
      }
    }

    .col_avatar {
      padding-right: 4px;
      position: relative;
      min-width: 24px;
      height: 18px;

      img {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        object-fit: cover;
        border-radius: 3px;
      }
    }

    .col_user {
      text-align: center;
      overflow: hidden;
      padding-right: 16px;

      @media screen and (max-width: 414px) {
        max-width: 88px;
        padding-right: 8px;
      }
    }

    .col_user_source {
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      max-width: 100px;

      @media screen and (max-width: 414px) {
        max-width: 64px;
      }
    }
    
    .current_user {
        background-color: #F2F2F2;
    }

    .col_pos {
        text-align: center !important;
        overflow: hidden;
        padding-right: 4px;
    }

</style>
