<template>
  <div>
    <vue-final-modal v-model="showModal" @click-outside="clickOutside" class="chat__context">

      <div class="chat__context__content">
        <div class="chat__context__list">

          <div class="chat__context__list__reactionheader">
            <div v-for="reaction in reactionsCount" class="chat__context__list__reactionheader__item">
              <span>{{ reaction.emoji }}</span>
              {{ reaction.count }}
            </div>
          </div>

          <div v-for="reaction in reactionsDetails" class="chat__context__list__item">
            <div class="chat__context__list__item__avatar">
              <img v-if="isValidIcon(getAvatar(reaction.user.fk_user))" v-bind:src="buildAvatarPath(getAvatar(reaction.user.fk_user), 300)">
            </div>
            <div class="chat__context__list__item__label">
              {{ reaction.user.nickname }}
            </div>
            <span>{{ reaction.emoji }}</span>
          </div>
          
        </div>
      </div>

    </vue-final-modal>
  </div>
</template>

<script>

export default {
  name: 'ReactionContextMenu',
  props: {
    chatItem: Object,
    showModal: false
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    clickOutside() {
      this.$emit('clickOutside', { } )
    },
  },
  computed: {
    reactionsCount() {
      if(!this.chatItem || !this.chatItem.reactions) {
        return []
      }
      var result = []
      const r = this.chatItem.reactions
      for(let i = 0; i < r.length; i++) {
        if(!result.includes(r[i].text)) {
          result.push(r[i].text)
        } 
      }
      for(let i = 0; i < result.length; i++) {
        var c = 0
        for(let j = 0; j < r.length; j++) {
          if(result[i] === r[j].text) {
            c++
          }
        }
        result[i] = { emoji: result[i], count: c }
      }
      return result
    },
    reactionsDetails() {
      if(!this.chatItem || !this.chatItem.reactions) {
        return []
      }
      var result = []
      const r = this.chatItem.reactions
      for(let i = 0; i < r.length; i++) {
        const u = this.$store.getters.getUserById(parseInt(r[i].user_id))
        if(u) {
          result.push({ emoji: r[i].text, user: u })
        } 
      }
      return result
    },
  }
}
</script>

<style lang="scss">
  .chat__context__list__reactionheader {
    position: sticky;
    top: 0;
    display: flex;
    background: $colorWhite;
    border-radius: 8px 8px 0 0;
    padding: 16px 0 12px;
    z-index: 3;

    &__item {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: $colorGreyDark;
      padding: 0 16px;

      &:not(:last-child) {
        box-shadow: 1px 0 0 0 rgba($colorGreyDark, .16);
      }

      span {
        font-size: 17px;
        line-height: 24px;
        margin-right: 6px;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .chat__context__list {
    max-height: calc(100vw - 128px);
  }
</style>