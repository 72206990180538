<template>
  <div>
    <NavigationBar>
      <template v-slot:center>
        Chat
      </template>
    </NavigationBar>
    <Chat></Chat>
  </div>
</template>

<script>
import NavigationBar from '../components/NavigationBar'
import Vue from 'vue'
import Chat from '@/components/Chat.vue'

export default {
  name: 'ChatView',
  components: { Chat, NavigationBar },
  data () {
    return {}
  },
  beforeCreate () {
    Vue.$log.debug('ChatView beforeCreate')
  },
  created () {
    Vue.$log.debug('ChatView created ')
  },
  mounted () {
    Vue.$log.debug('ChatView mounted')
    //this.$store.dispatch('setUnreadCount', 0)
  },
  beforeDestroy () {

  },
  methods: {},
  computed: {}
}
</script>

<style lang="scss" scoped>
  .page {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
  }
</style>
