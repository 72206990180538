import axios from 'axios'
import { API_TIMEOUT, DASHBOARD_RANKINGS_SIZE, TOP_TEN } from '@/main'
import store from '../vuex/store'
import moment from 'moment'

//export const API_URL = `https://tippspiel.deinsportplatz.de`

const apiClient = axios.create({
    //baseURL: window.localStorage.getItem('spmau'),
    timeout: API_TIMEOUT,
    withCredentials: true, // This is the default
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

function getAPIURL() {
    return store.state.apiUrl
}

function getToken() {
    return store.state.token
}

export default {
    
    getLiveRankingsByPositionBattle (betgame, competition, position) {
        return apiClient.get(getAPIURL() + '/bg_v_ranking_battle?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,bet_points,question_points,points_correction,matchday_wins,exact,goaldiff,tendency,no_points,betcount&' +
          'and=(position.gte.' + (position - DASHBOARD_RANKINGS_SIZE) + ',position.lte.' + (position + DASHBOARD_RANKINGS_SIZE) + ')&fk_betgame=eq.' + betgame +
          '&order=position.asc',
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getLiveRankingsByPositionTeam (betgame, competition, position) {
        return apiClient.get(getAPIURL() + '/bg_v_ranking_team?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,bet_points,question_points,points_correction,matchday_wins,exact,goaldiff,tendency,no_points,betcount&' +
          'and=(position.gte.' + (position - DASHBOARD_RANKINGS_SIZE) + ',position.lte.' + (position + DASHBOARD_RANKINGS_SIZE) + ')&fk_betgame=eq.' + betgame +
          '&order=position.asc',
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getLiveRankingsByPosition (betgame, competition, position, season) {
        return apiClient.get(getAPIURL() + '/bg_v_ranking_' + season + '?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,bet_points,question_points,points_correction,matchday_wins,exact,goaldiff,tendency,no_points,betcount&' +
          'and=(position.gte.' + (position - DASHBOARD_RANKINGS_SIZE) + ',position.lte.' + (position + DASHBOARD_RANKINGS_SIZE) + ')&fk_betgame=eq.' + betgame +
          '&fk_competition=eq.' + competition + '&order=position.asc',
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getUserRanking (betgame, competition, user, season) {
        return apiClient.get(getAPIURL() + '/bg_v_ranking_' + season + '?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,bet_points,question_points,points_correction,matchday_wins,exact,goaldiff,tendency,no_points,betcount&' +
          'fk_user=eq.' + user + '&fk_betgame=eq.' + betgame + '&fk_competition=eq.' + competition,
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getUserRankingBattle (betgame, competition, user) {
        return apiClient.get(getAPIURL() + '/bg_v_ranking_battle?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,bet_points,question_points,points_correction,matchday_wins,exact,goaldiff,tendency,no_points,betcount&' +
          'fk_user=eq.' + user + '&fk_betgame=eq.' + betgame,
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getUserRankingTeam (betgame, competition, user) {
        return apiClient.get(getAPIURL() + '/bg_v_ranking_team?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,bet_points,question_points,points_correction,matchday_wins,exact,goaldiff,tendency,no_points,betcount&' +
          'fk_user=eq.' + user + '&fk_betgame=eq.' + betgame,
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getRanking (betgame, competition, season) {
        return apiClient.get(getAPIURL() + '/bg_v_ranking_' + season + '?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,bet_points,question_points,points_correction,matchday_wins,exact,goaldiff,tendency,no_points,betcount&' +
          'order=position.asc&fk_betgame=eq.' + betgame + '&fk_competition=eq.' + competition + '&limit=' + TOP_TEN,
                {
                    headers: { Authorization: 'Bearer ' + getToken() }
                }
        )
    },
    
    getRankingBattle (betgame, competition) {
        return apiClient.get(getAPIURL() + '/bg_v_ranking_battle?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,bet_points,question_points,points_correction,matchday_wins,exact,goaldiff,tendency,no_points,betcount&' +
          'order=position.asc&fk_betgame=eq.' + betgame + '&limit=' + TOP_TEN,
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getRankingTeam (betgame, competition) {
        return apiClient.get(getAPIURL() + '/bg_v_ranking_team?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,bet_points,question_points,points_correction,matchday_wins,exact,goaldiff,tendency,no_points,betcount&' +
          'order=position.asc&fk_betgame=eq.' + betgame + '&limit=' + TOP_TEN,
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getLiveRankingByUsers (betgame, competition, users, season) {
        return apiClient.get(getAPIURL() + '/bg_v_ranking_' + season + '?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,bet_points,question_points,points_correction,matchday_wins,exact,goaldiff,tendency,no_points,betcount&' +
          'order=position.asc&fk_betgame=eq.' + betgame + '&fk_competition=eq.' + competition + '&fk_user=in.(' + users + ')',
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getLiveRanking (betgame, competition, username, currentuser, season) {
        var userfilter = ''
        if(username !== undefined && username !== null && username.trim().length > 1) {
            if(currentuser !== undefined && currentuser !== null && currentuser > 0) {
                userfilter = '&or=(user_name.ilike.*' + username + '*,fk_user.eq.' + currentuser + ')'
            } else {
                userfilter = '&user_name=ilike.*' + username + '*'
            }
        }
        return apiClient.get(getAPIURL() + '/bg_v_ranking_' + season + '?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,bet_points,question_points,points_correction,matchday_wins,exact,goaldiff,tendency,no_points,betcount&' +
          'order=position.asc&fk_betgame=eq.' + betgame + '&fk_competition=eq.' + competition + '&limit=' + TOP_TEN + userfilter,
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    getLiveRankingBattle (betgame, competition, username, currentuser) {
        var userfilter = ''
        if(username !== undefined && username !== null && username.trim().length > 1) {
            if(currentuser !== undefined && currentuser !== null && currentuser > 0) {
                userfilter = '&or=(user_name.ilike.*' + username + '*,fk_user.eq.' + currentuser + ')'
            } else {
                userfilter = '&user_name=ilike.*' + username + '*'
            }
        }
        return apiClient.get(getAPIURL() + '/bg_v_ranking_battle?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,bet_points,question_points,points_correction,matchday_wins,exact,goaldiff,tendency,no_points,betcount&' +
          'order=position.asc&fk_betgame=eq.' + betgame + '&limit=' + TOP_TEN + userfilter,
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getLiveRankingTeam (betgame, competition, username, currentuser) {
        var userfilter = ''
        if(username !== undefined && username !== null && username.trim().length > 1) {
            if(currentuser !== undefined && currentuser !== null && currentuser > 0) {
                userfilter = '&or=(user_name.ilike.*' + username + '*,fk_user.eq.' + currentuser + ')'
            } else {
                userfilter = '&user_name=ilike.*' + username + '*'
            }
        }
        return apiClient.get(getAPIURL() + '/bg_v_ranking_team?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,bet_points,question_points,points_correction,matchday_wins,exact,goaldiff,tendency,no_points,betcount&' +
          'order=position.asc&fk_betgame=eq.' + betgame + '&limit=' + TOP_TEN + userfilter,
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getLiveRankingPage (betgame, competition, season, username, offset, limit) {
        var userfilter = ''
        if(username !== undefined && username !== null && username.trim().length > 1) {
            userfilter = '&user_name=ilike.*' + username + '*'
        }
        return apiClient.get(getAPIURL() + '/bg_v_ranking_' + season + '?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,bet_points,question_points,points_correction,matchday_wins,exact,goaldiff,tendency,no_points,betcount&' +
          'order=position.asc&fk_betgame=eq.' + betgame + '&fk_competition=eq.' + competition + '&limit=' + limit + '&offset=' + offset + userfilter,
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getLiveRankingPageBattle (betgame, competition, username, offset, limit) {
        var userfilter = ''
        if(username !== undefined && username !== null && username.trim().length > 1) {
            userfilter = '&user_name=ilike.*' + username + '*'
        }
        return apiClient.get(getAPIURL() + '/bg_v_ranking_battle?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,bet_points,question_points,points_correction,matchday_wins,exact,goaldiff,tendency,no_points,betcount&' +
          'order=position.asc&fk_betgame=eq.' + betgame + '&limit=' + limit + '&offset=' + offset + userfilter,
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getLiveRankingPageTeam (betgame, competition, username, offset, limit) {
        var userfilter = ''
        if(username !== undefined && username !== null && username.trim().length > 1) {
            userfilter = '&user_name=ilike.*' + username + '*'
        }
        return apiClient.get(getAPIURL() + '/bg_v_ranking_team?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,bet_points,question_points,points_correction,matchday_wins,exact,goaldiff,tendency,no_points,betcount&' +
          'order=position.asc&fk_betgame=eq.' + betgame + '&limit=' + limit + '&offset=' + offset + userfilter,
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getMatchdayRanking (betgame, competition, season, matchday, limit, username, currentuser) {
        var userfilter = ''
        if(username !== undefined && username !== null && username.trim().length > 1) {
            if(currentuser !== undefined && currentuser !== null && currentuser > 0) {
                userfilter = '&or=(user_name.ilike.*' + username + '*,fk_user.eq.' + currentuser + ')'
            } else {
                userfilter = '&user_name=ilike.*' + username + '*'
            }
        }
        return apiClient.get(getAPIURL() + '/bg_v_matchday_ranking_' + season + '?select=fk_betgame,fk_competition,matchday,fk_user,user_name,points_total,exact,goaldiff,tendency,no_points,betcount&' +
          'fk_betgame=eq.' + betgame + '&fk_competition=eq.' + competition + '&matchday=eq.' + matchday + userfilter + '&limit=' + limit + '&order=points_total.desc,exact.desc',
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getMatchdayRankingsByUser (betgame, competition, season, user) {
        return apiClient.get(getAPIURL() + '/bg_v_matchday_ranking_' + season + '?select=fk_betgame,fk_competition,matchday,fk_user,user_name,points_total,exact,goaldiff,tendency,no_points,betcount&' +
          'fk_betgame=eq.' + betgame + '&fk_competition=eq.' + competition + '&fk_user=eq.' + user,
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getBetOdds (matches) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken() }
        }
        return apiClient.get(getAPIURL() + '/bg_match_bet?fk_match=in.(' + matches + ')', config)
    },
    
    getBets (betgameId, userId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken() }
        }
        if(userId != null) {
            return apiClient.get(getAPIURL() + '/bg_bet?fk_betgame=eq.' + betgameId + '&fk_user=eq.' + userId, config)
        } else {
            return apiClient.get(getAPIURL() + '/bg_bet?fk_betgame=eq.' + betgameId, config)
        }
    },
    
    getBetsByMatches (betgameId, matchIds, season) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken() }
        }
        return apiClient.get(getAPIURL() + '/bg_bet?fk_betgame=eq.' + betgameId + '&fk_match=in.(' + matchIds + ')', config)
    },
    
    getUserBetgames (userId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.post(getAPIURL() + '/rpc/user_betgames_battles_teams', { user_id: userId}, config)
    },
    
    getBetgameLeague(betgameId) {
        return apiClient.get(getAPIURL() + '/bg_betgame_competition?select=bg_competition(id,name,type),bg_season(id,name,is_current)&fk_betgame=eq.' + betgameId)
    },
    
    getBetgameWithLeague(betgameId) {
        return apiClient.get(getAPIURL() + '/bg_betgame_competition?select=bg_betgame(*,bg_betgame_fk_user_key(nickname)),bg_competition(id,name,type),bg_season(id,name,is_current)&fk_betgame=eq.' + betgameId)
    },
    
    getBetgameByInvitation (betgameId, invitation) {
        return apiClient.get(getAPIURL() +
          '/bg_betgame?id=eq.' + betgameId + '&invitation_code=eq.' + invitation
        )
    },
    
    getBetgames (betgameIds, user) {
        return apiClient.get(getAPIURL() +
          '/bg_betgame?or=(fk_user.eq.' + user + ',id.in.(' + betgameIds + '))'
        )
    },
    
    getCurrentSeason () {
        return apiClient.get(getAPIURL() + '/bg_season?is_current=eq.true')
    },
    
    getTeams (betgameId) {
        var config = {
        //    headers: { Authorization: 'Bearer ' + getToken() }
        }
        
        return apiClient.get(getAPIURL() + '/bg_team?select=id,name,shortname,logo,short,color,bet_id,name_int,shortname_int,short_int,team_type,sports_type,is_placeholder', config)
    },
    
    getLeagueTeams (leagueId, seasonId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken() }
        }
        
        return apiClient.get(getAPIURL() + '/bg_competition_team?select=bg_team(id,name,shortname,logo,short,color,' +
          'bet_id,kickerid,heimspielid,name_int,shortname_int,short_int,team_type,sports_type,is_placeholder)&fk_competition=eq.' + leagueId +
          '&fk_season=eq.' + seasonId, config)
    },
    
    getTeamsByLeagues (leagueIds, seasonId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken() }
        }
        
        return apiClient.get(getAPIURL() + '/bg_competition_team?select=bg_team(id,name,shortname,logo,short,color,' +
          'bet_id,kickerid,heimspielid,name_int,shortname_int,short_int,team_type,sports_type,is_placeholder)&fk_competition=in.(' + leagueIds + ')' +
          '&fk_season=eq.' + seasonId, config)
    },
    
    getTeamsBySportsType (type) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken() }
        }
        
        return apiClient.get(getAPIURL() + '/bg_team?sports_type=eq.' + type, config)
    },
    
    getSchedule (leagueId, seasonId) {
        const leagues = [1, 2, 3, 14, 71, 91, 97, 111, 116]
        if(leagues.includes(parseInt(leagueId)) && (parseInt(seasonId) === 14 || parseInt(seasonId) === 15)) {
            return apiClient.get(getAPIURL() + '/schedule/matches_' + leagueId + '_' + seasonId + '.json')
        } else if(parseInt(leagueId) === 36 && parseInt(seasonId) === 14) {
            return apiClient.get(getAPIURL() + '/data/schedule_' + leagueId + '_' + seasonId + '.json')
        } else {
            return apiClient.get(getAPIURL() + '/bg_v_schedule?fk_competition=eq.' + leagueId + '&fk_season=eq.' + seasonId)
        }
        /*
        else if(parseInt(leagueId) === 1 && parseInt(seasonId) === 13) {
            return apiClient.get('https://teamtip.net/schedule/matches_' + leagueId + '_' + seasonId + '.json')
        } else if(parseInt(leagueId) === 2 && parseInt(seasonId) === 13) {
            return apiClient.get('https://teamtip.net/schedule/matches_' + leagueId + '_' + seasonId + '.json')
        } else if(parseInt(leagueId) === 3 && parseInt(seasonId) === 13) {
            return apiClient.get('https://teamtip.net/schedule/matches_' + leagueId + '_' + seasonId + '.json')
        } else if(parseInt(leagueId) === 71 && parseInt(seasonId) === 13) {
            return apiClient.get('https://teamtip.net/schedule/matches_' + leagueId + '_' + seasonId + '.json')
        } else if(parseInt(leagueId) === 91 && parseInt(seasonId) === 13) {
            return apiClient.get('https://teamtip.net/schedule/matches_' + leagueId + '_' + seasonId + '.json')
        } else if(parseInt(leagueId) === 97 && parseInt(seasonId) === 13) {
            return apiClient.get('https://teamtip.net/schedule/matches_' + leagueId + '_' + seasonId + '.json')
        } else if(parseInt(leagueId) === 111 && parseInt(seasonId) === 13) {
            return apiClient.get('https://teamtip.net/schedule/matches_' + leagueId + '_' + seasonId + '.json')
        } else if(parseInt(leagueId) === 116 && parseInt(seasonId) === 13) {
            return apiClient.get('https://teamtip.net/schedule/matches_' + leagueId + '_' + seasonId + '.json')
        } 
        */
    },
    
    isEmailRegistered(email) {
        var data = {
            'email': email
        }
        return apiClient.post(getAPIURL() + '/rpc/email_valid', data)
    },
    
    getBet (token, data) {
        var config = {
            headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_bet?fk_user=eq.' + data.fk_user + '&fk_match=eq.' + data.fk_match + 
          '&fk_betgame=eq.' + data.fk_betgame + '&goalshome=eq.' + data.goalshome + '&goalsguest=eq.' + data.goalsguest, config)
    },
    
    putBet (token, data) {
        var config = {
            headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' }
        }
        return apiClient.put(getAPIURL() + '/bg_bet?fk_user=eq.' + data.fk_user + '&fk_match=eq.' + data.fk_match + '&fk_betgame=eq.' + data.fk_betgame, JSON.stringify(data), config)
    },
    
    putUserBetgame (token, data) {
        var config = {
            headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' }
        }
        return apiClient.put(getAPIURL() + '/bg_user_betgame?fk_user=eq.' + data.fk_user + '&fk_betgame=eq.' + data.fk_betgame, JSON.stringify(data), config)
    },
    
    getMatchdayWins (competition, betgame, season) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken() }
        }
        
        return apiClient.get(getAPIURL() + '/bg_v_matchday_winner_' + season + '?select=fk_betgame,fk_competition,matchday,fk_user,user_name,points_total,exact,goaldiff,tendency,no_points,betcount&' +
          'fk_competition=eq.' + competition + '&fk_betgame=eq.' + betgame + '&points_total=gt.0', config)
    },
    
    patchUser (token, id, data) {
        var config = {
            headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' }
        }
        return apiClient.patch(getAPIURL() + '/bg_user?id=eq.' + id, JSON.stringify(data), config)
    },
    
    postRefreshToken (data) {
        var config = {
            headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' }
        }
        return apiClient.post(getAPIURL() + '/rpc/refresh_token', data)
    },
    
    patchBetgame (id, data) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.patch(getAPIURL() + '/bg_betgame?id=eq.' + id, JSON.stringify(data), config)
    },
    
    getQuestions (betgameId, seasonId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_question?fk_betgame=eq.' + betgameId + '&fk_season=eq.' + seasonId + '&order=id.asc', config)
    },
    
    getSolutions (questionIDs) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_solution?fk_question=in.(' + questionIDs + ')', config)
    },
    
    getAnsers (betgameId, userId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_answer?fk_betgame=eq.' + betgameId + '&fk_user=eq.' + userId, config)
    },
    
    postAnswer (data) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.post(getAPIURL() + '/bg_answer', JSON.stringify(data), config)
    },
    
    deleteUserAnswers (data) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.delete(getAPIURL() + '/bg_answer?fk_user=eq.' + data.fk_user + '&fk_question=eq.' + data.fk_question + '&fk_betgame=eq.' + data.fk_betgame, config)
    },
    
    getOthersBets (betgameId, userId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken() }
        }
        return apiClient.get(getAPIURL() + '/bg_bet?&fk_betgame=eq.' + betgameId + '&fk_user=neq.' + userId + '&limit=1', config)
    },
    
    getOthersAnswers (betgameId, userId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken() }
        }
        return apiClient.get(getAPIURL() + '/bg_answer?fk_betgame=eq.' + betgameId + '&fk_user=neq.' + userId + '&limit=1', config)
    },
    
    userEnterBetgame (betgameId, userId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken() }
        }
        return apiClient.post(getAPIURL() + '/rpc/user_enter_betgame', {
            betgameid: '' + betgameId,
            userid: '' + userId
        }, config)
    },
    
    deleteUserAnswersByBetgame (betgameId, userId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.delete(getAPIURL() + '/bg_answer?fk_user=eq.' + userId + '&fk_betgame=eq.' + betgameId, config)
    },
    
    deleteUserBetsByBetgame (betgameId, userId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.delete(getAPIURL() + '/bg_bet?fk_user=eq.' + userId + '&fk_betgame=eq.' + betgameId, config)
    },
    
    deleteUserBetgameRelation (betgameId, userId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.delete(getAPIURL() + '/bg_user_betgame?fk_user=eq.' + userId + '&fk_betgame=eq.' + betgameId, config)
    },
    
    deleteBetgameAuthor (betgameId, userId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.delete(getAPIURL() + '/bg_author?fk_user=eq.' + userId + '&fk_betgame=eq.' + betgameId, config)
    },
    
    postQuestion (data) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json',
                'Prefer': 'return=representation' }
        }
        return apiClient.post(getAPIURL() + '/bg_question?select=id', JSON.stringify(data), config)
    },
    
    patchQuestion (id, data) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.patch(getAPIURL() + '/bg_question?id=eq.' + id, JSON.stringify(data), config)
    },
    
    deleteQuestion (questionId, betgameId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.delete(getAPIURL() + '/bg_question?id=eq.' + questionId + '&fk_betgame=eq.' + betgameId, config)
    },
    
    betCount (betgameId, preferred) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Prefer': 'count=' + preferred}
        }
        return apiClient.head(getAPIURL() + '/bg_bet?fk_betgame=eq.' + betgameId, config)
    },
    
    userCount (betgameId, preferred) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Prefer': 'count=' + preferred}
        }
        return apiClient.head(getAPIURL() + '/bg_user_betgame?fk_betgame=eq.' + betgameId, config)
    },
    
    startBetgameTransfer (betgameId, email) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.post(getAPIURL() + '/rpc/start_betgame_transfer', {
            betgame_id: '' + betgameId,
            email_recipient: '' + email
        }, config)
    },
    
    fetchBetgameTransfer(betgameId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_betgame_transfer?select=bg_user(email)&fk_betgame=eq.' + betgameId, config)
    },
    
    fetchBetgameTransfersByUser(userId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_betgame_transfer?select=bg_betgame(id,name)&fk_user_recipient=eq.' + userId, config)
        //return apiClient.get(getAPIURL() + '/bg_betgame_transfer?select=bg_betgame(id,name,icon)&fk_user_recipient=eq.' + userId, config)
    },
    
    deleteBetgameTransfer (betgameId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.delete(getAPIURL() + '/bg_betgame_transfer?fk_betgame=eq.' + betgameId, config)
    },
    
    finishBetgameTransfer (betgameId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.post(getAPIURL() + '/rpc/finish_betgame_transfer', {
            betgame_id: '' + betgameId
        }, config)
    },
    
    postUnsubscription (data) {
        var config = {
            headers: { 'Content-Type': 'application/json' }
        }
        return apiClient.post('https://teamtip.net/bg_unsubscribed', JSON.stringify(data), config)
    },
    
    postB2BContact (data) {
        var config = {
            headers: { 'Content-Type': 'application/json' }
        }
        return apiClient.post(getAPIURL() + '/bg_b2bcontact', JSON.stringify(data), config)
    },
    
    deleteSolutions (data) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.delete(getAPIURL() + '/bg_solution?fk_question=eq.' + data.fk_question, config)
    },
    
    postSolution (data) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.post(getAPIURL() + '/bg_solution', JSON.stringify(data), config)
    },
    
    fetchTournamentRounds(competiton, season) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_tournament_round?fk_competition=eq.' + competiton + '&fk_season=eq.' + season, config)
    },
    
    fetchTournamentGroups(rounds) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_tournament_group?fk_tournament_round=in.(' + rounds + ')', config)
    },
    
    fetchTournamentMatches(rounds) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_tournament_match?fk_tournament_round=in.(' + rounds + ')', config)
    },
    
    fetchTournamentGroupTables(groups) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_tournament_group_table?fk_tournament_group=in.(' + groups + ')', config)
    },
    
    fetchBetgameUsersTable (betgame, isAdmin) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        if(isAdmin) {
            return apiClient.get(getAPIURL() + '/bg_user_betgame?fk_betgame=eq.' + betgame + '&select=bg_user(id,nickname,email,avatar),fk_betgame', config)
        } else {
            return apiClient.get(getAPIURL() + '/bg_user_betgame?fk_betgame=eq.' + betgame + '&select=bg_user(id,nickname,avatar),fk_betgame', config)   
        }
    },
    
    removeUserFromBetgame (userId, betgameId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken() }
        }
        return apiClient.post(getAPIURL() + '/rpc/remove_user_from_betgame', {
            userid: '' + userId,
            betgameid: '' + betgameId,
        }, config)
    },
    
    deleteAnswers (fk_question) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.delete(getAPIURL() + '/bg_answer?fk_question=eq.' + fk_question, config)
    },
    
    putUserBetgamePoints (data) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.put(getAPIURL() + '/bg_user_betgame_points?fk_user=eq.' + data.fk_user + '&fk_betgame=eq.' + data.fk_betgame, JSON.stringify(data), config)
    },
    
    fetchUserBetgamePoints(betgame) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_user_betgame_points?fk_betgame=eq.' + betgame, config)
    },
    
    deleteUserBetgamePoints (data) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.delete(getAPIURL() + '/bg_user_betgame_points?fk_user=eq.' + data.fk_user + '&fk_betgame=eq.' + data.fk_betgame, config)
    },
    
    pointsCorrectionsCount (betgameId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Prefer': 'count=exact'}
        }
        return apiClient.head(getAPIURL() + '/bg_user_betgame_points?fk_betgame=eq.' + betgameId, config)
    },
    
    patchUserApp (fk_user, push_token, data) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.patch(getAPIURL() + '/bg_user_app?push_token=eq.' + push_token + '&fk_user=eq.' + fk_user, JSON.stringify(data), config)
    },
    
    postUserApp (data) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.post(getAPIURL() + '/bg_user_app', JSON.stringify(data), config)
    },
    
    getBetgameCompetitions(betgames) {
        return apiClient.get(getAPIURL() + '/bg_betgame_competition?select=fk_betgame,bg_competition(id,name,type),bg_season(id,name,is_current)&fk_betgame=in.(' + betgames + ')')
    },
    
    getQuestionAnsers (questionId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_answer?fk_question=eq.' + questionId, config)
    },
    
    getBetgameRankings (betgame, season) {
        return apiClient.get(getAPIURL() + '/bg_v_ranking_' + season + '?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,bet_points,question_points,points_correction,matchday_wins,exact,goaldiff,tendency,no_points,betcount' +
          '&fk_betgame=eq.' + betgame + '&order=position.asc&limit=' + TOP_TEN,
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getLivetickerData (league) {
        return apiClient.get(getAPIURL() + '/liveticker/liveticker_' + league + '.json')
    },
    
    getTableData (league, season) {
        return apiClient.get(getAPIURL() + '/leaguetable/table_' + league + '_' + season + '.json')
        //return apiClient.get(getAPIURL() + '/leaguetable/table_' + league + ".json")
    },
    
    postBetgameMatches (token, matches) {
        var config = {
            headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' }
        }
        return apiClient.post(getAPIURL() + '/bg_betgame_match', JSON.stringify(matches), config)
    },
    
    getBetgameMatches (betgameId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_betgame_match?fk_betgame=eq.' + betgameId + '&select=bg_v_schedule(*)', config)
        //return apiClient.get(getAPIURL() + '/bg_betgame_match?fk_betgame=eq.' + betgameId, config)
    },
    
    deleteBattleMatches (betgameId, matches) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.delete(getAPIURL() + '/bg_betgame_match?fk_betgame=eq.' + betgameId + '&fk_match=in.(' + matches + ')', config)
    },
    
    deleteBets (betgameId, matches) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.delete(getAPIURL() + '/bg_bet?fk_betgame=eq.' + betgameId + '&fk_match=in.(' + matches + ')', config)
    },
    
    getBetgameInfos(betgames) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_v_betgame_info?fk_betgame=in.(' + betgames + ')', config)
    },
    
    fetchUnsubscribed(id, email) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_unsubscribed?or=(email.ilike.*' + email + '*,fk_user.eq.' + id + ')', config)
    },
    
    fetchCompetitions() {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_competition', config)
    },
    
    fetchQuestionRankings(betgame) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_v_question_ranking?fk_betgame=eq.' + betgame, config)
    },
    
    postBetgameTeams (token, teams) {
        var config = {
            headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' }
        }
        return apiClient.post(getAPIURL() + '/bg_betgame_team', JSON.stringify(teams), config)
    },
    
    fetchBetgameTeams(betgame) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_betgame_team?fk_betgame=eq.' + betgame + '&select=fk_team', config)
    },
    
    getTeamsMatches (teams, season) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_v_schedule?and=(fk_season.eq.' + season + ',or(fk_team_home.in.(' + teams + '),fk_team_guest.in.(' + teams + ')))', config)
    },
    
    postUserRating (data) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.post(getAPIURL() + '/bg_user_rating', JSON.stringify(data), config)
    },
} 
