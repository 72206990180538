<template>
  <div class="profilecard">

    <!-- Header -->
    <div class="profilecard__header">

      <!-- Avatar -->
      <div class="profilecard__header__image">
        <img v-if="!isValidIcon(this.$store.state.user.avatar)" src="../assets/icons/avatar_placeholder.svg">
        <img v-else v-bind:src="buildAvatarPath(this.$store.state.user.avatar, 300)">
        <router-link v-if="!isValidIcon(this.$store.state.user.avatar)" to="/profileeditavatar" class="profilecard__header__image__button"></router-link>
      </div>

      <!-- Ranking -->
      <div class="profilecard__header__ranking">
        <div class="profilecard__header__ranking__label">
          {{ userLivePosition }}. {{ $t('ranking_placement') }}

          <!-- TODO @Lennard: Die zweite Klasse im DIV muss mit "positive" oder "negative" enden, abhängig davon, ob der Trend des Nutzers (analgo zur Darstellung in der Tippübersicht) nach oben oder unten geht -->
          <div class="profilecard__header__ranking__tendency profilecard__header__ranking__tendency--positive">
            <img src="@/assets/icons/ic_12_arrow_white.svg">
          </div>
        </div>
        <div class="profilecard__header__ranking__sublabel">
          {{ $t('menu.total_ranking') }}
        </div>
      </div>

    </div>

    <!-- Footer -->
    <div class="profilecard__footer">

      <!-- Punkte -->
      <div class="profilecard__footer__item">
        <img src="@/assets/icons/ic_16_strokes.svg">
        {{ userLivePoints }} {{ $t('rankings_table_header_points') }}.
      </div>

      <!-- Spieltagssiege -->
      <div v-if="this.isBetgame() && !this.isTournament()" class="profilecard__footer__item">
        <img src="@/assets/icons/ic_16_star-2.svg">
        {{ userLiveMatchdayWins }} {{ $t('matchdays') }}
      </div>

      <!-- Volltreffer -->
      <div class="profilecard__footer__item">
        <img src="@/assets/icons/ic_16_flame.svg">
        {{ userLiveExact }} {{ $t('exact_bets') }}
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'ProfileCard',
  components: { },
  data () {
    return {}
  },
  beforeCreate () {
  },
  created () {
  },
  mounted () {
  },
  computed: {
    userLivePosition() {
      if(!this.hasSeasonStarted()) {
        return '1'
      }
      if(!this.$store.state.userLiveRanking) {
        if(this.$store.state.betgameUsers && this.$store.state.betgameUsers.length > 0) {
          return this.$store.state.betgameUsers.length + 1
        }
        return '1'
      }
      return this.$store.state.userLiveRanking.position
    },
    userLivePoints() {
      if(!this.$store.state.userLiveRanking) {
        return '0'
      }
      return this.$store.state.userLiveRanking.points_total
    },
    userLiveMatchdayWins() {
      if(!this.$store.state.userLiveRanking) {
        return '0'
      }
      return this.$store.state.userLiveRanking.matchday_wins
    },
    userLiveExact() {
      if(!this.$store.state.userLiveRanking) {
        return '0'
      }
      return this.$store.state.userLiveRanking.exact
    },
  },
  methods: {}
}
</script>

<style lang="scss">
  .profilecard {
    background: $colorWhite;
    box-shadow: 0 0 1px 0 rgba($colorGreyDark, .08), 0px 4px 16px rgba($colorGreyDark, 0.08);
    border-radius: 8px;
    width: 100%;
    margin-bottom: 24px;
    margin-top: 16px;
    
    &__header {
      display: flex;
      align-items: center;
      padding: 16px;

      &__image {
        display: flex;
        position: relative;
        margin-right: 20px;

        img {
          width: 72px;
          height: 72px;
          border-radius: 6px;
          object-fit: cover;
          background: rgba($colorGreyDark, .08);
        }

        &__button {
          cursor: pointer;
          position: absolute;
          width: 28px;
          height: 28px;
          bottom: -4px;
          right: -4px;
          background: var(--color-primary);
          box-shadow: 0 0 0 2px $colorWhite;
          border-radius: 50%;

          &:before {
            content: '';
            position: absolute;
            top: 8px;
            left: 8px;
            width: 12px;
            height: 12px;
            background: url('../assets/icons/ic_12_plus_white.svg');
          }
        }
      }

      &__ranking {

        &__label {
          display: flex;
          align-items: center;
          font-size: 28px;
          font-weight: 500;
          color: $colorGreyDark;
        }

        &__tendency {
          display: flex;
          border-radius: 50%;
          padding: 6px;
          margin-left: 6px;

          &--positive {
            background: $colorPositive;
          }

          &--negative {
            background: $colorNegative;

            img {
              transform: rotate(180deg);
            }
          }
        }

        &__sublabel {
          font-size: 14px;
          color: rgba($colorGreyDark, .48);
        }
      }
    }

    &__footer {
      display: flex;
      box-shadow: 0 -1px 0 0 rgba($colorGreyDark, .08);
      padding: 12px 0;

      &__item {
        display: flex;
        flex: auto;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: $colorGreyDark;

        &:not(:last-child) {
          box-shadow: 1px 0 0 0 rgba($colorGreyDark, .16);
        }

        img {
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }
      }
    }
  }
</style>
