<template>
  <div :key="componentKey" class="home">

      <!-- HEADER -->

      <div class="header" id="header">
        <div class="container">

          <nav>
            <div class="navigation-logo">
              <a href="https://teamtip.net">
                <img src="../assets/landingpage/logo-teamtip.svg" alt="">
              </a>
              <div class="navigation-divider"></div>
              <a href="https://www.sportplatz-media.com" target="_blank">
                <img src="../assets/landingpage/logo-sportplatzmedia-2022.svg" alt="">
              </a>
            </div>
            <ul class="navigation-list">
              <li v-if="!this.isApp()" class="navigation-list-item">
                <a href="/tipprunde/1/kostenlose-1-bundesliga-tipprunde">{{ $t('home.bundesliga') }}</a>
              </li>
              <li v-if="!this.isApp()" class="navigation-list-item">
                <a href="#whitelabel_id" v-smooth-scroll="{ updateHistory: false }">{{ $t('home.whitelabel') }}</a>
              </li>
              <!--
              <li v-if="!this.isApp()" class="navigation-list-item">
                <a href="#features_id" v-smooth-scroll="{ updateHistory: false }">{{ $t('home.features') }}</a>
              </li>
              -->
              <!--
              <li v-if="!this.isApp()" class="navigation-list-item">
                <router-link to="/about">
                  Über Uns
                </router-link>
              </li>
              -->
              <li class="navigation-list-language">
                <a href="#" @click="languageSelected( 'de')" v-bind:class="{ 'navigation-list-language-item--selected': selectedLanguage === 'de' }" 
                   class="navigation-list-language-item">
                  <img src="../assets/icons/ic_flag_germany.svg" class="navigation-list-language-flag">
                </a>
                <a href="#" @click="languageSelected( 'en')" v-bind:class="{ 'navigation-list-language-item--selected': (!selectedLanguage || selectedLanguage !== 'de') }" 
                   class="navigation-list-language-item">
                  <img src="../assets/icons/ic_flag_england.svg" class="navigation-list-language-flag">
                </a>
              </li>
              <router-link to="/login" class="router_link">
                <li class="navigation-list-button">
                  <LoginIcon></LoginIcon>
                  Login
                </li>
              </router-link>
            </ul>
          </nav>

          <div v-if="this.isApp()" class="header-content header-content--app">
            <div class="header-content-left">
              <h1>{{ $t('home.welcome_title') }}</h1>
              <p>{{ $t('home.welcome_text') }}</p>
              <div class="button-group">
                <router-link to="/createuserbetgame" class="router_link">
                  <div class="button"><img src="../assets/landingpage/ic_24_add.svg">{{ $t('home.create_betgame') }}</div>
                </router-link>
                <!--
                <router-link to="/register" class="router_link">
                  <div class="button"><img src="../assets/landingpage/ic_24_add.svg">{{ $t('home.register') }}</div>
                </router-link>
                -->
                <!--
                <router-link to="/searchbetgame" class="router_link">
                  <div class="button"><img src="../assets/landingpage/ic_24_add.svg">{{ $t('home.invitation_link') }}</div>
                </router-link>
                -->
                <!--
                <router-link to="/createuserbetgame" class="router_link">
                  <div class="button"><img src="../assets/landingpage/ic_24_add.svg">{{ $t('home.create_battle') }}</div>
                </router-link>
                -->
              </div>
            </div>
            <!--
            <div class="header-content-right" v-on:click="openVideo">
              <img src="../assets/landingpage/header-hand.png" srcset="../assets/landingpage/header-hand@2x.png 2x" alt="">
              <img src="../assets/landingpage/heroimage.png" srcset="../assets/landingpage/heroimage@2x.png 2x" alt="">
            </div>
            -->
          </div>

          <div v-else class="header-content">

            <div class="header-content-left">
              <div class="devices">
                <a href="https://apps.apple.com/de/app/teamtip/id1496608495" target="_blank" class="devices-item">
                  <img src="../assets/landingpage/device-apple.svg" alt="Apple">
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.sportplatzmedia.teamtip" target="_blank" class="devices-item">
                  <img src="../assets/landingpage/device-android.svg" alt="Android">
                </a>
                <!--
                <a href="" class="devices-item">
                  <img src="../assets/landingpage/device-huawei.svg" alt="Huawei">
                </a>
                <a href="" class="devices-item">
                  <img src="../assets/landingpage/device-blueberry.svg" alt="Blueberry">
                </a>
                -->
                <a href="https://teamtip.net/" target="_blank" class="devices-item">
                  <img src="../assets/landingpage/device-web.svg" alt="Web">
                </a>
              </div>
              <h1>{{ $t('home.welcome_title') }}</h1>
              <!--<p>{{ $t('home.welcome_text') }}</p>-->
              <p v-if="selectedLanguage === 'de'">Messe dich mit Freunden, Familie und Arbeitskollegen zur <a href="https://teamtip.net/tipprunde/1/kostenlose-1-bundesliga-tipprunde">Bundesliga</a> und in über <a href="https://teamtip.zendesk.com/hc/de/articles/4402646144657-Welche-Wettbewerbe-kann-ich-in-TEAMTIP-tippen">20 weiteren Wettbewerben</a>. Starte dein Tippspiel mit eigenen Regeln, unbegrenzter Teilnehmerzahl und optimalem Liveerlebnis. </p>
              <p v-else>Compete with friends, family and work colleagues in the <a href="https://teamtip.net/tipprunde/1/kostenlose-1-bundesliga-tipprunde">Bundesliga</a> and over <a href="https://teamtip.zendesk.com/hc/de/articles/4402646144657-Welche-Wettbewerbe-kann-ich-in-TEAMTIP-tippen">20 other competitions</a>. Start your prediction round with your own rules, an unlimited number of participants and an optimal live experience.</p>
              
              <!-- Mobile / Native -->
              <div v-if="showAppstoreIcons" class="button-group">
                <router-link to="" class="router_link">
                  <div class="button button--appstore"><img src="../assets/landingpage/ic_32_system_apple.svg">{{ $t('home.download_ios') }}</div>
                </router-link>
                <router-link to="" class="router_link">
                  <div class="button button--appstore"><img src="../assets/landingpage/ic_32_system_android.svg">{{ $t('home.download_android') }}</div>
                </router-link>
              </div>
              <!-- Web / Desktop -->
              <div v-else class="button-group">
                <router-link to="/createuserbetgame" class="router_link">
                  <div class="button"><img src="../assets/landingpage/ic_24_add.svg">{{ $t('home.create_betgame') }}</div>
                </router-link>
                <!--
                <router-link to="/searchbetgame" class="router_link">
                  <div class="button"><img src="../assets/landingpage/ic_24_arrow-right.svg">{{ $t('home.invitation_link') }}</div>
                </router-link>
                -->
              </div>
              
            </div>
            
            <div class="header-content-right">
              <img src="../assets/landingpage/landingpage_2023.png" srcset="../assets/landingpage/landingpage_2023@2x.png 2x" alt="">
            </div>
          
          </div>

          <div class="header-more" href="#features_id" v-smooth-scroll="{ updateHistory: false }">
            {{ $t('home.all_features') }}
            <img src="../assets/icons/ic_12_arrow_down_white.svg">
          </div>
          
        </div>
      </div>
  

      <modal class="modal" name="videoModal" id="videoModal" :scrollable="true" width="100%" height="auto">
          <div class="modal-header">
            <div class="modal-header-left"></div>
            <div class="modal-header-center">{{ $t('home.video_title') }}</div>
            <div class="modal-header-right">
              <button
                type="button"
                class="btn-close"
                @click="$modal.hide('videoModal')"
                aria-label="Close modal">
              </button>
            </div>
          </div>
          <div class="modal-body">
            <video-player style="width: 100%; height: 100%;" :options="videoOptions"/>
          </div>
        </modal>
    
      <!-- FEATURES -->

      <div class="features" id="features_id">
        <div class="container">

          <div class="features-item">
            <img src="../assets/landingpage/ic_64_feature_tipprunde.svg" alt="">
            <h2>{{ $t('home.features_title_1') }}</h2>
            <p>{{ $t('home.features_text_1') }}</p>
          </div>

          <div class="features-item">
            <img src="../assets/landingpage/ic_64_feature_wettbewerbe.svg" alt="">
            <h2>{{ $t('home.features_title_2') }}</h2>
            <!-- <p>{{ $t('home.features_text_2') }}</p> -->
            <p v-if="selectedLanguage === 'de'">TEAMTIP bietet dir Tipprunden zu allen europäischen Top-Ligen und internationalen Turnieren. Neben einer Vielzahl deutscher Ligen kannst du Tipprunden zur <a href="https://teamtip.net/tipprunde/19/kostenlose-champions-league-tipprunde">Champions League</a>, <a href="https://teamtip.net/prediction-game/91/premier-league">Premier League</a>, <a href="https://teamtip.net/tipprunde/111/kostenlose-serie-a-tipprunde">Serie A</a> und in in <a href="https://teamtip.zendesk.com/hc/de/articles/4402646144657-Welche-Wettbewerbe-kann-ich-in-TEAMTIP-tippen">20 weiteren Wettbewerben</a> erstellen. Natürlich sind auch die großen internationalen Turniere, wie WM und EM, mit dabei.</p>
            <p v-else>TEAMTIP offers you prediction rounds for all top European leagues and international tournaments. In addition to a large number of German leagues, you can create prediction rounds for the <a href="https://teamtip.net/tipprunde/19/kostenlose-champions-league-tipprunde">Champions League</a>, <a href="https://teamtip.net/prediction-game/91/premier-league">Premier League</a>, <a href="https://teamtip.net/tipprunde/111/kostenlose-serie-a-tipprunde">Serie A</a> and over <a href="https://teamtip.zendesk.com/hc/de/articles/4402646144657-Welche-Wettbewerbe-kann-ich-in-TEAMTIP-tippen">20 other competitions</a>. Of course, the major international tournaments such as the WC and EC are also included.</p>
          </div>

          <div class="features-item">
            <img src="../assets/landingpage/ic_64_feature_regelwerk.svg" alt="">
            <h2>{{ $t('home.features_title_3') }}</h2>
            <p>{{ $t('home.features_text_3') }}</p>
          </div>

          <div class="features-item">
            <img src="../assets/landingpage/ic_64_feature_live.svg" alt="">
            <h2>{{ $t('home.features_title_4') }}</h2>
            <p>{{ $t('home.features_text_4') }}</p>
          </div>

          <div class="features-item">
            <img src="../assets/landingpage/ic_64_feature_battle.svg" alt="">
            <h2>{{ $t('home.features_title_5') }}</h2>
            <p>{{ $t('home.features_text_5') }}</p>
          </div>

          <div class="features-item">
            <img src="../assets/landingpage/ic_64_feature_lieblingsteam.svg" alt="">
            <h2>{{ $t('home.features_title_7') }}</h2>
            <p>{{ $t('home.features_text_7') }}</p>
          </div>
          
        </div>
      </div>

      <div v-if="!this.isApp()" class="whitelabel" id="whitelabel_id">
        <div class="container">
          <div class="whitelabel-content">
            <div class="whitelabel-content-left">
              <small>{{ $t('home.business') }}</small>
              <h3>{{ $t('home.business_title') }}</h3>
              <p>{{ $t('home.business_text_1') }}</p>
              <p>{{ $t('home.business_text_2') }}</p>
              <div class="whitelabel-features">
                <div class="whitelabel-features-title">
                  {{ $t('home.business_your_advantages') }}
                </div>
                <ul>
                  <li>
                    <img src="../assets/landingpage/ic_24_whitelabel_server.svg" alt="">
                    {{ $t('home.business_hosted_in_eu') }}
                  </li>
                  <li>
                    <img src="../assets/landingpage/ic_24_whitelabel_support.svg" alt="">
                    {{ $t('home.business_24_7_support') }}
                  </li>
                  <li>
                    <img src="../assets/landingpage/ic_24_whitelabel_design.svg" alt="">
                    {{ $t('home.business_individual_design') }}
                  </li>
                  <li>
                    <img src="../assets/landingpage/ic_24_whitelabel_design.svg" alt="">
                    {{ $t('home.business_short_implmentation_time') }}
                  </li>
                  <li>
                    <img src="../assets/landingpage/ic_24_whitelabel_server.svg" alt="">
                    {{ $t('home.business_gdpr_dsgvo') }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="whitelabel-content-right">
              <div class="input-container">

                <div>
                  <div class="input-header">
                    {{ $t('home.business_contact_title') }}
                  </div>
                  <div class="input-infotext">
                    {{ $t('home.business_contact_text') }}
                  </div>
                  <div class="input-element">
                      <input name="fullname" id="fullname" placeholder="Vor- und Nachname"
                             type="text" v-model="contact_name" value/>
                      <label for="fullname">{{ $t('home.business_contact_name') }}</label>
                  </div>
                  <div class="input-element">
                    <input name="company" id="company" placeholder="Unternehmen"
                           type="text" v-model="contact_company" value/>
                    <label for="fullname">{{ $t('home.business_contact_company') }}</label>
                  </div>
                  <div class="input-element">
                      <input name="enail" id="email" placeholder="E-Mail-Adresse"
                             type="text" v-model="contact_email" value/>
                      <label for="email">{{ $t('home.business_contact_email') }}</label>
                  </div>
                  <div class="input-element">
                      <input name="telephone" id="telephone" placeholder="Telefonnummmer (optional)"
                             type="text" v-model="contact_phone" value/>
                      <label for="telephone">{{ $t('home.business_contact_telephone') }}</label>
                  </div>
                </div>

                <div class="input-button-row">
                    <button name="button" type="submit" v-on:click="sendB2BContactClicked">
                      {{ $t('home.business_contact_send_button') }}
                    </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    
      <!--
      <div class="container" style="display: block; text-align: center; padding-bottom: 100px;">
        <h3 style="font-size: 28px;line-height: 36px; font-weight: 500; color: #5F5D61; max-width: 576px; margin: 15px auto 0;">Die Champions League steht in den Startlöchern!</h3>
        <div style="font-size: 17px; line-height: 24px; color: #ACACAC; max-width: 576px; margin: 10px auto 20px;">Starte jetzt dein kostenloses Tippspiel und tippe mit Familie, Freunden und Kollegen in einer privaten Tipprunde mit unbegrenzter Teilnehmerzahl.</div>
        <router-link to="/createuserbetgame" class="router_link">
          <button style="text-align: center; max-width: 300px;" name="button">Jetzt eigene Champions League Tipprunde starten</button>
        </router-link>
      </div>
      -->
    
      <footer class="footer">
        <div class="container">
          <div class="logo">
            <a href="">
              <img src="../assets/landingpage/logo-teamtip.svg" alt="Logo TEAMTIP">
            </a>
          </div>
          <ul>
            <li>
              <router-link to="/imprint" class="router_link">
                {{ $t('home.footer_imprint') }}
              </router-link>
            </li>
            <li>
              <router-link to="/termsofuse" class="router_link">
                {{ $t('home.footer_terms_of_use') }}
              </router-link>
            </li>
            <li>
              <router-link to="/privacy" class="router_link">
                {{ $t('home.footer_privacy') }}
              </router-link>
            </li>
            <li v-on:click="showCMP" style="color: white; cursor: pointer">
                {{ $t('home.footer_privacy_settings') }}
            </li>
            <li>
              <a v-if="selectedLanguage === 'de'" href="https://teamtip.zendesk.com/hc/de" target="_blank" style="text-decoration: none; color: white">{{ $t('home.footer_faq') }}</a>
              <a v-else-if="!selectedLanguage || selectedLanguage !== 'de'" href="https://teamtip.zendesk.com/hc/en-us" target="_blank" style="text-decoration: none; color: white">{{ $t('home.footer_faq') }}</a>
            </li>
            <li>
              <router-link to="/contact" class="router_link">
                {{ $t('home.footer_contact') }}
              </router-link>
            </li>
          </ul>
          <a href="#header" class="back-to-top" v-smooth-scroll="{ updateHistory: false }">
            <span>{{ $t('home.footer_scroll_to_top') }}</span>
            <img src="../assets/landingpage/ic_32_back-to-top.svg" alt="Back to top">
          </a>
        </div>
      </footer>

    </div>

    <!--<div class="swiper" id="swiper_container">
      <WelcomeSwiper></WelcomeSwiper>
    </div>

    <div id="login_form" class="login-form" v-on:keyup.enter="login">

      <div class="input-container">

        <form>

          <div class="input-element">
            <input v-model="email" type="email" name="email" id="email" placeholder="email" value />
            <label for="email">E-Mail-Adresse</label>
          </div>

          <div class="input-element">
            <input v-model="password" type="password" name="password" id="password" placeholder="password" value />
            <label for="password">Passwort</label>
          </div>

        </form>

        <div class="input-button-row">
          <button v-on:click="login" id="submit_buttom" name="submit_buttom" variant="success">Login</button>
          <button class="button-outline" v-on:click="register" id="register_button" name="register_button" variant="outline-primary">Registrieren</button>
        </div>
  
        <div class="input-button-row">
          <button class="button-outline" v-on:click="createBetgame" id="create_button" name="create_button" variant="outline-primary">Tipprunde eröffnen</button>
          <button class="button-outline" v-on:click="searchBetgame" id="search_button" name="search_button" variant="outline-primary">Tipprunde suchen</button>
        </div>
        
      </div>
  
      <div v-if="errors.length > 0" class="login-error">
        <span>{{ errors[0] }}</span>
      </div>
      
      <div class="forgot-password">
        <span>Passwort vergessen?</span>
        <router-link to="/resetpassword">Zurücksetzen</router-link>
      </div>
  
      <div class="finish-registration">
        <span>Bestätigungs-Code erhalten?</span>
        <router-link to="/validateregistration">Eingeben</router-link>
      </div>
      
    </div>

    <div v-if="!loggedIn">
      To use this app you'll need to
      <router-link to="/login">
        Login
      </router-link>
      or
      <router-link to="/register">
        Register
      </router-link>
    </div>
    -->
</template>

<script>

import WelcomeSwiper from '../components/WelcomeSwiper'
import VideoPlayer from '../components/VideoPlayer.vue'
import Vue from 'vue'
import { TOAST_DURATION } from '../main'
import Numeral from 'numeral'
import moment from 'moment'
import LoginIcon from './../components/icons/LoginIcon'


export default {
  name: 'home',
  components: {
    WelcomeSwiper, VideoPlayer, LoginIcon
  },
  data() {
    return {
      email: '',
      password: '',
      errors: [],
      loginAttempts: 0,
      bgname: '',
      componentKey: 0,
      contact_name: '',
      contact_company: '',
      contact_email: '',
      contact_phone: '',
      videoOptions: {
        autoplay: false,
        controls: true,
        poster: 'https://teamtip.net/images/video_tutorial_preview.jpg',
        sources: [
          {
            src: 'https://teamtip.net/videos/teamtip.mp4',
            type: "video/mp4"
          },
          {
            src: 'https://teamtip.net/videos/teamtip.webm',
            type: "video/webm"
          }
        ]
      }
    }
  },
  beforeCreate() {
  },
  created() {
    this.$store.dispatch('clearDataBeforeLogin')
    .then(() => {
      if(this.$route.query.email) {
        this.email = this.$route.query.email
      } else if(this.$route.params.email) {
        this.email = this.$route.params.email
      }
    }).catch(error => {
    })
  },
  mounted() {
    if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
      window.bgIsLoggedIn = this.bgIsLoggedIn
      window.bgRouterPush = this.bgRouterPush
    }
  
    let q = this.$router.currentRoute.query
    this.bgname = q.bgname
    //this.swiper.slideTo(3, 1000, false)
  
    // 100vh for mobile devices
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    this.componentKey += 1
  },
  computed: {
    showAppstoreIcons() {
      return false && this.isMobile() && navigator.userAgent.indexOf('TEAMTIP') === 0
    },
    selectedLanguage() {
      return this.$store.state.language
    }
  },
  methods: {
    showCMP() {
      window.__cmp('showScreen')
    },
    languageSelected(lang) {
      this.$store.dispatch('setLanguage', lang)
      .then(() => {
        Vue.$log.debug('languageSelected ok + ' + this.$store.state.language)
        this.$i18n.locale = lang
        Numeral.locale(lang)
        moment.locale(lang)
      }).catch(function (error) {
        Vue.$log.debug('languageSelected error ' + error)
      })
    },
    openVideo() {
      this.$modal.show('videoModal');
    },
    sendB2BContactClicked() {
      const c = window.localStorage.getItem('postB2BContactCount') || 0
      Vue.$log.debug('sendB2BContactClicked ' + this.contact_name + ' --- ' + this.contact_company + ' --- ' + this.contact_email + ' --- ' + this.contact_phone + ' --- ' + c)
      if(c > 10) {
        return
      }
      if(this.isNullOrUndefined(this.contact_name) || this.contact_name.length < 3) {
        return
      }
      if(this.isNullOrUndefined(this.contact_email) || !this.isValidEmail(this.contact_email)) {
        return
      }
      const contact_data = {
        'name': this.contact_name,
        'company': this.contact_company,
        'email': this.contact_email,
        'telephone': this.contact_phone
      }
      this.$store.dispatch('postB2BContact', contact_data).then(response => {
        this.$toast.open({ message: this.$i18n.t('home.business_contact_sent_message'), position: 'top', duration: TOAST_DURATION, type: 'success' })
        window.localStorage.setItem('postB2BContactCount', c + 1)
        this.contact_name = ''
        this.contact_company = ''
        this.contact_email = ''
        this.contact_phone = ''
      }, error => {
        Vue.$log.debug(error.toString())
      })
    },
    joinBetgame(betgame, code) {
      if(betgame && code && code !== 'null') {
        this.$router.push('/join/' + betgame + '/' + code)
      } else {
        Vue.$log.debug('home joinBetgame ' + betgame + ' --- ' + code)
      }
    },
    login() {
      if(this.loginAttempts < 5) {
        this.loginAttempts = this.loginAttempts + 1
        this.errors = []
        if(this.isNullOrUndefined(this.email) || this.email.length === 0) {
          this.errors.push(this.$t('errors.enter_login_email'))
        } else if(!this.isValidEmail(this.email)) {
          this.errors.push(this.$t('errors.enter_login_email'))
        }
        if(this.isNullOrUndefined(this.password) || this.password.length === 0) {
          this.errors.push(this.$t('errors.enter_login_password'))
        }
        if (this.errors.length === 0) {
          this.$store
          .dispatch('login', {
            email: String(this.email).toLowerCase(),
            pass: this.password
          })
          .then(() => {
            this.loginAttempts = 0
            const id = window.localStorage.getItem('spmbgid')
            if (id !== null && id !== undefined && this.isNumeric(id) && id > 0) {
              this.$router.push({ name: 'dashboard', query: { a: 'a' } })
            } else {
              this.$router.push('/searchbetgame')
            }
          }).catch(error => {
            if (error.response && error.response.status === 403) {
              this.errors.push(this.$t('errors.login_failed_check_email_password'))
            } else {
              this.errors.push(error)
            }
          })
        }
      } else {
        this.errors = []
      }
    },
    register() {
      this.$router.push({ name: 'register', params: { emailparam: this.email, passparam: this.password }
      })
    },
    createBetgame() {
      this.$router.push({ name: 'createuserbetgame'})
    },
    searchBetgame() {
      this.$router.push({ name: 'searchbetgame'})
    },
    bgIsLoggedIn(data) {
      return this.$store.getters.loggedIn
    },
    bgRouterPush(path) {
      this.$router.push( path ).catch(err => {})
    }
  }
}

/*
import { authComputed } from '../vuex/helpers.js'
export default {
  computed: {
    ...authComputed
  }
}
 */
</script>

<style lang="scss" scoped>

  .home {
    padding: 0 !important;
  }

  .router_link {
    text-decoration: none;
  }

  .container {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    max-width: 1108px;
    margin: 0px auto;
    padding: 0 16px;
    box-sizing: border-box;
  }

  .features {

    padding: 160px 0 63px;

    @media screen and (max-width: 414px) {
      padding: 96px 0 0;
    }

    .container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      /*max-width: 912px;*/
    }

    &-item {
      display: flex;
      width: calc(50% - 48px);
      flex-direction: column;
      margin-bottom: 90px;

      a {
        color: $colorGreyDark;
      }
      
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 48px;
        padding-bottom: 48px;
        border-bottom: 2px solid rgba($colorGreyLight, .08);

        &:last-child {
          border-bottom: 0;
        }
      }

      img {
        align-self: flex-start;
      }

      h2 {
        font-size: 24px;
        line-height: 32px;
        color: $colorGreyDark;
        font-weight: 500;
        margin-bottom: 10px;
      }

      p {
        font-size: 17px;
        line-height: 24px;
        color: $colorGreyMid;
      }
    }
  }


  .whitelabel {
    background: url('../assets/landingpage/whitelabel-background.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 160px 0;

    @media screen and (max-width: 414px) {
      padding: 96px 0;
    }

    small {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: .3px;
      color: $colorWhite;
      opacity: .48;
      position: relative;
      padding-left: 24px;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 13px;
        height: 10px;
        transform: translateY(-50%);
        background: url('../assets/landingpage/ic_arrow.svg')
      }
    }

    h3 {
      font-size: 28px;
      line-height: 40px;
      color: $colorWhite;
      font-weight: 500;
      margin: 5px 0 23px;
    }

    p {
      font-size: 17px;
      line-height: 24px;
      color: rgba($colorWhite, .8);
    }

    &-features {
      display: flex;
      flex-direction: column;
      background: rgba($colorWhite, .08);
      padding: 19px 24px 0;
      margin-left: -24px;
      margin-top: 60px;
      border-radius: 8px;

      @media screen and (max-width: 767px) {
        margin-left: 0;
        padding-bottom: 0;
      }

      @media screen and (max-width: 414px) {
        margin-top: 44px;
      }

      &-title {
        font-size: 17px;
        line-height: 24px;
        font-weight: 500;
        color: $colorWhite;
        margin-bottom: 2px;
      }

      ul {
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;

        li {
          display: flex;
          align-items: center;
          background: $colorWhite;
          border-radius: 4px;
          padding: 9px 12px;
          font-size: 14px;
          font-weight: 500;
          color: $colorGreyDark;
          margin: 0 16px 16px 0 ;

          @media screen and (max-width: 414px) {
            width: 100%;
            margin-right: 0;
          }

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;

            @media screen and (max-width: 414px) {
              margin-bottom: 8px;
            }
          }

          img {
            margin-right: 8px;
          }
        }
      }
    }

    &-content {
      display: flex;
      align-items: center;
      flex: 1;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        flex: 1;
      }

      &-left {
        display: flex;
        flex: 0 64%;
        width: 100%;
        max-width: 557px;
        flex-direction: column;

        @media screen and (max-width: 767px) {
          flex: 1;
        }
      }

      &-right {
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }
    }
  }

  .input-container {
    margin: 8px 0 0 24px;

    @media screen and (max-width: 767px) {
      margin: 48px 0 0 0;
    }
  }
  .input-header {
    font-size: 20px;
    padding: 20px 0 12px;
  }
  .input-infotext {
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 8px;
  }
  .input-button-row {
    margin-top: 24px;
  }


  .top3 {
    padding: 123px 0 160px;
    text-align: center;

    @media screen and (max-width: 414px) {
      padding: 87px 0 96px;
    }

    h3 {
      font-size: 28px;
      line-height: 36px;
      font-weight: 500;
      color: $colorGreyDark;
      max-width: 576px;
      margin: 0px auto;
    }

    .subline {
      font-size: 17px;
      line-height: 24px;
      color: $colorGreyMid;
      max-width: 576px;
      margin: 15px auto 0;
    }

    &-list {
      display: flex;
      margin-top: 74px;

      @media screen and (max-width: 414px) {
        margin-top: 42px;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }

      .team-list {
        margin: 0 16px;
        flex: 1;
        box-sizing: border-box;

        @media screen and (min-width: 768px) {
          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        .logo {
          width: 64px;
          height: 64px;
          border-radius: 6px;
          overflow: hidden;
          object-fit: cover;
        }

        @media screen and (max-width: 767px) {
          margin: 0 auto 16px;
          width: 100%
        }
      }
    }

  }

</style>
