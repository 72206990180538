<template>
  <div class="rules" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">

    <NavigationBar>
      <template v-slot:left>
        <div v-if="canGoBack()">
          <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
        </div>
        <div v-else>
        </div>
      </template>
      <template v-slot:center>
        {{ $t('profile.conditions') }}
      </template>
    </NavigationBar>

    <div v-if="userlang === 'de'">
      <br><br>

      <h1>Allgemeine Geschäftsbedingungen (AGB)</h1>

      <h3>1. Geltungsbereich</h3>
      <p>1.1 Die nachfolgenden Nutzungsbedingungen (nachfolgend „Bedingungen“) gelten für sämtliche Leistungen, welche
        die SPM Sportplatz-Media GmbH, Schleidenstraße 3, 22083 Hamburg (nachfolgend "SPM Sportplatz-Media"), im Zusammenhang
        mit ihrer, unter anderem unter der Domain www.teamtip.net erreichbaren, Webseite (nachfolgend „Webseite“) und
        der teamtip Applikation (nachfolgend „App“; Webseite und App gemeinsam „Angebote“) gegenüber ihren Nutzern
        erbringt.</p>
      <p>1.2. Das Verhältnis der Nutzer mit dem Betreiber der Plattform (z.B. Apple’s App-Store, Google‘s Play Store),
        über welche die App vertrieben wird, einschließlich etwaiger In-App-Käufe, richtet sich ausschließlich nach den
        jeweiligen zwischen dem Nutzer und dem Plattformbetreiber geschlossenen Bedingungen.</p>

      <h3>2. Registrierung</h3>
      <p>2.1 Die Nutzung der Angebote erfordert eine Registrierung des Nutzers unter Eingabe seiner persönlichen Daten
        und einer gültigen Email Adresse. Die Angaben sind wahrheitsgemäß zu machen und bei Änderungen zu aktualisieren.
        Nach Registrierung fordert SPM Sportplatz-Media den Nutzer auf, die angegebene E-Mail Adresse zu bestätigen.</p>
      <p>2.2. Im Rahmen der Registrierung hat der Nutzer ein Passwort zu wählen. Das Passwort hat der Nutzer geheim zu
        halten und nicht an Dritte weiter zu geben. Sofern Dritte durch ein Verschulden des Nutzers Kenntnis vom
        Passwort erlangt haben, haftet der Nutzer für den durch die Nutzung des Passwortes etwaig entstehenden
        Schaden.</p>
      <p>2.3 Der Nutzer kann eine Tipprunde anlegen und andere Personen durch Angabe ihrer E-Mail-Adresse einladen. Der
        Nutzer versichert, dass er keine Einladungen an wissentlich ungültige E-Mail-Adressen und keine Einladungen an
        Personen verschickt, die nicht zuvor in den Erhalt einer Einladung eingewilligt haben.</p>
      <p>2.4 Wenn ein Nutzer eine Tipprunde anlegt, Teilnehmer einlädt und die Tipprunde verwaltet, ist er der Admin der
        Tipprunde (im Folgenden "Admin"). Ein Teilnehmer kann von einem Admin ebenfalls als Admin freigeschaltet werden.
        Hierbei ist der Admin für sämtliche von ihm oder von Teilnehmern eingegebenen personenbezogenen Daten
        datenschutzrechtlich verantwortlich. SPM Sportplatz Media verarbeitet die Daten nach Art. 28 DSGBO nur im
        Auftrag des jeweiligen Admins. Zur Einhaltung der Datenschutzregelungen gehört in der Regel ein Vertrag zur
        Auftragsverarbeitung nach Artikel 28 DSGVO, soweit nicht die Adminfunktion nach Art 2 Abs. 2c DSGVO
        ausschließlich privat oder familiär betrieben wird. Damit ein Admin alle Funktionen datenschutzkonform nutzen
        kann, schließt er – soweit er nicht als natürliche Person zur Ausübung ausschließlich persönlicher oder
        familiärer Tätigkeiten handelt – mit SPM Sportplatz Media durch Zustimmungen zu diesen Nutzungsbedingungen auch
        einen Vertrag nach Art. 28 DSGVO. In seiner Eigenschaft als datenschutzrechtlich Verantwortlicher nutzt der
        Admin gegenüber seinen Teilnehmern eine eigene Datenschutzerklärung. Sofern er nicht von der Möglichkeit
        Gebrauch macht, eigene Datenschutzhinweise zur Verfügung zu stellen, gelten im Verhältnis zwischen Admin und
        Teilnehmern diese Datenschutzhinweise.
        SPM Sportplatz Media ist nur für die über das Verhältnis zwischen Admin und Teilnehmern hinausgehende
        Datenverarbeitung, nämlich die Bereitstellung der Leistungen auf der Webseite und innerhalb der Webanwendung
        sowie der mobilen App im Sinne der DSGVO verantwortlich.</p>

      <h3>3. Leistungen und Inhalte</h3>
      <p>3.1 SPM Sportplatz-Media bietet im Rahmen seiner Angebote verschiedene Funktionen an, welche die Erstellung von
        Tipprunden zu Sportereignissen wie beispielsweise Weltmeisterschaften, Europameisterschaften,
        Liga-Meisterschaften, z.B. in der Sportart Fußball ermöglicht sowie Tipprunden-Teilnehmern ermöglicht an zuvor
        erstellten Tipprunden teilzunehmen („Leistungen“). Die Leistungen sind grundsätzlich kostenlos nutzbar.</p>
      <p>3.2 SPM Sportplatz-Media ist jederzeit berechtigt, die Leistungen oder ihren Umfang zu ändern.</p>

      <h3>4. Nutzungsrechte</h3>
      <p>4.1 Der Nutzer erhält das einfache, nicht übertragbare und nicht unterlizenzierbare Recht, die Leistungen für
        die Dauer der Registrierung für private Zwecke zu nutzen.</p>

      <h3>5. Nutzerpflichten, Zugangssperrung</h3>
      <p>5.1 Der Nutzer darf bei Nutzung der Angebote nicht (i) mit seinem Nutzungsverhalten gegen die guten Sitten
        verstoßen; (ii) gewerbliche Schutz-, Urheber-, Persönlichkeits-, Eigentumsrechte oder sonstige Rechte Dritter
        verletzen; (iii) Inhalte mit Viren, sog. Trojanischen Pferden oder sonstige Programmierungen, die Software
        beschädigen können, übermitteln; (iv) Hyperlinks oder Inhalte eingeben, speichern oder senden, zu denen er nicht
        befugt ist, sowie (v) Quellcode in die Angebote integrieren, wodurch es ihm möglich ist, Daten aus der Datenbank
        oder den Angeboten zu extrahieren.</p>
      <p>5.2 SPM Sportplatz-Media ist berechtigt, den Zugang zu den Angeboten zu sperren, wenn der Nutzer gegen seine
        Pflichten aus diesen Bedingungen verstößt.</p>
      <p>5.3 Nutzer haben die Möglichkeit eine Tipprunde durchzuführen oder einer Tipprunde beizutreten. Tipprunden
        dürfen nicht für illegales Glücksspiel im Rahmen der jeweils geltenden gesetzlichen Grundlagen verwendet werden.
        Eine derartige Verwendung berechtigt SPM Sportplatz-Media zur sofortigen Löschung der Tipprunde.</p>
      <p>5.4 SPM Sportplatz-Media haftet nicht für den Ausfall der Angebote oder für Fehler innerhalb der Angebote,
        insbesondere nicht für die fehlerhafte Darstellung von Spielplänen und Ergebnissen, soweit nicht Vorsatz oder
        grobe Fahrlässigkeit vorliegen.</p>

      <h3>6. Hyperlinks</h3>
      <p>6.1 Die Angebote können Hyperlinks auf Webseiten Dritter enthalten. SPM Sportplatz-Media übernimmt für die
        Inhalte dieser Webseiten weder eine Verantwortung noch macht SPM Sportplatz-Media sich diese Webseiten und ihre
        Inhalte zu eigen, da SPM Sportplatz-Media die verlinkten Informationen nicht kontrolliert und für die dort
        bereit gehaltenen Inhalte und Informationen auch nicht verantwortlich ist. Deren Nutzung erfolgt auf eigenes
        Risiko des Nutzers.</p>

      <h3>7. Gewährleistung</h3>
      <p>7.1 SPM Sportplatz-Media gewährleistet eine möglichst störungsfreie Erreichbarkeit der Angebote im Rahmen des
        technisch und betrieblich Zumutbaren. Zeiten, in denen die Angebote aufgrund von technischen oder sonstigen
        Problemen außerhalb des Einschlussbereichs von SPM Sportplatz-Media (etwa höhere Gewalt, Verschulden Dritter
        etc.) nicht oder nur eingeschränkt erreichbar ist, sind hiervon ausgenommen. SPM Sportplatz-Media wird im Rahmen
        des technisch und betrieblich Zumutbaren von SPM Sportplatz-Media zu verantwortende Störungen unverzüglich
        beseitigen.</p>
      <p>7.2 Soweit Leistungen unentgeltlich zur Verfügung gestellt werden, ist eine Haftung für Sach- und Rechtsmängel,
        insbesondere für deren Fehlerfreiheit, Freiheit von Schutz- und Urheberrechten Dritter, Vollständigkeit und/oder
        Verwendbarkeit – außer bei Vorsatz oder Arglist – ausgeschlossen. Im Übrigen gelten die gesetzlichen
        Bestimmungen.</p>

      <h3>8. Haftung, Viren und Schadprogramme</h3>
      <p>8.1 SPM Sportplatz-Media haftet gegenüber dem Nutzer bei Vorsatz oder grober Fahrlässigkeit oder bei
        schuldhafter Verletzung des Lebens, des Körpers oder der Gesundheit für Schäden nach den gesetzlichen Vorgaben.
        Im Übrigen haftet SPM Sportplatz-Media gegenüber dem Nutzer nur für vertragstypische, vorhersehbare,
        unmittelbare Schäden und auch nur dann, wenn SPM Sportplatz-Media oder ein gesetzlicher Vertreter oder ein
        Erfüllungsgehilfe von SPM Sportplatz-Media schuldhaft handelte.</p>
      <p>8.2 Auch wenn SPM Sportplatz-Media sich stets bemüht, die Angebote frei von Viren und Schadprogrammen zu
        halten, garantiert SPM Sportplatz-Media keine Freiheit von Viren oder Schadprogrammen. Vor dem Herunterladen von
        Informationen wird der Nutzer zum eigenen Schutz sowie zur Verhinderung von Viren auf der Applikation für
        angemessene Sicherheitsvorrichtungen und Virenscanner sorgen.</p>

      <h3>9. Widerrufsbelehrung</h3>
      <p>9.1 Widerrufsrecht: Der Nutzer hat das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu
        widerrufen. Die Widerrufsfrist beginnt vierzehn Tage ab dem Tag des Vertragsabschlusses. Um das Widerrufsrecht
        auszuüben, muss der Nutzer SPM Sportplatz-Media GmbH, Schleidenstraße 3, 22083 Hamburg, kontakt@teamtip.net mittels
        einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über seinen Entschluss, diesen
        Vertrag zu widerrufen, informieren. Für den Widerruf kann folgendes Formular verwendet werden:
        <br><br>
        Muster-Widerrufsformular<br>
        (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es
        zurück.)<br><br>
         
        An: SPM Sportplatz Media GmbH, Schleidenstraße 3, 22083 Hamburg<br><br>
         
        Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren
        (*)/die Erbringung der folgenden Dienstleistung (*)<br><br>

        – Bestellt am (*)/erhalten am (*)<br>
        – Name des/der Verbraucher(s)<br>
        – Anschrift des/der Verbraucher(s)<br><br>
         
        – Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)<br>
        – Datum<br><br>
         
        (*) Unzutreffendes streichen.<br><br>

        Zur Wahrung der Widerrufsfrist reicht es aus, dass der Nutzer die Mitteilung über die Ausübung des
        Widerrufsrechts vor Ablauf der Widerrufsfrist absendet.</p>
      <p>9.2 Folgen des Widerrufs: Wenn der Nutzer diesen Vertrag widerruft, wird SPM Sportplatz-Media dem Nutzer alle
        Zahlungen, die SPM Sportplatz-Media bereits erhalten hat unverzüglich und spätestens binnen vierzehn Tagen ab
        dem Tag zurückzuzahlen, an dem die Mitteilung über den Widerruf eingegangen ist. Für diese Rückzahlung verwendet
        SPM Sportplatz-Media dasselbe Zahlungsmittel, das der Nutzer bei der ursprünglichen Transaktion eingesetzt
        haben, es sei denn, es wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden dem Nutzer wegen
        dieser Rückzahlung Entgelte berechnet. Hat der Nutzer verlangt, dass SPM Sportplatz-Media mit der Ausführung der
        Dienstleistungen während der Widerrufsfrist beginnen soll, so hat der Nutzer einen angemessenen Betrag zu
        zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem der Nutzer SPM Sportplatz-Media von der Ausübung des
        Widerrufsrechts hinsichtlich dieses Vertrags unterrichtet, bereits erbrachten Dienstleistungen im Vergleich zum
        Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
      <p>9.3 Besondere Hinweis: Das Widerrufsrecht erlischt vorzeitig, wenn der Vertrag von unserer Seite vollständig
        erfüllt ist, bevor Sie Ihr Widerrufsrecht ausgeübt haben.</p>

      <h3>10. Sonstige Bestimmungen</h3>
      <p>10.1 Mündliche Nebenabreden sind unwirksam. Änderungen und/oder Ergänzungen dieser Bedingungen bedürfen der
        Schriftform. Dies gilt auch für eine Abänderung dieser Klausel.</p>
      <p>10.2 SPM Sportplatz-Media behält sich das Recht vor, die Bedingungen zu ändern und/oder zu ergänzen. SPM
        Sportplatz-Media wird eine Änderung/Ergänzung durch Hinweis im Rahmen der Angebote ankündigen. Der Nutzer kann
        innerhalb von 14 Tagen ab Erscheinen des Hinweises per E-Mail Widerspruch gegen die Änderung/Ergänzung einlegen.
        Im Falle eines Widerspruchs ist SPM Sportplatz-Media berechtigt, den Vertrag mit dem jeweiligen Nutzer ab
        Inkrafttreten der Änderung/Ergänzung, bei Abonnement-Leistungen bei Ablauf des jeweiligen Zeitraums zu kündigen.
        Sofern ein fristgerechter Widerspruch unterbleibt, gelten die neuen Bedingungen als genehmigt.</p>
      <p>10.3 SPM Sportplatz-Media ist berechtigt, sich zur Erfüllung seiner Pflichten aus diesen Bedingungen der Hilfe
        Dritter zu bedienen. SPM Sportplatz-Media ist ferner berechtigt, sämtliche Rechte und Pflichten aus diesen
        Bedingungen auf einen Dritten zu übertragen.</p>
      <p>10.4 Sollten einzelne Bestimmungen dieser Bedingungen ungültig, unvollständig sein oder werden, bleibt die
        Gültigkeit der übrigen Bedingungen unberührt. Die entsprechende Lücke wird durch eine wirksame Bestimmung
        ersetzt, welche dem wirtschaftlichen Willen der Parteien am nächstem kommt.</p>
      <p>10.5 Es gilt materielles deutsches Recht unter Ausschluss deutschen Kollisionsrechts und UN-Kaufrechts.</p>

      <br><br>
      <p>
        Stand: Februar 2021
      </p>
      <br><br>

    </div>

    <div v-else>
      <br><br>

      <h1>General Terms and Conditions (GTC)</h1>

      <h3>1. Scope of application</h3>
      <p>1.1 The following terms and conditions of use (hereinafter "Terms and Conditions") apply to all services that
        SPM Sportplatz-Media GmbH, Schleidenstraße 3, 22083 Hamburg (hereinafter "SPM Sportplatz-Media"), provides to its
        users in connection with its website (hereinafter "Website"), which can be accessed under the domain
        www.teamtip.net, and the teamtip application (hereinafter "App"; Website and App together "Offers").</p>
      <p>1.2. The relationship of the users with the operator of the platform (e.g. Apple's App Store, Google's Play
        Store) through which the App is distributed, including any in-app purchases, shall be governed exclusively by
        the respective terms and conditions concluded between the user and the platform operator.</p>

      <h3>2. Registration</h3>
      <p>2.1 The use of the offers requires a registration of the user by entering his personal data and a valid email
        address. The information must be truthful and updated in the event of changes. After registration, SPM
        Sportplatz-Media will ask the user to confirm the email address provided.</p>
      <p>2.2. During registration the user has to choose a password. The user must keep the password secret and not pass
        it on to third parties. If third parties gain knowledge of the password through the fault of the user, the user
        shall be liable for any damage resulting from the use of the password.</p>
      <p>2.3 The user can set up a betting community and invite other persons by entering their e-mail address. The user
        assures that he/she will not send invitations to knowingly invalid e-mail addresses and will not send
        invitations to persons who have not previously consented to receiving an invitation.</p>
      <p>2.4 If a user creates a betting community, invites participants and manages the betting community, he/she is
        the admin of the betting community (hereinafter "admin"). A participant can also be activated as an admin by an
        admin. In this case, the Admin is responsible for all personal data entered by him or by participants under data
        protection law. SPM Sportplatz Media processes the data in accordance with Art. 28 DSGBO only on behalf of the
        respective admin. To comply with the data protection regulations usually includes a contract for commissioned
        processing under Article 28 DSGVO, unless the admin function under Article 2 para 2c DSGVO is operated
        exclusively privately or family. In order for an admin to be able to use all functions in a data protection
        compliant manner, he or she - unless acting as a natural person for the purpose of exclusively personal or
        family activities - also enters into a contract with SPM Sportplatz Media pursuant to Art. 28 DSGVO by agreeing
        to these Terms of Use. In his capacity as a data controller, the Admin uses his own privacy policy vis-à-vis his
        participants. Unless he makes use of the option to provide his own privacy policy, this privacy policy applies
        in the relationship between Admin and participants. SPM Sportplatz Media is only responsible for the data
        processing that goes beyond the relationship between the admin and participants, namely the provision of
        services on the website and within the web application and the mobile app within the meaning of the GDPR.</p>

      <h3>3. Services and contents</h3>
      <p>3.1 As part of its services, SPM Sportplatz-Media offers various functions which enable the creation of betting
        rounds on sporting events such as World Cups, European Championships, league championships, e.g. in the sport of
        football, and enables betting round participants to take part in previously created betting rounds ("services").
        The services can generally be used free of charge.</p>
      <p>3.2 SPM Sportplatz-Media is entitled to change the services or their scope at any time.</p>

      <h3>4. Rights of use</h3>
      <p>4.1 The user receives the simple, non-transferable and non-sublicensable right to use the services for private
        purposes for the duration of the registration.</p>

      <h3>5. User obligations, blocking of access</h3>
      <p>5.1 The user may not (i) violate morality with his use of the offers; (ii) violate industrial property rights,
        copyrights, personal rights, property rights or other rights of third parties; (iii) transmit content with
        viruses, so-called Trojan horses or other programming that can damage software; (iv) enter, store or send
        hyperlinks or content to which he is not authorised, and (v) integrate source code into the offers, which
        enables him to extract data from the database or the offers.</p>
      <p>5.2 SPM Sportplatz-Media is entitled to block access to the offers if the user violates his obligations under
        these terms.</p>
      <p>5.3 Users have the option of conducting a betting community or joining a betting community. Betting rounds may
        not be used for illegal gambling within the framework of the applicable legal basis. Such use entitles SPM
        Sportplatz-Media to immediately delete the betting community.</p>
      <p>5.4 SPM Sportplatz-Media is not liable for the failure of the offers or for errors within the offers, in
        particular not for the incorrect presentation of match schedules and results, unless there is intent or gross
        negligence.</p>

      <h3>6. Hyperlinks</h3>
      <p>6.1 The offers may contain hyperlinks to third party websites. SPM Sportplatz-Media assumes no responsibility
        for the content of these websites nor does SPM Sportplatz-Media adopt these websites and their content as its
        own, as SPM Sportplatz-Media does not control the linked information and is not responsible for the content and
        information provided there. Their use is at the user's own risk.</p>

      <h3>7. Warranty</h3>
      <p>7.1 SPM Sportplatz-Media guarantees a trouble-free accessibility of the offers as far as technically and
        operationally reasonable. Times in which the offers due to technical or other problems outside the scope of SPM
        Sportplatz-Media (such as force majeure, fault of third parties, etc.) is not or only limited accessibility, are
        excluded. SPM Sportplatz-Media will, within the limits of what is technically and operationally reasonable,
        immediately eliminate disruptions for which SPM Sportplatz-Media is responsible.</p>
      <p>7.2 Insofar as services are provided free of charge, liability for material defects and defects of title, in
        particular for their freedom from defects, freedom from intellectual property rights and copyrights of third
        parties, completeness and / or usability - except in case of intent or fraudulent intent - is excluded. In all
        other respects, the statutory provisions shall apply.</p>

      <h3>8. Liability, viruses and malware</h3>
      <p>8.1 SPM Sportplatz-Media is liable to the user in the case of intent or gross negligence or culpable injury to
        life, limb or health for damages in accordance with the statutory provisions. Otherwise, SPM Sportplatz-Media is
        liable to the user only for contract-typical, foreseeable, direct damage and only if SPM Sportplatz-Media or a
        legal representative or a vicarious agent of SPM Sportplatz-Media acted culpably.</p>
      <p>8.2 Even if SPM Sportplatz-Media always strives to keep the offers free of viruses and malware, SPM
        Sportplatz-Media does not guarantee freedom from viruses or malware. Before downloading information, the user
        will ensure appropriate security devices and virus scanners for his own protection as well as to prevent viruses
        on the application.</p>

      <h3>9. Cancellation policy</h3>
      <p>9.1 Right of withdrawal: The user has the right to withdraw from this contract within fourteen days without
        giving any reason. The revocation period begins fourteen days from the day of the conclusion of the contract. To
        exercise the right of withdrawal, the user must inform SPM Sportplatz-Media GmbH, Schleidenstraße 3, 22083 Hamburg,
        kontakt@teamtip.net by means of a clear declaration (e.g. a letter or e-mail sent by post) of his decision to
        withdraw from this contract. The following form can be used for the revocation:
        <br><br>
        Model revocation form<br>
        (If you wish to revoke the contract, please complete and return this form.)<br><br>
         
        To: SPM Sportplatz Media GmbH, Schleidenstraße 3, 22083 Hamburg, Germany<br><br>
         
        I/we (*) hereby revoke the contract concluded by me/us (*) for the purchase of the following goods (*)/the
        provision of the following service (*)<br><br>

        – Ordered on (*)/received on (*)<br>
        – Name of the consumer(s)<br>
        – Address of the consumer(s)<br><br>
         
        – Signature of consumer(s) (only in case of paper communication)<br>
        – Date(s)<br><br>
         
        (*) Delete where inapplicable.<br><br>

        In order to comply with the withdrawal period, it is sufficient for the user to send the notification of the
        exercise of the right of withdrawal before the expiry of the withdrawal period.</p>
      <p>9.2 Consequences of revocation: If the user revokes this contract, SPM Sportplatz-Media will reimburse the user
        for all payments already received by SPM Sportplatz-Media immediately and at the latest within fourteen days of
        the day on which the notification of revocation was received. For this repayment, SPM Sportplatz-Media shall use
        the same means of payment that the user used for the original transaction, unless expressly agreed otherwise; in
        no case shall the user be charged for this repayment. If the user has requested that SPM Sportplatz-Media begin
        to perform the services during the withdrawal period, the user shall pay a reasonable amount corresponding to
        the proportion of the services already performed up to the time the user notifies SPM Sportplatz-Media of the
        exercise of the right of withdrawal with regard to this contract compared to the total scope of the services
        provided for in the contract.</p>
      <p>9.3 Special note: The right of withdrawal expires prematurely if the contract is completely fulfilled by our
        side before you have exercised your right of withdrawal.</p>

      <h3>10. Other provisions</h3>
      <p>10.1 Verbal collateral agreements are invalid. Amendments and/or supplements to these terms and conditions must
        be made in writing. This also applies to an amendment of this clause.</p>
      <p>10.2 SPM Sportplatz-Media reserves the right to change and/or amend the conditions. SPM Sportplatz-Media will
        announce a change / addition by notice in the context of the offers. The user can object to the change/addition
        within 14 days of the publication of the notice by e-mail. In the event of an objection, SPM Sportplatz-Media is
        entitled to terminate the contract with the respective user as of the effective date of the change/addition, in
        the case of subscription services at the end of the respective period. If an objection is not made in due time,
        the new conditions shall be deemed to be approved.</p>
      <p>10.3 SPM Sportplatz-Media is entitled to use the help of third parties to fulfil its obligations under these
        conditions. SPM Sportplatz-Media is also entitled to transfer all rights and obligations under these terms and
        conditions to a third party.</p>
      <p>10.4 Should individual provisions of these terms and conditions be or become invalid, incomplete, the validity
        of the remaining conditions remains unaffected. The corresponding loophole shall be replaced by a valid
        provision which comes closest to the economic intention of the parties.</p>
      <p>10.5 German substantive law shall apply to the exclusion of German conflict of laws provisions and the UN
        Convention on Contracts for the International Sale of Goods.</p>

      <br><br>
      <p>
        Current as of: Feb 2021
      </p>
      <br><br>
    </div>

  </div>
</template>

<script>
import NavigationBar from '../components/NavigationBar'

export default {
  name: 'BetgameTermsOfUse',
  components: { NavigationBar },
  computed: {
    userlang () {
      return this.$store.state.language
    }
  },
  methods: {
    goBack () {
      if (this.$store.getters.loggedIn) {
        this.$router.push({ name: 'profile' }).catch(err => {
        })
      } else {
        this.$router.go(-1)
      }
    },
    canGoBack () {
      return window.history && window.history.length > 1
    }
  }
}

</script>

<style lang="scss" scoped>
</style>
