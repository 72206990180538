<template>
  <div class="betgameeditchatbot" id="betgameeditchatbot">

    <NavigationBar>
      <template v-slot:left>
        <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
      </template>
      <template v-slot:center>
        Chat
      </template>
    </NavigationBar>
    
    <form @submit.prevent="changeChatbotEnabled">
      <!--
      <div class="input-container">
        <div>
          <div class="input-header">
            {{ $t('betgame_settings.betgame_enable_chat') }}
          </div>
          <div class="input-infotext">
            {{ $t('betgame_settings.betgame_enable_chat_description') }}
          </div>
          <div class="input-radio">
            <input v-model="chat_enabled" value="true" type="radio" id="chatenabled_true" name="chatenabled" @click="chatEnabled"/>
            <label for="chatenabled_true">{{ $t('yes') }}</label>
          </div>
          <div class="input-radio">
            <input v-model="chat_enabled" value="false" type="radio" id="chatenabled_false" name="chatenabled"/>
            <label for="chatenabled_false">{{ $t('no') }}</label>
          </div>
        </div>
        <div class="input-button-row">
          <button name="button" type="submit">
            {{ $t('button_save_text') }}
          </button>
        </div>
      </div>
      -->
      <div v-if="chat_enabled === 'true'" class="input-container" style="margin-top: 16px;">
        <div>
          <div class="input-header">
            {{ $t('betgame_settings.betgame_enable_chat_bot') }}
          </div>
          <div class="input-infotext">
            {{ $t('betgame_settings.betgame_enable_chat_bot_description') }}
          </div>
          <div class="input-radio">
            <input v-model="chatbot_enabled" value="true" type="radio" id="chatbot_enabled_true" name="chatbotenabled"/>
            <label for="chatbot_enabled_true">{{ $t('yes') }}</label>
          </div>
          <div class="input-radio">
            <input v-model="chatbot_enabled" value="false" type="radio" id="chatbot_enabled_false" name="chatbotenabled"/>
            <label for="chatbot_enabled_false">{{ $t('no') }}</label>
          </div>
        </div>
        <div class="input-button-row">
          <button name="button" type="submit">
            {{ $t('button_save_text') }}
          </button>
        </div>
      </div>
    </form>
    
  </div>
</template>

<script>

import NavigationBar from './../components/NavigationBar'
import { TOAST_DURATION } from '../main'

export default {
  name: 'BetgameEditChatBot',
  components: { NavigationBar },
  data () {
    return {
      betgame: null,
      chat_enabled: 'true',
      chatbot_enabled: true
    }
  },
  created () {
    this.betgame = this.$route.params.betgame
    if(this.betgame !== null && this.betgame !== undefined) {
      //this.chat_enabled = this.betgame.chat_enabled
      this.chatbot_enabled = this.betgame.chatbot_enabled ? 'true' : 'false'
    }
  },
  mounted () {
    if (this.betgame === null || this.betgame === undefined) {
      this.goBack()
    }
  },
  computed: {},
  methods: {
    chatEnabled () {
      this.chatbot_enabled = true
    },
    updateBetgame (data) {
      this.$store.dispatch('patchBetgame', data)
      .then(() => {
        this.$toast.open({
          message: this.$i18n.t('save_changes_success'),
          position: 'top',
          duration: TOAST_DURATION,
          type: 'success'
        })
        this.goBack()
      })
      .catch(function (error) {
        this.$toast.open({
          message: this.$i18n.t('save_changes_error'),
          position: 'top',
          duration: TOAST_DURATION,
          type: 'error'
        })
      })
      .finally(function () {
      })
    },
    goBack () {
      this.$router.push('/betgameedit')
    },
    changeChatbotEnabled () {
      this.updateBetgame({ chatbot_enabled: this.chatbot_enabled === 'true'})
    }
  }
}
</script>

<style lang="scss" scoped>
.betgameeditchatbot {

  form {
    padding-top: 16px;
  }

  h1 {
    text-align: center;
  }

  p {
    text-align: center;
    margin-bottom: 26px;
  }
}


.grouped {
  margin-top: -16px;
}

.table-view {
  margin-bottom: 0;
}

.table-view-cell:last-child {
  border-bottom: 0;
}

.table-view-cell-label {
  color: rgba($colorGreyDark, .48);
  font-weight: 400;
}
</style>
