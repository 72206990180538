<template>
  <div class="report">

    <keep-alive>
      <NavigationBar id="rulesNavigationBarId" ref="rulesNavigationBarRef">
        <template v-slot:left>
          <br>
        </template>
        <template v-slot:center>
          {{ $t('feed.report_message') }}
        </template>
      </NavigationBar>
    </keep-alive>

    <div class="report__content">

      <div class="report__notification">
        <img src="@/assets/icons/ic_20_report_check.svg" alt="">
        <div class="report__notification__label">
          {{ $t('feed.message_reported_thanks') }}
        </div>
        <div class="report__notification__sublabel">
          {{ $t('feed.message_reported_success') }}
        </div>
      </div>

      <div class="report__progress">
        <small>{{ $t('feed.message_reported_whats_next') }}</small>
        <div class="report__progress__list">
          <div class="report__progress__item">
            <img src="@/assets/icons/ic_20_report_progress-active.svg" alt="">
            <div class="report__progress__item__label">
              {{ $t('feed.message_reported') }}
            </div>
          </div>
          <div class="report__progress__item">
            <img src="@/assets/icons/ic_20_report_progress.svg" alt="">
            <div class="report__progress__item__label">
              {{ $t('feed.message_reported_support_review') }}
            </div>
          </div>
          <div class="report__progress__item">
            <img src="@/assets/icons/ic_20_report_progress.svg" alt="">
            <div class="report__progress__item__label">
              {{ $t('feed.message_reported_result') }}
            </div>
          </div>
          <svg width="2" height="100%" viewBox="0 0 2 106" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 0V106" stroke="#C2C2C2" stroke-width="2" stroke-dasharray="2 4"/>
          </svg>
        </div>
      </div>

      <button v-on:click="backClicked">{{ $t('feed.back_to_chat') }}</button>

    </div>
    
  </div>
</template>

<script>
import NavigationBar from '../components/NavigationBar'
import Vue from 'vue'

export default {
  name: 'ReportSuccess',
  components: { NavigationBar },
  data () {
    return {
    }
  },
  methods: {
    backClicked () {
      this.$router.push('/feed/chat')
    },
  },
  computed: {
  }
}
</script>

<style lang="scss">
  .report {

    @media screen and (min-width: 576px) {
      max-width: 576px;
      margin: 0px auto;
    }

    &__notification {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: auto;
      margin-bottom: 40px;

      &__label {
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        color: $colorGreyDark;
        text-align: center;
        margin-bottom: 5px;
        margin-top: 24px;
      }

      &__sublabel {
        font-size: 14px;
        line-height: 20px;
        color: $colorGreyDark;
        text-align: center;
      }
    }

    &__progress {
      margin-bottom: 40px;

      &__list {
        position: relative;
        display: flex;
        flex-direction: column;
        background: $colorWhite;
        border-radius: 8px;
        margin-top: 16px;
        padding: 0 16px;

        &:before {
          content: '';
          position: absolute;
          width: 0px;
          height: calc(100% - 40px);
          top: 20px;
          left: 25px;
          border: dashed 0.75px #000;
          border-top-width:5px;
          border-right-width:0px;
          border-bottom-width:0px;
          border-left-width:0px;
        }

        svg {
          position: absolute;
          top: 20px;
          left: 25px;
          height: 128px;
        }
      }

      &__item {
        display: flex;
        align-items: center;
        z-index: 2;

        img {
          width: 20px;
          height: 20px;
          margin-right: 16px;
          background: $colorWhite;
          border-radius: 50%;
          box-shadow: 0 0 0 2px $colorWhite;
        }

        &__label {
          flex: 1;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          color: $colorGreyDark;
          padding: 18px 0;
        }

        &:not(:last-child) {
          .report__progress__item__label {
            box-shadow: inset 0 -1px 0 0 rgba($colorGreyDark, .08);
          }
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  .page {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
  }

  button {
    margin-top: auto;
  }
</style>
