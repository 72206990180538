<template>
  <div class="bet_toto_slider">

    <!--
    <NavigationBar>
      <template v-slot:left>
        <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
      </template>
      <template v-slot:center>
          <div class="navigation_title--headline">
            {{ $t('menu.bet') }}
          </div>
          <div v-on:click="opemMatchdaySelect" class="navigation_title--subline">
            {{ matchdayText }}
          </div>
         
        </template>
    </NavigationBar>
    -->

    <Tinder ref="tinder" key-name="id" :queue.sync="queue" :offset-y="10" @submit="onSubmit" :allow-down="true">
      <template slot-scope="scope">
        <div style="padding: 10px;">
        <BetMatchDetailSlide :match="getMatch(scope.data.id)" style="margin: 10px; padding-top: 20px;"></BetMatchDetailSlide>
        <MatchStatistics :match="getMatch(scope.data.id)" style="margin: 0px; padding-top: 30px; padding-bottom: 10px;"></MatchStatistics>
        </div>
        <!--
        <div
            class="pic"
            style="background-color: #24E07C"
        >
          {{ scope.data.id }}
          <img :src="teamLogo(scope.data.id, 'fk_team_home')" width="72">
          <img :src="teamLogo(scope.data.id, 'fk_team_guest')" width="72">
        </div>
        -->
      </template>
      <img class="like-pointer" slot="like" src="../assets/images/misc/toto_2.jpg">
      <img class="nope-pointer" slot="nope" src="../assets/images/misc/toto_1.jpg">
      <img class="super-pointer" slot="super" src="../assets/images/misc/toto_0.jpg">
      <img class="down-pointer" slot="down" src="../assets/icons/ic_16_drilldown.svg">
    </Tinder>
    
    <div class="btns">
      <!-- <img src="../assets/icons/ic_16_back.svg" @click="decide('rewind')"> -->
      <img src="../assets/images/misc/toto_1.jpg" @click="decide('nope')">
      <img src="../assets/images/misc/toto_0.jpg" @click="decide('super')">
      <img src="../assets/images/misc/toto_2.jpg" @click="decide('like')">
      <!-- <img src="../assets/icons/ic_24_hint.svg" @click="decide('help')"> -->
    </div>
    
  </div>
</template>

<script>

import NavigationBar from '../components/NavigationBar'
import Vue from 'vue'
import APIService from '@/services/APIService'
import Tinder from "vue-tinder";
import BetMatchDetailSlide from '../components/BetMatchDetailSlide'
import MatchStatistics from '../components/MatchStatistics'
import moment from 'moment'

export default {
  name: 'BetTotoSlider',
  components: { NavigationBar, Tinder, BetMatchDetailSlide, MatchStatistics },
  props: {
    matchday: {
      type: Number,
      default: () => {
        return 1
      }
    }
  },
  data () {
    return {
      queue: [],
      offset: 0,
      history: []
    }
  },
  created () {
    Vue.$log.debug('BetTotoSlider.created ')
    this.mock()
  },
  mounted () {
    
  },
  computed: {
    matches() {
      if(this.isBattle()) {
        const m = JSON.parse(JSON.stringify(this.$store.state.matches))
        const t = m.sort(function (a, b) {
          return Date.parse(a.matchdate) - Date.parse(b.matchdate)
        })
        return t
      } else {
        const m = this.$store.getters.getMatchesByMatchday(this.matchday).sort(function (a, b) {
          return Date.parse(a.matchdate) - Date.parse(b.matchdate)
        })
        return m
      }
    },
    matchCount() {
      return this.matches.length
    }
  },
  methods: {
    teamLogo(id, field) {
      const r = this.getMatch(id)[0]
      const l = this.getTeamLogo(r[field])
      if(l) {
        const t = this.getBGValue('spmau') + '/images/jerseys/72/' + l
        return t
      }
      return null
    },
    mock(count = 5, append = true) {
      const list = [];
      for (let i = 0; i < this.matches.length; i++) {
        list.push({ id: this.matches[i].id });
        this.offset++;
      }
      if (append) {
        this.queue = this.queue.concat(list);
      } else {
        this.queue.unshift(...list);
      }
      this.componentKey++
    },
    onSubmit(item) {
      const t = item.type
      const k = item.key
      const i = item.item
      item.id = k
      Vue.$log.debug('onSubmit ' + t + ' --- ' + k + ' --- ' + JSON.stringify(i))
      this.history.push(item)
      if(t === 'like' || t === 'nope' || t === 'super') {
        this.saveBet(k, t)
      }
      if(this.history.length === this.matchCount) {
        this.history = []
        this.mock(this.matchCount, true)
      }
    },
    async decide(choice) {
      if (choice === "rewind") {
        if (this.history.length) {
          this.$refs.tinder.rewind([this.history.pop()]);
        }
      } else if (choice === "help") {
        //this.mock()
        //window.open("https://shanlh.github.io/vue-tinder");
      } else {
        this.$refs.tinder.decide(choice);
      }
    },
    getMatch(id) {
      const t =  this.$store.getters.getMatcheById(id)
      return t
    },
    matchDayMatches (matchday) {
      return this.matches.filter(q => parseInt(q.matchday) === parseInt(matchday))
    },
    goBack () {
      this.$router.push({ name: 'dashboard'}).catch(err => {})
    },
    saveBet (matchid, choice) {
      Vue.$log.debug('saveBet ' + matchid + ' - ' + choice + ' --- ')
      var newbet = 1
      if(choice === 'like') {
        newbet = 2
      } else if(choice === 'super') {
        newbet = 0
      }
      var b = this.$store.getters.getBetByBetgameAndMatch(this.$store.state.betgameId, matchid)
      if (b != null) {
      } else {
        b = {
          'fk_user': parseInt(this.$store.state.user.id),
          'fk_match': matchid,
          'fk_betgame': parseInt(this.$store.state.betgameId)
        }
        this.$store.dispatch('addBet', b)
      }
      if(b.goalshome !== newbet) {
        const bet = {
          'fk_user': this.$store.state.user.id,
          'fk_match': matchid,
          'goalshome': newbet,
          'goalsguest': newbet,
          'fk_betgame': parseInt(this.$store.state.betgameId)
        }
        this.$store.dispatch('updateBet', bet).then(response => {
          //this.homeBetValue = this.bet.goalshome
          this.$store.dispatch('setLastBetSaveDate', moment().format('YYYY-MM-DD HH:mm:ss'))
        }, error => {
          //this.homeBetValue = this.bet.goalshome
          alert(this.$t('bet.save_bet_error_try_again'))
        })
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.bet_toto_slider {

  &-page {
    display: flex;
  }

  &-container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 80px);
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;

    button {
      margin-top: 27px;
    }
  }
}

#app .vue-tinder {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 70px;
  margin: auto;
  width: calc(100% - 40px);
  height: calc(100% - 50px - 23px - 65px - 47px - 16px);
  min-width: 300px;
  max-width: 355px;
}

.nope-pointer,
.like-pointer {
  position: absolute;
  z-index: 1;
  top: 20px;
  width: 64px;
  height: 64px;
}

.nope-pointer {
  right: 10px;
}

.like-pointer {
  left: 10px;
}

.super-pointer {
  position: absolute;
  z-index: 1;
  bottom: 80px;
  left: 0;
  right: 0;
  margin: auto;
  width: 112px;
  height: 78px;
}

.rewind-pointer {
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 10px;
  width: 112px;
  height: 78px;
}

.pic {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.btns {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  margin: 10px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 300px;
  max-width: 355px;
}

.btns img {
  margin-right: 12px;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.btns img:nth-child(2n + 1) {
  width: 53px;
}

.btns img:nth-child(2n) {
  width: 65px;
}

.btns img:nth-last-child(1) {
  margin-right: 0;
}

</style>
