<template>
  <div class="betgameEditUsers" id="betgameEditUsers">

    <NavigationBar>
      <template v-slot:left>
        <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
      </template>
      <template v-slot:center>
        {{ $t('betgame_settings.betgame_participants') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>
    
    <table id="bg_users_table" class="table">
      <tbody>
        <tr v-on:click="userClicked(user)" v-for="(user, index) in betgameUsers" :key="user.fk_user">
          <td style="width: 100%">{{ user.nickname }}</td>
          <td><div class="table-view-cell-drilldown"></div></td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
    
  </div>
</template>

<script>

import NavigationBar from './../components/NavigationBar'
import { TOAST_DURATION } from '../main'
import Vue from 'vue'

export default {
  name: 'BetgameEditUsers',
  components: { NavigationBar },
  data () {
    return {
    }
  },
  created () {
    if(this.$store.state.betgameId && parseInt(this.$store.state.betgameId) > 0) {
      Vue.$log.debug('BetgameEditUsers created fetchBetgameUsers ' + this.$store.state.betgameId)
      this.$store.dispatch('fetchBetgameUsers', this.$store.state.betgameId)
    }
  },
  mounted () {
    if(this.$store.state.betgame) {
      this.$store.dispatch('fetchUserBetgamePoints', this.$store.state.betgame.id)
    }
  },
  computed: {
    betgameUsers() {
      return this.$store.state.betgameUsers
    }
  },
  methods: {
    userClicked(user) {
      this.$router.push({ name: 'betgameedituser', params: { user: user }})
    },
    goBack () {
      this.$router.push('/betgameedit')
    }
  }
}
</script>

<style lang="scss" scoped>
  table {
    margin-top: 16px !important;

    @media screen and (min-width: 768px) {
      max-width: 576px;
      margin: 24px auto 0 !important;
      border-radius: 11px;
      padding: 16px;
      background: $colorWhite;
      box-shadow: 0 8px 24px 0 rgba(49,49,55,0.08);
    }

    td {
      cursor: pointer;
    }
  }
</style>
