<template>
  <div class="addusers" id="addusers">
    <div class="input-container">
      <div class="input-header">
        {{ $t('create_betgame.add_users') }}
      </div>
      <div class="input-infotext">
        {{ $t('create_betgame.add_users_description', {betgame: this.$store.state.betgame.name}) }}
      </div>
      <!--
      <div class="input-checkbox">
        <input v-on:change="addUsersChanged" name="addUsers" id="addUsers" type="checkbox" v-model="addUsers"/>
        <label for="addUsers">{{ $t('create_betgame.add_users') }}</label>
      </div>
      -->
      <a :key="0" href="" v-on:click.stop.prevent="betgameSelected(0)" class="table-view-cell">
        <div class="table-view-cell-label">
          {{ $t('create_betgame.dont_import') }}
        </div>
        <input v-on:change="betgameSelected(0)" :checked="selectBetgame === 0" name="addUsers" type="radio" />
        <div class="table-view-cell-value"></div>
      </a>
      <a v-for="(betgame, index) in userBetgamesOwner" :key="betgame.id" href=""
         v-bind:class="{ active : currentBetgame.id === betgame.id }"
         v-on:click.stop.prevent="betgameSelected(betgame.id)"
         class="table-view-cell">
        <div class="table-view-cell-icon size-24">
          <img v-if="isValidIcon(betgame.icon)" v-bind:src="buildIconPath(betgame.icon, 300)" style="width: 24px; height: 24px;">
          <img v-else src="../../assets/icons/ic_24_logo.svg">
        </div>
        <div class="table-view-cell-label">
          {{ betgame.name }}
          <div v-if="betgame.type !== 'NONE'" class="table-view-cell-label-sublabel">
            <span v-if="currentBetgame.id === betgame.id">
               {{ $t('active') }}
            </span>
            <span v-if="currentBetgame.id === betgame.id"> ∙ </span>
            {{ $t('admin') }} ∙ {{ getCompetition(betgame.id) }} ∙ {{ getSeason(betgame.id) }}
          </div>
          <div v-else class="table-view-cell-label-sublabel">
            <span v-if="currentBetgame.id === betgame.id">
               {{ $t('active') }}
            </span>
            <span v-if="currentBetgame.id === betgame.id"> ∙ </span>
            {{ $t('admin') }}
          </div>
        </div>
        <input v-on:change="betgameSelected(betgame.id)" :checked="selectBetgame === betgame.id" name="addUsers" type="radio" />
        <div class="table-view-cell-value">
          <div v-if="competitionFinished(getCompetitionId(betgame.id), getSeasonId(betgame.id), betgame.id)" class="indicator">{{ $t('betgame_finished') }}</div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'

export default {
  name: 'AddUsers',
  components: {},
  props: {
  },
  data () {
    return {
      addUsers: this.$store.state.createBetgame.addUsers,
      selectBetgame: this.$store.state.createBetgame.addUsersBetgame
    }
  },
  beforeCreate () {
    Vue.$log.debug('AddUsers.beforeCreate ' + this.selectBetgame)
  },
  created () {
    Vue.$log.debug('AddUsers.created ' + this.selectBetgame)
  },
  mounted () {
    Vue.$log.debug('AddUsers.mounted ' + this.selectBetgame)
  },
  computed: {
    currentBetgame() {
      return this.$store.state.betgame
    },
    userBetgamesOwner() {
      const newArray = this.$store.state.betgames_owner.filter(function (el) {
        return true;
      });
      return newArray.sort((a, b) => b.id - a.id)
    },
  },
  methods: {
    betgameSelected(id) {
      Vue.$log.debug('AddUsers.betgameSelected ' + id)
      this.selectBetgame = id
      this.addUsers = id > 0
      this.$store.dispatch('setCreateBetgame', {
        key: 'addUsers',
        value: id > 0
      })
      .then(() => {
        Vue.$log.debug('AddUsers.addUsersChanged ok ' + this.addUsers)
      }).catch(function (error) {
        Vue.$log.debug('AddUsers.addUsersChanged error ' + error)
      })
      this.$store.dispatch('setCreateBetgame', {
        key: 'addUsersBetgame',
        value: id
      })
      .then(() => {
        Vue.$log.debug('AddUsers.addUsersChanged addUsersBetgame ok ' + id)
      }).catch(function (error) {
        Vue.$log.debug('AddUsers.addUsersChanged addUsersBetgame error ' + error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.addusers {
  max-width: 400px;
  margin: 16px auto 16px;

  .indicator {
    padding: 5px 8px;
    border-radius: 10px;
    font-size: 9px;
    font-weight: 500;
    color: rgba($colorGreyDark, .64);
    background: rgba($colorGreyDark, .08);

    &--warning {
      color: $colorWhite;
      background: $colorNegative;
    }
  }

  .active {
    background: rgba($colorGreyLight, .04);
    padding-left: 0;

    &:before, &:after {
      content: '';
      position: absolute;
      width: 16px;
      height: 100%;
      top: 0;
      background: rgba($colorGreyLight, .04);
      border-radius: 0;
    }

    &:before {
      left: -16px;
    }

    &:after {
      right: -16px;
    }

    .table-view-cell-icon {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        right: -2px;
        bottom: -2px;
        width: 10px;
        height: 10px;
        border-radius: 6px;
        background: $colorPositive;
        box-shadow: 0 0 0 2px #F9F9F9;
      }
    }

    .table-view-cell-value {
      display: flex;
    }

    .table-view-cell-label-sublabel {
      span {

        &:first-child {
          color: $colorPositive;
          font-weight: 500;
        }
      }
    }
  }
}



input {
  display: none;
}

input[type="radio"]:checked+.table-view-cell-value:before {
  background: url('../../assets/icons/ic_20_radio_active.svg');
}

input[type="radio"]:disabled+.table-view-cell-value:before {
  opacity: .48;
}

.table-view-cell-value {
  position: relative;
  padding-right: 28px;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 20px;
    height: 20px;
    background: url('../../assets/icons/ic_20_radio.svg');
  }
}

.table-view-cell-label, .table-view-cell-label-sublabel {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

</style>
