<template>
    <div class="localrankings" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">
        
        <NavigationBar>
            <template v-slot:left>
                <img v-on:click="goBack" class="back" src="../assets/icons/ic_16_back.svg">
            </template>
            <template v-slot:center>
              Rankings
            </template>
        </NavigationBar>
        
        <div>
          <table class="">
            <thead>
            <th>pos</th>
            <th>nam</th>
            <th>tot</th>
            <th>bet</th>
            <th>que</th>
            <th>mdw</th>
            <th>exa</th>
            <th>gdi</th>
            <th>ten</th>
            <th>0p</th>
            <th>cou</th>
            </thead>
            <tbody>
            <tr
                v-for="(ranking, index) in this.rankings"
                :key="ranking.fk_user"
            >
              <td>{{ ranking.position }}</td>
              <td>{{ ranking.user_name }}</td>
              <td>{{ ranking.points_total }}</td>
              <td>{{ ranking.bet_points }}</td>
              <td></td>
              <td>{{ ranking.matchday_wins }}</td>
              <td>{{ ranking.exact }}</td>
              <td>{{ ranking.goaldiff }}</td>
              <td>{{ ranking.tendency }}</td>
              <td>{{ ranking.no_points }}</td>
              <td>{{ ranking.betcount }}</td>
            </tr>
            </tbody>
            <tfoot></tfoot>
          </table>
        </div>


      
      <div v-for="(matchdayranking, index) in matchdayrankings">
        <table class="">
          <thead>
          Spieltag {{ index }}
          </thead>
          <thead>
          <th>nam</th>
          <th>tot</th>
          <th>cou</th>
          <th>exa</th>
          <th>gdi</th>
          <th>ten</th>
          <th>0p</th>
          </thead>
          <tbody>
          <tr v-for="(ranking, index) in matchdayranking">
            <td>{{ ranking.user_name }} {{ ranking.matchdaywinner }}</td>
            <td>{{ ranking.points_total }}</td>
            <td>{{ ranking.betcount }}</td>
            <td>{{ ranking.exact }}</td>
            <td>{{ ranking.goaldiff }}</td>
            <td>{{ ranking.tendency }}</td>
            <td>{{ ranking.no_points }}</td>
            
          </tr>
          </tbody>
          <tfoot></tfoot>
        </table>
      </div>
      
  
    </div>
</template>

<script>
  import NavigationBar from '../components/NavigationBar'
  import { isNumeric } from '@/vuex/helpers'
  
  export default {
    name: 'localrankings',
    components: { NavigationBar },
    data() {
      return {
        matchdayrankings: {},
        rankings: []
      }
    },
    mounted () {
      this.$store.dispatch("fetchUserBetgamePoints", this.$store.state.betgameId)
      this.$store.dispatch("fetchQuestionRankings", this.$store.state.betgameId)
      this.matchdayrankings = {}
      this.rankings = []
      const users = this.$store.state.betgameUsers
      const betgame = this.$store.state.betgame
      const matches = this.$store.state.matches
      const questions = this.$store.state.questions.filter(q => parseInt(q.fk_betgame) === parseInt(this.$store.state.betgameId))
      for (var i=0; i < users.length; i++) {
        const user = users[i]
        var betcount = 0
        var betpoints = 0
        var exact = 0
        var goaldiff = 0
        var tendency = 0
        var nopoints = 0
        //console.log(JSON.stringify(user))
        for (var j=0; j < matches.length; j++) {
          const match = matches[j]
          //console.log(JSON.stringify(match))
          var matchpoints = 0
          var matchexact = 0
          var matchgoaldiff = 0
          var matchtendency = 0
          var matchnopoints = 0
          const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, user.fk_user)
          if(bet) {
            betcount += 1
            var goals_h = -1
            var goals_g = -1
            if(this.isNumeric(match.goalshome) && this.isNumeric(match.goalsguest)) {
              goals_h = match.goalshome
              goals_g = match.goalsguest
            } else if(this.isNumeric(match.livegoalshome) && this.isNumeric(match.livegoalsguest)) {
              goals_h = match.livegoalshome
              goals_g = match.livegoalsguest
            }
            if (bet.fk_match === match.id &&
                goals_h > -1 && goals_g > -1) {
              if (bet.goalshome === goals_h && bet.goalsguest === goals_g) {
                betpoints += betgame.points_bet_result
                matchpoints = betgame.points_bet_result
                matchexact = 1
                exact += 1
              } else if (bet.goalshome - bet.goalsguest === goals_h - goals_g) {
                betpoints += betgame.points_bet_goaldiff
                matchpoints = betgame.points_bet_goaldiff
                goaldiff += 1
                matchgoaldiff = 1
              } else if ((bet.goalshome > bet.goalsguest && goals_h > goals_g) ||
                  (bet.goalshome < bet.goalsguest && goals_h < goals_g) ||
                  (bet.goalshome === bet.goalsguest && goals_h === goals_g)) {
                betpoints += betgame.points_bet_tendency
                matchpoints = betgame.points_bet_tendency
                tendency += 1
                matchtendency = 1
              } else {
                nopoints += 1
                matchnopoints = 1
              }
            }
            
            // matchday finished??
            
            var mdrank = this.matchdayrankings[''+match.matchday]
            if(mdrank) {
              const userranks = mdrank.filter(q => parseInt(q.fk_user) === parseInt(user.fk_user))
              if(userranks && userranks.length > 0) {
                userranks[0].points_total += matchpoints
                userranks[0].betcount += 1
                userranks[0].exact += matchexact
                userranks[0].goaldiff += matchgoaldiff
                userranks[0].tendency += matchtendency
                userranks[0].no_points += matchnopoints
              } else {
                const userrank = {fk_betgame: parseInt(this.$store.state.betgameId),
                  fk_competition: parseInt(this.$store.state.leagueId),
                  matchday: match.matchday, fk_user: user.fk_user, user_name:user.nickname,
                  points_total: matchpoints, exact: matchexact, goaldiff: matchgoaldiff, 
                  tendency: matchtendency, no_points: matchnopoints, betcount: 1}
                mdrank.push(userrank)
              }
            } else {
              const userrank = {fk_betgame: parseInt(this.$store.state.betgameId),
                fk_competition: parseInt(this.$store.state.leagueId),
                matchday: match.matchday, fk_user: user.fk_user, user_name:user.nickname,
                points_total: matchpoints, exact: matchexact, goaldiff: matchgoaldiff, 
                tendency: matchtendency, no_points: matchnopoints, betcount: 1}
              this.matchdayrankings[''+match.matchday] = [userrank]
            }
          } 
        } // end matches
        
        this.rankings.push({position: i+1, fk_betgame: parseInt(this.$store.state.betgameId),
          fk_competition: parseInt(this.$store.state.leagueId), fk_user: user.fk_user,
          user_name: user.nickname, points_total: betpoints,
          bet_points: betpoints, question_points: 0, points_correction: 0,
          matchday_wins: 0, exact: exact, goaldiff:goaldiff, tendency: tendency,
          no_points: nopoints, betcount: betcount})
        
      } // end users

      var matchdaywins = 0
      for (const [key, value] of Object.entries(this.matchdayrankings)) {
        //console.log(key, value)
        const v = value.sort(function (a, b) {
          const pa = a.points_total
          const pb = b.points_total
          return pb - pa
        })
        var max = 0
        for (var j=0; j < value.length; j++) {
          const v = value[j]
          if(v.points_total > max) {
            max = v.points_total
          }
        }
        const userranks = value.filter(q => parseInt(q.points_total) === parseInt(max))
        for (var j=0; j < userranks.length; j++) {
          const v = userranks[j]
          v.matchdaywinner = 1
          const temp = this.rankings.filter(r => parseInt(r.fk_user) === parseInt(v.fk_user))
          if(temp && temp.length === 1) {
            temp[0].matchday_wins += 1
          }
        }
      }
      //console.log(JSON.stringify(this.matchdayrankings))
      
      
      
      
      this.rankings = this.rankings.sort(function (a, b) {
        const pa = a.points_total
        const pb = b.points_total
        return pb - pa
      })

      for (var j=0; j < this.rankings.length; j++) {
        this.rankings[j]["position"] = j + 1
      }

      /*
      this.matchdayrankings = {}
      const r1 = {position: 1, name: 'peter'}
      const r2 = {position: 2, name: 'otto'}
      const r3 = {position: 3, name: 'hans1'}
      this.matchdayrankings['1'] = [r1,r2,r3]
      const r5 = {position: 1, name: 'otto'}
      const r6 = {position: 2, name: 'hans1'}
      this.matchdayrankings['2'] = [r5,r6]
      const r7 = {position: 1, name: 'peter'}
      const r9 = {position: 2, name: 'hans1'}
      this.matchdayrankings['3'] = [r7,r9]
      const r10 = {position: 2, name: 'peter'}
      const r11 = {position: 3, name: 'otto'}
      const r12 = {position: 1, name: 'hans1'}
      this.matchdayrankings['4'] = [r10,r11,r12]
    */
          
      //console.log(JSON.stringify(this.matchdayrankings))
      //return sorted
    },

    computed: {

        /*
        
        [{"fk_betgame":24301,"fk_competition":1,"matchday":11,"fk_user":10180,"user_name":"Lennard","points_total":10,"exact":2,"goaldiff":1,"tendency":2,"no_points":4,"betcount":9}, 
 {"fk_betgame":24301,"fk_competition":1,"matchday":11,"fk_user":10056,"user_name":"olaf","points_total":8,"exact":2,"goaldiff":0,"tendency":2,"no_points":5,"betcount":9}, 
 {"fk_betgame":24301,"fk_competition":1,"matchday":11,"fk_user":10061,"user_name":"tom","points_total":6,"exact":1,"goaldiff":1,"tendency":1,"no_points":6,"betcount":9}, 
 {"fk_betgame":24301,"fk_competition":1,"matchday":11,"fk_user":93,"user_name":"leha","points_total":6,"exact":0,"goaldiff":1,"tendency":4,"no_points":4,"betcount":9}, 
 {"fk_betgame":24301,"fk_competition":1,"matchday":11,"fk_user":10063,"user_name":"vera","points_total":3,"exact":1,"goaldiff":0,"tendency":0,"no_points":8,"betcount":9}, 
 {"fk_betgame":24301,"fk_competition":1,"matchday":11,"fk_user":10187,"user_name":"la ola","points_total":3,"exact":0,"goaldiff":1,"tendency":1,"no_points":7,"betcount":9}, 
 {"fk_betgame":24301,"fk_competition":1,"matchday":11,"fk_user":10052,"user_name":"jens","points_total":2,"exact":0,"goaldiff":0,"tendency":2,"no_points":7,"betcount":9}]
 
        [{"fk_user":93,"fk_match":17502,"fk_betgame":24301,"goalshome":2,"goalsguest":1}, 
 {"fk_user":93,"fk_match":17503,"fk_betgame":24301,"goalshome":2,"goalsguest":3}, 
 {"fk_user":93,"fk_match":17504,"fk_betgame":24301,"goalshome":1,"goalsguest":2}, 
 {"fk_user":93,"fk_match":17505,"fk_betgame":24301,"goalshome":3,"goalsguest":1}, 
 {"fk_user":93,"fk_match":17506,"fk_betgame":24301,"goalshome":3,"goalsguest":1}, 
 {"fk_user":93,"fk_match":17507,"fk_betgame":24301,"goalshome":2,"goalsguest":3}] 
 
        {"id":17378,"fk_team_home":438,"fk_team_guest":182,"fk_competition":139,"fk_season":13,
        "matchday":1,"matchdate":"2022-11-21T17:00:00","goalshome":0,"goalsguest":2,
        "livegoalshome":null,"livegoalsguest":null,"status":"","goalshome_120":null,"goalsguest_120":null,
        "goalshome_90":null,"goalsguest_90":null,"team_home":"Sénégal","team_guest":"Niederlande"}
        
        {"id":30199,"name":"WM22 1234567890","rules":"","description":"",
        "points_bet_result":3,"points_bet_goaldiff":2,"points_bet_tendency":1,
        "email":"lh@spm.spm","hide_ads":false,"fk_user":93,"is_public":false,"invitation_code":"48778915",
        "created_at":"2022-10-21T18:58:15.375227","icon":null,
        "bet_mode":1,"sort_mode":1,"primary_color":"#0C3EC1","button_color":"#0C3EC1","link_color":"#0C3EC1",
        "is_whitelabel":false,"privacy":null,"analytics":null,"
        type":1,"result_mode":3,
        "competition":{"id":139,"name":"WM 2022","type":"TOURNAMENT"},
        "season":{"id":13,"name":"2022/23","is_current":true}}
        
        return [{"position":1,"fk_betgame":30199,"fk_competition":139,"fk_user":10187,"user_name":"la ola","points_total":8,"bet_points":4,"question_points":4,"points_correction":0,"matchday_wins":0,"exact":0,"goaldiff":1,"tendency":2,"no_points":45,"betcount":48},
          {"position":2,"fk_betgame":30199,"fk_competition":139,"fk_user":10180,"user_name":"Lennard","points_total":5,"bet_points":5,"question_points":0,"points_correction":0,"matchday_wins":0,"exact":0,"goaldiff":2,"tendency":1,"no_points":1,"betcount":4},
          {"position":3,"fk_betgame":30199,"fk_competition":139,"fk_user":10056,"user_name":"olaf","points_total":4,"bet_points":4,"question_points":0,"points_correction":0,"matchday_wins":0,"exact":0,"goaldiff":1,"tendency":2,"no_points":45,"betcount":48},
          {"position":4,"fk_betgame":30199,"fk_competition":139,"fk_user":93,"user_name":"leha","points_total":2,"bet_points":2,"question_points":0,"points_correction":0,"matchday_wins":0,"exact":0,"goaldiff":0,"tendency":2,"no_points":7,"betcount":9},
          {"position":5,"fk_betgame":30199,"fk_competition":139,"fk_user":10052,"user_name":"jens","points_total":2,"bet_points":2,"question_points":0,"points_correction":0,"matchday_wins":0,"exact":0,"goaldiff":0,"tendency":2,"no_points":46,"betcount":48},
          {"position":6,"fk_betgame":30199,"fk_competition":139,"fk_user":10061,"user_name":"tom","points_total":2,"bet_points":2,"question_points":0,"points_correction":0,"matchday_wins":0,"exact":0,"goaldiff":0,"tendency":2,"no_points":46,"betcount":48},
          {"position":7,"fk_betgame":30199,"fk_competition":139,"fk_user":10062,"user_name":"ute","points_total":1,"bet_points":1,"question_points":0,"points_correction":0,"matchday_wins":0,"exact":0,"goaldiff":0,"tendency":1,"no_points":47,"betcount":48}]
          
         */
  
    },
    methods: {
      goBack() {
        if (this.$store.getters.loggedIn) {
          this.$router.push({ name: 'profile' }).catch(err => {})
        } else {
          if(this.hasHistory()) {
            this.$router.go(-1)
          } else {
            this.$router.push({ name: 'home' })
          }
        }
      }
    }
  }
  
</script>

<style lang="scss" scoped>
</style>
