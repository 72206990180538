<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <path d="M18,9 C19.1045695,9 20,9.8954305 20,11 L20,19 C20,20.1045695 19.1045695,21 18,21 L6,21 C4.8954305,21 4,20.1045695 4,19 L4,11 C4,9.8954305 4.8954305,9 6,9 L18,9 Z M12,11.5 C10.6192881,11.5 9.5,12.6192881 9.5,14 C9.5,15.3807119 10.6192881,16.5 12,16.5 C13.3807119,16.5 14.5,15.3807119 14.5,14 C14.5,12.6192881 13.3807119,11.5 12,11.5 Z" id="bg" fill-opacity="0.24"></path>
    <path d="M12,0 C15.2383969,0 17.8775718,2.56557489 17.9958615,5.77506174 L18,6 L18,7 C20.209139,7 22,8.790861 22,11 L22,11 L22,19 C22,21.209139 20.209139,23 18,23 L18,23 L6,23 C3.790861,23 2,21.209139 2,19 L2,19 L2,11 C2,8.790861 3.790861,7 6,7 L6,7 L6,6 C6,2.6862915 8.6862915,0 12,0 Z M18,9 L6,9 C4.8954305,9 4,9.8954305 4,11 L4,11 L4,19 C4,20.1045695 4.8954305,21 6,21 L6,21 L18,21 C19.1045695,21 20,20.1045695 20,19 L20,19 L20,11 C20,9.8954305 19.1045695,9 18,9 L18,9 Z M12,10.5 C13.9329966,10.5 15.5,12.0670034 15.5,14 C15.5,15.5852037 14.4461522,16.924268 13.0008388,17.3548109 L13,18 C13,18.5522847 12.5522847,19 12,19 C11.4477153,19 11,18.5522847 11,18 L11.0001626,17.3551091 C9.55433459,16.9248865 8.5,15.5855698 8.5,14 C8.5,12.0670034 10.0670034,10.5 12,10.5 Z M12,12.5 C11.1715729,12.5 10.5,13.1715729 10.5,14 C10.5,14.8284271 11.1715729,15.5 12,15.5 C12.8284271,15.5 13.5,14.8284271 13.5,14 C13.5,13.1715729 12.8284271,12.5 12,12.5 Z M12,2 C9.85780461,2 8.10892112,3.68396847 8.00489531,5.80035966 L8,6 L8,7 L16,7 L16,6 C16,3.790861 14.209139,2 12,2 Z" id="outline" fill-rule="nonzero"></path>
  </svg>
</template>

<script>
export default {
  name: 'LoginIcon'
}
</script>

<style lang="scss" scoped>
svg {
  fill: var(--color-primary);
}
</style>
