/* eslint-disable */
<template>
  <div class="schedule" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">
  
    <keep-alive>
      <NavigationBar id="leagueTableNavigationBarId" ref="leagueTableNavigationBarRef">
        <template v-slot:center>
          {{ $t('menu.league_table') }}
        </template>
        <template v-slot:right>
        </template>
      </NavigationBar>
    </keep-alive>

    <!--
    <div v-if="this.$store.state.betgame.competition.id === 13" class="table-view">
      <div class="table-view-header">
        {{ $t('menu.league_table_at_m') }}
      </div>
      <LeagueTable :positions="positions_at_m" groupAt="m"></LeagueTable>
    </div>

    <div v-if="this.$store.state.betgame.competition.id === 13" class="table-view">
      <div class="table-view-header">
        {{ $t('menu.league_table_at_q') }}
      </div>
      <LeagueTable :positions="positions_at_q" groupAt="q"></LeagueTable>
    </div>

    <div v-if="this.$store.state.betgame.competition.id === 13" class="table-view">
      <div class="table-view-header">
        {{ $t('menu.league_table_at') }}
      </div>
      <LeagueTable :positions="positions"></LeagueTable>
    </div>
    -->
    
    <!--
    <div v-if="this.$store.state.betgame.competition.id === 115" class="table-view">
      <div class="table-view-header">
        {{ $t('menu.league_table_ch_m') }}
      </div>
      <LeagueTable :positions="positions_at_m" groupAt="m"></LeagueTable>
    </div>

    <div v-if="this.$store.state.betgame.competition.id === 115" class="table-view">
      <div class="table-view-header">
        {{ $t('menu.league_table_ch_q') }}
      </div>
      <LeagueTable :positions="positions_at_q" groupAt="q"></LeagueTable>
    </div>

    <div v-if="this.$store.state.betgame.competition.id === 115" class="table-view">
      <div class="table-view-header">
        {{ $t('menu.league_table_ch') }}
      </div>
      <LeagueTable :positions="positions"></LeagueTable>
    </div>
    -->
    
    <LeagueTable :positions="positions"></LeagueTable>
    
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LeagueTable from '../components/LeagueTable'
import NavigationBar from './../components/NavigationBar'

export default {
  components: { LeagueTable, NavigationBar },
  created() {
    if (this.$store.state.leagueId) {
      this.$store.dispatch('fetchTable', this.$store.state.leagueId)
    }
    if (this.$store.state.teams.length === 0) {
      this.$store.dispatch('fetchTeams', this.$store.state.betgameId)
    }
  },
  computed: mapState(['positions', 'positions_at_q', 'positions_at_m']),
  methods: {
    openMenu () {
      this.$modal.show('betgame-menu')
    },
    showSchedule() {
      this.$router.push({ name: 'schedule' })
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
