<template>
  <div class="betgameEditUser" id="betgameEditUser">

    <NavigationBar>
      <template v-slot:left>
        <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
      </template>
      <template v-slot:center>
        {{ titleText }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>

    <div v-if="betgameUser" class="input-container">
      <div class="input-element">
        <input v-model="betgameUser.nickname" type="email" name="usernickname" id="usernickname" placeholder="usernickname" value disabled />
        <label for="usernickname">{{ $t('user_name') }}</label>
      </div>
      <div class="input-element">
        <input v-on:click="coypEmailClicked" v-clipboard:copy="betgameUser.email" v-model="betgameUser.email" type="email" name="useremail" id="useremail" placeholder="useremail" value />
        <label for="useremail">{{ $t('email') }}</label>
      </div>
      <button v-on:click="contactUser">{{ $t('betgame_settings.button_contact_participant_text') }}</button>
    </div>

    <div class="input-container">
      <div class="input-header">
        {{ $t('betgame_settings.point_correction_headline') }}
      </div>
      <div class="input-infotext">
        {{ $t('betgame_settings.point_correction_description_1') }}<br>
        {{ $t('betgame_settings.point_correction_description_2') }}<br>
        {{ $t('betgame_settings.point_correction_description_3') }}
      </div>
      <div class="input-stepper">
        <div @click="pointsUp" class="input-stepper-button input-stepper-button-up"></div>
        <input id="points1" max="99" min="-99" v-model.number="points"
               step="1" placeholder="-" type="number">
        <div @click="pointsDown" class="input-stepper-button input-stepper-button-down"></div>
        <label for="points1">{{ $t('points') }}</label>
      </div>
      <button v-on:click="savePointsCorrection">{{ $t('betgame_settings.button_save_point_correction') }}</button>
    </div>
    
    <div class="input-container">
      <div class="input-header">
        {{ $t('betgame_settings.remove_participant_from_betgame_title') }}
      </div>
      <div class="input-infotext">
        {{ $t('betgame_settings.remove_participant_from_betgame_text_1') }}<br>
        {{ $t('betgame_settings.remove_participant_from_betgame_text_2') }}  
      </div>
      <button v-on:click="removeUser">{{ $t('betgame_settings.button_remove_participant_text') }}</button>
    </div>

  </div>
</template>

<script>

import NavigationBar from './../components/NavigationBar'
import { TOAST_DURATION } from '../main'

export default {
  name: 'BetgameEditUser',
  components: { NavigationBar },
  data () {
    return {
      betgameUser: null,
      points: 0
    }
  },
  created () {
    this.betgameUser = this.$route.params.user
    if(this.$store.state.userBetgamePoints && this.betgameUser) {
      const ubp = this.$store.state.userBetgamePoints.filter(s => s.fk_user === this.betgameUser.fk_user)
      if (ubp.length === 1) {
        this.points = ubp[0].points
      }
    }
  },
  mounted () {
    if (this.betgameUser === null || this.betgameUser === undefined) {
      this.goBack()
    }
  },
  computed: {
    titleText() {
      if(this.betgameUser) {
        return this.betgameUser.nickname
      }
      return this.$i18n.t('betgame_settings.betgame_participants')
    }
  },
  methods: {
    savePointsCorrection() {
      if(this.points !== 0 && this.betgameUser && this.points <= 200 && this.points >= -200) {
        const data = {
          'fk_user': parseInt(this.betgameUser.fk_user),
          'points': parseInt(this.points),
          'fk_betgame': parseInt(this.$store.state.betgameId)
        }
        this.$store.dispatch('updateUserBetgamePoints', data).then(response => {
          if(response.status >= 200 && response.status <= 204) {
            this.$toast.open({ message:  this.$i18n.t('betgame_settings.point_correction_saved'),
              position: 'top',
              duration: TOAST_DURATION,
              type: 'success'
            })
          } else {
          }
        }, error => {
          
        })
      } else if(this.points === 0 && this.betgameUser) {
        const data = {
          'fk_user': parseInt(this.betgameUser.fk_user),
          'points': 0,
          'fk_betgame': parseInt(this.$store.state.betgameId)
        }
        this.$store.dispatch('deleteUserBetgamePoints', data).then(response => {
          if(response.status >= 200 && response.status <= 204) {
            this.$toast.open({ message:  this.$i18n.t('betgame_settings.point_correction_saved'),
              position: 'top',
              duration: TOAST_DURATION,
              type: 'success'
            })
          } else {
          }
        }, error => {

        })
      } else {
        this.$toast.open({
          message: this.$i18n.t('save_changes_error'),
          position: 'top',
          duration: TOAST_DURATION,
          type: 'error'
        })
      }
    },
    pointsUp() {
      if(this.points < 200) {
        this.points += 1
      } else {
        this.points = 200
      }
    },
    pointsDown() {
      if(this.points > -200) {
        this.points -= 1
      } else {
        this.points = -200
      }
    },
    coypEmailClicked() {
      this.$toast.open({ message:  this.$i18n.t('betgame_settings.email_copied_to_clipboard'), position: 'top', duration: 1000, type: 'info' })
    },
    contactUser () {
      if(this.betgameUser && this.betgameUser.email) {
        window.location.href = 'mailto:' + this.betgameUser.email
      }
    },
    removeUser () {
      this.$log.debug('removeUser')
      var r = confirm(this.$i18n.t('betgame_settings.confirm_remove_participant', {participant: this.betgameUser.nickname}))
      if (r === true) {
        const that = this
        this.$store.dispatch('removeUserFromBetgame', {
          betgame: this.$store.state.betgameId,
          user: this.betgameUser.fk_user
        }).then(() => {
          that.$router.push('/betgameeditusers')
        }).catch(function (error) {
          that.$toast.open({ message: that.$i18n.t('error'), position: 'top', duration: 2 * TOAST_DURATION, type: 'error' })
        })
      }
    },
    goBack () {
      this.$router.push('/betgameeditusers')
    },
    isNullOrUndefined (data) {
      return data === null || data === undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.input-container {
  margin: 16px auto 0;
}

button {
  margin-top: 16px;
  margin-bottom: 16px;
}
</style>
