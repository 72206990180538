<template>
  <div class="validateRegistration" style="justify-content: flex-start; margin-top: 32px;">

    <NavigationBar >
      <template v-slot:left>
        <img v-on:click="goBack" class="back" src="../assets/icons/ic_16_back.svg">
      </template>
      <template v-slot:center>
        {{ $t('register.validate_registration') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>
  
    <h1>{{ $t('register.validation_code') }}</h1>
    
    <div v-if="email">
      <p>Wir haben dir an <b>{{ email }}</b> einen Code geschickt, den du zum Abschließen deiner Registierung in das unten stehende Feld eingeben musst.</p>
    </div>
    <div v-else>
      <p>{{ $t('register.enter_email_and_validation_code') }}</p>
    </div>
    
    <form @submit.prevent="validateRegistration">

      <div class="input-container">
  
        <div v-if="!email" class="input-element">
          <input v-model="userEmail" type="email" name="userEmail" id="userEmail" placeholder="email" value />
          <label for="userEmail">{{ $t('email') }}</label>
        </div>
        
        <div class="input-element">
          <input v-model="registrationCode" type="text" name="registrationCode" id="registrationCode" placeholder="000000" value maxlength="6" />
        </div>

        <div class="input-button-row">
          <button type="submit" name="button">
            {{ $t('register.finish_registration') }}
          </button>
        </div>

      </div>

    </form>
    
    <div class="forgot-password">
      <span>{{ $t('register.validation_email_not_received') }}</span>
      <a href="#" v-on:click="resendEmail" :disabled="isDisabled">{{ $t('register.resend_validation_email') }}</a>
    </div>

    <div v-if="userEmail && this.$store.getters.loggedIn" class="forgot-password" style="padding-top: 0px;">
      <span>{{ $t('register.wrong_email') }}</span>
      <a href="#" v-on:click="updateUserEmail">{{ $t('register.update_wrong_email') }}</a>
    </div>
    
  </div>
</template>

<script>
  
  import axios from 'axios'
  import NavigationBar from './../components/NavigationBar'
  import { API_TIMEOUT, TOAST_DURATION } from '../main'
  import Vue from 'vue'
  
  export default {
    name: 'ValidateRegistration',
    props: {
      email: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        registrationCode: '',
        isDisabled: false,
        userEmail: ''
      }
    },
    metaInfo: {
      title: 'Registrierungs-Code eingeben',
      meta: [
        { vmid: 'keywords', name: 'keywords', content: 'Registrierungs-Code eingeben, Anmelden, Registrierungs-Code erhalten' },
        { vmid: 'description', name: 'description', content: 'Registrierungs-Code eingeben' },
      ]
    },
    components: { NavigationBar },
    beforeCreate() {
      Vue.$log.debug('ValidateRegistration beforeCreate')
    },
    created () {
      Vue.$log.debug('ValidateRegistration created ')
    },
    mounted() {
      Vue.$log.debug('ValidateRegistration mounted ')
      if (this.$store.getters.loggedIn && this.$store.state.user.status === 'active') {
        this.$router.push({ name: 'dashboard' }).catch(err => {})
      }
      this.userEmail = this.email
      if(this.$route.query.email) {
        this.userEmail = this.$route.query.email
      } else if(this.$store.state.user && this.$store.state.user.email) {
        this.userEmail = this.$store.state.user.email
      }
      if(this.$route.query.code) {
        this.registrationCode = this.$route.query.code
        if(this.userEmail && this.registrationCode) {
          this.$store
          .dispatch(
                  'validateRegistration',
                  {
                    email: String(this.userEmail).toLowerCase(),
                    token: this.registrationCode
                  }
          )
          .then(() => {
            this.$toast.open({ message: this.$i18n.t('register.registration_finished'), position: 'top', duration: TOAST_DURATION, type: 'success' })
            if(this.$route.query.betgameid) {
              this.$router.push({ name: 'home', query: { email: this.userEmail, betgameid: this.$route.query.betgameid } })
            } else {
              this.$router.push({ name: 'home', query: { email: this.userEmail } })
            }
          })
        } else {
          alert(this.$i18n.t('register.enter_email_and_validation_code'))
        }
      }
    },
    methods: {
      updateUserEmail() {
        this.$router.push('/profilechangeemail')
      },
      resendEmail() {
        if(this.userEmail) {
          this.isDisabled = true
          const that = this
          axios.post(this.getBGValue('spmau') + '/rpc/resend_validation_email', {
            email: String(this.userEmail).toLowerCase(),
          }, { timeout: API_TIMEOUT } )
          .then(({ data }) => {
            alert(that.$i18n.t('register.email_sent'))
          }).catch(function (error) {
            alert(that.$i18n.t('register.email_send_error'))
          })
        } else {
          alert(this.$i18n.t('register.enter_validation_email'))
        }
      },
      validateRegistration() {
        if (this.$store.getters.loggedIn && this.$store.state.user && this.$store.state.user.state === 'active' &&
            this.userEmail && this.$store.state.user.email.toLowerCase() === this.userEmail.trim().toLowerCase()) {
          this.$toast.open({message: this.$i18n.t('register.registration_finished_good_luck'), position: 'top', duration: TOAST_DURATION, type: 'success' })
          if(this.$store.state.betgameId) {
            this.$router.push({ name: 'dashboard' }).catch(err => {
            })
          } else {
            this.$router.push({ name: 'mybetgames' }).catch(err => {
            })
          }
        } else {
          if (this.userEmail && this.registrationCode) {
            const that = this
            this.$store
            .dispatch(
                'validateRegistration',
                {
                  email: String(this.userEmail).toLowerCase(),
                  token: this.registrationCode
                }
            )
            .then(() => {
              this.$toast.open({message: this.$i18n.t('register.registration_finished'), position: 'top', duration: TOAST_DURATION, type: 'success' })
              if (this.$store.getters.loggedIn) {
                this.$router.push({ name: 'dashboard' })
              } else {
                this.$router.push({ name: 'home' })
              }
            }).catch(function (error) {
              alert(that.$i18n.t('register.validation_error_check_email_and_password'))
              //that.$router.push({ name: 'home', query: { email: that.userEmail }})
            })
          } else {
            alert(this.$i18n.t('register.enter_email_and_validation_code'))
          }
        }
      },
      goBack() {
        if(this.$store.getters.loggedIn) {
          if(this.hasHistory()) {
            this.$router.go(-1)
          } else {
            this.$router.push('/profile')
          }
        } else {
          this.$router.push({ name: 'login' }).catch(err => {
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .validateRegistration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 64px);
    padding: 64px 16px 0 16px;

    h1 {
        text-align: center;
    }

    p {
        text-align: center;
        margin-bottom: 26px;
    }
  }

  .forgot-password {
    width: 100%;
    text-align: center;
    color: $colorGreyDark;
    padding: 20px 0;

    a {
      font-weight: 500;
      text-decoration: none;
      margin-left: 6px;
      padding: 12px 0;
    }
  }

  .input-element{
    display: flex;
    justify-content: center;
  }

  #registrationCode {
    width: 253px;
    letter-spacing: 18px;
    margin-right: -18px;
    font-size: 40px;
    font-weight: 600;
    font-family: 'Courier New';
    border-bottom: 0;

    &::placeholder {
      opacity: .48;
    }
  }

</style>
