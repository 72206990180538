<template>
  <div class="updatePushToken" id="updatePushToken" style="justify-content: flex-start; margin-top: 32px;">
    
    <NavigationBar>
      <template v-slot:left>
        <img v-on:click="goBack" class="back" src="../assets/icons/ic_16_back.svg">
      </template>
      <template v-slot:center>
        {{ $t('profile.push_token') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>
      
    <h1>{{ $t('profile.update_push_token') }}</h1>
    <p>
      {{ $t('profile.update_push_token_text') }}
    </p>
    <p style="overflow-wrap: break-word;">
        {{ this.getPushToken() }}
    </p>
    <form @submit.prevent="updateToken">
      
      <div class="input-container">
          
        <div class="input-button-row">
          <button type="submit" name="button">
            {{ $t('profile.update_push_token') }}
          </button>
        </div>
      
      </div>
  
      <ul class="error-container">
        <li v-for="error in errors">{{ error }}</li>
      </ul>
      
    </form>
      
  </div>
</template>

<script>
  
  import NavigationBar from './../components/NavigationBar'
  import { TOAST_DURATION } from '../main'
  import moment from 'moment/moment'
  
  export default {
    components: { NavigationBar },
    data() {
      return {
        errors: []
      }
    },
    computed: {

    },
    methods: {
      getPushToken() {
        try {
          if (window.TeamtipAppInterface !== undefined && window.TeamtipAppInterface !== null) {
            return window.TeamtipAppInterface.getPushToken()
          } else if (window.webkit && window.webkit.messageHandlers.TeamtipAppInterface !== undefined && window.webkit.messageHandlers.TeamtipAppInterface !== null) {
            const b = window.webkit.messageHandlers.TeamtipAppInterface.postMessage('getPushToken')
            return window.localStorage.getItem('pushToken')
          } else {
            return ''
          }
        } catch (e) {
          return ''
        }
      },
      goBack() {
        this.$router.push('/profile')
      },
      updateToken() {
        this.errors = []
        if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
          const index = navigator.userAgent.lastIndexOf('TEAMTIP/')
          if(index > -1 && this.$store.state.token && this.$store.state.user) {
            const version = navigator.userAgent.substr(index + 8)
            const pt = this.getPushToken()
            const os = navigator.userAgent.lastIndexOf('iPhone') > -1 ? 'iOS' : 'android'
            if(version && version.trim() !== '' && (this.$store.state.appVersion === '' || this.$store.state.appVersion !== version)) {
              if(pt !== '' && pt !== 'undefined') {
                const data = {
                  os: os,
                  push_token: pt,
                  fk_user: this.$store.state.user.id,
                  app_version: version,
                  last_active: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
                  installation_date: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss')
                }
                this.$store.dispatch('postUserApp', data)
                .then(response => {
                  this.setBGValue('spmsala', new Date())
                  this.$toast.open({ message: this.$i18n.t('save_changes_success'),
                    position: 'top',
                    duration: TOAST_DURATION,
                    type: 'success'
                  })
                })
                .catch(function (error) {
                  this.errors.push('' + error)
                })
              } else {
                this.errors.push('pt ' + pt)
              }
            }
          } else {
            this.errors.push('version ' + version)
          }
        } else {
          this.errors.push('navigator.userAgent.indexOf')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .updatePushToken {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc((var(--vh, 1vh) * 100) - 64px);
    padding: 64px 16px 0 16px;
    max-width: 576px;
    margin: 0px auto;

    img.illustration {
      max-width: 180px;
      margin: 0 auto 19px;
    }
    
    h1 {
      text-align: center;
    }
    p {
      text-align: center;
      margin-bottom: 42px;
    }
  }
</style>
