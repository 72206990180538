<template>
  <div class="report">

    <keep-alive>
      <NavigationBar id="rulesNavigationBarId" ref="rulesNavigationBarRef">
        <template v-slot:left>
          <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
        </template>
        <template v-slot:center>
          {{ $t('feed.report_message') }}
        </template>
      </NavigationBar>
    </keep-alive>

    <!-- Selected Message -->
    <div class="report__message">
      <ChatAnswer :username="this.reportedItem.username" :text="this.reportedItem.text"></ChatAnswer>
    </div>

    <!-- Content -->
    <div class="report__content">

      <!-- Begründung -->
      <div class="input-container">
        <div class="input-header">
          {{ $t('feed.report_message_reason') }}
          <div v-on:click="openReportReason" class="input-header-icon">
            <HintIcon></HintIcon>
          </div>
        </div>
        <div class="input-infotext">
          {{ $t('feed.report_message_hint') }}
        </div>
        <div class="input-element input-element--outline">
          <textarea v-model="reportText" name="reportReason" type="text" id="report-reason" placeholder="report-reason" />
          <label for="report-reason">{{ $t('feed.reason_max_length') }}</label>
        </div>
      </div>

      <!-- Kontaktdaten -->
      <div class="input-container">
        <div class="input-header">
          {{ $t('feed.reporter_contact') }}
          <div v-on:click="openReportUser" class="input-header-icon">
            <HintIcon></HintIcon>
          </div>
        </div>
        <div class="input-infotext">
          {{ $t('feed.reporter_contact_hint') }}
        </div>
        <div class="input-element">
          <input v-model="reporterName" name="reportName" type="text" id="report-name" placeholder="report-name" />
          <label for="report-name">{{ $t('feed.reporter_name') }}</label>
        </div>
        <div class="input-element">
          <input v-model="reporterEmail" name="reportMail" type="text" id="report-mail" placeholder="report-mail" />
          <label for="report-mail">{{ $t('feed.reporter_email') }}</label>
        </div>
      </div>

      <button v-on:click="reportClicked" :disabled="this.reportText.length === 0 || this.reporterName.length === 0 || this.reporterEmail.length === 0">{{ $t('feed.send_message') }}</button>

    </div>

    <modal class="modal" name="report-reason" id="report-reason" :scrollable="true" width="100%" height="auto">
      <div class="modal-header">
        <div class="modal-header-left"></div>
        <div class="modal-header-center"></div>
        <div class="modal-header-right">
          <button
              type="button"
              class="btn-close"
              @click="$modal.hide('report-reason')"
              aria-label="Close modal">
          </button>
        </div>
      </div>
      <div class="modal-body">
        report-reason
      </div>
    </modal>

    <modal class="modal" name="report-user" id="report-user" :scrollable="true" width="100%" height="auto">
      <div class="modal-header">
        <div class="modal-header-left"></div>
        <div class="modal-header-center"></div>
        <div class="modal-header-right">
          <button
              type="button"
              class="btn-close"
              @click="$modal.hide('report-user')"
              aria-label="Close modal">
          </button>
        </div>
      </div>
      <div class="modal-body">
        report-user
      </div>
    </modal>
    
  </div>
</template>

<script>
import NavigationBar from '../components/NavigationBar'
import ChatAnswer from '../components/chat/ChatAnswer'
import HintIcon from './../components/icons/HintIcon'
import Vue from 'vue'

export default {
  name: 'ReportCreate',
  components: { NavigationBar, ChatAnswer, HintIcon },
  data () {
    return {
      reportedItem: null,
      reporterName: this.$store.state.user.nickname,
      reporterEmail: this.$store.state.user.email,
      reportText: ''
    }
  },
  beforeCreate () {
  },
  created () {
    if(this.$route.params && this.$route.params.item) {
      Vue.$log.debug('ReportCreate created ' + JSON.stringify(this.$route.params.item))
      this.reportedItem = this.$route.params.item
    } else {
      this.$router.push('/feed/chat')
    }
  },
  mounted () {
  },
  methods: {
    reportClicked() {
      this.$store.dispatch('chatReportMessage', {
        reason: this.reportText,
        name: this.reporterName,
        email: this.reporterEmail,
        messageid: this.reportedItem.messageid })
      .then(({ data }) => {
        this.$router.push('/report/success')
      })
      .catch(function (error) {
      })
    },
    goBack () {
      this.$router.push('/feed/chat')
    },
    openReportReason() {
      this.$modal.show('report-reason')
    },
    openReportUser() {
      this.$modal.show('report-user')
    },
  },
  computed: {
    
  }
}
</script>

<style lang="scss">
  .report {

    &__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding-top: 24px;
    }

    &__message {
      position: sticky;
      z-index: 100;
      top: 63px;
      padding: 8px 16px 16px;
      background: $colorWhite;
      margin: -1px -16px 0;
      box-sizing: border-box;
      box-shadow: inset 0 -1px 0 0 rgba($colorGreyDark, .16);

      @media screen and (min-width: 576px) {
        width: 100vw;
        margin: -1px calc((100vw - 576px) / -2);
      }

      .chat__answer {
        margin: 0 auto;

        @media screen and (min-width: 768px) {
          max-width: 408px;
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  .input-container {
    margin-bottom: 16px;

    @media screen and (min-width: 576px) {
      margin-top: 0;
    }
  }

  .input-element--outline {
    margin: 8px 0 12px;
  }

  textarea {
    min-height: 128px;
  }

  button {
    max-width: 432px;
    margin: 0 auto;
  }
</style>
