/* eslint-disable */
<template>
  <div :key="componentKey" class="schedule" v-bind:style="{ paddingBottom: '64px' }">
  
    <keep-alive>
      <NavigationBar id="specialQuestionNavigationBarId" ref="specialQuestionNavigationBarRef">
        <template v-slot:center>
          {{ $t('menu.special_questions') }}
        </template>
        <template v-slot:right>
        </template>
      </NavigationBar>
    </keep-alive>
    
    <h1>{{ $t('special_questions.title') }}</h1>
    <p>{{ $t('special_questions.text') }} {{ $t('special_questions.text_1') }}</p>

    
  
    <div v-for="(question, index) in questions" :key="question.id">
      <SpecialQuestion :question="question"></SpecialQuestion>
    </div>
  
    <div v-if="questions.length === 0">
      <p>{{ $t('special_questions.no_questions_added') }}</p>
      <p><button v-on:click="mailToBetgameAdmin">{{ $t('user_betgame_settings.button_contact_betgame_admin') }}</button></p>
    </div>
  
    <small v-if="lastAnswerSaveSuccess" style="margin-top: -5px">
      {{ $t('last_data_save_success') }}: {{ lastAnswerSaveSuccess | formatDateDayMonth }} um {{ lastAnswerSaveSuccess | formatTime }}
    </small>
    
    <!--
    <div class="list p-top-none">
      <div class="table-view-title">
        Wer wird Europameister?
      </div>
      <div class="question">
        <select id="question-1" name="question-1">
          <option hidden disabled selected>Bitte wählen</option>
          <option value="germany">Deutschland</option>
          <option value="england">England</option>
          <option value="france">Frankreich</option>
        </select>
      </div>
    </div>

    <div class="list p-top-none">
      <div class="table-view-title">
        Wer wird Europameister? Eine Frage mit einem etwas längeren Text, weil das vom User so erfasst werden kann.
      </div>
      <div class="question">
        <select id="question-2" name="question-2">
          <option hidden disabled selected>Bitte wählen</option>
          <option value="germany">Deutschland</option>
          <option value="england">England</option>
          <option value="france">Frankreich</option>
        </select>
      </div>
    </div>
    -->
    
    <!-- Sobald keine Eingabe mehr möglich ist, wird das Select-Element ausgeblendet und stattdessen eine Anzeige mit "Dein Tipp" und "Ergebnis" angezeigt -->
    <!--
    <div class="list p-top-none">
      <div class="table-view-title">
        Wer wird Europameister? (Ergebnis offen)
      </div>
      <div class="table-view-cell">
        <div class="table-view-cell-label">
          Dein Tipp
        </div>
        <div class="table-view-cell-value">
          England
        </div>
      </div>
      <div class="table-view-cell">
        <div class="table-view-cell-label">
          Ergebnis
        </div>
        <div class="table-view-cell-value">
          Ausstehend
        </div>
      </div>
    </div>
    -->
    
    <!-- Steht das Ergebnis fest, muss dem DIV ".list" die Klasse ".bet_correct" oder ".bet_wrong" zugeteilt werden -->
    <!--
    <div class="list p-top-none bet_correct">
      <div class="table-view-title">
        Wer wird Europameister?
      </div>
      <div class="table-view-cell">
        <div class="table-view-cell-label">
          Dein Tipp
        </div>
        <div class="table-view-cell-value">
          England
        </div>
      </div>
      <div class="table-view-cell">
        <div class="table-view-cell-label">
          Ergebnis
        </div>
        <div class="table-view-cell-value">
          England
        </div>
      </div>
    </div>
    -->
    
    <!--
    <small>
        Zuletzt gespeichert: {Datum} um {Uhrzeit} Uhr
    </small>
    -->
    
  </div>
</template>

<script>
import NavigationBar from './../components/NavigationBar'
import SpecialQuestion from './../components/SpecialQuestion'

export default {
  name: 'SpecialQuestions',
  components: { NavigationBar, SpecialQuestion },
  data () {
    return {
      componentKey: 0
    }
  },
  created() {
    //if (this.$store.state.questions === null || this.$store.state.questions.length === undefined) {
    const that = this
      this.$store.dispatch('fetchQuestions', []).then(function (response) {
        that.componentKey += 1
      })
      //this.$store.dispatch('fetchUserAnswers', []).then(function (response) {
      //  that.componentKey += 1
      //})
    //}
  
    if (this.$store.state.teams.length === 0) {
      this.$store.dispatch('fetchTeams', this.$store.state.leagueId)
    }
  },
  methods: {
    mailToBetgameAdmin() {
      window.location.href = 'mailto:' + this.mailToTarget
    }
  },
  computed: {
    lastAnswerSaveSuccess() {
      return this.$store.state.lastAnswerSaveSuccess
    },
    mailToTarget() {
      return this.$store.state.betgame.email
    },
    questions() {
      return this.$store.state.questions.filter(q => parseInt(q.fk_betgame) === parseInt(this.$store.state.betgameId))
    },
    userId() {
      return this.$store.state.user.id
    }
  },
}
</script>

<style lang="scss" scoped>
.schedule {
  max-width: 576px;
  margin: 43px auto 16px;

  h1 {
    text-align: center;
  }

  p {
    text-align: center;
    margin-bottom: 34px;
  }
}

.list {
  margin-bottom: 16px;

  .table-view-cell:last-child {
    border-bottom: 0;
  }

  &.bet_correct {
      background: #EEFDF5;
  }
  &.bet_wrong {
      background: #FFF1F0;
  }
}

.question {
  position: relative;
  
  select {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: $colorGreyDark;
    width: 100%;
    max-width: 100%; /* useful when width is set to anything other than 100% */
    box-sizing: border-box;
    margin: 0;
    border-radius: 0;
    border: 0;
    background: none;
    padding: 11px 0 12px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    &::-ms-expand {
      display: none;
    }

    option {
      font-size: 10px;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 16px;
    height: 16px;
    transform: translateY(-50%);
    background: url('../assets/icons/ic_16_collapse_down.svg');
  }
}

small {
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: $colorGreyMid;
    text-align: center;
    padding: 4px 0;
}

</style>
