<template>
    <div class="bet-box">
        <div class="bet-box-item">
            <a v-on:click="linkClicked('Favoritensieg')" :href="getFavWinsLink" target="_blank" class="externallink">
                <div class="bet-box-item-label">
                    Sieg {{ getTeamName(favoriteTeamId, getFavoriteTeamName(match.favoriteTeamId)) }}
                </div>
                <div class="bet-box-item-number">
                    {{ getLowerOdds }}
                </div>
            </a>
        </div>
        <div class="bet-box-item">
            <a v-on:click="linkClicked('> 2,5 Tore')" :href="getGoalsGT2Link" target="_blank" class="externallink">
                <div class="bet-box-item-label">
                    Mehr als 2,5 Tore
                </div>
                <div class="bet-box-item-number">
                    {{ getMatchBet.goals_gt_2 }}
                </div>
            </a>
        </div>
        <div class="bet-box-item">
            <a v-on:click="linkClicked('Kein Favoritensieg')" :href="getNotFavWinsLink" target="_blank" class="externallink">
                <div class="bet-box-item-label">
                    X oder Sieg {{ getTeamName(notFavoriteTeamId, getFavoriteTeamName(match.notFavoriteTeamId)) }}
                </div>
                <div class="bet-box-item-number">
                    {{ getMatchBet.not_favorite_wins }}
                </div>
            </a>
        </div>
        <div class="bet-box-item">
            <a v-on:click="linkClicked('Bwin Seite')" :href="getLink" target="_blank" class="externallink">
                <div class="bet-box-item-label">
                    Weitere Wetten bei
                </div>
                <img src="../assets/icons/ic_logo_bwin.svg" class="bet-box-item-logo">
            </a>
        </div>
        <div class="bet-box-infotext">
          Lizenziert (Whitelist) | 18+ | Suchtrisiko | <a target="_blank" href="https://buwei.de" style="color: darkgray">buwei.de</a>
        </div>
    </div>
</template>

<script>
  import { isNumeric } from '@/vuex/helpers'

  export default {
    name: 'BetBox',
    props: {
      match: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    computed: {
      getLink() {
        return 'https://sports.bwin.de/de/sports/fussball-4/wetten?wm=5047188'
      },
      getFavWinsLink() {
        const bet = this.$store.getters.getBetByBetgameAndMatch(this.$store.state.betgameId, this.match.id)
        if(bet === null || bet === undefined || !isNumeric(bet.goalshome) || !isNumeric(bet.goalsguest)) {
          return this.getLink
        }
        var options = ''
        const bwinbets = this.$store.getters.getMatchBet(this.match.id)
        if(bwinbets.length > 0) {
          const bwinbet =  bwinbets[0]
          if(isNumeric(bwinbet.bet_1) && isNumeric(bwinbet.bet_2) && isNumeric(bwinbet.bet_x)) {
            options += bwinbet.bet_id + '-' + bwinbet.result_market_id + '-'
            if (bwinbet.bet_1 > bwinbet.bet_2) {
              options += bwinbet.bet_2_id
            } else {
              options += bwinbet.bet_1_id
            } 
          }
        }            
        if(options.length > 0) {
          return 'https://sports.bwin.de/de/sports/fussball-4/wetten?stake=10&wm=5047188&options=' + options
        } else {
          return this.getLink
        }
      },
      getNotFavWinsLink() {
        const bet = this.$store.getters.getBetByBetgameAndMatch(this.$store.state.betgameId, this.match.id)
        if(bet === null || bet === undefined || !isNumeric(bet.goalshome) || !isNumeric(bet.goalsguest)) {
          return this.getLink
        }
        var options = ''
        const bwinbets = this.$store.getters.getMatchBet(this.match.id)
        if(bwinbets.length > 0) {
          const bwinbet =  bwinbets[0]
          if(isNumeric(bwinbet.not_favorite_wins_id)) {
            options += bwinbet.bet_id + '-' + bwinbet.double_chance_market_id + '-' + bwinbet.not_favorite_wins_id
          }
        }
        if(options.length > 0) {
          return 'https://sports.bwin.de/de/sports/fussball-4/wetten?stake=10&wm=5047188&options=' + options
        } else {
          return this.getLink
        }
      },
      getGoalsGT2Link() {
        const bet = this.$store.getters.getBetByBetgameAndMatch(this.$store.state.betgameId, this.match.id)
        if(bet === null || bet === undefined || !isNumeric(bet.goalshome) || !isNumeric(bet.goalsguest)) {
          return this.getLink
        }
        var options = ''
        const bwinbets = this.$store.getters.getMatchBet(this.match.id)
        if(bwinbets.length > 0) {
          const bwinbet =  bwinbets[0]
          if(isNumeric(bwinbet.goals_gt_2_id)) {
            options += bwinbet.bet_id + '-' + bwinbet.goals_market_id + '-' + bwinbet.goals_gt_2_id
          }
        }
        if(options.length > 0) {
          return 'https://sports.bwin.de/de/sports/fussball-4/wetten?stake=10&wm=5047188&options=' + options
        } else {
          return this.getLink
        }
      },
      getLowerOdds () {
        const r = this.$store.getters.getMatchBet(this.match.id)
        if (r.length > 0) {
          const bet = r[0]
          const home_wins = parseFloat(bet.bet_1)
          const guest_wins = parseFloat(bet.bet_2)
          return home_wins < guest_wins ? home_wins : guest_wins
        }
        return null
      },
      favoriteTeamId () {
        const r = this.$store.getters.getMatchBet(this.match.id)
        if (r.length > 0) {
          const bet = r[0]
          const home_wins = parseFloat(bet.bet_1)
          const guest_wins = parseFloat(bet.bet_2)
          return home_wins < guest_wins ? this.match.fk_team_home : this.match.fk_team_guest
        }
        return null
      },
      notFavoriteTeamId () {
        const r = this.$store.getters.getMatchBet(this.match.id)
        if (r.length > 0) {
          const bet = r[0]
          const home_wins = parseFloat(bet.bet_1)
          const guest_wins = parseFloat(bet.bet_2)
          return home_wins > guest_wins ? this.match.fk_team_home : this.match.fk_team_guest
        }
        return null
      },
      getMatchBet () {
        if (this.match) {
          const r = this.$store.getters.getMatchBet(this.match.id)
          if (r.length > 0) {
            return r[0]
          }
        }
        return null
      }
    },
    methods: {
      linkClicked(name) {
        this.$gtag.event(name, {
          'event_category': 'bwin',
          'event_label': this.match.team_home + ' - ' + this.match.team_guest,
          'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
          'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
          'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set',
          'dimension5': this.$store.state.betgame && this.$store.state.betgame.type ? this.$store.state.betgame.type : 'betgame type not set'
        })
      },
      getFavoriteTeamName(team) {
        if(this.match.fk_team_home === team) {
          return match.team_home
        } else if(this.match.fk_team_guest === team) {
          return match.team_guest
        }
        return ""
      }
    }
  }
</script>

<style lang="scss" scoped>
    .externallink {
        text-decoration: none;
    }
    .bet-box {
        display: flex;
        flex-wrap: wrap;
        padding: 0 8px;
        margin: 0px auto 26px;
        justify-content: space-between;
        max-width: 592px;
        position: relative;

        &:before {
            content: '-W-';
            position: absolute;
            right: 16px;
            top: -7px;
            text-align: right;
            font-size: 9px;
            color: $colorGreyMid;
        }
    
        &-item {
            display: flex;
            flex: calc(50% - 24px);
            flex-direction: column;
            background: $colorWhite;
            text-align: center;
            justify-content: center;
            margin: 8px;
            padding: 11px 0 7px 0;
            border-radius: 6px;
            box-shadow: 0 8px 24px 0 rgba(49, 49, 55, 0.08);
            transition: .2s ease-in-out;
            min-height: 70px;

            &:hover {
                cursor: pointer;
                box-shadow: 0 12px 24px 0 rgba(49, 49, 55, 0.12);
                transform: translateY(-2px);
            }
        
            &-label {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                font-size: 14px;
                color: $colorGreyDark;
            }
            
            &-number {
                font-size: 28px;
                font-weight: 600;
                color: $colorGreyDark;
                margin-top: 2px;
            }
            
            &-logo {
                margin: 4px 0;
            }
        }
    
        &-infotext {
            font-size: 14px;
            color: $colorGreyMid;
            text-align: center;
            width: 100%;
            margin-top: 7px;
        }
    }
</style>


