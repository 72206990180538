<template>
  <div class="betgamefinished">
    <div v-if="isBattleFirst(this.$store.state.user.id)" class="dashboard-image">
      <img src="../assets/icons/ic_dashboard_medal_gold.svg">
    </div>
    <div v-else-if="isBattleSecond(this.$store.state.user.id)" class="dashboard-image">
      <img src="../assets/icons/ic_dashboard_medal_silver.svg">
    </div>
    <div v-else-if="isBattleThird(this.$store.state.user.id)" class="dashboard-image">
      <img src="../assets/icons/ic_dashboard_medal_bronze.svg">
    </div>
    <div v-else-if="isBattleWinner(this.$store.state.user.id)" class="dashboard-image">
      <img src="../assets/icons/ic_dashboard_battle_win.svg">
    </div>
    <div v-else-if="!isBattleWinner(this.$store.state.user.id)" class="dashboard-image">
      <img src="../assets/icons/ic_dashboard_battle_lose.svg">
    </div>
    <div v-else class="dashboard-image">
      <img src="../assets/icons/ic_dashboard_pause.svg">
    </div>
    <div class="dashboard-text">
      <h1 v-if="isBattleFirst(this.$store.state.user.id)">{{ $t('dashboard.gold_title') }}</h1>
      <h1 v-else-if="isBattleSecond(this.$store.state.user.id)">{{ $t('dashboard.silver_title') }}</h1>
      <h1 v-else-if="isBattleThird(this.$store.state.user.id)">{{ $t('dashboard.bronze_title') }}</h1>
      <h1 v-else-if="isBattlePlace(this.$store.state.user.id)">{{ $t('dashboard.place_title') }}</h1>
      <h1 v-else-if="isBattle() && isBattleWinner(this.$store.state.user.id)">
        {{ $t('dashboard.summer_break_title_battle_winner') }}</h1>
      <h1 v-else-if="isBattle() && !isBattleWinner(this.$store.state.user.id)">
        {{ $t('dashboard.summer_break_title_battle_default') }}</h1>
      <h1 v-else-if="!isBattle() && isBattleWinner(this.$store.state.user.id)">
        {{ $t('dashboard.summer_break_title_battle_winner') }}</h1>
      <h1 v-else-if="!isBattle() && !isBattleWinner(this.$store.state.user.id)">
        {{ $t('dashboard.summer_break_title_battle_default') }}</h1>
      <h1 v-else>{{ $t('dashboard.summer_break') }}</h1>
      <p v-if="isBattleFirst(this.$store.state.user.id)">{{ $t('dashboard.gold_text') }}</p>
      <p v-else-if="isBattleSecond(this.$store.state.user.id)">{{ $t('dashboard.silver_text') }}</p>
      <p v-else-if="isBattleThird(this.$store.state.user.id)">{{ $t('dashboard.bronze_text') }}</p>
      <p v-else-if="isBattlePlace(this.$store.state.user.id)">{{ $t('dashboard.place_text') }}</p>
      <p v-else-if="this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 10700">
        {{ $t('dashboard.summer_break_text_em') }}</p>
      <p v-else-if="this.$store.state.betgame && isBattle() && !isBattleWinner(this.$store.state.user.id)">
        {{ $t('dashboard.summer_break_text_battle_default') }}</p>
      <p v-else-if="this.$store.state.betgame && isBattle() && isBattleWinner(this.$store.state.user.id)">
        {{ $t('dashboard.summer_break_text_battle_winner') }}</p>
      <p v-else-if="this.$store.state.betgame && !isBattle() && isBattleWinner(this.$store.state.user.id)">
        {{ $t('dashboard.summer_break_title_battle_winner_text') }}</p>
      <p v-else-if="this.$store.state.betgame && !isBattle() && !isBattleWinner(this.$store.state.user.id)">
        {{ $t('dashboard.summer_break_title_battle_default_text') }}</p>
      <p v-else>{{ $t('dashboard.summer_break_text') }}</p>
      <div v-if="isBattle() && isBattleWinner(this.$store.state.user.id)" class="powered-by-teamtip" style="">
        <button v-on:click="createBattle">{{ $t('dashboard.button_start_battle') }}</button>
      </div>
      <div v-else-if="isBattle() && !isBattleWinner(this.$store.state.user.id)" class="powered-by-teamtip" style="">
        <button v-on:click="createBattle">{{ $t('dashboard.button_start_battle') }}</button>
      </div>
      <div v-else-if="!isBattle() && isBattleWinner(this.$store.state.user.id)" class="powered-by-teamtip" style="">
        <button v-on:click="createBetgame">
          <img class="button-icon button-icon--left" src="../assets/icons/ic_12_plus_white.svg">{{ $t('dashboard.button_create_betgame') }}
        </button>
      </div>
      <div v-else-if="!isBattle() && !isBattleWinner(this.$store.state.user.id)" class="powered-by-teamtip" style="">
        <button v-on:click="createBetgame">
          <img class="button-icon button-icon--left" src="../assets/icons/ic_12_plus_white.svg">{{ $t('dashboard.button_create_betgame') }}
        </button>
      </div>
      <div v-else-if="this.isBetgameAdmin()" class="powered-by-teamtip" style="">
        <button v-on:click="showRankings">{{ $t('dashboard.button_view_rankings_total') }}</button>
      </div>
      <div v-else-if="!this.isBetgameAdmin()" class="powered-by-teamtip" style="">
        <button v-on:click="showRankings">{{ $t('dashboard.button_view_rankings_total') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BetgameFinished',
  components: {},
  data () {
    return {}
  },
  beforeCreate () {
  },
  created () {
  },
  mounted () {
  },
  computed: {},
  methods: {
    createBetgame () {
      this.$gtag.event('EigeneTipprunde', {
        'event_category': 'createBetgame',
        'event_label': 'teamtip.betgamefinished',
        'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
        'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
        'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set',
        'dimension5': this.$store.state.betgame && this.$store.state.betgame.type ? this.$store.state.betgame.type : 'betgame type not set'
      })
      this.$router.push('/createuserbetgame')
    },
    createBattle () {
      this.$gtag.event('EigeneTipprunde', {
        'event_category': 'createBetgame',
        'event_label': 'teamtip.betgamefinished',
        'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
        'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
        'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set',
        'dimension5': this.$store.state.betgame && this.$store.state.betgame.type ? this.$store.state.betgame.type : 'betgame type not set'
      })
      this.$router.push('/createuserbetgame?type=2')
    },
    showRankings () {
      this.$router.push('/rankings')
    }
  }
}
</script>

<style lang="scss">
.betgamefinished {
  background: $colorWhite;
  box-shadow: 0 0 1px 0 rgba($colorGreyDark, .08), 0px 4px 16px rgba($colorGreyDark, 0.08);
  border-radius: 8px;
  width: 100%;
  margin-bottom: 24px;
}
</style>
