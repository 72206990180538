<template>
  <div class="profileChangeRakingsPageSize" id="profileChangeRakingsPageSize">

    <NavigationBar>
      <template v-slot:left>
        <img v-on:click="goBack" class="back" src="../assets/icons/ic_16_back.svg">
      </template>
      <template v-slot:center>
        {{ $t('menu.matchday_ranking') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>


      <div class="input-container" style="margin-top: 30px;">

        <div class="input-header">
          {{ $t('profile.change_rankings_page_size') }}
        </div>
        
        <select @change="changed($event)" v-model="value" name="pagesize" id="pagesize" style="width: 100%; margin-top: 10px; margin-bottom: 20px;">
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="25">25</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="150">150</option>
          <option value="200">200</option>
          <option value="250">250</option>
        </select>
        
      </div>
    

  </div>
</template>

<script>

import NavigationBar from './../components/NavigationBar'
import { RANKINGS_PAGE_SIZE } from '@/main'

export default {
  name: 'ProfileChangeRakingsPageSize',
  components: { NavigationBar },
  data () {
    return {
      value: parseInt(this.getBGValue('spmrps') ? this.getBGValue('spmrps') : RANKINGS_PAGE_SIZE)
    }
  },
  mounted () {
    this.value = parseInt(this.getBGValue('spmrps') ? this.getBGValue('spmrps') : RANKINGS_PAGE_SIZE)
  },
  computed: {
  },
  methods: {
    changed(event) {
      this.value = event.target.value
      this.setBGValue('spmrps', parseInt(event.target.value))
    },
    goBack () {
      this.$router.push('/profile')
    },
    isNullOrUndefined (data) {
      return data === null || data === undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.profileChangeRakingsPageSize {

  form {
    padding-top: 16px;
  }

  h1 {
    text-align: center;
  }

  p {
    text-align: center;
    margin-bottom: 26px;
  }
}
</style>
