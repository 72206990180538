<template>
    <div class="rules" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">
        
        <NavigationBar>
            <template v-slot:left>
                <img v-on:click="goBack" class="back" src="../assets/icons/ic_16_back.svg">
            </template>
            <template v-slot:center>
              {{ $t('profile.imprint') }}
            </template>
        </NavigationBar>
        
        <div style="padding-bottom: 100px;">
            <h2>{{ $t('profile.imprint') }}</h2>
            <p>
                <strong>SPM Sportplatz Media GmbH</strong><br />
                Schleidenstraße 3<br />
                22083 Hamburg<br />
                E-Mail: <a href="mailto:kontakt@teamtip.net">kontakt@teamtip.net</a>
            </p>
            <div v-if="userlang === 'de'">
              <h3>Vermarktung</h3>
              <p>
                  Die Onlinevermarktung von TEAMTIP erfolgt durch die SPM Sportplatz-Media GmbH. Sollten Sie Interesse haben auf TEAMTIP oder im gesamten Sportplatz-Media-Netzwerk zu werben, richten Sie Ihre Anfrage bitte an: <a href="mailto:info@sportplatz-media.com">info@sportplatz-media.com</a><br>Weitere Informationen finden Sie unter <a href="https://www.sportplatz-media.com" target="_blank">www.sportplatz-media.com</a>.<br>Wir freuen uns auf Ihre Anfrage!
              </p>
              <h3>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</h3>
              <p>
                  <strong>SPM Sportplatz Media GmbH</strong><br />
                  Geschäftsführer: Marcel Hager<br />
                  USt.ID: DE 281650513<br />
                  Handelsregister: HRB 122001
              </p>
              <br>
              <p>
                  Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
              </p>
            </div>
            <div v-else>
              <h3>Marketing</h3>
              <p>
                The online marketing of TEAMTIP is carried out by SPM Sportplatz-Media GmbH. If you are interested in advertising on TEAMTIP or in the entire Sportplatz-Media network, please send your request to: <a href="mailto:info@sportplatz-media.com">info@sportplatz-media.com</a><br>For more information, see <a href="https://www.sportplatz-media.com" target="_blank">www.sportplatz-media.com</a>.<br>We look forward to your inquiry!
              </p>
              <h3>Responsible for the content after § 55 Abs. 2 RStV</h3>
              <p>
                <strong>SPM Sportplatz Media GmbH</strong><br />
                Managing director: Marcel Hager<br />
                USt.ID: DE 281650513<br />
                Commercial register: HRB 122001
              </p>
              <br>
              <p>
                Liability notice: Despite careful content control, we assume no liability for the content of external links. The operators of the linked pages are solely responsible for their content.
              </p>
            </div>
            <div>
              <h3></h3>
              <p style="text-align: right">{{ packageVersion }}</p>
            </div>
        </div>
      
    </div>
</template>

<script>
  import NavigationBar from '../components/NavigationBar'
  
  export default {
    name: 'BetgameImprint',
    components: { NavigationBar },
    computed: {
      userlang() {
        return this.$store.state.language
      },
      packageVersion() {
        return process.env.PACKAGE_VERSION || '0'
      }
    },
    methods: {
      goBack() {
        if (this.$store.getters.loggedIn) {
          this.$router.push({ name: 'profile' }).catch(err => {})
        } else {
          this.$router.push({ name: 'home' }).catch(err => {})
        }
      }
    }
  }
  
</script>

<style lang="scss" scoped>
</style>
