<template>
  <div class="userdata" id="userdata">
    <div v-if="notLoggedIn" class="input-container" style="margin-bottom: 16px;">
      <div class="input-header">
        {{ $t('create_betgame.user_data') }}
      </div>
      <div class="input-element" v-bind:class="{ validation_true: nicknameValid === true, validation_false: name.length > 0 && nicknameValid === false }">
        <input @focusout="handleNameFocus" v-model="name" name="name" type="text" id="username" placeholder="username"/>
        <label for="username">{{ $t('user_name') }}</label>
      </div>
      <div class="input-element" v-bind:class="{ validation_true: emailValid === true, validation_false: email.length > 0 && emailValid === false }">
        <input @focusout="handleEmailFocus" v-model="email" type="email" name="email" id="email" placeholder="email"/>
        <label for="email">{{ $t('email') }}</label>
      </div>
      <div class="input-element" v-bind:class="{ validation_true: passwordValid === true, validation_false: password.length > 0 && passwordValid === false }">
        <input @focusout="handlePasswordFocus" v-model="password" type="password" name="password" id="password" placeholder="password"/>
        <label for="password">{{ $t('password') }}</label>
      </div>
    </div>
    <div v-if="notLoggedIn" class="input-container" style="margin-bottom: 32px;">
      <div class="input-header">
        {{ $t('create_betgame.conditions_and_data_protection') }}
      </div>
      <div class="input-checkbox">
        <input v-on:change="agreedTOSChanged" id="agreedTOS" name="agreedTOS" type="checkbox" v-model="agreedTOS"/>
        <label for="agreedTOS">{{ $t('create_betgame.agree_terms_of_use') }} <a target="_blank" href="/termsofuse">({{ $t('create_betgame.link') }})</a>.</label>
      </div>
      <div class="input-empty">
        <a target="_blank" href="/privacy">{{ $t('create_betgame.data_protection_teamtip') }}</a>
      </div>
      <div class="input-checkbox">
        <input v-on:change="isUnder18Changed" id="isUnder18" name="isUnder18" type="checkbox" v-model="isUnder18"/>
        <label for="isUnder18">{{ $t('create_betgame.i_am_under_18') }} <span style="font-size: 14px; opacity: .48;">({{ $t('create_betgame.optional') }})</span></label>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import APIService from '@/services/APIService'

export default {
  name: 'UserData',
  components: {},
  props: {
  },
  data () {
    return {
      name: (this.$store.state.createBetgame.userName.length > 0 ? this.$store.state.createBetgame.userName : ''),
      email: (this.$store.state.createBetgame.email.length > 0 ? this.$store.state.createBetgame.email : ''),
      password: '',
      agreedTOS: false,
      isUnder18: false
    }
  },
  beforeCreate () {
    Vue.$log.debug('UserData.beforeCreate ' + this.email)
  },
  created () {
    Vue.$log.debug('UserData.created ' + this.email)
  },
  mounted () {
    Vue.$log.debug('UserData.mounted ' + this.email)
  },
  computed: {
    notLoggedIn() {
      return !this.$store.getters.loggedIn
    },
    nicknameValid () {
      if(this.name === null || this.name === undefined) {
        return false
      }
      return this.name.trim().length >= 3 && this.name.trim().length < 100
    },
    emailValid () {
      if(this.email === null || this.email === undefined) {
        return false
      }
      const valid = this.isValidEmail(this.email)
      return valid
    },
    passwordValid () {
      if(this.password === null || this.password === undefined) {
        return false
      }
      return this.password.trim().length >= 8 && this.password.trim().length < 100
    }
  },
  methods: {
    agreedTOSChanged() {
      //if(this.agreedTOS !== null && this.agreedTOS !== undefined) {
        this.$store.dispatch('setCreateBetgame', {
          key: 'agreedTOS',
          value: this.agreedTOS
        })
        .then(() => {
          Vue.$log.debug('AddUsers.agreedTOSChanged ok ' + this.agreedTOS)
        }).catch(function (error) {
          Vue.$log.debug('AddUsers.agreedTOSChanged error ' + error)
        })
      //}
    },
    isUnder18Changed() {
      //if(this.isUnder18 !== null && this.isUnder18 !== undefined) {
        this.$store.dispatch('setCreateBetgame', {
          key: 'isUnder18',
          value: this.isUnder18
        })
        .then(() => {
          Vue.$log.debug('UserData.isUnder18Changed ok ' + this.isUnder18)
        }).catch(function (error) {
          Vue.$log.debug('UserData.isUnder18Changed error ' + error)
        })
      //}
    },
    handleNameFocus() {
      //if(this.name && this.name.length > 3) {
        this.$store.dispatch('setCreateBetgame', {
          key: 'userName',
          value: this.name
        })
        .then(() => {
          Vue.$log.debug('UserData.handleNameFocus ok ' + this.name)
        }).catch(function (error) {
          Vue.$log.debug('UserData.handleNameFocus error ' + error)
        })
      //}
    },
    handleEmailFocus() {
      /*
      this.$store.dispatch('setCreateBetgame', {
        key: 'email',
        value: this.email
      })
      .then(() => {
        Vue.$log.debug('UserData.handleEmailFocus ok ' + this.email)
      }).catch(function (error) {
        Vue.$log.debug('UserData.handleEmailFocus error ' + error)
      })
      */
      
      if(this.email !== null && this.email !== undefined && this.isValidEmail(this.email)) {
        APIService.isEmailRegistered(this.email.toLowerCase().trim()).then(response => {
          if (response.data.valid === '1') {
            //this.emailAlreadyRegistered = false
            this.$store.dispatch('setCreateBetgame', {
              key: 'email',
              value: this.email.toLowerCase().trim()
            })
            .then(() => {
              Vue.$log.debug('UserData.handleEmailFocus ok ' + this.email)
            }).catch(function (error) {
              Vue.$log.debug('UserData.handleEmailFocus error ' + error)
            })
          } else {
            //this.emailAlreadyRegistered = true
            this.$emit('emailError', this.$t('errors.email_already_registered'))
          }
        })
      } else {
        this.$emit('emailError', this.$t('errors.enter_valid_email'))
      }
    },
    handlePasswordFocus() {
      //if(this.password && this.password.length > 3) {
        this.$store.dispatch('setCreateBetgame', {
          key: 'password',
          value: this.password
        })
        .then(() => {
          Vue.$log.debug('UserData.handlePasswordFocus ok ' + this.password)
        }).catch(function (error) {
          Vue.$log.debug('UserData.handlePasswordFocus error ' + error)
        })
      //}
    }
  }
}
</script>

<style lang="scss" scoped>

.userdata {
  max-width: 400px;
  margin: 16px auto 16px;
}

</style>
