<template>
  <div class="profileChangeEmail" id="profileChangeEmail">
  
    <NavigationBar>
      <template v-slot:left>
        <img v-on:click="goBack" class="back" src="../assets/icons/ic_16_back.svg">
      </template>
      <template v-slot:center>
        {{ $t('profile.change_email_address') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>
    
    <form @submit.prevent="changeEmail">

      <div class="input-container">

        <div class="input-infotext">
          {{ $t('profile.update_email_text') }}
        </div>
        
        <div>
          <div class="input-element">
            <input v-model="email_old" type="email" name="email_old" id="email_old" placeholder="email_old" value disabled />
            <label for="email_old">{{ $t('profile.current_email_address') }}</label>
          </div>

          <div class="input-element">
            <input v-model="email_new" type="email" name="email_new" id="email_new" placeholder="email_new" autofocus value />
            <label for="email_new">{{ $t('profile.new_email_address') }}</label>
          </div>
  
          <!--
          <div class="input-element">
            <input v-model="password" type="password" name="password" id="password" placeholder="password" autofocus value />
            <label for="password">{{ $t('password') }}</label>
          </div>
          -->
        </div>

        <div class="input-button-row">
          <button type="submit" name="button">
            {{ $t('button_save_text') }}
          </button>
        </div>

      </div>
      
    </form>
  
    <ul class="error-container">
      <li v-for="error in errors">{{ error }}</li>
    </ul>
    
  </div>
</template>

<script>
  
  import APIService from '@/services/APIService'
  import NavigationBar from './../components/NavigationBar'
  import { TOAST_DURATION } from '../main'
  
export default {
  components: { NavigationBar },
  data() {
    return {
      email_new: '',
      email_old: '',
      password: '',
      errors: []
    }
  },
  beforeMount(){
    this.email_old = this.$store.state.user.email
  },
  methods: {
    goBack() {
      this.$router.push('/profile')
    },
    changeEmail() {
      this.errors = []
      if(this.isNullOrUndefined(this.email_new) || this.email_new.trim().length === 0) {
        this.errors.push(this.$i18n.t('profile.error_enter_new_email_address'))
      } else if(!this.isValidEmail(this.email_new)) {
        this.errors.push(this.$i18n.t('profile.error_enter_valid_email_address'))
      }  /* else if(this.password === null || this.password.length === 0) {
        this.errors.push(this.$i18n.t('profile.error_password_confirmation_missing'))
      } */
      if (this.errors.length === 0) {
        const that = this
        APIService.isEmailRegistered(this.email_new.toLowerCase().trim()).then(response => {
          if (response.data.valid === '1') {
            this.$store.dispatch('patchUser', {
              email: this.email_new.toLowerCase().trim()
            }).then(() => {
              this.$toast.open({ message:  this.$i18n.t('profile.success_new_email_address_saved'), position: 'top', duration: TOAST_DURATION, type: 'info' })
              this.email_old = this.email_new.toLowerCase().trim()
              this.email_new = ''
              this.password = ''
            }).catch(function (error) {
              that.password = ''
              if (error.response.status === 409) {
                alert(that.$i18n.t('errors.email_already_registered'))
              } else {
                alert(that.$i18n.t('profile.error_new_email_address_not_saved') + ' '  + error)
              }
            })
          } else {
            this.password = ''
            this.errors.push(this.$i18n.t('errors.email_already_registered'))
          }
        })
      }
    },
    isNullOrUndefined(data) {
      return data === null || data === undefined
    },
    isValidEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  }
}
</script>

<style lang="scss" scoped>
  .profileChangeEmail {

    form {
      padding-top: 16px;
    }
    h1 {
      text-align: center;
    }
    p {
      text-align: center;
      margin-bottom: 26px;
    }
  }
</style>
