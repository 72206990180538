<template>
  <div :key="componentKey" class="searchbetgame" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">

    <NavigationBar >
      <template v-if="this.$store.getters.loggedIn" v-slot:left>
        <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
      </template>
      <template v-slot:center>
        {{ $t('betgames') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>

    <div class="notification">
      <h1>{{ $t('search_betgame.title') }}</h1>
      <p>{{ $t('search_betgame.text') }}</p>
      <router-link to="/createUserBetgame">
        <button><img class="button-icon button-icon--left" src="../assets/icons/ic_12_plus_white.svg">{{ $t('user_betgame_settings.button_create_betgame') }}</button>
      </router-link>

      <div class="divider">
        <div class="divider-stroke"></div>
        <div class="divider-label">{{ $t('search_betgame.invitation_text') }}</div>
        <div class="divider-stroke"></div>
      </div>

      <input v-model="invitationLink" type="text" name="invitation_link" id="invitation_link" :placeholder="$t('search_betgame.invitation_link_input')" class="invitation-input" />
      <button v-on:click="invitationLinkClicked">{{ $t('search_betgame.invitation_link_button') }}</button>
      
    </div>    
    
  </div>
</template>

<script>
import NavigationBar from './../components/NavigationBar'
import { TOAST_DURATION } from '@/main'

export default {
  name: 'SearchBetgame',
  components: {
    NavigationBar
  },
  data() {
    return {
      componentKey:0,
      invitationLink: ''
    }
  },
  metaInfo: {
    title: 'Tipprunden',
    meta: [
      { vmid: 'keywords', name: 'keywords', content: 'Tipprunden, Öffentlich, Private, Erstellen, Suchen, Einladung, Einladungslink, Link, Teilnehmen, Tippen' },
      { vmid: 'description', name: 'description', content: 'Tipprunde erstellen, Öffentliche Tipprunde, Einladungslink' },
    ]
  },
  beforeCreate() {
  },
  mounted() {
  },
  created() {
  },
  computed: {
  },
  methods: {
    invitationLinkClicked() {
      const ilink = this.invitationLink ? this.invitationLink.trim().toLowerCase() : ''
      if(ilink.startsWith('https://teamtip.net/join/')) {
        const res = ilink.replace('https://teamtip.net/join/', '')
        if(res.includes('/')) {
          const ids = res.split('/')
          if(ids.length === 2) {
            const id = ids[0]
            const code = ids[1]
            if (isNaN(parseInt(ids[0])) || isNaN(parseInt(ids[1]))) {
              this.$toast.open({ message: this.$i18n.t('register.invitation_link_invalid'), position: 'top', duration: TOAST_DURATION, type: 'error'})
              return
            } else {
              if(this.$store.getters.isUserConnectedToBetgame(id)) {
                this.setBGValue('spmbgid', id)
                if (navigator.userAgent.indexOf('TEAMTIP') > 0) {
                  this.setBGValue('betgameId', id)
                }
                this.$store.dispatch('switchBetgame', id).then(() => {
                  this.$gtag.set({
                    'dimension1': (this.$store.state.betgame && this.$store.state.betgame.id === id && this.$store.state.betgame.name) ? this.$store.state.betgame.name : 'betgame not set',
                    'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
                    'dimension3': (this.$store.state.betgame && this.$store.state.betgame.id === id && this.$store.state.betgame.name) ? this.$store.state.betgame.name : 'betgame not set',
                    'dimension5': this.$store.state.betgame && this.$store.state.betgame.type ? this.$store.state.betgame.type : 'betgame type not set'
                  })
                  if(this.$store.state.betgame && this.$store.state.user) {
                    this.$gtag.set({
                      user_properties: {
                        permission_manage_prediction_round: '' + this.isAdmin(this.$store.state.betgame.id),
                        prediction_mode: this.getBetgameType(this.$store.state.betgame),
                        prediction_competiton: this.getCompetitionId(this.$store.state.betgame.id),
                        notification_preferences: this.getNotificationPreferences(),
                        user_age: (this.$store.state.user.isadult) ? '>=18' : '<18'
                      },
                    })
                  }
                  this.$router.push('/feed/dashboard')
                })
              } else {
                this.$router.push({ name: 'betgamewelcome', params: { id: id, code: code }})
              }
            }
          }
        }
      } else {
        this.$toast.open({ message: this.$i18n.t('registe.invitation_link_invalid'), position: 'top', duration: TOAST_DURATION, type: 'error'})
      }
    },
    goBack() {
      if(this.hasHistory()) {
        if(this.$store.state.betgame && this.$store.state.betgame.id) {
          this.$router.go(-1)
        } else {
          this.$router.push('/mybetgames')
        }
      } else {
        if(this.$store.state.betgame && this.$store.state.betgame.id) {
          this.$router.push('/feed/dashboard')
        } else {
          this.$router.push('/mybetgames')
        }
      }
    },
  }
}

</script>

<style lang="scss" scoped>

  .searchbetgame {
    max-width: 576px;
    margin: 0 auto auto auto;

    @media screen and (min-width: 768px) {
      margin: 48px auto 0;
    }
  }

  .router_link {
    text-decoration: none;
  }

  .divider {
    display: flex;
    align-items: center;
    margin: 30px 0 24px;

    &-stroke {
      flex: 1;
      height: 1px;
      background: rgba($colorGreyLight, .16);
    }

    &-label {
      margin: 0 8px;
      font-size: 11px;
      font-weight: 500;
      color: rgba($colorGreyDark, .4);
      text-transform: uppercase;
    }
  }

  .invitation-input {
    background: rgba($colorGreyDark, .08);
    border: 0;
    font-size: 17px;
    width: 100%;
    padding: 14px 0;
    margin: -11px 0 0;
    text-align: center;
    border-radius: 6px;
    color: $colorGreyDark;

    &:focus {
      outline: 0;
      box-shadow: inset 0 0 0 1px rgba($colorGreyLight, .04);
    }

    ~ button {
      display: none;
      opacity: 0;
      margin-top: 16px;
    }

    &:not(:placeholder-shown) {
      ~ button {
        opacity: 1;
        display: inline-block;
      }
    }
  }

</style>
