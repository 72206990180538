import { render, staticRenderFns } from "./BetgameDescription.vue?vue&type=template&id=cb3d3a4e&scoped=true"
import script from "./BetgameDescription.vue?vue&type=script&lang=js"
export * from "./BetgameDescription.vue?vue&type=script&lang=js"
import style0 from "./BetgameDescription.vue?vue&type=style&index=0&id=cb3d3a4e&prod&lang=scss"
import style1 from "./BetgameDescription.vue?vue&type=style&index=1&id=cb3d3a4e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb3d3a4e",
  null
  
)

export default component.exports