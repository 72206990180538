<template>
  <div class="betgame-description" id="betgamedescription">
    <div class="input-container" style="height: 100%">
      <div class="input-header">
        {{ $t('create_betgame.betgame_description') }}
      </div>
      <div class="input-infotext">
        {{ $t('create_betgame.betgame_description_description') }}
      </div>
      <div class="input-element">
        <textarea @focusout="handleBetgameDescriptionFocus" v-model="betgameDescription" name="betgameDescription" type="text" id="tipprunde-beschreibung" placeholder="tipprunde-beschreibung"/>
        <label for="tipprunde-beschreibung">{{ $t('create_betgame.max_characters', { max: 1000 }) }}</label>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'

export default {
  name: 'BetgameDescription',
  components: {},
  props: {
  },
  data () {
    return {
      betgameDescription: (this.$store.state.createBetgame.betgameDescription.length > '' ? 
          this.$store.state.createBetgame.betgameDescription : '')
    }
  },
  beforeCreate () {
    Vue.$log.debug('BetgameDescription.beforeCreate ' + this.betgameDescription)
  },
  created () {
    Vue.$log.debug('BetgameDescription.created ' + this.betgameDescription)
  },
  mounted () {
    Vue.$log.debug('BetgameDescription.mounted ' + this.betgameDescription)
  },
  computed: {},
  methods: {
    handleBetgameDescriptionFocus() {
      if(this.betgameDescription && this.betgameDescription.length > 3) {
        this.$store.dispatch('setCreateBetgame', {
          key: 'betgameDescription',
          value: this.betgameDescription
        })
        .then(() => {
          Vue.$log.debug('BetgameDescription.handleBetgameDescriptionFocus ok ' + this.betgameDescription)
        }).catch(function (error) {
          Vue.$log.debug('BetgameDescription.handleBetgameDescriptionFocus error ' + error)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.betgame-description {
  max-width: 400px;
  margin: 16px auto 16px;
}

textarea {
  &:focus, &:not(:placeholder-shown) {
    ~ label {
      top: calc(50% - 24px);
    }
  }
}

</style>
