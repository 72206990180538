<template>
  <div class="feed">

    <keep-alive>
      <NavigationBar id="rulesNavigationBarId" ref="rulesNavigationBarRef">
        <template v-slot:center>
          {{ betgameName }}
        </template>
      </NavigationBar>
    </keep-alive>

    <!-- Tabbar (for Dashboard & Feed) -->
    <div class="navigation__tabbar">
      <div v-on:click="tabChanged('dashboard')"  class="navigation__tabbar__item" v-bind:class="this.selectedTab === 'dashboard' ? 'navigation__tabbar__item--active' : ''">
        <div class="navigation__tabbar__item__label">
          Home
        </div>
      </div>
      <div v-on:click="tabChanged('chat')" class="navigation__tabbar__item" v-bind:class="this.selectedTab === 'chat' ? 'navigation__tabbar__item--active' : ''">
        <div class="navigation__tabbar__item__label">
          Chat
          <span v-if="this.$store.state.unreadCount && this.$store.state.unreadCount > 0">{{ this.$store.state.unreadCount }}</span>
        </div>
      </div>
    </div>
    
    <Dashboard v-if="this.selectedTab === 'dashboard'"></Dashboard>
    <Chat v-else-if="this.selectedTab === 'chat'"></Chat>

    <transition v-if="ratingOverlayOpened" name="review-modal">
      <div class="review-modal-mask">
        <div class="review-modal-wrapper">
          <div class="review-modal-container">
            <div class="input-header">
              {{ $t('review_request_title') }}
            </div>
            <div class="input-infotext">
              {{ $t('review_request_text') }}
            </div>
            <star-rating style="justify-content: center;" @rating-selected="ratingChanged($event)" :rating="rating" :show-rating="false"></star-rating>
            <div class="review-modal-footer">
              <button class="review-modal-default-button" @click="ratingSkipped()">
                {{ $t('later') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    
  </div>
</template>

<script>

import NavigationBar from '../components/NavigationBar'
import Vue from 'vue'
import Dashboard from './DashboardNew.vue'
import Chat from '@/components/Chat.vue'
import StarRating from 'vue-star-rating'

export default {
  name: 'dashboard',
  components: { Chat, Dashboard, NavigationBar, StarRating },
  data () {
    return {
      selectedTab: 'dashboard',
      rating: 0,
      ratingOverlayOpened: false,
      ratingRequested: false
    }
  },
  beforeCreate() {
    Vue.$log.debug('Feed beforeCreate ' + this.$router.currentRoute.name)
  },
  created() {
    Vue.$log.debug('Feed created ' + this.$route.params.tab + ' ---' + this.$router.currentRoute.name)
    if(this.$router.currentRoute && this.$router.currentRoute.name && this.$router.currentRoute.name === 'chatview') {
      this.selectedTab = 'chat'
    } else if(this.$router.currentRoute && this.$router.currentRoute.name && this.$router.currentRoute.name.includes('dashboardold')) {
      this.selectedTab = 'dashboard'
    } else if(this.$route.params && this.$route.params.tab && this.$route.params.tab.length > 0) {
      this.selectedTab = this.$route.params.tab
    }
    Vue.$log.debug('Feed created selectedTab ' + this.selectedTab)
  },
  mounted() {
    Vue.$log.debug('Feed mounted')
    if(this.selectedTab === 'dashboard') {
      if(this.showSPMReviewPopup()) {
        this.requestReview()
      }
    }
  },
  beforeDestroy () {

  },
  methods: {
    showSPMReviewPopup() {
      if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
        var appOpenedCount = 0
        try {
          if (window.TeamtipAppInterface !== undefined && window.TeamtipAppInterface !== null) {
            const appData = window.TeamtipAppInterface.getAppScoreData()
            try {
              const data = JSON.parse(appData)
              appOpenedCount = data.appOpenedCount
            } catch (e) {
              appOpenedCount = 0
            }
          } else if (window.webkit && window.webkit.messageHandlers.TeamtipAppInterface !== undefined && window.webkit.messageHandlers.TeamtipAppInterface !== null) {
            const tmp = window.webkit.messageHandlers.TeamtipAppInterface.postMessage('getAppScoreData')
            appOpenedCount = window.localStorage.getItem('appOpenedCount')
          } else {
            appOpenedCount = 0
          }
        } catch (e) {
          appOpenedCount = 0
        }
        if(appOpenedCount !== null && appOpenedCount !== undefined && parseInt(appOpenedCount) > 60 && parseInt(appOpenedCount) % 61 === 0) {
          if(this.$store.state.reviewPopupShown === 1 || this.ratingRequested) {
            return false
          }
          this.$store.dispatch('setReviewPopupShown', 1)
          return true
        } else if(this.$store.state.user && (this.$store.state.user.email.includes('@spm.') || this.$store.state.user.id === 10180 || this.$store.state.user.id === 10187) && 
            appOpenedCount !== null && appOpenedCount !== undefined && parseInt(appOpenedCount) % 5 === 0) {
          if(this.$store.state.reviewPopupShown === 1 || this.ratingRequested) {
            return false
          }
          this.$store.dispatch('setReviewPopupShown', 1)
          return true
        }
      }
      return false
    },
    requestReview() {
      this.ratingRequested = true
      this.$store.dispatch('setReviewPopupShown', 1)
      this.ratingOverlayOpened = true
    },
    ratingSkipped() {
      this.ratingOverlayOpened = false
      this.$store.dispatch('addRating', {'rating': 0, 'date': new Date(),
        'version': this.buildVersion(), 'client': this.userAgent(), 'appOpened': this.appOpenedCount(),
        'reviewRequested': this.reviewRequestedCount(), 'info': '' } ).then(() => {})
    },
    ratingChanged(r) {
      this.rating = r
      this.ratingOverlayOpened = false
      this.$store.dispatch('addRating', {'rating': r, 'date': new Date(),
        'version': this.buildVersion(), 'client': this.userAgent(), 'appOpened': this.appOpenedCount(),
        'reviewRequested': this.reviewRequestedCount(), 'info': '' } ).then(() => {})
      if(this.rating === 5) {
        try {
          if (window.TeamtipAppInterface !== undefined && window.TeamtipAppInterface !== null) {
            const a = window.TeamtipAppInterface.requestAppStoreReview()
          } else if (window.webkit && window.webkit.messageHandlers.TeamtipAppInterface !== undefined && window.webkit.messageHandlers.TeamtipAppInterface !== null) {
            const b = window.webkit.messageHandlers.TeamtipAppInterface.postMessage('requestAppStoreReview')
          } else {
          }
        } catch (e) {
        }
      }
    },
    tabChanged(tab) {
      Vue.$log.debug('Feed.vue tabChanged ' + tab)
      this.selectedTab = tab
      if(this.selectedTab === 'dashboard') {
        window.scrollTo(0,0)
      } else if(this.selectedTab === 'chat') {
        setTimeout(() => {
          const e = document.getElementById('NEW_MESSAGES_DIVIDER')
          if(e) {
            e.scrollIntoView()
            window.scrollBy({ top: -150, left: 0, behavior: "smooth" })
          } else {
            const e = document.getElementById('1')
            if(e) {
              e.scrollIntoView()
            }
          }
          if(this.$store.state.chat_items && this.$store.state.chat_items.length < 10) {
            this.$store.dispatch('setUnreadCount', 0)
          }
        }, 0)
      }
    },

  },
  computed: {
    betgameName() {
      if(this.$store.state.betgame) {
        return this.$store.state.betgame.name
      } else {
        if(this.getBGValue('spmbgid') && parseInt(this.getBGValue('spmbgid')) > 0) {
          this.$store.dispatch('fetchBetgame', this.getBGValue('spmbgid'))
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .feed {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0px auto;
    min-height: 100%;
    box-sizing: border-box;
  }

  .navigation__tabbar {
    position: sticky;
    top: 63px;
    z-index: 500;
    display: flex;
    justify-content: center;
    background: $colorWhite;
    width: 100vw;
    margin: -1px -16px 0;
    box-shadow: inset 0 -1px rgba($colorGreyDark, .24);

    &__item {
      display: flex;
      justify-content: center;
      width: calc(768px / 2);
      padding: 12px 0;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: $colorGreyDark;
      cursor: pointer;
      text-decoration: none;

      &:not(.navigation__tabbar__item--active):hover {
        color: rgba(0,0,0,0.8);
      }

      &--active {
        color: var(--color-primary);
        box-shadow: inset 0 -2px 0 0 var(--color-primary);
      }

      &__label {
        position: relative;

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          content: '';
          top: -10px;
          right: -16px;
          min-width: 16px;
          height: 16px;
          padding: 0.5px 0 0 1px;
          border-radius: 8px;
          font-size: 10px;
          font-weight: 500;
          color: $colorWhite;
          background: $colorLive;
          box-sizing: border-box;
        }
      }
    }
  }
</style>
