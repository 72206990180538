<template>
    <div class="rules" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">
        
        <NavigationBar>
            <template v-slot:center>
                Tippabgabe
            </template>
        </NavigationBar>
        
        <div>
        
        </div>
      
    </div>
</template>

<script>
  import NavigationBar from '../components/NavigationBar'
  import { isNumeric } from '../vuex/helpers'
  
  export default {
    name: 'BetgameTippen',
    components: { NavigationBar },
    beforeCreate() {
      this.$log.debug('BetgameTippen beforeCreate ' + this.$route.params.user + ' - ' + this.$route.params.betgame + ' - ' + this.$route.params.matchday)
    },
    created () {
      this.$log.debug('BetgameTippen created ' + this.$route.params.user + ' - ' + this.$route.params.betgame + ' - ' + this.$route.params.matchday)
      if(isNumeric(this.$route.params.user) && parseInt(this.$route.params.user) > 0 && isNumeric(this.$route.params.betgame) && parseInt(this.$route.params.betgame) > 0 &&
        isNumeric(this.$route.params.matchday) && parseInt(this.$route.params.matchday) > 0 && parseInt(this.$route.params.matchday) < 100) {
        if (this.$store.getters.loggedIn) {
          this.$log.debug('BetgameTippen created 1 - ' + this.$store.state.user.id + ' --- ' + this.$route.params.user)
          if ('' + this.$store.state.user.id === this.$route.params.user) {
            this.$log.debug('BetgameTippen created 2')
            if ('' + this.$store.state.betgame.id === this.$route.params.betgame) {
              this.$log.debug('BetgameTippen created 3')
              this.$router.push({ name: 'bet', params: { matchday: this.$route.params.matchday } })
            } else {
              const b1 = this.$store.state.betgames_owner.filter(betgame => betgame.id === parseInt(this.$route.params.betgame))
              const b2 = this.$store.state.betgames_author.filter(betgame => betgame.id === parseInt(this.$route.params.betgame))
              const b3 = this.$store.state.betgames_participant.filter(betgame => betgame.id === parseInt(this.$route.params.betgame))
              if(b1.length > 0 || b2.length > 0 || b3.length > 0) {
                this.$log.debug('BetgameTippen switchBG ')
                const md = this.$route.params.matchday
                this.setBGValue('spmbgid', '' + this.$route.params.betgame)
                if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                  this.setBGValue('betgameId', '' + this.$route.params.betgame)
                }
                this.$store.dispatch('switchBetgame', this.$route.params.betgame).then(() => {
                  this.$router.push({ name: 'bet', params: { matchday: md } })
                })
              } else {
                this.$router.push('/feed/dashboard')
              }
            }
          } else {
            this.$store.dispatch('logout').then(() => {
              this.$router.push('/').then(() => {
                window.location.reload()
              })
            })
          }
        } else {
          this.$router.push('/')
        }
      } else {
        this.$router.push('/')
      }
    },
    mounted() {
      this.$log.debug('BetgameTippen mounted ' + this.$route.params.user + ' - ' + this.$route.params.betgame + ' - ' + this.$route.params.matchday)
    }
  }
  
</script>

<style lang="scss" scoped>
</style>
