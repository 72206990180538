/* eslint-disable */
<template>
  <div class="rules" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">
  
    <keep-alive>
      <NavigationBar id="rulesNavigationBarId" ref="rulesNavigationBarRef">
        <template v-slot:center>
          {{ $t('menu.rules') }}
        </template>
      </NavigationBar>
    </keep-alive>

    <div class="rules__content">
    
      <div v-if="betGameRules" v-html="betGameRules"></div>
      
      <div v-else>

        <div class="input-container">
          <div class="input-header">
            {{ $t('rules.place_bet_title') }}
          </div>
          <div class="input-infotext">
            <p>{{ $t('rules.place_bet_paragraph_1') }}</p>
            <p>{{ $t('rules.place_bet_paragraph_2') }}</p>
            <!-- <p v-if="this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.type === 'TOURNAMENT'">{{ $t('rules.place_bet_paragraph_3') }}</p> -->
            <p v-if="betmode === 1">{{ $t('rules.place_bet_paragraph_3_result') }}</p>
            <p v-if="betmode === 2">{{ $t('rules.place_bet_paragraph_3_toto') }}</p>
            <p v-if="betmode === 1">
              <ul>
                <li>{{ $t('rules.bet_exact_result') }}: {{ pointsBetResult }} {{ $t('points') }}</li>
                <li>{{ $t('rules.bet_goal_difference') }}: {{ pointsBetGoaldiff }} {{ $t('points') }}</li>
                <li v-if="pointsBetTendency !== 1">{{ $t('rules.bet_tendency') }}: {{ pointsBetTendency }} {{ $t('points') }}</li>
                <li v-else>{{ $t('rules.bet_tendency') }}: {{ pointsBetTendency }} {{ $t('point') }}</li>
              </ul>
            </p>
            <p v-if="betmode === 2 && pointsBetResult === 1">{{ $t('rules.place_bet_paragraph_4_toto_point') }}</p>
            <p v-else-if="betmode === 2 && pointsBetResult > 1">{{ $t('rules.place_bet_paragraph_4_toto_points', {points: pointsBetResult}) }}</p>
          </div>
        </div>

        <div v-if="betmode === 1" class="input-container">
          <div class="input-header">
            {{ $t('examples') }}
          </div>
          <table>
            <thead>
                <th>{{ $t('result') }}</th>
                <th>{{ $t('your_bet') }}</th>
                <th>{{ $t('points') }}</th>
            </thead>
            <tbody>
            <tr v-for="(sample, index) in samples" :key="sample.id">
              <td>{{ sample.result }}</td>
              <td>{{ sample.bet }}</td>
              <td>{{ getPoints(sample.points) }}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div v-if="betmode !== 2 || competitionType === 'LEAGUE'" class="input-container">
          <div class="input-header">
            {{ $t('rules.tie_title') }}
          </div>
          <div v-if="isTournament()" class="input-infotext">
            <p>{{ $t('rules.tie_paragraph_1_tournament') }}</p>
          </div>
          <div v-else class="input-infotext">
            <p v-if="isBattle() || isTeamsBetgame()">{{ $t('rules.tie_paragraph_battle') }}</p>
            <p v-else-if="betmode === 1 && sortmode === 1">{{ $t('rules.tie_paragraph_1') }}</p>
            <p v-else-if="betmode === 1 && sortmode === 2">{{ $t('rules.tie_paragraph_2') }}</p>
            <p v-else-if="betmode === 2 && competitionType === 'LEAGUE'">{{ $t('rules.tie_paragraph_toto_league') }}</p>
            <p v-if="!isBattle() && !isTeamsBetgame()">{{ $t('rules.tie_paragraph_3') }}</p>
          </div>
        </div>

        <div v-if="competitionType !== 'LEAGUE'" class="input-container">
          <div class="input-header">
            {{ $t('rules.result_mode_title') }}
          </div>
          <div class="input-infotext">
            <p v-if="resultmode === 3">{{ $t('betgame_settings.result_mode_90m_description') }}</p>
            <p v-else-if="resultmode === 2">{{ $t('betgame_settings.result_mode_120m_description') }}</p>
            <p v-else-if="resultmode === 1">{{ $t('betgame_settings.result_mode_total_description') }}<br>
              {{ $t('betgame_settings.result_mode_total_description_example') }}</p>
            <p>{{ $t('betgame_settings.result_mode_admin_hint') }}</p>
          </div>
        </div>

        <div class="input-container">
          <div class="input-header">
            {{ $t('rules.special_result_title') }}
          </div>
          <div class="input-infotext">
            <p>{{ $t('rules.special_result_text') }}</p>
          </div>
        </div>
        
      </div>
      
    </div>
  </div>
</template>

<script>

import NavigationBar from '../components/NavigationBar'

export default {
    name: 'Rules',
    components: { NavigationBar },
    data () {
      return {
        samples: [
          { id: 0, result: '2:0', bet: '2:0', points: 3 },
          { id: 1, result: '2:0', bet: '3:1', points: 2 },
          { id: 2, result: '2:0', bet: '5:3', points: 2 },
          { id: 3, result: '2:0', bet: '1:0', points: 1 },
          { id: 4, result: '2:0', bet: '1:1', points: 0 },
          { id: 5, result: '2:0', bet: '0:1', points: 0 },
          { id: 6, result: '2:0', bet: '0:2', points: 0 },
          { id: 7, result: '1:3', bet: '1:3', points: 3 },
          { id: 8, result: '1:3', bet: '0:2', points: 2 },
          { id: 9, result: '1:3', bet: '2:4', points: 2 },
          { id: 10, result: '1:3', bet: '0:3', points: 1 },
          { id: 11, result: '1:3', bet: '2:2', points: 0 },
          { id: 12, result: '1:3', bet: '1:0', points: 0 },
          { id: 13, result: '1:3', bet: '2:0', points: 0 },
          { id: 14, result: '1:1', bet: '1:1', points: 3 },
          { id: 15, result: '1:1', bet: '2:2', points: 2 },
          { id: 16, result: '1:1', bet: '0:0', points: 2 },
          { id: 17, result: '1:1', bet: '0:1', points: 0 },
          { id: 18, result: '1:1', bet: '1:0', points: 0 },
        ]
      }
    },
    beforeCreate () {
      if(this.$store.state.betgame) {
        this.$store.dispatch('fetchBetgame', this.$store.state.betgame.id)
      }
    },
    methods: {
      getPoints(points) {
        if(points === 0 || !this.$store.state.betgame) {
          return 0
        } else if(points === 1) {
          return this.pointsBetTendency
        } else if(points === 2) {
          return this.pointsBetGoaldiff
        } else if(points === 3) {
          return this.pointsBetResult
        } else {
          return 0
        }
      },
      openMenu () {
        this.$modal.show('betgame-menu')
      }
    },
    computed: {
      competitionType () {
        if(this.$store.state.betgame && this.$store.state.betgame.competition) {
          return this.$store.state.betgame.competition.type
        }
        return ''
      },
      resultmode() {
        return this.$store.state.betgame.result_mode || RESULT_MODE_TOTAL
      },
      betmode() {
        return this.$store.state.betgame.bet_mode || 1
      },
      sortmode() {
        return this.$store.state.betgame.sort_mode || 1
      },
      pointsBetResult () {
        return this.$store.state.betgame.points_bet_result
      },
      pointsBetGoaldiff () {
        return this.$store.state.betgame.points_bet_goaldiff
      },
      pointsBetTendency () {
        return this.$store.state.betgame.points_bet_tendency
      },
      betGameRules () {
        return this.$store.state.betgame.rules
      },
    }
  }
</script>
<style lang="scss" scoped>
  .rules_header {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .rules__content {
    max-width: 576px;
    margin: 0 auto;
    padding: 0 0 48px;

    @media screen and (max-width: 767px) {
      padding: 16px 0;

      .input-container {
        margin-bottom: 16px;
      }
    }

    ul {
      padding-left: 16px;
    }

    table {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: $colorGreyDark;
      width: 100%;
      margin: 12px 0;
      -webkit-border-horizontal-spacing: 0;

      th, td {
        text-align: center;
        font-weight: normal;
        width: calc(100% / 3);
        padding: 2px 0;
        margin: 0;
      }

      th {
        font-weight: 500;
      }

      tr {

        &:nth-child(even) {
          background: rgba($colorGreyDark, .04);
        } 
      }
    }
  }
</style>
