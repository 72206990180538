import { render, staticRenderFns } from "./EnterPasswordReset.vue?vue&type=template&id=15cb7fac&scoped=true"
import script from "./EnterPasswordReset.vue?vue&type=script&lang=js"
export * from "./EnterPasswordReset.vue?vue&type=script&lang=js"
import style0 from "./EnterPasswordReset.vue?vue&type=style&index=0&id=15cb7fac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15cb7fac",
  null
  
)

export default component.exports