<template>
  <div class="betgametype" id="betgametype">
    <div class="input-container">
      <div class="input-header">
        {{ $t('create_betgame.select_type_title') }}
      </div>
      <div class="input-radio">
        <input id="betgame" v-on:change="betgameTypeSelected" v-model="betgameType" name="betgameType" type="radio" v-bind:value="'betgame'" value="" />
        <label for="betgame">{{ $t('create_betgame.select_type_betgame') }}</label>
        <div class="input-infotext" v-html="$t('create_betgame.select_type_betgame_description')"></div>
      </div>
      <div class="input-radio">
        <input id="battle" v-on:change="betgameTypeSelected" v-model="betgameType" name="betgameType" type="radio" v-bind:value="'battle'" value="" />
        <label for="battle">{{ $t('create_betgame.select_type_battle') }}</label>
        <div class="input-infotext" v-html="$t('create_betgame.select_type_battle_description')"></div>
      </div>
      <div class="input-radio">
        <input id="teams" v-on:change="betgameTypeSelected" v-model="betgameType" name="betgameType" type="radio" v-bind:value="'teams'" value="" />
        <label for="teams">{{ $t('create_betgame.select_type_teams') }}</label>
        <div class="input-infotext" v-html="$t('create_betgame.select_type_teams_description')"></div>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import { TYPE_BATTLE, TYPE_BATTLE_ID, TYPE_BETGAME, TYPE_BETGAME_ID, TYPE_TEAMS, TYPE_TEAMS_ID } from '@/main'

export default {
  name: 'BetgameType',
  components: {},
  props: {
  },
  data () {
    return {
      betgameType: this.$store.state.createBetgame.betgameType
    }
  },
  created () {
    Vue.$log.debug('BetgameType.created ' + this.betgameType)
    const t = this.$route.query.type
    if(t && parseInt(t) === TYPE_BETGAME_ID) {
      this.betgameType = TYPE_BETGAME
      this.$store.dispatch('setCreateBetgame', {
        key: 'betgameType',
        value: this.betgameType
      })
      .then(() => {
        Vue.$log.debug('BetgameType.created ok ' + this.betgameType)
      }).catch(function (error) {
        Vue.$log.debug('BetgameType.created error ' + error)
      })
    } else if(t && parseInt(t) === TYPE_BATTLE_ID) {
      this.betgameType = TYPE_BATTLE
      this.$store.dispatch('setCreateBetgame', {
        key: 'betgameType',
        value: this.betgameType
      })
      .then(() => {
        Vue.$log.debug('BetgameType.created ok ' + this.betgameType)
      }).catch(function (error) {
        Vue.$log.debug('BetgameType.created error ' + error)
      })
    } else if(t && parseInt(t) === TYPE_TEAMS_ID) {
      this.betgameType = TYPE_TEAMS
      this.$store.dispatch('setCreateBetgame', {
        key: 'betgameType',
        value: this.betgameType
      })
      .then(() => {
        Vue.$log.debug('BetgameType.created ok ' + this.betgameType)
      }).catch(function (error) {
        Vue.$log.debug('BetgameType.created error ' + error)
      })
    }
  },
  computed: {},
  methods: {
    betgameTypeSelected() {
      if(this.betgameType && (this.betgameType === TYPE_BATTLE || this.betgameType === TYPE_BETGAME || this.betgameType === TYPE_TEAMS)) {
        this.$store.dispatch('setCreateBetgame', {
          key: 'betgameType',
          value: this.betgameType
        })
        .then(() => {
          Vue.$log.debug('BetgameType.betgameTypeSelected ok ' + this.betgameType)
        }).catch(function (error) {
          Vue.$log.debug('BetgameType.betgameTypeSelected error ' + error)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.betgametype {
  max-width: 400px;
  margin: 16px auto 16px;
}

</style>
