<template>
  <div class="betgamedescription">
    <div class="betgamedescription__logo">
      <img v-if="!isValidIcon(this.$store.state.betgame.icon)" src="../assets/icons/logo_placeholder-new.svg">
      <img v-else v-bind:src="buildIconPath(this.$store.state.betgame.icon, 300)">
      <router-link v-if="!isValidIcon(this.$store.state.betgame.icon) && this.isBetgameAdmin()" to="/betgameediticon" class="betgamedescription__logo__button"></router-link>
    </div>
    <div class="betgamedescription__label">
      {{ betgameName }}
    </div>
    <div class="betgamedescription__sublabel">
      {{ $t('betgame_settings.betgame_about') }}
    </div>
    <p v-if="this.$store.state.betgame && this.$store.state.betgame.name && this.$store.state.betgame.description && this.$store.state.betgame.description.length > 0 && this.$store.state.betgame.name.trim() !== this.$store.state.betgame.description.trim()"
      v-html="this.formatBetgameDescription(this.$store.state.betgame.id, this.$store.state.betgame.description)">
    </p>
    <router-link v-else-if="this.isBetgameAdmin()" :to="{ name: 'betgameeditdescription', params: { betgame: betgame }}" href="" class="button">
      <img src="../assets/icons/ic_12_plus_white.svg" class="button-icon button-icon--left">
      {{ $t('betgame_settings.betgame_add_description') }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'BetgameDescription',
  components: {},
  data () {
    return {}
  },
  beforeCreate () {
  },
  created () {
  },
  mounted () {
  },
  computed: {
    betgame() {
      if(this.$store.state.betgame) {
        return this.$store.state.betgame
      }
      return null
    },
    betgameName() {
      if(this.$store.state.betgame) {
        return this.$store.state.betgame.name
      } else {
        if(this.getBGValue('spmbgid') && parseInt(this.getBGValue('spmbgid')) > 0) {
          this.$store.dispatch('fetchBetgame', this.getBGValue('spmbgid'))
        }
        return ''
      }
    },
  },
  methods: {}
}
</script>

<style lang="scss">
  .betgamedescription {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $colorWhite;
    box-shadow: 0 0 1px 0 rgba($colorGreyDark, .08), 0px 4px 16px rgba($colorGreyDark, 0.08);
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;
    box-sizing: border-box;

    &__logo {
      position: relative;
      margin: 16px 0;

      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 8px;
      }

      &__button {
        cursor: pointer;
        position: absolute;
        width: 28px;
        height: 28px;
        bottom: 0;
        right: 0;
        background: var(--color-primary);
        box-shadow: 0 0 0 2px $colorWhite;
        border-radius: 50%;

        &:before {
          content: '';
          position: absolute;
          top: 8px;
          left: 8px;
          width: 12px;
          height: 12px;
          background: url('../assets/icons/ic_12_plus_white.svg');
        }
      }
    }

    &__label {
      font-size: 17px;
      line-height: 24px;
      font-weight: 500;
      color: $colorGreyDark;
      margin: -2px 0 2px;
    }

    &__sublabel {
      font-size: 14px;
      line-height: 20px;
      color: rgba($colorGreyDark, .48);
      margin-bottom: 24px;
    }

    p {
      width: 100%;
    }
  }
</style>

<style lang="scss" scoped>
  
  .button {
    margin-bottom: 16px;
  }

  @media screen and (max-width:767px) {
    .button {
      display: flex;
      justify-content: center;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 0;
    }
  }
</style>
