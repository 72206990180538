<template>
  <div id="betgame_users_table">
    <div class="searchbar" style="width: 100%; margin-left: -8px;" v-bind:class="{ searchbar_active: searchActive }">
      <div class="searchbar-item searchtype-user">
        <input class="search-input" @input="searchInputChanged" type="text" placeholder="User suchen..." ref="searchinput">
      </div>
      <div v-if="false" class="searchbar-item searchtype-location">
        <select class="search-input" @change="filterChanged($event)" ref="searchinput">
          <option value="">Alle Landkreise</option>
          <option v-for="(src) in usersources" :value="src">{{ src }}</option>
        </select>
      </div>
    </div>  
    <table id="bg_users_table" class="table">
        <thead>
            <th></th>
            <th class="col_avatar"></th>
            <th class="col_user"></th>
            <th class="col_user_source"></th>
            <th class="col_value col_value--all">{{ $t('rankings_table_header_points') }}</th>
            <th v-if="!isBattle() && !isTournament()" class="col_value col_value--all"><img src="../assets/icons/ic_16_star.svg"></th>
        </thead>
        <tbody>
          <tr
                  v-for="(user, index) in betgameUsersFiltered"
                  :key="user.fk_user"
                  v-bind:class="{ current_user: isCurrentUser(user.fk_user) }"
          >
              <td></td>
              <td class="col_avatar" v-if="isValidIcon(getAvatar(user.fk_user))">
                <img v-bind:src="buildAvatarPath(getAvatar(user.fk_user), 300)">
              </td>
              <td :colspan="isValidIcon(getAvatar(user.fk_user)) ? 2 : 3" class="col_user">{{ user.nickname }}</td>
              <td class="col_value col_value--all">0</td>
              <td v-if="!isBattle() && !isTournament()" class="col_value col_value--all">0</td>
          </tr>
        </tbody>
        <tfoot></tfoot>
    </table>
  </div>
</template>

<script>
  export default {
    name: 'BetgameUsersTable',
    data() {
      return {
        betgameUsersFiltered: this.betgameUsers,
        searchString: '',
        usersources: ['Stadt Oldenburg', 'Stadt Emden', 'Stadt Delmenhorst', 'Stadt Wilhelmshaven', 'LK Ammerland',
          'LK Aurich', 'LK Cloppenburg', 'LK Friesland', 'LK Leer', 'LK Oldenburg', 'LK Vechta', 'LK Wesermarsch',
          'LK Wittmund', 'Ostfriesische Inseln'],
        filterString: ''
      };
    },    
    props: {
      betgameUsers: {
        type: Array,
        default: () => {
          return []
        }
      },
      searchActive: {
        type: Boolean,
        default: () => {
          return false
        }
      }
    },
    computed: {
    },
    methods: {
      doFilter() {
        if(this.searchString.length < 2 && this.filterString.length === 0) {
          this.betgameUsersFiltered = [...this.betgameUsers]
        } else {
          this.betgameUsersFiltered = []
          for (var i = 0; i < this.betgameUsers.length; i++) {
            const u = this.betgameUsers[i]
            var add = true
            if(this.searchString.length > 1 && !u.nickname.includes(this.searchString)) {
              add = false
            }
            if(this.filterString.length > 0 && u.source !== this.filterString) {
              add = false
            }
            if(add) {
              this.betgameUsersFiltered.push(u)
            }
          }
        }
      },
      isCurrentUser(userId) {
        if(this.$store.state.user) {
          return this.$store.state.user.id === userId
        }
      },
      filterChanged(event) {
        this.filterString = event.target.value
        this.doFilter()
      },
      searchInputChanged({ type, target }) {
        this.searchString = target.value.trim()
        this.doFilter()
      },
    },
    watch: {
      searchActive: function (newValue) {
        if(newValue === false && (this.searchString.trim().length > 1 || this.filterString.length > 0)) {
          this.searchString = ''
          this.filterString = ''
          this.betgameUsersFiltered = [...this.betgameUsers]
        } else if(newValue === true) {
          this.$refs.searchinput.value = ''
          this.filterString = ''
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    
    .table {
      width: 100%;
      margin: 0px;

      @media screen and (max-width: 767px) {
        width: calc(100% + 16px);
        margin: 0 -8px;
      }

      td, th {
        border-bottom: 1px solid rgba($colorGreyLight, .08);

        /*&:first-child {
          max-width: 4px;
        }*/

        thead & {
          font-weight: 400;
          color: $colorGreyLight;
        }
      }
    }

    .col_value {
      text-align: right;
      overflow: hidden;
      font-weight: 400;

      &:last-child {
        padding-left: 4px;
        padding-right: 8px;
        text-align: center;
      }

      &--all, &--points, &--questions {
        max-width: 32px;
        min-width: 24px;
        padding: 0 4px;
        text-align: center;

        img {
          margin-bottom: -3px;
        }
      }

      &--all {
        font-weight: 500;

        thead & {
          font-weight: 400;
        }
      }
    }
    
    .col_user {
      text-align: center;
      overflow: hidden;
      padding-right: 16px;
      max-width: 88px;
      padding-right: 8px;
    }

    .col_user_source {
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      max-width: 64px;
    }

    .current_user {
        background-color: #F2F2F2;
    }

    .col_avatar {
      padding-right: 8px;
      position: relative;
      min-width: 24px;
      height: 18px;

      img {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        object-fit: cover;
        border-radius: 3px;
      }
    }
</style>
