<template>
  <div class="betgamename" id="betgamename">
    <div class="input-container" style="height: 100%">
      <div class="input-header">
        {{ $t('create_betgame.betgame_name') }}
      </div>
      <div class="input-infotext">
        {{ $t('create_betgame.betgame_name_description') }}
      </div>
      <div class="input-element" v-bind:class="{ validation_true: nameValid === true, validation_false: betgameName.length > 0 && nameValid === false }">
        <input @focusout="handleBetgameNameFocus" v-model="betgameName" name="betgameName" type="text" id="tipprunde-name" placeholder="tipprunde-name"/>
        <label for="tipprunde-name">{{ $t('create_betgame.betgame_name') }}</label>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'

export default {
  name: 'BetgameName',
  components: {},
  props: {
  },
  data () {
    return {
      betgameName: (this.$store.state.createBetgame.betgameName.length > 0 ? this.$store.state.createBetgame.betgameName : '')
    }
  },
  beforeCreate () {
    Vue.$log.debug('BetgameName.beforeCreate ' + this.betgameName)
  },
  created () {
    Vue.$log.debug('BetgameName.created ' + this.betgameName)
  },
  mounted () {
    Vue.$log.debug('BetgameName.mounted ' + this.betgameName)
  },
  computed: {
    nameValid () {
      if(this.betgameName === null || this.betgameName === undefined) {
        return false
      }
      return this.betgameName.trim().length >= 3 && this.betgameName.trim().length < 100
    }
  },
  methods: {
    handleBetgameNameFocus() {
      //if(this.betgameName && this.betgameName.length > 3) {
        this.$store.dispatch('setCreateBetgame', {
          key: 'betgameName',
          value: this.betgameName
        })
        .then(() => {
          Vue.$log.debug('BetgameName.handleBetgameNameFocus ok ' + this.betgameName)
        }).catch(function (error) {
          Vue.$log.debug('BetgameName.handleBetgameNameFocus error ' + error)
        })
      //}
    }
  }
}
</script>

<style lang="scss" scoped>

.betgamename {
  max-width: 400px;
  margin: 16px auto 16px;
}

</style>
