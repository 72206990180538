<template>
  <div id="bg_rankings_table">
    <table class="table">
        <thead>
            <th v-if="matchRunning" style="color: red" class="col_pos"><span class="match_result_live">Live</span></th>
            <th v-else class="col_pos"></th>
            <th class="col_avatar"></th>
            <th class="col_user"></th>
            <th class="col_user_source"></th>
            <th class="col_value">{{ $t('rankings_table_header_points') }}</th>
            <th v-if="!isBattle() && !isTournament() && !isTeamsBetgame()" class="col_value col_value--points"><img src="../assets/icons/ic_16_star.svg"></th>
        </thead>
        <tbody></tbody>
        <tr
                v-for="(ranking, index) in rankings"
                :key="ranking.fk_user"
                v-bind:class="{ current_user: isCurrentUser(ranking.fk_user) }"
        >
            <td v-if="!equalPoints(index)" class="col_pos">{{ ranking.position }}.</td>
            <td v-else></td>       
            <td class="col_avatar" v-if="isValidIcon(getAvatar(ranking.fk_user))">
              <img v-bind:src="buildAvatarPath(getAvatar(ranking.fk_user), 300)">
            </td>
            <td :colspan="isValidIcon(getAvatar(ranking.fk_user)) ? 2 : 3" class="col_user">{{ ranking.user_name }}</td>
            <td class="col_value">{{ ranking.points_total }}</td>
            <td v-if="!isBattle() && !isTournament() && !isTeamsBetgame()" class="col_value">{{ ranking.matchday_wins }}</td>
        </tr>
        <tfoot>
        </tfoot>
    </table>
        
    <router-link v-if="this.showButton" to="/rankings">
      <button>{{ $t('dashboard.button_view_rankings_total') }}</button>
    </router-link>
  </div>
</template>

<script>
  export default {
    name: 'RankingsTable',
    props: {
      showButton: true,
      rankings: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    computed: {
      matchRunning() {
        return this.$store.getters.matchWithLiveGoals
      },
      sortmode() {
        return this.$store.state.betgame.sort_mode || 1
      }
    },
    methods: {
      equalPoints(index) {
        if(index === 0 || !this.rankings || this.rankings.length <= index) {
          return false
        }
        const r1 = this.rankings[index]
        const r2 = this.rankings[index - 1]
        if(this.sortmode === 1) {
          if (r1.points_total === r2.points_total && r1.matchday_wins === r2.matchday_wins && r1.exact === r2.exact && r1.goaldiff === r2.goaldiff) {
            return true
          }
        } else if(this.sortmode === 2) {
          if (r1.points_total === r2.points_total && r1.exact === r2.exact && r1.matchday_wins === r2.matchday_wins && r1.goaldiff === r2.goaldiff) {
            return true
          }
        }
        return false
      },      
      getMatchdayWins (user) {
        return this.$store.getters.getMatchdayWins(user)
      },
      isCurrentUser(userId) {
        if(this.$store.state.user) {
          return this.$store.state.user.id === userId
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    
    .table {

        td, th {
            border-bottom: 1px solid rgba($colorGreyLight, .08);

            &:first-child {
                //max-width: 4px;
            }

            thead & {
                font-weight: 400;
                color: $colorGreyLight;
            }
        }
    }

    .col_value {
        text-align: right;
        overflow: hidden;

        &:last-child {
            padding-left: 16px;
            text-align: center;
        }

        &--all, &--points, &--questions {

          img {
            margin-bottom: -3px;
          }
        }
    }

    .col_avatar {
      padding-right: 8px;
      position: relative;
      min-width: 24px;
      height: 18px;

      img {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        object-fit: cover;
        border-radius: 3px;
      }
    }

    .col_user {
      text-align: center;
      overflow: hidden;
      padding-right: 16px;
      max-width: 88px;
      padding-right: 8px;
      line-height: 0;
    }

    .col_user_source {
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      max-width: 64px;
    }

    .current_user {
        background-color: #F2F2F2;
    }

    .col_pos {
        text-align: center !important;
        overflow: hidden;
        padding-right: 4px;
    }
    
    .match_result_live {
        color: var(--color-primary);
        font-weight: 500;
        text-transform: uppercase;
        position: relative;
        padding-right: 12px;
        font-size: 14px;
        opacity: .24;
        -webkit-transform: scale(0.2);
        transform: scale(0.2);
        -webkit-animation: live-data-v-43a5ba3a 3.4s infinite;
        animation: live-data-v-43a5ba3a 3.4s infinite;
    
        &:before {
            content: '';
            position: absolute;
            right: 0;
            top: 5px;
            width: 6px;
            height: 6px;
            border-radius: 3px;
            background: var(--color-primary);
        }
    }

    button {
      margin-bottom: 16px;
    }
</style>
