<template>
  <div class="profileChangeUserAge" id="profileChangeUserAge">

    <NavigationBar>
      <template v-slot:left>
        <img v-on:click="goBack" class="back" src="../assets/icons/ic_16_back.svg">
      </template>
      <template v-slot:center>
        {{ $t('profile.age_rating') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>

    <form @submit.prevent="isUnder18Changed">

      <div class="input-container">
        
        <div class="input-checkbox">
          <input v-on:change="isUnder18Changed" id="isUnder18" name="isUnder18" type="checkbox" v-model="isUnder18"/>
          <label for="isUnder18" style="border-bottom: 0px;">{{ $t('create_betgame.i_am_over_18') }}</label>
        </div>

        <div class="input-button-row">
          <button type="submit" name="button">
            {{ $t('button_save_text') }}
          </button>
        </div>

      </div>

    </form>

    <ul class="error-container">
      <li v-for="error in errors">{{ error }}</li>
    </ul>

  </div>
</template>

<script>

import NavigationBar from './../components/NavigationBar'
import { TOAST_DURATION } from '../main'
import Vue from 'vue'
import Numeral from 'numeral'
import moment from 'moment'

export default {
  name: 'profileChangeUserAge',
  components: { NavigationBar },
  data () {
    return {
      errors: [],
      isUnder18: false
    }
  },
  mounted () {
    this.isUnder18 = this.$store.state.user.isadult
  },
  methods: {
    goBack () {
      this.$router.push('/profile')
    },
    isUnder18Changed () {
      this.errors = []
      this.$store.dispatch('patchUser', {isadult: this.isUnder18}).then(() => {
      })
    },
    isNullOrUndefined (data) {
      return data === null || data === undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.profileChangeUserAge {

  form {
    padding-top: 16px;
  }

  h1 {
    text-align: center;
  }

  p {
    text-align: center;
    margin-bottom: 26px;
  }
}
</style>
