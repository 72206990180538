<template>
  <div class="dashboardranking">

    <div class="dashboardranking__header">
      <div class="dashboardranking__header__label">
        {{ $t('menu.total_ranking') }}
      </div>
      <div class="dashboardranking__header__value">
        {{ $t('rankings_table_header_points') }}.
      </div>
      <div v-if="isBetgame() && !isTournament()" class="dashboardranking__header__value">
        <img src="@/assets/icons/ic_16_star.svg">
      </div>
    </div>

    <div class="dashboardranking__content">
      
      <div v-for="(ranking, index) in rankings" :key="ranking.fk_user" v-bind:class="{ 'dashboardranking__item--highlighted': isCurrentUser(ranking.fk_user) }" class="dashboardranking__item">
        <div v-if="!equalPoints(index)" class="dashboardranking__item__position">
          {{ ranking.position }}.
        </div>
        <div v-else class="dashboardranking__item__position">
        </div>
        <img v-if="isValidIcon(getAvatar(ranking.fk_user))" v-bind:src="buildAvatarPath(getAvatar(ranking.fk_user), 300)" class="dashboardranking__item__avatar">
        <div class="dashboardranking__item__username">
          {{ ranking.user_name }}
        </div>
        <div class="dashboardranking__item__value">
          {{ ranking.points_total }}
        </div>
        <div v-if="isBetgame() && !isTournament()" class="dashboardranking__item__value">
          {{ ranking.matchday_wins }}
        </div>
      </div>
      
      <!--
       TODO @Lennard: Habe hier drei mal das Item dargestellt, um zu veranschaulichen, dass der mittlere der fünf Einträge durch die zusätzliche Klasse ".dashboardranking__item--highlighted" hervorgehoben werden soll 
      <div class="dashboardranking__item dashboardranking__item--highlighted">

        <TODO @Lennard: Sofern bei einem der fünf Einträge die Position vierstellig ist, soll dieses DIV bei allen Einträgen um die Klasse ".dashboardranking__item__position--highnumber" erweitert werden 
        <div class="dashboardranking__item__position">
          2
        </div>
        <img src="" class="dashboardranking__item__avatar">
        <div class="dashboardranking__item__username">
          Dirk Schuster
        </div>
        <div class="dashboardranking__item__value">
          311
        </div>
        <div class="dashboardranking__item__value">
          7
        </div>
      </div>
      -->

    </div>

    <router-link to="/rankings" class="button">{{ $t('dashboard.button_view_rankings_total') }}</router-link>
  </div>
</template>

<script>
export default {
  name: 'DashboardRanking',
  props: {
    rankings: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  components: {},
  data () {
    return {}
  },
  beforeCreate () {
  },
  created () {
  },
  mounted () {
  },
  computed: {
    sortmode() {
      return this.$store.state.betgame.sort_mode || 1
    }
  },
  methods: {
    equalPoints(index) {
      if(index === 0 || !this.rankings || this.rankings.length <= index) {
        return false
      }
      const r1 = this.rankings[index]
      const r2 = this.rankings[index - 1]
      if(this.sortmode === 1) {
        if (r1.points_total === r2.points_total && r1.matchday_wins === r2.matchday_wins && r1.exact === r2.exact && r1.goaldiff === r2.goaldiff) {
          return true
        }
      } else if(this.sortmode === 2) {
        if (r1.points_total === r2.points_total && r1.exact === r2.exact && r1.matchday_wins === r2.matchday_wins && r1.goaldiff === r2.goaldiff) {
          return true
        }
      }
      return false
    },
    isCurrentUser(userId) {
      if(this.$store.state.user) {
        return this.$store.state.user.id === userId
      }
    }
  }
}
</script>

<style lang="scss">
  .dashboardranking {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: $colorWhite;
    box-shadow: 0 0 1px 0 rgba($colorGreyDark, .08), 0px 4px 16px rgba($colorGreyDark, 0.08);
    border-radius: 8px;
    margin-bottom: 24px;

    &__header {
      display: flex;
      align-items: center;
      padding: 16px 0 12px 16px;
      color: $colorGreyDark;
      box-sizing: border-box;

      &__label {
        flex: 1;
        font-size: 17px;
        font-weight: 500;
      }

      &__value {
        font-size: 14px;
        opacity: .48;
        text-align: right;

        &:last-child {
          display: flex;
          justify-content: center;
          width: 48px;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__item {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      color: $colorGreyDark;
      padding: 10px 0;

      &:not(:last-child) {
        box-shadow: inset 0 -1px 0 0 rgba($colorGreyDark, .08)
      }

      &--highlighted {
        background: rgba($colorGreyDark, .08);
        box-shadow: none !important;
      }

      &__position {
        width: 40px;
        text-align: center;

        &--highnumber {
          width: 48px;
        }
      }

      &__avatar {
        width: 24px;
        height: 24px;
        border-radius: 3px;
        object-fit: cover;
        margin-right: 8px;
      }

      &__username {
        flex: 1;
      }

      &__value {
        text-align: right;

        &:last-child {
          text-align: center;
          width: 48px;
        }
      }
    }

    .button {
      margin: 12px 16px 16px;
      text-align: center;
    }
  }
</style>
