<template>
	<div class="chat__input">
    <div class="chat__input__content">
      <textarea v-model="textAreaText" @keypress.enter="enterPressed" ref="textArea" placeholder="" onInput="this.parentNode.dataset.replicatedValue = this.value"></textarea>
      <div v-on:click="sendTextClicked" class="chat__input__send"></div>
    </div>
    <ChatAnswer v-if="answer" @answerClosed="answerClosed" :username="answerUser" :text="answerText" closeable></ChatAnswer>
    <div class="chat__input__background"></div>
  </div>
</template>

<script>
  import ChatAnswer from '../../components/chat/ChatAnswer'

	export default {
	  name: 'ChatInput',
    components: { ChatAnswer },
    props: {
      answer: Boolean,
      answerUser: String,
      answerText: String,
      answerMessageId: String
    },
	  data () {
	    return {
        textAreaText: ''
      }
	  },
	  beforeCreate () {
	  },
	  methods: {
      enterPressed(e) {
        if(e.key === 'Enter') {
          e.stopPropagation()
          //this.sendTextClicked()
        }
      },
      sendTextClicked() {
        if(this.textAreaText.trim().length > 0) {
          if (this.answer && this.answerMessageId) {
            this.$emit('textEntered', { text: this.textAreaText, in_response_to: this.answerMessageId })
          } else {
            this.$emit('textEntered', { text: this.textAreaText })
          }
        }
        this.textAreaText = ''
        this.$refs.textArea.value = ''
        this.$refs.textArea.parentNode.dataset.replicatedValue = ''
      },
      answerClosed() {
        this.$emit('answerClosed', {  })
      }
    },
	  computed: {}
	}
</script>

<style lang="scss">

	.chat__input {
      position: sticky;
      z-index: 2;
      bottom: 16px;
      display: flex;
      flex-direction: column;
      margin: 16px 12px 0;
      box-sizing: border-box;
      border-radius: 24px;
      overflow: hidden;

      &__content {
        position: relative;
        display: flex;
        align-items: flex-end;
        flex: 1;

        &:before {
          content: attr(data-replicated-value) " ";
          white-space: pre-wrap;
          visibility: hidden;
          font-size: 16px;
          padding: 14px 4px 14px 20px;
          width: 100%;
          word-break: break-word;
          max-height: 104px;
        }
      }

      &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $colorWhite;
        border-radius: 24px;
        box-shadow: inset 0 0 0 1px rgba($colorGreyDark, .24);
        transition: all .2s ease-in-out;
      }

      textarea {
        position: absolute;
        z-index: 1;
        padding: 14px 4px 14px 20px;
        box-sizing: border-box;
        margin: 0;
        border: 0;
        outline: none;
        width: calc(100% - 44px);
        height: 100%;
        font-size: 16px;
        resize: none;
        line-height: 20px;
        background: none;
        color: $colorGreyDark;
        transition: all .2s ease-in-out;

        &:hover {
          ~ .chat__input__background {
            box-shadow: inset 0 0 0 1px rgba($colorGreyDark, .32);
          }
        }

        &:focus, &:active {
          outline: none;

          ~ .chat__input__background {
            box-shadow: inset 0 0 0 1px rgba($colorGreyDark, .32);
          }
        }

        &:not(:placeholder-shown) {
          ~ .chat__input__send {
            margin-right: 0;
            opacity: 1;
          }
        }
      }

      &__send {
        position: relative;
        z-index: 3;
        display: flex;
        opacity: 0;
        margin-right: -44px;
        flex-shrink: 0;
        position: relative;
        padding: 8px 8px 8px 4px;
        width: 32px;
        height: 32px;
        box-sizing: content-box;
        border-radius: 50%;
        cursor: pointer;
        transition: all .2s ease-in-out;

        &:before {
          z-index: 2;
          position: absolute;
          content: url('../../assets/chat/ic_16_send.svg');
          padding: 8px;
          box-sizing: border-box;
          width: 32px;
          height: 32px;
          background: var(--color-primary);
          border-radius: 50%;
          box-shadow: 0 4px 8px 0 rgba(var(--color-primary-rgb), .16);
        }
      }
    }
</style>
