<template>
  <div style="border-radius: 10px; border: #F54D42 4px solid; padding: 5px; background-color: white; 
             margin: 16px auto 0px; max-width: 458px;">
    <div class="dashboard-text" style="padding: 2px;">
      <h1 style="color: #F54D42">{{ $t('dashboard.finish_registration') }}</h1>
      <p style="color: #5F5D61">{{ $t('dashboard.please_enter_validation_code', {email: this.$store.state.user.email}) }}</p>
      <router-link :to="{name: 'validateregistration'}">
        <button @click="setEnterCodeClicked(1)" style="margin-top: 10px;" id="validate_registration">{{ $t('dashboard.button_enter_validation_code') }}</button>
      </router-link>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'ValidationPending',
  components: { },
  data () {
    return {}
  },
  beforeCreate () {
    Vue.$log.debug('ValidationPending beforeCreate')
  },
  created () {
    Vue.$log.debug('ValidationPending created ')
  },
  mounted () {
    Vue.$log.debug('ValidationPending mounted')
  },
  beforeDestroy () {

  },
  methods: {
    setEnterCodeClicked(val) {
      this.setBGValue('spmvr', val)
    }
  },
  computed: {}
}
</script>

<style lang="scss" scoped>

</style>
