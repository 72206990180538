/* eslint-disable */
<template>
  <div :key="componentKey" class="betgame_welcome" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">
  
    <NavigationBar >
      <template v-slot:left>
        <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="searchBetgame">
      </template>
      <template v-slot:center>
        {{ $t('betgame_welcome.join_betting_group') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>

    <div v-if="!this.isApp()" class="banner">
      <div class="banner__content">
        <img src="../assets/landingpage/logo-teamtip.svg">
        <div class="banner__text">
          <h1>{{ $t('create_betgame.welcome_to_teamtip') }}</h1>
          <p>{{ $t('betgame_welcome.teamtip_info') }}</p>
        </div>
      </div>
    </div>
    
    <div class="team-list" :key="betgameId">
      <img v-if="this.$store.state.betgame && this.$store.state.betgame.icon"  v-bind:src="buildIconPath(this.$store.state.betgame.icon, 300)" class="logo">
      <img v-else src="../assets/icons/ic_64_logo.svg">
      <h1 v-if="this.$store.state.betgame">{{ this.$store.state.betgame.name }}</h1>
      <span v-if="this.$store.state.betgame && this.$store.state.betgame.competition && parseInt(this.$store.state.betgame.type) !== batlleid && parseInt(this.$store.state.betgame.type) !== teamsid" class="league">{{ this.$store.state.betgame.competition.name }}</span>
      <p v-html="this.formatBetgameDescription(this.betgameId, this.$store.state.betgame.description)" v-if="this.$store.state.betgame && this.$store.state.betgame.description && this.$store.state.betgame.description !== this.$store.state.betgame.name"></p>
    </div>
  
    <div v-if="!loggedIn" class="team-list">
      {{ $t('betgame_welcome.already_registered') }}
      <button v-on:click="doLogin">{{ $t('betgame_welcome.button_login_and_place_bets') }}</button>
    </div>
  
    <div v-if="!loggedIn" class="team-list">
      {{ $t('betgame_welcome.not_registered_now') }}
      <button v-on:click="doRegister">{{ $t('betgame_welcome.button_register_and_place_bets') }}</button>
    </div>

    <div v-if="loggedIn" class="team-list">
      {{ $t('betgame_welcome.join_betgroup') }}
      <button v-on:click="enterBetgame">{{ $t('betgame_welcome.button_join_and_place_bets') }}</button>
    </div>

    <div v-if="loggedIn" class="team-list">
      {{ $t('betgame_welcome.dont_join_betgroup') }}
      <button v-on:click="searchBetgame">{{ $t('betgame_welcome.button_back_to_overview') }}</button>
    </div>
    
  </div>
</template>

<script>
import NavigationBar from './../components/NavigationBar'
import Vue from 'vue'
import { TOAST_DURATION, TYPE_BATTLE_ID, TYPE_TEAMS_ID } from '../main'

export default {
  name: 'BetgameWelcome',
  components: { NavigationBar },
  data() {
    return {
      betgameId: null,
      code: null,
      componentKey: 0
    }
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn
    },
    batlleid() {
      return TYPE_BATTLE_ID
    },
    teamsid() {
      return TYPE_TEAMS_ID
    }
  },
  beforeCreate() {
    if(this.$route.params) {
      this.$log.debug('BetgameWelcome beforeCreate ' + this.$route.params.id + ' - ' + this.$route.params.name + ' - ' + this.$route.params.code)
    } else {
      this.$log.debug('BetgameWelcome beforeCreate no params')
    }
  },
  created () {
    if(this.$route.params) {
      this.$log.debug('BetgameWelcome created '  + this.$route.params.id + ' - ' + this.$route.params.name + ' - ' + this.$route.params.code)
    } else {
      this.$log.debug('BetgameWelcome created no params')
    }
    if(this.$store.getters.loggedIn && this.$route.params && this.$route.params.id && 
        this.$store.state.betgame && this.$store.state.betgame.id && 
        parseInt(this.$route.params.id) === parseInt(this.$store.state.betgame.id)) {
      this.$log.debug('BetgameWelcome goto dashboard ' + this.$store.state.betgame.id)
      this.$router.push({ name: 'dashboard'})
    } else if(this.$store.getters.loggedIn && this.$route.params && this.$route.params.id && 
        this.$store.state.betgame && this.$store.state.betgame.id &&
        this.$store.getters.isUserConnectedToBetgame(this.$route.params.id) && 
        this.$store.getters.isUserBetgameParticipant(this.$route.params.id)) {
      this.$log.debug('BetgameWelcome isUserConnectedToBetgame ' + this.$store.state.betgame.id)
      this.clearBGValue('spmbgid')
      if (navigator.userAgent.indexOf('TEAMTIP') > 0) {
        this.clearBGValue('betgameId')
      }
      this.$router.push({ name: 'mybetgames'})
    } else {
      if (this.$route.params.id && this.$route.params.code) {
        this.betgameId = this.$route.params.id
        this.code = this.$route.params.code
        const that = this
        this.$store.dispatch('checkInvitation', [this.betgameId, this.code]).then(() => {
          const that = this
          const betgameid = this.$route.params.id
          this.setBGValue('spmbgid', betgameid)
          this.setBGValue('spmic', this.$route.params.code)
          if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
            this.setBGValue('betgameId', betgameid)
          }
          this.$store.dispatch('fetchBetgame', betgameid).then(() => {
            //this.$store.dispatch('fetchBetgameLeague', betgameid).then(function (response) {
              that.$log.debug('BetgameWelcome created loaded '  + betgameid)
              that.componentKey += 1
            //})
          })
        }).catch(function (error) {
          Vue.$log.debug('BetgameWelcome checkInvitation ' + error)
          that.$toast.open({ message: that.$i18n.t('register.invitation_link_invalid'), position: 'top', duration: TOAST_DURATION, type: 'error' })
          that.clearBGValue('spmbgid')
          if (navigator.userAgent.indexOf('TEAMTIP') > 0) {
            that.clearBGValue('betgameId')
          }
          if (that.$store.getters.loggedIn) {
            that.$router.push({ name: 'mybetgames' })
          } else {
            that.$router.push({ name: 'home' })
          }
        })
      } else {
        this.$toast.open({ message: this.$i18n.t('register.invitation_link_invalid'), position: 'top', duration: TOAST_DURATION, type: 'error' })
        this.clearBGValue('spmbgid')
        if (navigator.userAgent.indexOf('TEAMTIP') > 0) {
          this.clearBGValue('betgameId')
        }
        if (this.$store.getters.loggedIn) {
          this.$router.push({ name: 'mybetgames' })
        } else {
          this.$router.push({ name: 'home' })
        }
      }
    }
  },
  mounted() {
    if(this.$route.params) {
      this.$log.debug('BetgameWelcome mounted ' + this.$route.params.id + ' - ' + this.$route.params.name)
    } else {
      this.$log.debug('BetgameWelcome mounted no params')
    }
  },
  methods: {
    doLogin(betgame) {
      window.localStorage.setItem('spmbgid', this.betgameId)
      if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
        window.localStorage.setItem('betgameId', this.betgameId)
      }
      this.$router.push({ name: 'login', params: { action: 'join', betgame: this.betgameId }})
    },
    doRegister() {
      window.localStorage.setItem('spmbgid', this.betgameId)
      if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
        window.localStorage.setItem('betgameId', this.betgameId)
      }
      this.$router.push({ name: 'register'})
    },
    enterBetgame() {
      if (this.$store.getters.loggedIn) {
        const that = this
        this.$store.dispatch('userEnterBetgame', this.betgameId).then(() => {
          this.$log.debug('enterBetgame1 ' + this.betgameId)
          this.setBGValue('spmbgid', this.betgameId)
          if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
            this.setBGValue('betgameId', this.betgameId)
          }
          this.$store.dispatch('switchBetgame', this.betgameId).then(() => {
            this.$log.debug('enterBetgame2 ' + this.betgameId)
            this.$store.dispatch('fetchBetgame', this.betgameId).then(() => {
              that.$store.dispatch('addBetgamesParticipant', that.$store.state.betgame).then(() => {
                that.$router.push('/feed/dashboard')
              })
            })
          })
        })
      }
    },
    searchBetgame() {
      if (this.$store.getters.loggedIn) {
        if(this.$store.state.userBetgames && this.$store.state.userBetgames.length > 0) {
          const switchId = this.$store.state.userBetgames[0].id
          this.$store.dispatch('clearSelectedBetgame', 0).then(() => {
            this.setBGValue('spmbgid', switchId)
            if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
              this.setBGValue('betgameId', switchId)
            }
            this.$store.dispatch('switchBetgame', switchId).then(() => {
              this.$router.push({ name: 'mybetgames'})
            })
          })
        } else {
          this.$store.dispatch('clearSelectedBetgame', 0).then(() => {
            this.$router.push({ name: 'mybetgames'})
          })
        }
      } else {
        this.$router.push({ name: 'home' }).catch(err => {
        })
      }
    }
  }
}
</script>

<style lang="scss">
  
  .team-list {
    max-width: 528px;
    margin: 16px auto;
    padding: 16px 16px 16px 16px;
    text-align: center;
    background: $colorWhite;
    box-shadow: 0 8px 24px 0 rgba(49,49,55,0.08);
    border-radius: 11px;
    overflow: hidden;

    img {
      margin-top: 32px;
      margin-bottom: 22px;
    }

    .logo {
      width: 64px;
      height: 64px;
      border-radius: 6px;
      overflow: hidden;
      object-fit: cover;
    }

    .league {
      color: $colorGreyMid;
    }

    p {
      text-align: left;
      margin-top: 32px;
    }

    button {
      margin-top: 34px;
    }
  }

  .banner {
    background: url('../assets/landingpage/header-background.jpg');
    width: calc(100% + 32px);
    margin: 0 -16px 40px;
    padding: 24px 0;

    @media screen and (max-width: 528px) {
      padding: 16px 0;
      margin: 0 -16px 24px;
    }

    &__content {
      display: flex;
      width: 100%;
      max-width: 528px;
      margin: 0px auto;

      @media screen and (max-width: 528px) {
        padding: 0 16px;
        box-sizing: border-box;
      }
    }

    &__text {
      margin-left: 24px;

      h1 {
        font-size: 17px;
      }

      p {
        font-size: 14px;
        opacity: .64;
      }

      h1, p {
        color: $colorWhite;
      }
    }
  }
  
</style>
