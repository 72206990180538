<template>
  <div class="bwin-banner" v-on:click="deepLinkClicked">
    <div class="bwin-banner__logo">
      <img src="@/assets/icons/ic_logo_bwin_small_white.svg">
    </div>
    <div class="bwin-banner__text">
      Im Team der <span>Tippkönig</span>,<br>
      aber beim <span>Wetter</span> kneifst du?
    </div>
    <div class="bwin-banner__cta">
      <img src="@/assets/icons/ic_16_arrow_white.svg">
    </div>
  </div>
</template>

<script>
export default {
  name: 'BwinBanner',
  components: { },
  data () {
    return {
      deepLink: 'https://sports.bwin.de/de/sports/fussball-4/wetten?wm=5047188'
    }
  },
  beforeCreate () {
  },
  created () {
  },
  mounted () {
  },
  computed: {},
  methods: {
    deepLinkClicked() {
      if(this.deepLink) {
        this.$gtag.event('dashboard.top', {
          'event_category': 'bwin',
          'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
          'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
          'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set',
          'dimension5': this.$store.state.betgame && this.$store.state.betgame.type ? this.$store.state.betgame.type : 'betgame type not set'
        })
        if(window.parent) {
          window.parent.open(this.deepLink, '_blank')
        } else {
          window.open(this.deepLink, '_blank')
        }
      }
    },    
  }
}
</script>

<style lang="scss">
  .bwin-banner {
    position: relative;
    display: flex;
    align-items: center;
    background: #000000;
    border-radius: 8px;
    width: 100%;
    color: $colorWhite;
    padding: 10px 16px;
    box-sizing: border-box;
    cursor: pointer;
    margin-top: 16px;
    margin-bottom: 24px;

    &:before {
      position: absolute;
      content: '-W-';
      top: 4px;
      left: 4px;
      font-size: 9px;
      color: $colorWhite;
    }

    &__logo {
      flex: 1;
    }

    &__text {
      position: relative;
      z-index: 1;
      margin: auto;
      font-size: 11px;
      line-height: 16px;
      text-align: center;

      &:before {
        position: absolute;
        content: '🤔';
        font-size: 32px;
        top: -8px;
        right: -24px;
      }

      span {
        position: relative;
        color: #000000;
        font-weight: 500;
        margin: 0 5px 0 3px;

        &:after {
          content: '';
          position: absolute;
          z-index: -1;
          background: #F3DD16;
          top: -2px;
          left: -3px;
          width: calc(100% + 6px);
          height: calc(100% + 4px);
          transform: rotate(-1.52deg);
        }

        &:last-of-type {
          margin: 0 3px;

          &:after {
            transform: rotate(2deg);
          }
        }
      }
    }

    &__cta {
      display: flex;
      justify-content: flex-end;
      flex: 1;

      img {
        background: rgba($colorWhite, .24);
        border-radius: 2px;
        padding: 4px;
        transition: all .2s ease-in-out;

        &:hover {
          background: rgba($colorWhite, .28);
          transform: scale(1.05);
        }
      }
    }
  }
</style>
