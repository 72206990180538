<template>

  <div class="specialquestiondetail" id="specialQuestionDetail" style="margin-top: 16px;" v-bind:style="{ paddingBottom: '64px' }">

    <keep-alive>
      <NavigationBar id="specialQuestionNavigationBarId" ref="specialQuestionNavigationBarRef">
        <template v-slot:left>
          <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
        </template>
        <template v-slot:center>
          {{ $t('menu.special_questions') }}
        </template>
        <template v-slot:right>
        </template>
      </NavigationBar>
    </keep-alive>

    <div class="list">

      <div v-if="question.answer_count < 2" class="table-view-title">
        {{ question.question }}
      </div>
      <div v-else class="table-view-title">
        {{ question.question }} ({{ question.answer_count }} {{ $t('special_questions.answers') }})
      </div>
      
      <div>
        <div v-if="question.answer_count === 1 && question.type !== 'TEAM'">
          <div class="table-view-cell">
            <div class="table-view-cell-label">
              {{ $t('special_questions.solution') }}
            </div>
            <div v-if="solutions.length === 1 && question.type === 'TEAM'" class="table-view-cell-value">
              {{ getTeamFullName(parseInt(solutions[0].solution), '') }}
            </div>
            <div v-else-if="solutions.length === 1 && question.type === 'NUMBER'" class="table-view-cell-value">
              {{ solutions[0].solution }}
            </div>
            <div v-else-if="solutions.length === 1 && question.type === 'BOOL' && solutions[0].solution === '1'"
                 class="table-view-cell-value">
              {{ $t('yes') }}
            </div>
            <div v-else-if="solutions.length === 1 && question.type === 'BOOL' && solutions[0].solution === '0'"
                 class="table-view-cell-value">
              {{ $t('no') }}
            </div>
            <div v-else class="table-view-cell-value">
              {{ $t('special_questions.solution_pending') }}
            </div>

          </div>
        </div>
        <div v-if="question.answer_count > 0 && question.type === 'TEAM'">
          <div v-if="solutions && solutions.length > 0" v-for="sol in solutions"
               class="table-view-cell">
            <div class="table-view-cell-label">
              {{ $t('special_questions.solution') }}
            </div>
            <div class="table-view-cell-value">
              {{ getTeamFullName(parseInt(sol.solution), '') }}
            </div>
          </div>
          <div v-if="!solutions || solutions.length === 0" class="table-view-cell">
            <div class="table-view-cell-label">
              {{ $t('special_questions.solution') }}
            </div>
            <div class="table-view-cell-value">
              {{ $t('special_questions.solution_pending') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th>{{ $t('rankings_table_header_points') }}</th>
        </tr>
      </thead>
      <tbody v-for="(r, index) in totalRankings" :key="r.position">
        <tr>
          <td>{{ r.position }}.</td>
          <td>{{ r.user_name }}</td>
          <td>{{ r.points_total }}</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <span v-for="(a, i) in getAnswersForUser(r.fk_user)"
                  v-bind:class="{ specialquestion_success: a.points !== '0' && solutions.length > 0, 
                  specialquestion_failed: a.points === '0' && solutions.length > 0 }">{{ a.answer }}</span>
          </td>
          <td v-if="solutions.length > 0">{{ getPointsForUser(r.fk_user) }}</td>
          <td v-else></td>
        </tr>
      </tbody>
    </table>

    <!--
    <div class="navigation_bottom">
      <div class="navigation_bottom--left">
        <img src="../assets/icons/ic_16_back.svg">
      </div>
      <div class="navigation_bottom--center navigation_bottom--center--grey">
        Frage 1/5
      </div>
      <div class="navigation_bottom--right">
        <img src="../assets/icons/ic_16_next.svg">
      </div>
    </div>
    -->
  </div>
  
</template>

<script>

import 'vue-multiselect/dist/vue-multiselect.min.css'
import NavigationBar from '@/components/NavigationBar'

export default {
  name: 'SpecialQuestionDetail',
  components: { NavigationBar },
  data () {
    return {
      question: null
    }
  },
  beforeCreate () {
  },
  created () {
    this.question = this.$route.params.question
    this.$store.dispatch('fetchQuestionAnswers', { fk_question: this.question.id }).then(function (response) {
    })
    this.$store.dispatch('fetchBetgameRankings', { fk_betgame: this.$store.state.betgame.id }).then(function (response) {
    })
  },
  mounted () {

  },
  computed: {
    totalRankings () {
      if (this.$store.state.betgameRankings) {
        return this.$store.state.betgameRankings
      }
      return []
    },
    solutions () {
      return this.$store.state.solutions.filter(s => s.fk_question === this.question.id)
    }
  },
  methods: {
    getPointsForUser (user) {
      var result = 0
      const answers = this.getAnswersForUser (user)
      for (var i = 0; i < answers.length; i++) {
        var s = answers[i]
        result += parseInt(s.points)
      }
      return result
    },
    getAnswersForUser (user) {
      const answers = this.$store.state.questionAnswers[this.question.id]
      if(answers === null || answers === undefined) {
        return []
      }
      const user_answers = answers.filter(s => s.fk_user === user)
      var result = []
      if (this.question.type === 'TEAM') {
        for (var i = 0; i < user_answers.length; i++) {
          var correct = false
          if(this.solutions) {
            for (var j = 0; j < this.solutions.length; j++) {
              const s = this.solutions[j]
              if (s !== null && parseInt(s.solution) === parseInt(user_answers[i].answer)) {
                correct = true
                break
              }
            }
          }
          var t = { 
            answer: this.getTeamName(parseInt(user_answers[i].answer), ''), 
            points: (correct ? '' + this.question.points : '0')
          }
          result.push(t)
        }
        return result
      } else if (this.question.type === 'NUMBER') {
        if(user_answers.length === 1 && this.solutions && this.solutions.length > 0) {
          var t = {
            answer: '' + user_answers[0].answer,
            points: (parseInt(this.solutions[0].solution) === parseInt(user_answers[0].answer) ? '' + this.question.points : '0')
          }
          result.push(t)
        } else if(user_answers.length === 1 && this.solutions && this.solutions.length === 0) {
          var t = {
            answer: '' + user_answers[0].answer,
            points: 0
          }
          result.push(t)
        }
        return result
      } else if (this.question.type === 'BOOL') {
        if(user_answers.length === 1 && this.solutions && this.solutions.length > 0) {
          var t = {
            answer: user_answers[0].answer === '0' ? this.$i18n.t('no') : this.$i18n.t('yes'),
            points: (parseInt(this.solutions[0].solution) === parseInt(user_answers[0].answer) ? '' + this.question.points : '0')
          }
          result.push(t)
        } else if(user_answers.length === 1 && this.solutions && this.solutions.length === 0) {
          var t = {
            answer: user_answers[0].answer === '0' ? this.$i18n.t('no') : this.$i18n.t('yes'),
            points: 0
          }
          result.push(t)
        }
        return result
      }
      return result
    },
    goBack () {
      this.$router.push('/specialquestions')
    }
  }
}
</script>

<style lang="scss" scoped>

  .list {
    position: relative;
    padding: 4px 16px;
    margin-bottom: 24px;
    max-width: 576px;
    margin: 8px auto 0;
  }

  .table-view-cell:last-child {
    border-bottom: 0;
  }

  .table {
    width: calc(100% + 32px);
    margin-top: 16px;

    @media screen and (min-width: 768px) {
      max-width: 608px;
      margin: 16px auto 0;
    }

    thead {
      tr {
        background: $colorBackground;
      }
    }

    tbody {
      tr {
        &:first-child {
          margin-bottom: -20px;

          td {
            border-bottom: 0;
          }
        }
      }

      &:first-of-type {
        tr {
          @media screen and (min-width: 768px) {
            overflow: hidden;
            &:first-child {
              border-top-left-radius: 11px;
              border-top-right-radius: 11px;
            }
          }
        }
      }

      &:last-of-type {
        tr {
          @media screen and (min-width: 768px) {
            overflow: hidden;
            &:last-child {
              border-bottom-left-radius: 11px;
              border-bottom-right-radius: 11px;
            }
          }
        }
      }
    }

    tr {
      display: flex;

    }

    td, th {

      &:first-child {
        padding-left: 4px;
        padding-right: 12px;
        max-width: 28px;
        width: 100%;
        flex-shrink: 0;
        text-align: center;
        justify-content: center;
      }

      &:nth-child(2) {
        display: flex;
        flex: 1;
        flex-wrap: wrap;

        span {

          &:not(:last-child) {
            margin-right: 4px;

            &:after {
              content: ',';
            }
          }

          &.specialquestion_success {
            color: $colorPositive;
          }

          &.specialquestion_failed {
            color: $colorNegative;
          }
        }
      }

      &:last-child {
        min-width: 32px;
      }
    }
  }

  .specialquestion_success {
    color: $colorPositive;
  }

  .specialquestion_failed {
    color: $colorNegative;
  }
</style>
