/* eslint-disable */
<template>
  <div class="login" id ="login">

    <NavigationBar>
      <template v-slot:left>
        <img v-on:click="goBack" class="back" src="../assets/icons/ic_16_back.svg">
      </template>
      <template v-slot:center>
        {{ $t('login_user.title') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>

    <div class="vld-parent">
      <loading :active.sync="isLoading"
               :can-cancel="false"
               :color="betgamePrimaryColor"
               :opacity="opacity"
               :is-full-page="isFullPage">
        <div style="margin-bottom: 20px; font-size: 18pt;" slot="before">{{ $t('login_user.loading_user_data') }}</div>
      </loading>
    </div>

    <!--
    <div class="swiper" id="swiper_container">
      <WelcomeSwiper></WelcomeSwiper>
    </div>
    -->
    
    <form @submit.prevent="login">

      <div class="input-container">

        <div>
          <div class="input-element">
            <input v-model="email" type="email" name="email" id="email" placeholder="email" value />
            <label for="email">{{ $t('email') }}</label>
          </div>

          <div class="input-element">
            <input v-model="password" type="password" name="password" id="password" placeholder="password" value />
            <label for="password">{{ $t('password') }}</label>
          </div>
        </div>

        <div class="input-button-row">
          <button type="submit" name="button">
            {{ $t('login') }}
          </button>
        </div>

      </div>
    </form>
  
    <!-- <button v-on:click="callJSString">JavaScript</button> -->
    
    <div class="forgot-password">
      <span>{{ $t('login_user.user_not_registered') }}</span>
      <router-link to="/register">{{ $t('login_user.register_now') }}</router-link>
    </div>
    <div class="forgot-password">
      <span>{{ $t('login_user.start_betgame') }}</span>
      <router-link to="/createuserbetgame">{{ $t('login_user.start_betgame_now') }}</router-link>
    </div>
    <div class="forgot-password">
      <span>{{ $t('login_user.validation_code_received') }}</span>
      <router-link to="/validateregistration">{{ $t('login_user.enter_validation_code') }}</router-link>
    </div>
    <div class="forgot-password">
      <span>{{ $t('forgot_password') }}</span>
      <router-link to="/resetpassword">{{ $t('reset_password') }}</router-link>
    </div>

  </div>
</template>

<script>
import NavigationBar from './../components/NavigationBar'
import WelcomeSwiper from '../components/WelcomeSwiper'
import { bootstrap } from 'vue-gtag'
import Loading from 'vue-loading-overlay'
import Numeral from 'numeral'
import moment from 'moment'

export default {
  components: {
    NavigationBar,
    WelcomeSwiper,
    Loading
  },
  data() {
    return {
      email: '',
      password: '',
      action: '',
      isLoading: false,
      opacity: 0.9,
      isFullPage: true,
      betgameToJoin: ''
    }
  },
  metaInfo: {
    title: 'Login',
    meta: [
      { vmid: 'keywords', name: 'keywords', content: 'Login, Registrieren, Passwort Zurücksetzen, Anmelden, Registrierungs-Code erhalten' },
      { vmid: 'description', name: 'description', content: 'Login' },
    ]
  },
  mounted() {
    const e = localStorage.getItem('logine')
    const p = localStorage.getItem('loginp')
    if(e && e.trim().length > 1 && e !== 'null' && e !== 'undefined' &&
        p && p.trim().length > 1 && p !== 'null' && p !== 'undefined') {
      this.email = e
      this.password = p
    } else {
      if(e && e.trim().length > 1 && e !== 'null' && e !== 'undefined') {
        this.email = e
      } else {
        this.email = ''
        localStorage.removeItem('logine')
      }
      this.password = ''
      localStorage.removeItem('loginp')
    }
  },
  created() {
    this.$store.dispatch('clearDataBeforeLogin')
    .then(() => {
      if(this.$route.query.email) {
        this.email = this.$route.query.email
      } else if(this.$route.params.email) {
        this.email = this.$route.params.email
      }
      if(this.$route.params.action) {
        this.action = this.$route.params.action
        this.$log.debug('LoginUser.created action ' + this.action)
      }
      if(this.$route.params.betgame) {
        this.betgameToJoin = this.$route.params.betgame
        this.$log.debug('LoginUser.created betgame ' + this.betgameToJoin)
      }
    }).catch(error => {
    })
  },
  computed: {
    betgamePrimaryColor() {
      if(this.$store.state.betgame && this.$store.state.betgame.primary_color && this.$store.state.betgame.primary_color.length > 0) {
        return this.$store.state.betgame.primary_color
      }
      return '#000000'
    }
  },
  watch: {
    email: {
      handler: function() {
        localStorage.setItem('logine', this.email)
      },
      deep: true
    },
    password: {
      handler: function() {
        localStorage.setItem('loginp', this.password)
      },
      deep: true
    }
  },
  methods: {
    callJSString() {
      try {
        if (window.TeamtipAppInterface !== undefined && window.TeamtipAppInterface !== null) {
          const a = window.TeamtipAppInterface.getPushToken()
        } else if (window.webkit && window.webkit.messageHandlers.TeamtipAppInterface !== undefined && window.webkit.messageHandlers.TeamtipAppInterface !== null) {
          const b = window.webkit.messageHandlers.TeamtipAppInterface.postMessage('getPushToken')
          const c = window.localStorage.getItem('pushToken')
        } else {
        }
      } catch (e) {
      }
    },
    sendLogin() {
      try {
        if (window.TeamtipAppInterface !== undefined && window.TeamtipAppInterface !== null) {
          const a = window.TeamtipAppInterface.login()
        } else if (window.webkit && window.webkit.messageHandlers.TeamtipAppInterface !== undefined && window.webkit.messageHandlers.TeamtipAppInterface !== null) {
          const b = window.webkit.messageHandlers.TeamtipAppInterface.postMessage('login')
          if(this.isSPMUser()) {
            const c = window.webkit.messageHandlers.TeamtipAppInterface.postMessage({
              'user': this.$store.state.user.id,
              'token': this.$store.state.token,
            })
          }
        } else {
        }
      } catch (e) {
      }
    },
    goBack () {
      this.password = ''
      localStorage.removeItem('loginp')
      this.$router.push({ name: 'home' }).catch(err => {
      })
    },
    login() {
      if(!this.email || this.email.trim() === '') {
        const	email_input = document.getElementById('email')
        const temp = email_input.value
        if(temp && temp.trim().length > 1) {
          this.email = temp
        }
      }
      if(!this.password || this.password.trim() === '') {
        const	password_input = document.getElementById('password')
        const temp = password_input.value
        if(temp && temp.trim().length > 1) {
          this.password = temp
        }
      }
      if(!this.email || this.email.trim() === '') {
        alert(this.$i18n.t('errors.email_or_password_wrong'))
      } else if(!this.password || this.password.trim() === '') {
        alert(this.$i18n.t('errors.email_or_password_wrong'))
      } else if(this.email && this.password) {
        this.isLoading = true
        const that = this
        this.$store
        .dispatch('login', {
          email: String(this.email).toLowerCase(),
          pass: this.password
        })
        .then((res) => {
          localStorage.removeItem('loginp')
          if(this.$store.state.language !== undefined && this.$store.state.language !== null &&
              this.$store.state.language.length > 1) {
            Numeral.locale(this.$store.state.language)
            moment.locale(this.$store.state.language)
          }
          if(this.$store.state.language !== undefined && this.$store.state.language !== null &&
              this.$store.state.language.length > 1 && this.$i18n.locale !== this.$store.state.language) {
            this.$i18n.locale = this.$store.state.language
          }
          this.sendLogin()
          bootstrap().then(gtag => {
            this.$gtag.set({
              'user_id': this.$store.state.user ? '' + this.$store.state.user.id : '',
              'anonymize_ip': true,
              'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
              'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
              'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set',
              'dimension5': this.$store.state.betgame && this.$store.state.betgame.type ? this.$store.state.betgame.type : 'betgame type not set'
            })
            if(this.$store.state.betgame && this.$store.state.user) {
              this.$gtag.set({
                user_properties: {
                  permission_manage_prediction_round: '' + this.isAdmin(this.$store.state.betgame.id),
                  prediction_mode: this.getBetgameType(this.$store.state.betgame),
                  prediction_competiton: this.getCompetitionId(this.$store.state.betgame.id),
                  notification_preferences: this.getNotificationPreferences(),
                  user_age: (this.$store.state.user.isadult) ? '>=18' : '<18'
                },
              })
            }
            this.$gtag.config({ user_id: this.$store.state.user ? '' + this.$store.state.user.id : '' })
            /*
          this.$gtag.event('login', {
            'event_category': 'login',
            'event_label': window.location.href,
            'anonymize_ip': true,
            'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
            'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
            'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set'
          })
          */
          })
          this.$log.debug("LoginUser: " + JSON.stringify(res))
          if(res && res > 0) {
            if(this.action === 'join' && this.betgameToJoin !== '') {
              this.$store.dispatch('userEnterBetgame', this.betgameToJoin).then(() => {
                this.$store.dispatch('switchBetgame', this.betgameToJoin).then(() => {
                  this.$router.push({ name: 'dashboard' })
                })
              })
            } else {
              this.$store.dispatch('switchBetgame', res).then(() => {
                this.$router.push({ name: 'dashboard' })
              })
            }
          } else if(this.$store.state.betgameId) {
            this.$store.dispatch('switchBetgame', this.$store.state.betgameId).then(() => {
              this.$router.push({ name: 'dashboard' })
            })
          } else if(this.$store.state.user && this.$store.state.user.fk_betgame) {
            this.$store.dispatch('switchBetgame', this.$store.state.user.fk_betgame).then(() => {
              this.$router.push({ name: 'dashboard' })
            })
          } else {
            this.$router.push('/mybetgames')
          }
        }).catch(function (error) {
          that.password = ''
          localStorage.removeItem('loginp')
          if (error.response && error.response.status === 403) {
            alert(that.$i18n.t('errors.email_or_password_wrong'))
          } else {
            alert(that.$i18n.t('errors.login_error') + ' ' + error)
          }
        }).finally(function () {
          that.isLoading = false
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 16px;
    padding: 16px 16px 0 16px;

    h1 {
      text-align: center;
    }
    p {
      text-align: center;
      margin-bottom: 26px;
    }
  }

  .forgot-password {
    width: 100%;
    text-align: center;
    color: $colorGreyDark;
    padding: 21px 0 0;

    a {
      font-weight: 500;
      text-decoration: none;
      margin-left: 6px;
      padding: 12px 0;
    }
  }

  @media screen and (max-width: 414px) {
    .swiper {
      margin: 16px 0;
    }
  }
</style>
