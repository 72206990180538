<template>
    <div :key="componentKey" class="betgameCopy" id="betgameCopy">
        
        <NavigationBar>
            <template v-slot:left>
                <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
            </template>
            <template v-slot:center>
              {{ $t('betgame_settings.betgame_copy') }}
            </template>
            <template v-slot:right>
            </template>
        </NavigationBar>
        
        <form @submit.prevent="copyBetgame">
            <div class="input-container">
                <div>
                    <div class="input-header">
                      {{ $t('betgame_settings.betgame_copy') }}
                    </div>
                    <div class="input-infotext">
                      {{ $t('betgame_settings.betgame_copy_description_1') }}<br><br>
                      {{ $t('betgame_settings.betgame_copy_description_2') }}<br><br>
                      {{ $t('betgame_settings.betgame_copy_description_3') }}<br>
                    </div>
                </div>
                <div class="input-button-row">
                    <button name="button" type="submit">
                        {{ $t('betgame_settings.betgame_copy_button') }}
                    </button>
                </div>
            </div>
        </form>
      
        <ul class="error-container">
            <li v-for="error in errors">{{ error }}</li>
        </ul>
        
    </div>
</template>

<script>
  
  import NavigationBar from './../components/NavigationBar'
  import { TOAST_DURATION } from '../main'
  import APIService from '@/services/APIService'
  import Vue from 'vue'
  
  export default {
    name: 'BetgameCopy',
    components: { NavigationBar },
    data () {
      return {
        deletion: false,
        errors: [],
        betgame: null,
        componentKey: 0,
        action: ''
      }
    },
    created () {
      this.betgame = this.$route.params.betgame
      this.action = this.$route.params.action
      Vue.$log.debug('BetgameCopy created ' + this.betgame.id + ' --- ' + this.action)
    },
    mounted () {
      Vue.$log.debug('BetgameCopy mounted ' + JSON.stringify(this.betgame))
      if (this.betgame === null || this.betgame === undefined) {
        this.goBack()
      }
    },
    methods: {
      copyBetgame() {
        this.errors = []
        if (this.errors.length === 0) {
            const that = this
            this.$store.dispatch('copyBetgame', {
                user_id: '' + this.$store.state.user.id,
                betgame: '' + this.betgame.id,
                season: '15',
                betgame_name: this.betgame.name + ' 2024/25',
            })
            .then((data) => {
                Vue.$log.debug('copyBetgame success ' + data)
                that.$store.dispatch('clearSelectedBetgame', 0).then(() => {
                  that.setBGValue('spmbgid', data)
                  if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
                    that.setBGValue('betgameId', data)
                  }
                  that.$store.dispatch('switchBetgame', data).then(() => {
                    that.$router.push('/betgameedit')
                  })
                })
                //that.$store.dispatch('switchBetgame', data).then(() => {
                //    that.$router.push('/betgameedit')
                //})
            }).catch(function (error) {
                Vue.$log.debug('copyBetgame error ' + JSON.stringify(error))
                alert(that.$i18n.t('betgame_settings.betgame_copy_error') + ' ' + error)
            })
          }
      },
      goBack () {
        this.$router.push('/betgameedit')
      },
      isNullOrUndefined(data) {
        return data === null || data === undefined
      }
    }
  }
</script>

<style lang="scss" scoped>
    .betgameCopy {
        
        form {
            padding-top: 16px;
        }
        
        h1 {
            text-align: center;
        }
        
        p {
            text-align: center;
            margin-bottom: 26px;
        }
    }
</style>
