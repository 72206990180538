<template>
    <div class="betgameeditresultmode" id="betgameeditresultmode">
        
        <NavigationBar>
            <template v-slot:left>
                <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
            </template>
            <template v-slot:center>
              {{ $t('betgame_settings.result_mode') }}
            </template>
            <template v-slot:right>
              <div v-on:click="openHelp"><HintIcon class="navigation_icon"></HintIcon></div>
            </template>
        </NavigationBar>
        
        <ul class="error-container">
            <li v-for="error in errors">{{ error }}</li>
        </ul>

        <form @submit.prevent="changeBetgameResultmode">
            <div class="input-container">
              <div>
                <div class="input-header">
                  {{ $t('betgame_settings.result_mode_select') }}
                </div>
                <div class="input-infotext">
                  {{ $t('betgame_settings.result_mode_description_4') }}
                </div>
                <div class="input-radio">
                  <input :disabled="!isEditable" v-model="betgame.result_mode" v-bind:value="3" type="radio" id="90min" name="resultmode"/>
                  <label for="90min">{{ $t('betgame_settings.result_mode_90m') }}</label>
                </div>
                <div class="input-radio">
                  <input :disabled="!isEditable" v-model="betgame.result_mode" v-bind:value="2" type="radio" id="120min" name="resultode"/>
                  <label for="120min">{{ $t('betgame_settings.result_mode_120m') }}</label>
                </div>
                <div class="input-radio">
                  <input :disabled="!isEditable" v-model="betgame.result_mode" v-bind:value="1" type="radio" id="total" name="resultode"/>
                  <label for="total">{{ $t('betgame_settings.result_mode_total') }}</label>
                </div>
                <div v-if="betCount > 0" class="input-infotext">
                  <br>
                  {{ $t('betgame_settings.betgame_reset_description_6') }}<br>
                </div>
                <div v-if="betCount > 0" class="input-element">
                  <input autofocus id="email" name="email" placeholder="email"
                         type="email" v-model="email" value/>
                  <label for="email">{{ $t('email') }}</label>
                </div>
                <div v-if="betCount > 0" class="input-element">
                  <input id="pasword" name="pasword" placeholder="pasword"
                         type="password" v-model="password" value/>
                  <label for="pasword">{{ $t('password') }}</label>
                </div>
              </div>
              <div class="input-button-row">
                <button name="button" type="submit">
                  {{ $t('button_save_text') }}
                </button>
              </div>
            </div>
        </form>

        <modal class="modal" name="edit-points-help" id="edit-points-help" :scrollable="true" width="100%" height="auto">
          <div class="modal-header">
            <div class="modal-header-left"></div>
            <div class="modal-header-center">{{ $t('help') }}</div>
            <div class="modal-header-right">
              <button
                type="button"
                class="btn-close"
                @click="$modal.hide('edit-points-help')"
                aria-label="Close modal">
              </button>
            </div>
          </div>
          <div class="modal-body">
            <div class="table-view">
              <div class="table-view-title">
                {{ $t('betgame_settings.result_mode_90m') }}
              </div>
              <div class="table-view-cell grouped">
                <div class="table-view-cell-label">
                  {{ $t('betgame_settings.result_mode_90m_description') }}
                </div>
              </div>
              <div class="table-view-title">
                {{ $t('betgame_settings.result_mode_120m') }}
              </div>
              <div class="table-view-cell grouped">
                <div class="table-view-cell-label">
                  {{ $t('betgame_settings.result_mode_120m_description') }}
                </div>
              </div>
              <div class="table-view-title">
                {{ $t('betgame_settings.result_mode_total') }}
              </div>
              <div class="table-view-cell grouped">
                <div class="table-view-cell-label">
                  {{ $t('betgame_settings.result_mode_total_description') }}
                </div>
              </div>
            </div>
          </div>
        </modal>
    </div>
</template>

<script>

import NavigationBar from './../components/NavigationBar'
import { RESULT_MODE_TOTAL, TOAST_DURATION } from '@/main'
import HintIcon from './../components/icons/HintIcon'

export default {
    name: 'BetgameEditResultmode',
    components: { NavigationBar, HintIcon },
    data () {
      return {
        betgame: null,
        errors: [],
        email: '',
        password: ''
      }
    },
    created () {
      this.betgame = this.$route.params.betgame
      if(this.betgame && !this.betgame.result_mode) {
        this.betgame.result_mode = RESULT_MODE_TOTAL
      }
    },
    mounted () {
      if (this.betgame === null || this.betgame === undefined) {
        this.goBack()
      }
      this.$store.dispatch('fetchBetCount', 'exact').then(response => {
      })
    },
    computed: {
      isEditable() {
        return true // this.$store.getters.startedMatches === 0 || this.$store.state.betCount === 0
      },
      betCount() {
        //if(this.$store.state.betCount < 0) {
          this.$store.dispatch('fetchBetCount', 'exact').then(response => {
            this.componentKey += 1
          })
        //}
        return this.$store.state.betCount
      }
    },
    methods: {
      openHelp() {
        this.$modal.show('edit-points-help');
      },
      updateBetgame (data) {
        if(this.betCount > 0) {
          this.$store.dispatch('patchBetgame', data)
          .then(() => {
            this.$toast.open({
              message: this.$i18n.t('save_changes_success'),
              position: 'top',
              duration: TOAST_DURATION,
              type: 'success'
            })
            this.goBack()
          })
          .catch(function (error) {
            this.$toast.open({
              message: this.$i18n.t('save_changes_error'),
              position: 'top',
              duration: TOAST_DURATION,
              type: 'error'
            })
          })
          .finally(function () {
          })
        } else {
          this.$store.dispatch('patchBetgame', data)
          .then(() => {
            this.$toast.open({
              message: this.$i18n.t('save_changes_success'),
              position: 'top',
              duration: TOAST_DURATION,
              type: 'success'
            })
            this.goBack()
          })
          .catch(function (error) {
            this.$toast.open({
              message: this.$i18n.t('save_changes_error'),
              position: 'top',
              duration: TOAST_DURATION,
              type: 'error'
            })
          })
          .finally(function () {
          })
        }
      },
      goBack () {
        this.$router.push('/betgameedit')
      },
      changeBetgameResultmode () {
        this.errors = []
        if(this.betCount > 0) {
          if (this.isNullOrUndefined(this.email) || this.email.length === 0) {
            this.errors.push(this.$i18n.t('betgame_settings.enter_email_address'))
          } else {
            if (this.email && this.$store.state.user &&
                String(this.email).toLowerCase() !== String(this.$store.state.user.email).toLowerCase()) {
              this.errors.push(this.$i18n.t('betgame_settings.enter_administrator_email_address'))
            }
          }
          if (this.isNullOrUndefined(this.password) || this.password.length === 0) {
            this.errors.push(this.$i18n.t('betgame_settings.enter_password'))
          }
        }
        if (this.errors.length === 0) {
          this.$store.dispatch('setBetgame', this.betgame)
          this.updateBetgame({ result_mode: this.betgame.result_mode })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    .betgameeditresultmode {
        
        form {
            padding-top: 16px;
        }
        
        h1 {
            text-align: center;
        }
        
        p {
            text-align: center;
            margin-bottom: 26px;
        }
    }

    
  .grouped {
    margin-top: -16px;
  }

  .table-view {
    margin-bottom: 0;
  }

  .table-view-cell:last-child {
    border-bottom: 0;
  }

  .table-view-cell-label {
    color: rgba($colorGreyDark, .48);
    font-weight: 400;
  }
</style>
