<template>
    <div class="betgameEditSpecialQuestionAnswer" id="betgameEditSpecialQuestionAnswer">
        
        <NavigationBar>
            <template v-slot:left>
                <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
            </template>
            <template v-slot:center>
              {{ $t('betgame_settings.edit_special_question_button_enter_solution') }}
            </template>
            <template v-slot:right>
            </template>
        </NavigationBar>
    
        <div class="notification">
            <p v-if="question.answer_count === 1">{{ $t('betgame_settings.edit_special_question_enter_solution_description') }} <a href="/specialquestions">{{ $t('menu.special_questions') }}</a></p>
            <p v-else-if="question.answer_count > 1">{{ $t('betgame_settings.edit_special_question_enter_solutions_description', {answer_count: question.answer_count}) }}</p>
        </div>
    
        <div class="list">
        
            <div class="table-view-title">
                {{ question.question }}
            </div>
    
            <div class="table-view-cell">
                <multiselect @input="multiChanged" v-if="question.type === 'TEAM'" v-model="values" :options="options" :multiple="true" :max="options.length" :show-labels="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" :placeholder="$t('please_select')" label="name" track-by="id" :preselect-first="false">
                </multiselect>
            
                <multiselect @input="singleChanged" v-else-if="question.answer_count === 1 && question.type === 'NUMBER'" v-model="value" :options="optionsNumber" :searchable="false" :close-on-select="true" :show-labels="false" :placeholder="$t('please_select')"></multiselect>
            
                <multiselect @input="singleChanged" v-else-if="question.answer_count === 1 && question.type === 'BOOL'" v-model="value" :options="optionsBool" track-by="name" label="name" :searchable="false" :close-on-select="true" :show-labels="false" :placeholder="$t('please_select')">
                    <template slot="singleLabel" slot-scope="{ option }">
                        <span v-if="option.val === 1">{{ $t('yes') }}</span>
                        <span v-else-if="option.val === 0">{{ $t('no') }}</span>
                        <span v-else></span>
                    </template>
                </multiselect>
            
              <!--
                <multiselect @input="singleChanged" v-else-if="question.answer_count === 1 && question.type === 'TEAM'" v-model="value" deselect-label="Can't remove this value" track-by="id" label="name" :show-labels="false" :placeholder="$t('please_select')" :options="options" :searchable="false" :allow-empty="true">
                    <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                </multiselect>
                -->
        
            </div>
        </div>

      <button v-if="showDeleteButton" v-on:click="deleteSolution" style="margin-top: 16px;" 
              class="button-danger">{{ $t('button_delete_solution_text') }}</button>
      
    </div>
</template>

<script>
  
  import NavigationBar from './../components/NavigationBar'
  import Vue from 'vue'
  import { TOAST_DURATION } from '../main'
  
  export default {
    name: 'BetgameEditSpecialQuestionAnswer',
    components: { NavigationBar },
    data () {
      return {
        question: null,
        value: null,
        values: [],
        solutionSaved: false
      }
    },
    created () {
      this.question = this.$route.params.question
      if(this.question === null || this.question === undefined) {
        this.$router.push('/betgameeditspecialquestions')
      } else {
        const solutions = this.$store.state.solutions.filter(s => s.fk_question === this.question.id)
        if (this.question.answer_count === 1) {
          if (this.question.type === 'NUMBER' && solutions[0]) {
            this.value = parseInt(solutions[0].solution)
          } else if (this.question.type === 'TEAM' && solutions[0]) {
            //this.value = this.question.optionsJSON.filter(s => s.id === parseInt(solutions[0].solution))[0]
            var temp = []
            for (var i = 0; i < solutions.length; i++) {
              const val = solutions[i]
              const v = this.question.optionsJSON.filter(s => s.id === parseInt(val.solution))[0]
              temp.push(v)
            }
            this.values = temp
          } else if (this.question.type === 'BOOL' && solutions[0]) {
            if(parseInt(solutions[0].solution) === 0) {
              this.value = { name: 'Nein', val: 0 }
            } else if(parseInt(solutions[0].solution) === 1) {
              this.value = { name: 'Ja', val: 1 }
            }
          } else {
            this.value = null
          }
        } else if (this.question.answer_count > 1) {
          var temp = []
          for (var i = 0; i < solutions.length; i++) {
            const val = solutions[i]
            const v = this.question.optionsJSON.filter(s => s.id === parseInt(val.solution))[0]
            temp.push(v)
          }
          this.values = temp
        }
      }
    },
    mounted () {

    },
    computed: {
      showDeleteButton() {
        return this.question !== undefined && this.question.id > 0 && 
            this.question.fk_betgame === parseInt(this.$store.state.betgameId) && 
            (this.value || (this.values && this.values.length > 0))
      },
      options() {
        return this.question.optionsJSON !== undefined && this.question.optionsJSON !== null ? this.question.optionsJSON : []
      },
      optionsBool() {
        return [
          { name: 'Ja', val: 1 },
          { name: 'Nein', val: 0 }
        ]
      },
      optionsNumber() {
        const o = this.question.options
        const minmax = o.trim().split(',')
        if(minmax.length === 2) {
          var min = parseInt(minmax[0])
          var max = parseInt(minmax[1])
          if(min > max) {
            var temp = min
            min = max
            max = temp
          }
          var result = [max-min]
          if(min !== max) {
            for (var i = min, c = 0; i <= max; i++, c++) {
              result[c] = i;
            }
          }
          return result
        }
        return  []
      }
    },
    methods: {
      deleteSolution() {
        Vue.$log.debug('deleteSolution  ' + this.question.id)
        this.$store.dispatch('deleteSolution', this.question.id).then(response => {
          Vue.$log.debug('Solution deleteSolution  ok ' + JSON.stringify(response))
          this.value = null
          this.values = []
          this.answerSaved = true
        }, error => {
          Vue.$log.debug(error.toString())
        })
      },
      multiChanged() {
        if(this.values.length < this.question.answer_count) {
          return
        }
        var solutions = []
        for(var i=0; i<this.values.length; i++) {
          const val = this.values[i]
          solutions.push({
            'fk_question': this.question.id,
            'solution': parseInt(val.id),
          })
        }
        this.$store.dispatch('updateSolution', solutions).then(response => {
          Vue.$log.debug('Solution multiChanged updateSolution ok')
          this.answerSaved = true
          Vue.$toast.open({ message: this.$i18n.t('special_questions.solutions_saved'), position: 'top', duration: TOAST_DURATION, type: 'success' })
        }, error => {
          Vue.$log.debug(error.toString())
        })
      },
      singleChanged() {
        var s = this.value.id
        if(this.question.type === 'NUMBER') {
          s = this.value
        } else if(this.question.type === 'BOOL') {
          s = this.value.val
        }
        const solution = {
          'fk_question': this.question.id,
          'solution': parseInt(s)
        }
        Vue.$log.debug('Solution singleChanged ' + JSON.stringify(solution))
        this.$store.dispatch('updateSolution', solution).then(response => {
          Vue.$log.debug('Solution singleChanged updateSolution ok')
          this.solutionSaved = true
          Vue.$toast.open({ message: this.$i18n.t('special_questions.solution_saved'), position: 'top', duration: TOAST_DURATION, type: 'success' })
        }, error => {
          Vue.$log.debug(error.toString())
        })
      },
      goBack () {
        this.$router.push({ name: 'betgameeditspecialquestiondetail', params: { question: this.question }})
      }
    }
  }
</script>

<style lang="scss" scoped>
    .betgameEditName {
        
        form {
            padding-top: 16px;
        }
        
        h1 {
            text-align: center;
        }
        
        p {
            text-align: center;
            margin-bottom: 26px;
        }
    }
</style>
