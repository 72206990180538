<template>

  <div class="matchdayslider__countdown">

    <!-- Tage -->
    <div class="matchdayslider__countdown__item">
      <div class="matchdayslider__countdown__item__label">
        {{ days }}
      </div>
      <div class="matchdayslider__countdown__item__sublabel">
        {{ days === 1 ? $t('dashboard.countdown_day')  : $t('dashboard.countdown_days') }}
      </div>
    </div>

    <!-- Stunden -->
    <div class="matchdayslider__countdown__item">
      <div class="matchdayslider__countdown__item__label">
        {{ hours }}
      </div>
      <div class="matchdayslider__countdown__item__sublabel">
        {{ $t('dashboard.countdown_hours') }}
      </div>
    </div>

    <!-- Minuten -->
    <div class="matchdayslider__countdown__item">
      <div class="matchdayslider__countdown__item__label">
        {{ minutes }}
      </div>
      <div class="matchdayslider__countdown__item__sublabel">
        {{ $t('dashboard.countdown_minutes') }}
      </div>
    </div>

    <!-- Sekunden -->
    <div class="matchdayslider__countdown__item">
      <div class="matchdayslider__countdown__item__label">
        {{ seconds }}
      </div>
      <div class="matchdayslider__countdown__item__sublabel">
        {{ $t('dashboard.countdown_seconds') }}
      </div>
    </div>

  </div>
</template>

<script>

import moment from 'moment/moment'

export default {
  name: 'MatchdaySliderCountdown',
  data () {
    return {
      now: moment(),
      date: null,
      diff: null,
      interval: null,
      countdownStopped: false,
      secondsInit: 0,
      minutesInit: 0,
      hourssInit: 0,
      daysInit: 0
    }
  },
  beforeCreate () {
  },
  created () {
    if(this.nextMatch) {
      const n = this.nowToLocalTimeZone()
      const d = this.parseMatchdateToLocalTimeZone(this.nextMatch.matchdate).toDate()
      const range = n.diff(d)
      const dif = moment.duration(range)
      if (dif != null) {
        this.minutesInit = Math.abs(dif.get('minutes'))
        this.secondsInit = Math.abs(dif.get('seconds'))
        this.daysInit = Math.abs(dif.get('days'))
        this.hourssInit = Math.abs(dif.get('hours'))
      }
    }
  },
  mounted () {
    if(this.interval == null) {
      this.interval = setInterval(() => {
        if (this.nextMatch != null && this.date == null) {
          //this.date = new Date(Date.parse(this.nextMatch.matchdate))
          this.date = this.parseMatchdateToLocalTimeZone(this.nextMatch.matchdate).toDate()
        }
        this.now = this.nowToLocalTimeZone()
        if(this.date < this.now.toDate()) {
          this.countdownStopped = true
        }
      }, 1000)
    }
  },
  watch: {
    now (value) {
      if (this.now != null && this.date != null) {
        const range = this.now.diff(this.date)
        this.diff = moment.duration(range)
      }
    }
  },
  computed: {
    seconds () {
      if (this.diff != null) {
        return Math.abs(this.diff.get('seconds'))
      } else {
        return 0
      }
    },
    minutes () {
      if (this.diff != null) {
        return Math.abs(this.diff.get('minutes'))
      } else {
        return 0
      }
    },
    hours () {
      if (this.diff != null) {
        return Math.abs(this.diff.get('hours'))
      } else {
        return 0
      }
    },
    days () {
      if (this.diff != null) {
        return Math.abs(this.now.diff(this.date, 'days'))
      } else {
        return 0
      }
    },
    nextMatch() {
      if(this.$store.state.matches.length > 0) {
        return this.$store.getters.getClosestFutureMatch
      } else {
        if(this.$store.state.leagueId) {
          this.$store.dispatch('fetchSchedule', this.$store.state.leagueId)
        } else {
          if(this.getBGValue('spmbgid') && parseInt(this.getBGValue('spmbgid')) > 0) {
            this.$store.dispatch('fetchBetgameLeague', this.getBGValue('spmbgid'))
          }
        }
      }
    },
  },
  methods: {}
}
</script>

<style lang="scss">
  .matchdayslider__countdown {
    display: flex;
    margin: 0 auto 8px;

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: rgba($colorGreyDark, .08);
      border-radius: 4px;
      padding: 4px 0;
      margin: 0 4px;
      min-width: 48px;

      &__label {
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        color: $colorGreyDark;
      }

      &__sublabel {
        font-size: 11px;
        color: rgba($colorGreyDark, .48);
        margin-bottom: 1px;
      }
    }
  }
</style>
