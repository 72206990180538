<template>
  <div class="betmode" id="betmode">
    <div class="input-container">
      <div class="input-header">
        {{ $t('betgame_settings.bet_mode_select') }}
      </div>
      <div class="input-radio">
        <input v-on:change="betmodeSelected" v-model="betmode" v-bind:value="1" type="radio" id="ergebnis" name="modus" value="" />
        <label for="ergebnis">{{ $t('betgame_settings.bet_mode_result') }}</label>
        <div class="input-infotext">{{ $t('betgame_settings.bet_mode_description_1') }}</div>
        <div class="input-radio-image"><img src="../../assets/images/mode/betmode_result.png" alt="Tippabgabe-Modus Ergebnis"></div>
       
      </div>
      <div class="input-radio">
        <input v-on:change="betmodeSelected" v-model="betmode" v-bind:value="2" type="radio" id="toto" name="modus" value="" />
        <label for="toto">{{ $t('betgame_settings.bet_mode_toto') }}</label>
        <div class="input-infotext">{{ $t('betgame_settings.bet_mode_description_2') }}</div>
        <div class="input-radio-image"><img src="../../assets/images/mode/betmode_toto.png" alt="Tippabgabe-Modus Toto"></div>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'

export default {
  name: 'Betmode',
  components: {},
  props: {
  },
  data () {
    return {
      betmode: '' + this.$store.state.createBetgame.betmode
    }
  },
  beforeCreate () {
    Vue.$log.debug('Betmode.beforeCreate ' + this.betmode)
  },
  created () {
    Vue.$log.debug('Betmode.created ' + this.betmode)
  },
  mounted () {
    Vue.$log.debug('Betmode.mounted ' + this.betmode)
  },
  computed: {},
  methods: {
    betmodeSelected() {
      if(this.betmode && (parseInt(this.betmode) === 1 || parseInt(this.betmode) === 2)) {
        this.$store.dispatch('setCreateBetgame', {
          key: 'betmode',
          value: this.betmode
        })
        .then(() => {
          Vue.$log.debug('Betmode.betmodeSelected ok ' + this.betmode)
        }).catch(function (error) {
          Vue.$log.debug('Betmode.betmodeSelected error ' + error)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.betmode {
  max-width: 400px;
  margin: 16px auto 16px;
}

.input-radio {
  .input-radio-image {
    width: 100%;
    background: $colorWhite;
    border-bottom: 1px solid rgba($colorGreyLight, .2);
    margin-top: -8px;
    position: relative;
    z-index: 2;padding-bottom: 8px;

    img {
      width: 100%;

      @media screen and (min-width: 450px) {
        max-width: 300px;
      }
    }
  }

  &:last-child {
    .input-radio-image {
      border-bottom: 0;
    }
  }
}

.input-container {
  margin-top: 0 !important;
}

</style>
