<template>
  <div>
    
  </div>
</template>

<script>

import Vue from 'vue'

export default {
  name: 'BetgameSwitch',
  components: {  },
  data() {
    return {
    }
  },
  beforeCreate() {
    Vue.$log.debug('BetgameSwitch beforeCreate ' + this.$route.params.id + ' ' + this.$route.params.name)
    if(this.$route.params.id && this.$store.getters.loggedIn) {
      const switchId = parseInt(this.$route.params.id)
      const routeName = this.$route.params.name 
      if(switchId && switchId > 0) {
        if(this.$store.getters.isUserConnectedToBetgame(switchId) || this.$store.getters.isUserBetgameParticipant(switchId)) {
          window.localStorage.setItem('betgameId', switchId)
          window.localStorage.setItem('spmbgid', switchId)
          this.$store.dispatch('switchBetgame', '' + switchId).then(() => {
            Vue.$log.debug('BetgameSwitch beforeCreate switchBetgame ' + switchId + ' ' + routeName)
            if (routeName && routeName !== '') {
              if(routeName === 'chat') {
                this.$router.push('/feed/chat')
              } else {
                this.$router.push({ name: routeName, params: {} })
              }
            } else {
              this.$router.push({ name: 'dashboard', params: {} })
            }
          })
        } else {
          this.$router.push({ name: 'mybetgames'})
        }
      }
    } else {
      this.$router.push({ name: 'home' })
    }
  },
  created () {
    Vue.$log.debug('BetgameSwitch created ' + this.$route.params.id)
  },
  mounted() {
    Vue.$log.debug('BetgameSwitch mounted ' + this.$route.params.id)
  },
  computed: {

  },
  methods: {

  }
}
</script>

<style lang="scss">

</style>
