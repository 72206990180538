<template>
  <div class="about">

      <!-- HEADER -->

      <div class="header" id="header">
        <div class="container">

          <nav>
            <div class="navigation-logo">
              <a href="../home">
                <img src="../assets/landingpage/logo-teamtip.svg" alt="">
              </a>
              <div class="navigation-divider"></div>
              <a href="https://www.sportplatz-media.com" target="_blank">
                <img src="../assets/landingpage/logo-sportplatzmedia-2022.svg" alt="">
              </a>
            </div>
            <ul class="navigation-list">
              <!-- TODO @Lennard: Link zu Whitelabel & Features soll auf Home verzweigen und dort an die entsprechende Stelle scrollen -->
              <li v-if="!this.isApp()" class="navigation-list-item">
                <a href="#whitelabel_id" v-smooth-scroll="{ updateHistory: false }">{{ $t('home.whitelabel') }}</a>
              </li>
              <li v-if="!this.isApp()" class="navigation-list-item">
                <a href="#features_id" v-smooth-scroll="{ updateHistory: false }">{{ $t('home.features') }}</a>
              </li>
              <li v-if="!this.isApp()" class="navigation-list-item">
                <!-- TODO @Lennard: Übersetzung fehlt -->
                <router-link to="/about">
                  Über Uns
                </router-link>
              </li>
              <li class="navigation-list-language">
                <a href="#" @click="languageSelected( 'de')" v-bind:class="{ 'navigation-list-language-item--selected': selectedLanguage === 'de' }" 
                   class="navigation-list-language-item">
                  <img src="../assets/icons/ic_flag_germany.svg" class="navigation-list-language-flag">
                </a>
                <a href="#" @click="languageSelected( 'en')" v-bind:class="{ 'navigation-list-language-item--selected': (!selectedLanguage || selectedLanguage !== 'de') }" 
                   class="navigation-list-language-item">
                  <img src="../assets/icons/ic_flag_england.svg" class="navigation-list-language-flag">
                </a>
              </li>
              <router-link to="/login" class="router_link">
                <li class="navigation-list-button">
                  <LoginIcon></LoginIcon>
                  Login
                </li>
              </router-link>
            </ul>
          </nav>

          <div class="header__headline">
            <span>Über uns</span>
            <h1>Wir organisieren<br>dein Team!</h1>
          </div>

        </div>
      </div>

      <div class="about__content">
        <div class="container">

          <div class="textblock textblock--imageRight">
            <div class="textblock__text">
              <h3>Unsere Mission</h3>
              <p>Die Nutzung von MeinSponsor ist vollständig kostenfrei und spielend leicht! Wir stellen die Verbindung zwischen euch und unseren attraktiven Werbepartnern her, um den Zugang zu schnellem und einfachem Sponsoring zu erleichtern!</p>
            </div>
            <div class="textblock__image">
              <img src="" srcset="" alt="Unsere Mission">
              <!--<img src="../assets/about/mission.jpg" srcset="../assets/about/mission@2x.jpg 2x" alt="Unsere Mission">-->
            </div>
          </div>

          <div class="textblock textblock--imageLeft">
            <div class="textblock__text">
              <h3>Unser Team</h3>
              <p>Die Nutzung von MeinSponsor ist vollständig kostenfrei und spielend leicht! Wir stellen die Verbindung zwischen euch und unseren attraktiven Werbepartnern her, um den Zugang zu schnellem und einfachem Sponsoring zu erleichtern!</p>
            </div>
            <div class="textblock__image">
              <img src="" srcset="" alt="Unser Team">
              <!--<img src="../assets/about/team.jpg" srcset="../assets/about/team@2x.jpg 2x" alt="Unser Team">-->
            </div>
          </div>

        </div>
      </div>

      <div class="apps">
        <div class="container">

          <h2>Wir bieten so viel mehr...</h2>
          <p>Entdecke weitere Apps & Plattformen aus dem Haus von Sportplatz Media. Wir begleiten dich bei allen Themen rund um den Sport!</p>

          <div class="apps__container">

            <!-- SportlerPlus -->
            <div class="apps__item">
              <img class="apps__item__logo" src="../assets/about/logo-sportlerplus.svg" alt="Logo SportlerPlus">
              <h4>SportlerPlus</h4>
              <p>SportlerPlus ist dein Personal-Trainer für zuhause und bietet individuelle Workouts mit Bezug auf deinen Einzel- oder Teamsport. Trainiere so individuell wie noch nie mit der SportlerPlus-App!</p>
              <a href="https://www.sportlerplus.de" target="_blank" class="button">
                Mehr erfahren
              </a>
            </div>

            <!-- Mein Sponsor -->
            <div class="apps__item">
              <img class="apps__item__logo" src="../assets/about/logo-meinsponsor.svg" alt="Logo MeinSponsor">
              <h4>MeinSponsor</h4>
              <p>MeinSponsor bietet dir die Möglichkeit, die Kasse deines Vereins aufzubessern. Einfach die spannenden Deals der Sponsoring-Partner annehmen, umsetzen und schon klingelt die Kasse!</p>
              <a href="https://www.meinsponsor.de" target="_blank" class="button">
                Mehr erfahren
              </a>
            </div>

            <!-- Fussball Crowd -->
            <div class="apps__item">
              <img class="apps__item__logo" src="../assets/about/logo-fussballcrowd.svg" alt="Logo Fussball Crowd">
              <h4>Fussball Crowd</h4>
              <p>Die Förderplattform für dein Fussball-Projekt. Lege schnell und einfach dein Projekt an und sammle Gelder für Trikosätze, Trainingslager, Mannschaftsbus, Kunstrasenplatz, u.v.m.</p>
              <a href="https://www.fussball-crowd.de" target="_blank" class="button">
                Mehr erfahren
              </a>
            </div>

            <!-- SpielerPlus -->
            <div class="apps__item">
              <img class="apps__item__logo" src="../assets/about/logo-spielerplus.svg" alt="Logo SpielerPlus">
              <h4>SpielerPlus</h4>
              <p>SpielerPlus ist Pionier in der digitalen Organisation und Kommunikation von Sportteams. Alle Themen rund um dein Team lassen sich mit SpielerPlus kinderleicht organisieren.</p>
              <a href="https://www.spielerplus.de" target="_blank" class="button">
                Mehr erfahren
              </a>
            </div>

          </div>

        </div>
      </div>
    
      <div class="recruiting">
        <div class="container">
          <h2>Du möchtest Teil des Teams werden?</h2>
          <p>Dann warte nicht länger und bewirb dich auf eine der ausgeschriebenen Stellen. Nichts dabei? Dann sende uns deine Initiativbewerbung.</p>
          <a href="https://sportplatz-media.com/jobs/" target="_blank" class="button">
            Jetzt bewerben
          </a>
        </div>
      </div>

      <footer class="footer">
        <div class="container">
          <div class="logo">
            <a href="../home">
              <img src="../assets/landingpage/logo-teamtip.svg" alt="Logo TEAMTIP">
            </a>
          </div>
          <ul>
            <li>
              <router-link to="/imprint" class="router_link">
                {{ $t('home.footer_imprint') }}
              </router-link>
            </li>
            <li>
              <router-link to="/termsofuse" class="router_link">
                {{ $t('home.footer_terms_of_use') }}
              </router-link>
            </li>
            <li>
              <router-link to="/privacy" class="router_link">
                {{ $t('home.footer_privacy') }}
              </router-link>
            </li>
            <li>
              <a v-if="selectedLanguage === 'de'" href="https://teamtip.zendesk.com/hc/de" target="_blank" style="text-decoration: none; color: white">{{ $t('home.footer_faq') }}</a>
              <a v-else-if="!selectedLanguage || selectedLanguage !== 'de'" href="https://teamtip.zendesk.com/hc/en-us" target="_blank" style="text-decoration: none; color: white">{{ $t('home.footer_faq') }}</a>
            </li>
            <li>
              <router-link to="/contact" class="router_link">
                {{ $t('home.footer_contact') }}
              </router-link>
            </li>
          </ul>
          <a href="#header" class="back-to-top" v-smooth-scroll="{ updateHistory: false }">
            <span>{{ $t('home.footer_scroll_to_top') }}</span>
            <img src="../assets/landingpage/ic_32_back-to-top.svg" alt="Back to top">
          </a>
        </div>
      </footer>

    </div>

</template>

<script>

import Vue from 'vue'
import { TOAST_DURATION } from '../main'
import Numeral from 'numeral'
import moment from 'moment'
import LoginIcon from './../components/icons/LoginIcon'


export default {
  name: 'home',
  components: {
    LoginIcon
  },
  computed: {
    selectedLanguage() {
      return this.$store.state.language
    }
  },
  methods: {
    languageSelected(lang) {
      this.$store.dispatch('setLanguage', lang)
      .then(() => {
        Vue.$log.debug('languageSelected ok + ' + this.$store.state.language)
        this.$i18n.locale = lang
        Numeral.locale(lang)
        moment.locale(lang)
      }).catch(function (error) {
        Vue.$log.debug('languageSelected error ' + error)
      })
    },
  }
}

</script>

<style lang="scss" scoped>

  .about {
    padding: 0 !important;

    &__content {
      padding: 80px 0;
      background: #F8F8F8;

      @media screen and (max-width: 767px) {
        padding: 48px 0 0;
      }
    }
  }

  .router_link {
    text-decoration: none;
  }

  .container {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    max-width: 1108px;
    margin: 0px auto;
    padding: 0 16px;
    box-sizing: border-box;
  }

  /* HEADER OVERWRITES */
  .header {
    background-image: url('../assets/landingpage/header-background.jpg');
    background-position: top center;
    height: auto;
    padding: 36px 0;

    @media screen and (max-width: 767px) {
      padding: 18px 0;
      min-height: inherit;
    }
  }

  .apps {
    background: $colorWhite;
    display: flex;
    justify-content: center;
    padding: 96px 0 88px;
    box-shadow: inset 0 2px 0 0 rgba($colorGreyDark, .16);

    @media screen and (max-width: 767px) {
      padding: 64px 0;
    }

    h2 {
      font-size: 32px;
      color: $colorGreyDark;
      text-align: center;
      margin-bottom: 18px;

      @media screen and (max-width: 767px) {
       font-size: 24px;
       text-align: left;
      }

      ~ p {
        font-size: 17px;
        line-height: 24px;
        color: rgba($colorGreyDark, .64);
        max-width: 660px;
        width: 100%;
        margin: 0px auto;
        text-align: center;

        @media screen and (max-width: 767px) {
          text-align: left;
          font-size: 17;
          line-height: 24px;
          color: rgba($colorGreyDark, .64);
        }
      }
    }

    .apps__container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 940px;
      margin: 40px auto 0;
    }

    .apps__item {
      display: flex;
      flex-direction: column;
      width: calc(50% - 32px);
      margin: 40px 0;

      @media screen and (max-width: 767px) {
        width: 100%;

        &:not(:last-child) {
          box-shadow: inset 0 -1px 0 0 rgba($colorGreyDark, .08);
          padding: 40px 0;
          margin: 0;
        }
      }

      &__logo {
        width: 64px;
        height: 64px;
        border-radius: 8px;
        background: #000;
      }

      h4 {
        font-size: 22px;
        color: $colorGreyDark;
        margin: 18px 0 10px;

        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
      }

      p {
        font-size: 14px;
        line-height: 20px;
        color: rgba($colorGreyDark, .64);
      }

      .button {
        display: inline-flex;
        padding: 10px 16px 9px;
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
        color: $colorGreyDark;
        background: rgba($colorGreyDark, .12);
        border-radius: 8px;
        margin-right: auto;
        margin-top: 17px;
        transition: all .2s ease-in-out;

        &:hover {
          background: rgba($colorGreyDark, .16);
        }

        &:after {
          content: url('../assets/about/ic_external_link.svg');
          width: 9px;
          height: 9px;
          margin-left: 7px;
        }
      }
    }
  }

  .recruiting {
    background: url('../assets/landingpage/whitelabel-background.jpg');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 110px 0 96px;
    box-sizing: border-box;
    overflow: hidden;

    h2 {
      font-size: 32px;
      color: $colorWhite;
      text-align: center;
      max-width: 720px;
      margin: 0px auto;

      @media screen and (max-width: 767px) {
        font-size: 28px;
      }
    }

    p {
      font-size: 20px;
      line-height: 28px;
      color: rgba($colorWhite, .8);
      text-align: center;
      max-width: 720px;
      margin: 0px auto;
      margin-top: 18px;

      @media screen and (max-width: 767px) {
        font-size: 17px;
        line-height: 24px;
      }
    }

    .button {
      display: inline-flex;
      padding: 18px 24px;
      font-size: 17px;
      font-weight: 500;
      text-decoration: none;
      color: var(--color-primary);
      background: $colorWhite;
      border-radius: 8px;
      margin: 41px auto 0px;
    }
  }

</style>
