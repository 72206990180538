<template>
  <div class="register" id="register" style="justify-content: flex-start; margin-top: 32px;">
  
    <NavigationBar>
      <template v-slot:left>
        <img v-on:click="goBack" class="back" src="../assets/icons/ic_16_back.svg">
      </template>
      <template v-slot:center>
        {{ $t('register.register') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>
    
    <div style="margin-top: 20px;">
      <h1>{{ $t('register.title') }}</h1>
      <p>{{ $t('register.text') }}</p>
    </div>
    
    <form @submit.prevent="register">

        
        <div class="input-container">

          <div class="input-header">
            {{ $t('register.your_account') }}
          </div>

          <div class="input-element" v-bind:class="{ validation_true: nicknameValid === true, validation_false: name.length > 0 && nicknameValid === false }">
            <input v-model="name" type="text" name="name" id="name" placeholder="name" />
            <label for="name">{{ $t('nickname') }}</label>
          </div>

          <div class="input-element" v-bind:class="{ validation_true: emailValid === true, validation_false: email.length > 0 && emailValid === false }" >
            <input @blur="handleEmailBlur" v-model="email" type="email" name="email" id="email" placeholder="email" />
            <label for="email">{{ $t('email') }}</label>
          </div>

          <div class="input-element" v-bind:class="{ validation_true: passwordValid === true, validation_false: password.length > 0 && passwordValid === false }">
            <input v-model="password" type="password" name="password" id="password" placeholder="password" />
            <label for="password">{{ $t('password') }}</label>
          </div>

        </div>

        <div v-if="false" class="input-container">

          <div class="input-header">
            {{ $t('register.personal_information') }}
          </div>

          <div class="input-element">
            <select name="landkreis" id="landkreis" v-model="landkreis" required>
              <option value="empty">Keine Angabe</option>
              <option value="Hamburg">Hamburg</option>
            </select>
            <label for="landkreis">Landkreis</label>
          </div>

          <div class="input-element" >
            <input v-model="adresse" type="email" name="adresse" id="adresse" placeholder="adresse" />
            <label for="adresse">Adresse</label>
          </div>

        </div>

        <div class="input-container">

          <div class="input-header">
            {{ $t('create_betgame.conditions_and_data_protection') }}
          </div>

          <div class="input-checkbox">
            <input v-model="agreedTOS" type="checkbox" id="agreedTOS" name="agreedTOS" />
            <label for="agreedTOS">{{ $t('create_betgame.agree_terms_of_use') }} <a target="_blank" href="/termsofuse">({{ $t('create_betgame.link') }})</a>.</label>
          </div>

          <div class="input-empty">
            <a target="_blank" href="/privacy">{{ $t('register.data_protection') }}</a>
          </div>

          <div class="input-checkbox">
            <input v-model="isUnder18" type="checkbox" id="isUnder18" name="isUnder18" />
            <label for="isUnder18">{{ $t('create_betgame.i_am_under_18') }} <span style="font-size: 14px; opacity: .48;">({{ $t('create_betgame.optional') }})</span></label>
          </div>

        </div>

        <div class="input-button-row">
          <button type="submit" name="button" :disabled="this.emailAlreadyRegistered">
            {{ $t('register.button_register_text') }}
          </button>
        </div>

    </form>
  
    <ul class="error-container" style="margin-bottom: 20px;">
      <li v-for="error in errors">{{ error }}</li>
    </ul>
  
    <div v-if="emailAlreadyRegistered" class="forgot-password">
      <p>
        <span>{{ $t('forgot_password') }} </span>
        <router-link :to="{ name: 'resetpassword', params: { email: this.email }}">{{ $t('reset_password') }}</router-link>
      </p>
      <p>
        <span>{{ $t('create_betgame.teamtip_account_exists') }} </span>
        <router-link :to="{ name: 'login', params: { email: this.email }}">{{ $t('login') }}</router-link>
      </p>
    </div>
    
  </div>
</template>

<script>
  
  import APIService from '@/services/APIService'
  import NavigationBar from './../components/NavigationBar'
  import Numeral from 'numeral'
  import moment from 'moment'
  
export default {
  name: 'registeruser',
  components: { NavigationBar },
  props: {
    emailparam: {
      type: String,
      default: ''
    },
    passparam: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      name: '',
      email: this.emailparam,
      password: this.passparam,
      errors: [],
      emailAlreadyRegistered: false,
      agreedTOS: false,
      isUnder18: false
    }
  },
  computed: {
    nicknameValid () {
      if(this.name === null || this.name === undefined) {
        return false
      }
      return this.name.length >= 3
    },
    emailValid () {
      if(this.email === null || this.email === undefined) {
        return false
      }
      const valid = this.isValidEmail(this.email)
      return valid
      /*
      if(valid) {
        APIService.isEmailRegistered(this.email).then(response => {
          if (response.data.valid === '1') {
            this.emailAlreadyRegistered = false
            this.errors = this.errors.filter(e => e !== this.$t('errors.email_already_registered'))
            return true
          } else {
            this.emailAlreadyRegistered = true
            if(!this.errors.includes(this.$t('errors.email_already_registered'))) {
              this.errors.push(this.$t('errors.email_already_registered'))
            }
            return false
          }
        })
      } else {
        this.errors = this.errors.filter(e => e !== this.$t('errors.email_already_registered'))
        return valid
      }
       */
    },
    passwordValid () {
      if(this.password === null || this.password === undefined) {
        return false
      }
      return this.password.length > 6
    }
  },
  methods: {
    handleEmailBlur() {
      if(!this.isNullOrUndefined(this.email) && this.isValidEmail(this.email)) {
        const that = this
        APIService.isEmailRegistered(this.email.toLowerCase().trim()).then(response => {
          if (response.data.valid === '1') {
            that.emailAlreadyRegistered = false
            that.errors = that.errors.filter(e => e !== that.$t('errors.email_already_registered'))
          } else {
            that.emailAlreadyRegistered = true
            if (!that.errors.includes(that.$t('errors.email_already_registered'))) {
              that.errors.push(that.$t('errors.email_already_registered'))
            }
          }
        })
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    register() {
      this.errors = []
      this.emailAlreadyRegistered = false
      if(this.isNullOrUndefined(this.name)) {
        this.errors.push(this.$i18n.t('errors.enter_nickname'))        
      } else if(this.name.length === 0) {
        this.errors.push(this.$i18n.t('errors.enter_nickname'))
      } else if(this.name.length > 100) {
        this.errors.push(this.$i18n.t('errors.enter_nickname_length'))
      }
      if(this.isNullOrUndefined(this.email)) {
        this.errors.push(this.$i18n.t('errors.enter_email'))        
      } else if(this.email.length === 0) {
        this.errors.push(this.$i18n.t('errors.enter_email'))
      } else if(this.email.length > 100) {
        this.errors.push(this.$i18n.t('errors.enter_email'))
      } else if(!this.isValidEmail(this.email)) {
        this.errors.push(this.$i18n.t('errors.enter_valid_email'))
      }
      if(this.isNullOrUndefined(this.password) || this.password.length === 0 || this.password.length > 100) {
        this.errors.push(this.$i18n.t('errors.enter_password_length'))
      }
      if (!this.agreedTOS) {
        this.errors.push(this.$i18n.t('errors.confirm_terms_of_use'))
      }
      if (this.errors.length === 0) {
        APIService.isEmailRegistered(this.email.toLowerCase().trim()).then(response => {
          if (response.data.valid === '1') {
            const language = this.$store.state.language ? this.$store.state.language : 'de'
            this.$store.dispatch('register', {
              nick: this.name,
              email: this.email.toLowerCase().trim(),
              pass: this.password,
              lang: language,
              source: this.$store.state.userSource || this.getBGValue('spmic') || '',
              fk_betgame: this.getBGValue('spmbgid')
            })
            .then(() => {
              this.$store.dispatch('patchUser', {acceptedtos: this.agreedTOS, isadult: !this.isUnder18}).then(() => {
              })
              const uid = '' + this.$store.state.user.id
              const uem = '' + this.$store.state.user.email.charAt(0) + this.reverse(uid)
              const temp = this.reverse(String(this.$store.state.user.registration_date)).replaceAll('T', this.reverse(uem))
              .replaceAll(':', '').replaceAll('-', uem).concat(uid)
              const data = {
                '_id': uid,
                'first_name': this.$store.state.user.nickname,
                'email': this.$store.state.user.email.toLowerCase().trim(),
                'password': temp
              }
              this.$store.dispatch('chatCreateUser', data).then(response => {
                this.$store.dispatch('chatLoginUser', {'user': data._id, 'password': data.password}).then(response => {
                  this.$store.dispatch('patchUser', {
                    chatuser: uid + '-' + temp
                  })
                })
                .catch(function (error) {
                })
              })
              .catch(function (error) {
              })
              if(this.$store.state.language !== undefined && this.$store.state.language !== null &&
                  this.$store.state.language.length > 1) {
                Numeral.locale(this.$store.state.language)
                moment.locale(this.$store.state.language)
              }
              if(this.$store.state.language !== undefined && this.$store.state.language !== null &&
                  this.$store.state.language.length > 1 && this.$i18n.locale !== this.$store.state.language) {
                this.$i18n.locale = this.$store.state.language
              }
              this.setBGValue('spmic', '')
              const bgid = this.getBGValue('spmbgid')
              if(bgid !== undefined && bgid !== null && this.isNumeric(bgid) && bgid > 0) {
                this.$store.dispatch('userEnterBetgame', bgid).then(() => {
                  this.$router.push('/feed/dashboard')
                })
              } else {
                this.$router.push('/searchbetgame')
              }
            }).catch(function (error) {
              if (error.response.status === 409) {
                alert(this.$t('errors.email_already_registered'))
              } else {
                alert(this.$t('errors.registration_failed'))
              }
            })
          } else {
            this.emailAlreadyRegistered = true
            this.errors.push(this.$t('errors.email_already_registered'))
          }
        })
      }
    },
    isNullOrUndefined(data) {
      return data === null || data === undefined
    },
    isValidEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !this.emailAlreadyRegistered && re.test(String(email).toLowerCase());
    }
  }
}
</script>

<style lang="scss" scoped>
  .register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 64px);
    padding: 64px 16px 0 16px;
    max-width: 400px;
    margin: 0px auto;

    h1 {
      text-align: center;
    }
    p {
      text-align: center;
      margin-bottom: 26px;
    }
  }
  .forgot-password {
    width: 100%;
    text-align: center;
    color: $colorGreyDark;
    padding: 20px 0;
  
    a {
      font-weight: 500;
      text-decoration: none;
      margin-left: 6px;
      padding: 12px 0;
    }
  
    p {
      margin-bottom: 16px;
    }
  }
  .input-container {
    margin: 16px auto 0;
  }
  button {
    margin-top: 16px;
  }
</style>
