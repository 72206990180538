/* eslint-disable */
<template>
  <div class="tableByBets" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">
  
    <keep-alive>
      <NavigationBar id="tableByBetsNavigationBarId" ref="tableByBetsNavigationBarRef">
        <template v-slot:center>
          {{ $t('menu.league_table_by_bets') }}
        </template>
        <template v-slot:right>
        </template>
      </NavigationBar>
    </keep-alive>
    
    <LeagueTable :positions="positions"></LeagueTable>
    
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LeagueTable from '../components/LeagueTable'
import NavigationBar from './../components/NavigationBar'
import Vue from 'vue'

export default {
  components: { LeagueTable, NavigationBar },
  beforeCreate() {
    Vue.$log.debug('TableByBets beforeCreate')
  },
  created() {
    Vue.$log.debug('TableByBets created')
    if (this.$store.state.teams.length === 0) {
      this.$store.dispatch('fetchTeams', this.$store.state.betgameId)
    }
  },
  mounted() {
    Vue.$log.debug('TableByBets mounted ')
  },
  computed: {
    positions() {
      const result = []
      for (let i = 0; i < this.$store.state.teams.length; i++) {
        const team = this.$store.state.teams[i]
        var teamPoints = 0
        var teamMatchesWon = 0
        var teamMatchesDraw = 0
        var teamMatchesLost = 0
        var teamGoalsScored = 0
        var teamGoalsAgainst = 0
        const teamMatches = this.$store.state.matches.filter(match => match.fk_team_home === team.id || match.fk_team_guest === team.id)
        for (let j = 0; j < teamMatches.length; j++) {
          const match = teamMatches[j]
          if(match.goalshome === undefined || match.goalshome === null || match.goalshome === '' ||
              match.goalsguest === undefined || match.goalsguest === null || match.goalsguest === '') {
            if((match.livegoalshome === undefined || match.livegoalshome === null || match.livegoalshome === '') &&
                (match.livegoalsguest === undefined || match.livegoalsguest === null || match.livegoalsguest === '')) {
              continue
            }
          }
          const bets = this.$store.state.bets.filter(bet => bet.fk_match === match.id &&
              bet.fk_betgame === parseInt(this.$store.state.betgameId) && bet.fk_user === this.$store.state.user.id)
          if (bets && bets.length === 1) {
            const bet = bets[0]
            if (match.fk_team_home === team.id) {
              teamGoalsScored += bet.goalshome
              teamGoalsAgainst += bet.goalsguest
              if (bet.goalshome > bet.goalsguest) {
                teamPoints += 3
                teamMatchesWon += 1
              } else if (bet.goalshome === bet.goalsguest) {
                teamPoints += 1
                teamMatchesDraw += 1
              } else {
                teamMatchesLost += 1
              }
            } else if (match.fk_team_guest === team.id) {
              teamGoalsScored += bet.goalsguest
              teamGoalsAgainst += bet.goalshome
              if (bet.goalsguest > bet.goalshome) {
                teamPoints += 3
                teamMatchesWon += 1
              } else if (bet.goalsguest === bet.goalshome) {
                teamPoints += 1
                teamMatchesDraw += 1
              } else {
                teamMatchesLost += 1
              }
            }
          }
        }
        var pos = {}
        pos.position = 17
        pos.team = team.name
        pos.fk_team = team.id
        pos.fk_competition = 1
        pos.fk_season = 14
        pos.matches_won = teamMatchesWon
        pos.matches_lost = teamMatchesLost
        pos.matches_draw = teamMatchesDraw
        pos.goals_scored = teamGoalsScored
        pos.goals_against = teamGoalsAgainst
        pos.points = teamPoints
        pos.goals_diff = teamGoalsScored - teamGoalsAgainst
        pos.matches_total = teamMatchesWon + teamMatchesDraw + teamMatchesLost
        result.push(pos)
      }
      result.sort((a, b) => {
        if (a.points > b.points) {
          return -1
        } else if (a.points < b.points) {
          return 1
        } else {
          if (a.goals_diff > b.goals_diff) {
            return -1
          } else if (a.goals_diff < b.goals_diff) {
            return 1
          } else {
            if (a.goals_scored > b.goals_scored) {
              return -1
            } else if (a.goals_scored < b.goals_scored) {
              return 1
            } else {
              return 0
            }
          }
        }
      })
      for (let i = 0; i < result.length; i++) {
        result[i].position = i + 1
      }
      return result
    },
  },
  methods: {
    openMenu () {
      this.$modal.show('betgame-menu')
    },
    showSchedule() {
      this.$router.push({ name: 'schedule' })
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
