<template>
    <div class="betgameEditDescription" id="betgameEditDescription">
        
        <NavigationBar>
            <template v-slot:left>
                <img class="back" src="../assets/icons/ic_16_back.svg" v-on:click="goBack">
            </template>
            <template v-slot:center>
              {{ $t('betgame_settings.betgame_description') }}
            </template>
            <template v-slot:right>
            </template>
        </NavigationBar>

        <ul class="error-container">
            <li v-for="error in errors">{{ error }}</li>
        </ul>
        
        <form @submit.prevent="changeBetgameDescription">
          <div class="input-container">
            <div>
              <div class="input-header">
                {{ $t('betgame_settings.betgame_description_title') }}
              </div>
              <div class="input-infotext">
                {{ $t('betgame_settings.betgame_description_text') }}
              </div>
              <div class="input-element">
                  <textarea-autosize
                          placeholder=""
                          ref="betgame_description"
                          v-model="betgame.description"
                          :min-height="30"
                          :max-height="350"
                  />
              </div>
            </div>
            <div class="input-button-row">
              <button name="button" type="submit">
                {{ $t('button_save_text') }}
              </button>
            </div>
          </div>
        </form>
    
    </div>
</template>

<script>
  
  import NavigationBar from './../components/NavigationBar'
  import { TOAST_DURATION } from '../main'
  
  export default {
    name: 'BetgameEditDescription',
    components: { NavigationBar },
    data () {
      return {
        betgame: null,
        errors: []
      }
    },
    created () {
      this.betgame = this.$route.params.betgame
    },
    mounted () {
      if (this.betgame === null || this.betgame === undefined) {
        this.goBack()
      };
    },
    methods: {
      updateBetgame (data) {
        this.$store.dispatch('patchBetgame', data)
        .then(() => {
          this.$toast.open({ message: this.$i18n.t('save_changes_success'),
            position: 'top',
            duration: TOAST_DURATION,
            type: 'success'
          })
          this.goBack()
        })
        .catch(function (error) {
          this.$toast.open({ message: this.$i18n.t('save_changes_error'),
            position: 'top',
            duration: TOAST_DURATION,
            type: 'error'
          })
        })
        .finally(function () {
        })
      },
      goBack () {
        this.$router.push('/betgameedit')
      },
      changeBetgameDescription () {
        this.errors = []
        if (this.betgame.description.length > 999) {
          this.errors.push(this.$i18n.t('betgame_settings.max_characters', { max: 1000 }))
        }
        if (this.errors.length === 0) {
          this.$store.dispatch('setBetgame', this.betgame)
          this.updateBetgame({ description: this.betgame.description })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    .betgameEditDescription {
        margin-top: 16px;
        
        form {
            padding-top: 16px;
        }
        
        h1 {
            text-align: center;
        }
        
        p {
            text-align: center;
            margin-bottom: 26px;
        }
    }
</style>
