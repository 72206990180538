<template>

    <div class="p-top-none" id="specialQuestion">

      <div class="specialquestion-header">
        <div class="specialquestion-header-left">
          <div v-if="new Date(question.editable_until) > new Date()" >
            {{ $t('special_questions.place_bet_until_short') }} {{ question.editable_until | formatDateShort }}
          </div>
          <div v-else>
            <div v-if="this.solutions.length === 0">
              {{ $t('special_questions.place_bet_until') }}
            </div>
            <div>
              {{ $t('special_questions.points_won') }}
            </div>
          </div>
        </div>
        <div class="specialquestion-header-right">
          <div v-if="new Date(question.editable_until) > new Date()" >
              {{ questionTotalPoints }} {{ $t('points') }}
          </div>
          <div v-else>
            <div v-if="this.solutions.length === 0" style="text-align: right">
              {{ question.editable_until | formatDateShort }}
            </div>
            <div style="text-align: right">
              {{ pointsAchieved }}/{{ questionTotalPoints }} {{ $t('rankings_table_header_points') }}
            </div>
          </div>
        </div>
      </div>
      <div class="list">
        
        <!--
        <div v-if="question.answer_count < 2" class="table-view-title" v-bind:class="{ question_answered: answerSaved }">
            {{ question.question }}
        </div>
        <div v-else class="table-view-title" v-bind:class="{ question_answered: answerSaved }">
            {{ question.question }} ({{question.answer_count}} {{ $t('special_questions.answers') }})
        </div>
        -->

        <div v-if="question.answer_count < 2" @click="questionClicked(question)" class="table-view-title" v-bind:class="{ question_answered: answerSaved }">
          {{ question.question }}
        </div>
        <div v-else class="table-view-title" @click="questionClicked(question)" v-bind:class="{ question_answered: answerSaved }">
          {{ question.question }} ({{question.answer_count}} {{ $t('special_questions.answers') }})
        </div>
        
        <div v-if="this.parseMatchdate(this.question.editable_until) > Date.now()" @click="questionClicked(question)" class="table-view-cell">
    
            <multiselect :disabled="!isEditable" @input="multiChanged" v-if="question.answer_count > 0 && question.type === 'TEAM'" v-model="values" :options="options" :multiple="true" :max="question.answer_count" :show-labels="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" :placeholder="$t('please_select')" label="name" track-by="id" :preselect-first="false">
            </multiselect>
    
            <multiselect :disabled="!isEditable" @input="singleChanged" v-else-if="question.answer_count === 1 && question.type === 'NUMBER'" v-model="value" :options="optionsNumber" :searchable="false" :close-on-select="true" :show-labels="false" :placeholder="$t('please_select')"></multiselect>
    
            <multiselect :disabled="!isEditable" @input="singleChanged" v-else-if="question.answer_count === 1 && question.type === 'BOOL'" v-model="value" :options="optionsBool" track-by="name" label="name" :searchable="false" :close-on-select="true" :show-labels="false" :placeholder="$t('please_select')">
                <template slot="singleLabel" slot-scope="{ option }">
                    <span v-if="option && option.val === 1">{{ $t('yes') }}</span>
                    <span v-else-if="option && option.val === 0">{{ $t('no') }}</span>
                    <span v-else></span>
                </template>
            </multiselect>
            
          <!--
            <multiselect :disabled="!isEditable" @input="singleChanged" v-else-if="question.answer_count === 1 && question.type === 'TEAM'" v-model="value" deselect-label="Can't remove this value" track-by="id" label="name" :show-labels="false" :placeholder="$t('please_select')" :options="options" :searchable="false" :allow-empty="true">
                <template slot="singleLabel" slot-scope="{ option }">{{ getTeamFullName(option.id, option.name) }}</template>
            </multiselect>
          -->

        </div>
        
        <div v-else @click="questionClicked(question)">
            <div v-if="question.answer_count === 1 && question.type !== 'TEAM'">
                <div class="table-view-cell" v-bind:class="{ bet_correct: correctAnswer, bet_wrong: wrongAnswer }">
                    <div class="table-view-cell-label">
                      {{ $t('special_questions.your_answer') }}
                    </div>
                    <div v-if="this.value && question.type === 'TEAM'" class="table-view-cell-value">
                        {{ this.value.name }}
                    </div>
                    <div v-else-if="question.type === 'NUMBER'" class="table-view-cell-value">
                        {{ this.value }}
                    </div>
                    <div v-else-if="question.type === 'BOOL' && this.value && this.value.val === 1" class="table-view-cell-value">
                      {{ $t('yes') }}
                    </div>
                    <div v-else-if="question.type === 'BOOL' && this.value && this.value.val === 0" class="table-view-cell-value">
                      {{ $t('no') }}
                    </div>
                </div>
                <div class="table-view-cell">
                    <div class="table-view-cell-label">
                      {{ $t('special_questions.solution') }}
                    </div>
                    <div v-if="solutions.length === 1 && question.type === 'TEAM'" class="table-view-cell-value">
                        {{ getTeamFullName(parseInt(solutions[0].solution), '') }}
                    </div>
                    <div v-else-if="solutions.length === 1 && question.type === 'NUMBER'" class="table-view-cell-value">
                        {{ solutions[0].solution }}
                    </div>
                    <div v-else-if="solutions.length === 1 && question.type === 'BOOL' && solutions[0].solution === '1'" class="table-view-cell-value">
                      {{ $t('yes') }}
                    </div>
                    <div v-else-if="solutions.length === 1 && question.type === 'BOOL' && solutions[0].solution === '0'" class="table-view-cell-value">
                      {{ $t('no') }}
                    </div>
                    <div v-else class="table-view-cell-value">
                      {{ $t('special_questions.solution_pending') }}
                    </div>
                    <!-- <div class="table-view-cell-drilldown"></div> -->
                </div>
            </div>
            <div v-if="question.answer_count > 0 && question.type === 'TEAM'">
                <div v-if="valuesSorted && valuesSorted.length > 0" v-for="val in valuesSorted" v-bind:class="{ bet_correct: val.correctAnswer, bet_wrong: val.wrongAnswer }" class="table-view-cell">
                    <div class="table-view-cell-label">
                      {{ $t('special_questions.your_answer') }}
                    </div>
                    <div class="table-view-cell-value">
                        {{ val.name }}
                    </div>
                </div>
                <div v-if="!valuesSorted || valuesSorted.length === 0" class="table-view-cell bet_wrong">
                    <div class="table-view-cell-label">
                      {{ $t('special_questions.your_answer') }}
                    </div>
                    <div class="table-view-cell-value">
                    </div>
                    <!-- <div class="table-view-cell-drilldown"></div> -->
                </div>
                <div v-if="solutionsSorted && solutionsSorted.length > 0" v-for="sol in solutionsSorted" class="table-view-cell">
                    <div class="table-view-cell-label">
                      {{ $t('special_questions.solution') }}
                    </div>
                    <div class="table-view-cell-value">
                      {{ getTeamFullName(parseInt(sol.solution), '') }}
                    </div>
                    <!-- <div class="table-view-cell-drilldown"></div> -->
                </div>
                <div v-if="!solutionsSorted || solutionsSorted.length === 0" class="table-view-cell">
                  <div class="table-view-cell-label">
                    {{ $t('special_questions.solution') }}
                  </div>
                  <div class="table-view-cell-value">
                    {{ $t('special_questions.solution_pending') }}
                  </div>
                  <!-- <div class="table-view-cell-drilldown"></div> -->
                </div>
            </div>
        </div>
        <div v-if="!questionEditable">
          <button v-on:click="questionClicked(question)" style="margin-top: 8px; margin-bottom: 8px;">{{ $t('special_questions.show_answers') }}</button>
        </div>  
      </div>

    </div>
</template>

<script>
  
  import 'vue-multiselect/dist/vue-multiselect.min.css'
  import Vue from 'vue'
  
  export default {
    name: 'SpecialQuestion',
    props: {
      question: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data () {
      return {
        value: null,
        values: [],
        answerSaved: false
      }
    },
    created () {
      const answers = this.$store.state.answers.filter(s => s.fk_question === this.question.id)
      if(this.question.answer_count === 1) {
        if(this.question.type === 'NUMBER' && answers[0]) {
          this.value = parseInt(answers[0].answer)
        } else if(this.question.type === 'TEAM' && answers[0]) {
          //this.value = this.question.optionsJSON.filter(s => s.id === parseInt(answers[0].answer))[0]
          var temp = []
          for(var i=0; i<answers.length; i++) {
            const val = answers[i]
            const v = this.question.optionsJSON.filter(s => s.id === parseInt(val.answer))[0]
            if(v) {
              temp.push(v)
            } else {
              temp.push({"id":0,"name":this.$i18n.t('special_questions.invalid_selection')})
            }
          }
          this.values = temp
        } else if(this.question.type === 'BOOL' && answers[0]) {
          if(parseInt(answers[0].answer) === 0) {
            this.value = { name: this.$i18n.t('no'), val: 0 }
          } else if(parseInt(answers[0].answer) === 1) {
            this.value = { name: this.$i18n.t('yes'), val: 1 }
          }
        } else {
          this.value = null
        }
      } else if(this.question.answer_count > 1){
        var temp = []
        for(var i=0; i<answers.length; i++) {
          const val = answers[i]
          const v = this.question.optionsJSON.filter(s => s.id === parseInt(val.answer))[0]
          temp.push(v)
        }
        this.values = temp
      }
    },
    mounted () {

    },
    computed: {
      pointsAchieved() {
        var points = 0
        if(this.question.type === 'NUMBER' && this.question.answer_count === 1 && this.solutions.length === 1) {
          points = this.value === parseInt(this.solutions[0].solution) ? this.question.points : 0
        } else if(this.value && this.question.type === 'BOOL' && this.question.answer_count === 1 && this.solutions.length === 1) {
          points = this.value.val === parseInt(this.solutions[0].solution) ? this.question.points : 0
        } else if(this.question.type === 'TEAM' && this.solutions.length > 0) {
          for(var i=0; i<this.valuesSorted.length; i++) {
            if(this.valuesSorted[i].correctAnswer) {
              points += this.question.points
            }
          }
        }
        return points
      },
      questionTotalPoints() {
        return this.question.points * this.question.answer_count
      },
      valuesSorted() {
        var temp = this.values.sort(function(a,b){
          var x = a.name < b.name? -1:1
          return x
        })
        for(var i=0; i<temp.length; i++) {
          temp[i].correctAnswer = this.solutions.length > 0 && this.solutions.filter(s => parseInt(s.solution) === temp[i].id).length > 0
          temp[i].wrongAnswer = this.solutions.length > 0 && !temp[i].correctAnswer
        }
        return temp
      },
      solutionsSorted() {
        return this.solutions
      },
      correctAnswer() {
        if(this.parseMatchdate(this.question.editable_until) > Date.now()) {
          return false
        }
        if(this.solutions.length === 0) {
          return false
        }
        if(this.question.type === 'NUMBER' && this.question.answer_count === 1 && this.solutions.length === 1) {
          return this.value === parseInt(this.solutions[0].solution)
        } else if(this.question.type === 'BOOL' && this.question.answer_count === 1 && this.solutions.length === 1) {
          if(this.value) {
            return this.value.val === parseInt(this.solutions[0].solution)
          } else {
            return false
          }
        } else if(this.question.type === 'TEAM' && this.solutions.length > 0) {
          for(var i=0; i<this.values.length; i++) {
            const aid = this.values[i].id
            var found = false
            for(var j=0; j<this.solutions.length; j++) {
              if(aid === parseInt(this.solutions[j].solution)) {
                found = true
                break
              }
            }
            if(!found) {
              return false
            }
          }
        }
        return true
      },
      wrongAnswer() {
        if(this.parseMatchdate(this.question.editable_until) > Date.now()) {
          return false
        }
        if(this.solutions.length === 0) {
          return false
        }
        if(this.question.type === 'NUMBER' && this.question.answer_count === 1 && this.solutions.length === 1) {
          return this.value !== parseInt(this.solutions[0].solution)
        } else if(this.question.type === 'BOOL' && this.question.answer_count === 1 && this.solutions.length === 1) {
          if(this.value) {
            return this.value.val !== parseInt(this.solutions[0].solution)
          } else {
            return true
          }
        } else if(this.question.type === 'TEAM' && this.solutions.length > 0) {
          for(var i=0; i<this.values.length; i++) {
            const aid = this.values[i].id
            var found = false
            for(var j=0; j<this.solutions.length; j++) {
              if(aid === parseInt(this.solutions[j].solution)) {
                found = true
                break
              }
            }
            if(!found) {
              return true
            }
          }
        }
        return false
      },
      isEditable() {
        return this.solutions.length === 0 && this.parseMatchdate(this.question.editable_until) > Date.now()
      },
      options() {
        return this.question.optionsJSON !== undefined && this.question.optionsJSON !== null ? this.question.optionsJSON : []
      },
      optionsBool() {
        return [
          { name: this.$i18n.t('yes'), val: 1 },
          { name: this.$i18n.t('no'), val: 0 }
        ]
      },
      optionsNumber() {
        const o = this.question.options
        const minmax = o.trim().split(',')
        if(minmax.length === 2) {
          const min = parseInt(minmax[0]) < parseInt(minmax[1]) ? parseInt(minmax[0]) : parseInt(minmax[1])
          const max = parseInt(minmax[0]) < parseInt(minmax[1]) ? parseInt(minmax[1]) : parseInt(minmax[0])
          var result = [max-min]
          if(min !== max) {
            for (var i = min, c = 0; i <= max; i++, c++) {
              result[c] = i;
            }
          }
          return result
        }
        return  []
      },
      solutions () {
        return this.$store.state.solutions.filter(s => s.fk_question === this.question.id)
      },
      questionEditable() {
        return this.question && this.parseMatchdate(this.question.editable_until) > Date.now()
      }
    },
    methods: {
      questionClicked(q) {
        if(this.parseMatchdate(q.editable_until) < Date.now()) {
          this.$router.push({ name: 'specialquestiondetail', params: { question: q } })
        }
      },
      multiChanged() {
        if(this.values.length !== this.question.answer_count) {
          return
        }
        var answers = []
        for(var i=0; i<this.values.length; i++) {
          const val = this.values[i]
          answers.push({
            'fk_user': this.$store.state.user.id,
            'fk_question': this.question.id,
            'answer': parseInt(val.id),
            'fk_betgame': parseInt(this.$store.state.betgameId)
          })
        }
        const that = this
        this.$store.dispatch('updateAnswer', answers).then(response => {
          Vue.$log.debug('SpecialQuestion multiChanged updateAnswer ok')
          this.answerSaved = true
          this.$store.dispatch('fetchUserAnswers', []).then(function (response) {
          })
        }, error => {
          Vue.$log.debug(error.toString())
          alert(that.$i18n.t('special_questions.save_answer_error'))
        })
      },
      singleChanged() {
        var a = this.value.id
        if(this.question.type === 'NUMBER') {
          a = this.value
        } else if(this.value && this.question.type === 'BOOL') {
          a = this.value.val
        }
        const answer = {
          'fk_user': this.$store.state.user.id,
          'fk_question': this.question.id,
          'answer': parseInt(a),
          'fk_betgame': parseInt(this.$store.state.betgameId)
        }
        const that = this
        this.$store.dispatch('updateAnswer', answer).then(response => {
          Vue.$log.debug('SpecialQuestion singleChanged updateAnswer ok')
          this.answerSaved = true
          this.$store.dispatch('fetchUserAnswers', []).then(function (response) {
          })
        }, error => {
          Vue.$log.debug(error.toString())
          alert(that.$i18n.t('special_questions.save_answer_error'))
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
    .list {
        position: relative;
        padding: 4px 16px;
        margin-bottom: 24px;
    }
    
    .table-view-header-small {
        display: flex;
        flex-direction: column;
        margin-bottom: 0px;
        display: flex;
        flex-direction: row;
        font-size: 12px;
        font-weight: 300;
        color: $colorGreyMid;
        padding: 8px 0 0px;
        border-bottom: none;
    }

    .table-view-cell:last-child {
      border-bottom: 0;
    }

    .table-view-cell {
      position: relative;

      &-label, &-value {
        position: relative;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -16px;
        width: calc(100% + 32px);
        height: calc(100% + 1px);
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        background: rgba($colorGreyLight, .08);
        width: 100%;
        height: 1px;
        opacity: 0
      }
    }

    .bet_correct {
      &:before {
        background: #EEFDF5;
      }
      &:after {
        opacity: 1;
      }
    }

    .bet_wrong {
      &:before {
        background: #FFF1F0;
      }
      &:after {
        opacity: 1;
      }
    }

    .specialquestion-header {
      display: flex;
      font-size: 14px;
      color: $colorGreyMid;
      margin: -3px 0 8px;

      &-left {
        margin-right: auto;
      }
    }

    .question_answered {
      position: relative;

      &:after {
        z-index: 2;
        content: 'Gespeichert';
        position: absolute;
        left: 2px;
        bottom: -2px;
        color: $colorPositive;
        padding-left: 8px;
        font-size: 10px;
        background: url(../assets/icons/ic_bet_saved.svg) no-repeat;
        background-position: left;
      }
    }
</style>
