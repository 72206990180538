<template>
  <div class="matchdaywinner">
    <div class="dashboard-image">
      <span>{{ matchdayWins.length }}</span>
      <img src="../assets/achievements/ic_140_achievement_spieltagssieg.svg">
    </div>
    <div class="dashboard-text">
      <h1>{{ $t('dashboard.matchday_winnner_title') }}</h1>
      <p v-if="matchdayWins.length > 1">{{ $t('dashboard.matchday_winnner_text', { count: matchdayWins.length }) }}</p>
      <p v-else-if="matchdayWins.length === 1">{{ $t('dashboard.matchday_winnner_first_time_text') }}</p>
      <router-link :to="{name: 'rankingsbymatchday', params: { matchday: nextMatch.matchday - 1, push: this.pushParameter }}">
        <button id="view_results">{{ $t('dashboard.button_view_results') }}</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MatchdayWinner',
  components: {},
  data () {
    return {
      pushParameter: ''
    }
  },
  beforeCreate () {
  },
  created () {
  },
  mounted () {
  },
  computed: {
    matchdayWins () {
      if (this.$store.state.user) {
        return this.$store.getters.getMatchdayWins(this.$store.state.user.id)
      }
      return []
    },
    nextMatch () {
      if (this.$store.state.matches.length > 0) {
        return this.$store.getters.getClosestFutureMatch
      } else {
        if (this.$store.state.leagueId) {
          this.$store.dispatch('fetchSchedule', this.$store.state.leagueId)
        } else {
          if (this.getBGValue('spmbgid') && parseInt(this.getBGValue('spmbgid')) > 0) {
            this.$store.dispatch('fetchBetgameLeague', this.getBGValue('spmbgid'))
          }
        }
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss">
.matchdaywinner {
  background: $colorWhite;
  box-shadow: 0 0 1px 0 rgba($colorGreyDark, .08), 0px 4px 16px rgba($colorGreyDark, 0.08);
  border-radius: 8px;
  width: 100%;
  margin-bottom: 24px;
}
</style>
