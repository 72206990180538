<template>
  <div class="chat__answer" v-on:click="answerClicked">
    <span>{{ username }}</span>
    <p>{{ text }}</p>
    <div v-if="closeable" v-on:click="answerClosed" class="chat__answer__close"></div>
  </div>
</template>

<script>
  	export default {
	    name: 'ChatAnswer',
	    props: {
        username: String,
        text: String,
        closeable: Boolean
	    },
    	data () {
 			return {}
    	},
    	beforeCreate () {
    	},
    	methods: {
        answerClicked() {
          this.$emit('answerClicked', {  })
        },
        answerClosed(emoji) {
          this.showAllEmojis = false
          this.$emit('answerClosed', { } )
        },
      },
    	computed: {}
  	}
</script>

<style lang="scss">
  .chat__answer {
    display: flex;
    flex-direction: column;
    position: relative;
    display: flex;
    margin: 8px -8px 8px -8px;
    background: rgba(var(--color-primary-rgb), .08);
    padding: 12px 8px 10px 16px;
    border-radius: 4px;
    overflow: hidden;
    transition: all .2s ease-in-out;
    cursor: pointer;

    &:hover {
      background: rgba(var(--color-primary-rgb), .12);
      cursor: pointer;
    }

    /* If displayed inside input field */
    .chat__input & {
      border-radius: 12px;
      margin: 0 12px 12px;
      z-index: 2;
    }

    /* If displayed inside chatitem from sender */
    .chat__item--sender & {
      background: rgba($colorWhite, .24);

      &:before {
        background: $colorWhite;
      }
    }

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 3px;
      height: 100%;
      background: var(--color-primary);
    }

    &__close {
      width: 32px;
      height: 32px;
      top: 4px;
      right: 4px;
      position: absolute;
      cursor: pointer;

      &:hover {
        &:before, &:after {
          background: #000;
        }
      }

      &:before, &:after {
        position: absolute;
        content: '';
        top: calc(50% - .75px);
        left: calc(50% - 6px);
        width: 12px;
        height: 1.5px;
        transform: rotate(45deg);
        background: $colorGreyDark;
        transition: all .2s ease-in-out;
      }

      &:before {
        transform: rotate(-45deg);
      }
    }

    span {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: var(--color-primary);
      margin-bottom: 2px;

      /* If displayed inside chatitem from sender */
      .chat__item--sender & {
        color: $colorWhite;
      }
    }

    p {
      font-size: 14px !important;
      line-height: 18px !important;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;  
      overflow: hidden;
    }
  }
</style>